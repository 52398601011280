import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Injectable, Injector, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataStateChangeEventArgs, GridComponent, InfiniteScrollService, InfiniteScrollSettingsModel, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';


export interface paramsSearch{
  api           : string;
  sizePage      : number;
  width         : string;
  height        : string;
  keyCode?      : string;
}
export interface keyboard {
  key:string;
}

@Component({
  selector: 'app-search2',
  templateUrl: './search2.component.html',
  styleUrls: ['./search2.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [InfiniteScrollService]
})
export class Search2Component implements OnInit, OnDestroy {

  ngUnsubscribe = new Subject();
  private subscription: Subscription;

  //public params: paramsSearch;
  @ViewChild('idInput',{static: false}) input: ElementRef;

  @ViewChild('grid') public grid: GridComponent;
  //@Input() params : paramsSearch;
  //@Input() columns : object;
  //@Output() loadData = new EventEmitter<object>();
  //@Output() afterClose = new EventEmitter<object>();

  private ObjSubjectRx$: Subject<any> = new Subject<any>();

  //@Input() dialogInstance: MatDialogRef<SearchComponent, any>
  public params : paramsSearch;
  public columns : object;
  //public Config:any;

  public setFocus: any;
  //@Input() data : object;
  public data : object;

  public pageSettings: Object;
  public infiniteOptions: InfiniteScrollSettingsModel;
  
  public state: DataStateChangeEventArgs;
  public filter : string='';
  public title:string="Buscar..";
  public rowSelect:any;
  public heightAG : string;
  public selectionOptions : SelectionSettingsModel;
  public textDefault: string;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogConfig : any,
              public dialogRef: MatDialogRef<Search2Component>)
    {

    }


  ngOnInit(): void {
      //console.log(this.dialogConfig);
      this.params=this.dialogConfig.params;
      this.columns=this.dialogConfig.columns;
      this.selectionOptions = { mode: 'Both' }; //Cell //Both
      this.heightAG='calc('+this.params.height+' - 205px)';

      this.infiniteOptions = { enableCache: true };
      this.pageSettings = {
        pageSize: this.params.sizePage,
      };
      this.state = { skip: 1, take: this.params.sizePage };
      this.filter='';
      //console.log(this.params);
      this.textDefault="";
      if (this.params.keyCode){
        //let inputElm = this.input.nativeElement;
        //inputElm.value=this.params.keyCode;
        this.textDefault=this.params.keyCode;
        this.filter=this.params.keyCode;
      }
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
      this.state=state;
      let changes={
        state: state,
        filter: this.filter
      }
      this.ObjSubjectRx$.next(changes);
  }
  public getData(): Observable<any>{
    return this.ObjSubjectRx$.asObservable();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    let time=500;
    let inputElm = this.input.nativeElement;
    fromEvent(inputElm, 'keyup')
      .pipe(debounceTime(time))
      .subscribe((response) => {
        let res = <keyboard> response;
        if (res){
          if (res.key!='ArrowDown' && res.key!='Escape' && res.key!='Tab'){
            this.onFilterData();
          }
        }
     });
     let changes={
      state: this.state,
      filter: this.filter
    }
    this.ObjSubjectRx$.next(changes);
  }

  onKeydown(event,value){
    //console.log(event);
    if (event.key=='ArrowDown'){
      //this.grid.
      window.setTimeout(() => {
        //this.grid.focu.focus();
        //console.log(event);
        let cell  = {
          rowIndex: 1,
          cellIndex: 1
        }
        //this.grid.selectCell(cell);
        this.grid.selectRow(0); 
        //this.grid.cur.CurrentCell.MoveTo(GridRangeInfo.Cell(2, 2));
        //this.grid.set
      }, 0);
    }
  }
  keyPressed(e) {
    //console.log(e);
    if (e.key === 'Enter') {
      e.cancel = true;
      this.onAceptar();
    }
  }
  /*
  onDoubleClick(args: any): void{
    this.setFocus = args.column;
  }
  */

  onRecordDoubleClick(event){
    //console.log(event);
    let cIndex=event.cellIndex;
    const field: string = this.grid.getColumns()[cIndex].field;
    this.onAceptar();
  }

  onFilterData(){
    let inputElm = this.input.nativeElement;
    this.filter=inputElm.value;
    this.state.skip=1;
    let changes={
      state: this.state,
      filter: this.filter
    }
    //this.loadData.emit(changes);
    this.ObjSubjectRx$.next(changes);
  }
  rowSelected(event){
    //console.log(event);
    if (event.data){
      this.rowSelect=event.data;
    }
  }
  onAceptar(){
    this.dialogRef.close(this.rowSelect);
  }
  onClose(){
    this.dialogRef.close(null);
  }

  onScroll(event) {
    console.log(event)
  }

}
