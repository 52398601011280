import { Injectable, Injector } from '@angular/core';
import { Contact, PagedResult, Fact, Customer } from '../../models';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { CustomerResourceParameters } from '../../helpers';
import { HttpParams } from '@angular/common/http';
import { LocationAddress } from '../../models/LocationAddress';

@Injectable({
  providedIn: 'root'
})
export class CustomersService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }

  getCustomersByName(searchText: string): Observable<Contact[]> {
    const url = this.getApiQuery(`Customer/Contacts/Search?searchText=${searchText}`);
    return this._httpClient.get<Contact[]>(url, { headers: this.getAuthorizationHeader() });
    // return this._httpClient.get<Contact[]>(url);
  }

  getCustomerNotes(searchText: string) {

    const url = this.getApiQuery(`Customer/GetCustomerNotesById?ID=${searchText}`);
    console.log(url);
    return this._httpClient.get(url, { headers: this.getAuthorizationHeader() });
  }

  getCustomersById(searchText: string) {
    const url = this.getApiQuery(`Customer/Contact?ID=${searchText}`);
    return this._httpClient.get(url, { headers: this.getAuthorizationHeader() });
  }

  getContactById(searchText: any) {
    const url = this.getApiQuery(`Customer/GetContactFullById?ID=${searchText}`);
    return this._httpClient.get(url, { headers: this.getAuthorizationHeader() });
  }

  getCustomersByTaxId(searchText: string) {
    const url = this.getApiQuery(`Customer/Contact?TaxID=${searchText}`);
    return this._httpClient.get(url, { headers: this.getAuthorizationHeader() });

  }
  getCustomersByTaxId2(TaxID: string) {
    let params = new HttpParams();
    if (TaxID) { params = params.append('TaxID', TaxID)};
    //const url = this.getApiQuery(`Customer/CustomerIdentity?id=${TaxID}`);
    const url = this.getApiQuery(`Customer/ContactByTaxID`);
    return this._httpClient.get(url,
      {
        params: params,
        headers: this.getAuthorizationHeader()
      });
  }
  

  getTaxId(searchText: string) {
    const url = this.getApiQuery(`Customer/GetNameByTaxID?TaxID=${searchText}`);
    return this._httpClient.get(url, { headers: this.getAuthorizationHeader() });

  }

  getCustomers(args: CustomerResourceParameters): Observable<PagedResult<Contact>> {
    const url = this.getApiQuery('Customer/Pagging');
    let params = new HttpParams();
    if (args.SearchTerm) {
      params = params.append('SearchTerm', args.SearchTerm);
    }
    params = params.append('PageNumber', args.PageNumber.toString());
    params = params.append('PageSize', args.PageSize.toString());
    return this._httpClient.get<PagedResult<Contact>>(url, { headers: this.getAuthorizationHeader(), params: params });
  }

  createCustomer(contact: Contact) {
    const url = this.getApiQuery('Customer/InsertCustomer');
    return this._httpClient.post(url, contact, { headers: this.getAuthorizationHeader() });
  }

  updateCustomer(contact: Contact) {
    const url = this.getApiQuery('Customer/UpdateCustomerLocation');
    return this._httpClient.post(url, contact, { headers: this.getAuthorizationHeader() });
  }

  insertInvoice(fact: Fact) {
    const url = this.getApiQuery('Customer/InsertFactura');
    return this._httpClient.post(url, fact, { headers: this.getAuthorizationHeader() });
  }

  getLocationAddress(Lat: number, Long: number) {
    const url = 'http://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&featureTypes=&location='.concat(String(Long),',',String(Lat));
    return this._httpClient.get<LocationAddress>(url);
  }



  createRecord(customer: Customer) {
    //http://FE-NET.NET:8700/api/CustomerService/SaveCustomer
    const url = this.getApiQuery('CustomerService/SaveCustomer');
    return this._httpClient.post(url, customer, { headers: this.getAuthorizationHeader() });
  }

  updateRecord(customer: Customer) {
    //const url = this.getApiQuery('Customer/InsertCustomer');
    const url = this.getApiQuery('CustomerService/SaveCustomer');
    return this._httpClient.post(url, customer, { headers: this.getAuthorizationHeader() });
  }


  getCountry() {
    let params = new HttpParams();
    //if (TaxID) { params = params.append('TaxID', TaxID)};
    const url = this.getApiQuery(`RRHH/GetCountry`);
    return this._httpClient.get(url,
      {
        //params: params,
        headers: this.getAuthorizationHeader()
      });
  }

}
