import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BankempService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }

  getBanckEmp() {
    //let params: HttpParams = new HttpParams();
    //if (DocType) { params = params.append('DocType',DocType); }
    const url = this.getApiQuery(`/ControlEditor/GetEmp1_P01_BANCO`);
    return this._httpClient.get(url, { 
      //params: params,
      headers: this.getAuthorizationHeader() 
    });
  }

}
