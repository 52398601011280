import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class CatalogComponent<T> implements OnInit {

  @Input() pageTitle: string;
  @Input() dataSource: T[];
  @Input() tableHeaders: string[][];
  apiRoute: string;
  buttons: RowButton[];

  constructor() { }

  ngOnInit() {
  }

  trackById(index, item) {
    return index;
  }

}

export class RowButton {
  icon?: string;
  title?: string;
  tooltip?: string;
  tooltipPlacement: 'bottom' | 'top';
  isVisible: boolean;
  fn: () => void;
}
