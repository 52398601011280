import { Phone } from './Sales/Phone';

export class Enterprise {
    constructor () {
        this.Phones = [];
    }

    Name: string;
    RUC: string;
    Address: string;
    Phones: Phone[];
}
