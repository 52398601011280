<page-heading pageTitle="{{ titleComponent | translate}}">
</page-heading>
<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-4">
      <board-card [data]="dataCard_Ven">
      </board-card>
    </div>
    <div class="col-lg-4">
      <board-card [data]="dataCard_Ven">
      </board-card>
    </div>
    <div class="col-lg-4">
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <board-chartbar [data]="dataBars_Ven">
      </board-chartbar>
    </div>
    <div class="col-md-6">
      <board-chartpie [data]="dataPie_Ven">
      </board-chartpie>
    </div>
  </div>
  <div class="row">
    <span></span>
  </div>
  <div class="row">
    <div class="col-md-12">
      <board-chartline [data]="dataLine_Ven">
      </board-chartline>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <board-table [data]="dataTable_Ven">
      </board-table>
    </div>
  </div>
</div>