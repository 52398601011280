<page-heading pageTitle="{{documentType}}"
    [showBack]="!isTabs"
    (clickBack)="onBack()">
    <div *ngIf="document?.DocumentItems?.length > 0" class="d-flex">
        <button
            class="btn btn-white btn-action bouton-image monBouton"
            (click)="onVerRide()"
            type="submit" tooltip="{{ 'RIDE' | translate }}" placement="bottom">
        </button>

        <button
            class="btn btn-white btn-action"
            (click)="onViewerText()"
            type="submit" tooltip="{{ 'Preview' | translate }}" placement="bottom">
            <i class="ti-zoom-in"></i>
        </button>

        <button
            class="btn btn-white btn-action"
            (click)="PrintPOS()"
            type="submit" tooltip="{{ 'Print' | translate }}" placement="bottom">
            <i class="ti-printer"></i>
        </button>
    </div>
</page-heading>

<div class="row">
    <div class="col-lg-12">
        <div class="wrapper wrapper-content animated fadeIn">
            <div class="ibox-content p-xl invoice-box" id='printable'>
                <div class="row border-bottom invoice-header">
                    <!-- <div class="col-sm-6"></div> -->
                    <div class="col-sm-12 text-center">
                        <p class="company-name">
                            <strong>{{document?.Enterprise?.Name}}</strong>
                        </p>
                        <address>
                            <i class="ti-location-arrow"></i> {{document?.Enterprise?.Address}}
                            <p><i class="ti-mobile"></i> {{document?.Enterprise?.Phones[0]?.Number}}</p>
                        </address>

                        <!-- No Factura -->
                        <!-- <div *ngIf="!isQuotation else quotationNumber">
                            <h4>{{'InvoiceNumber' | translate}}</h4>
                            <h4 class="text-navy">{{document?.Serie}}</h4>
                        </div>
                        <ng-template #quotationNumber>
                            <div>
                                <h4>{{'QuotationNumber' | translate}} {{document.Id}}</h4>
                            </div>
                        </ng-template> -->
                        <!-- Factura To -->
                        <!-- <span>To:</span>
                        <address>
                            <strong>{{document?.Customer?.Name}}</strong><br>
                            <i class="fa fa-map-marker"></i> {{document?.Customer?.Address}}<br>
                            <p *ngIf="document?.Customer?.Phones.length > 0"><strong>{{'Phone' | translate}}:</strong>
                                {{document?.Customer?.Phones[0].Number}}</p>
                        </address> -->
                        <!-- Dates -->
                        <!-- <p>
                            <span *ngIf="!isQuotation else quotationDate"><strong>Invoice Date:</strong>
                                {{document?.Fecha | date:'MMMM dd, yyyy'}}</span><br>
                            <ng-template #quotationDate>
                                <span><strong>Quotation Date:</strong>
                                    {{document?.Fecha | date:'MMMM dd, yyyy'}}</span><br>
                            </ng-template>
                            <span><strong>Due Date:</strong> {{document?.Fecha | date:'MMMM dd, yyyy'}}</span>
                        </p> -->
                    </div>
                </div>

                <div class="row invoice-header-data">
                    <div class="col-md-5">
                        <p class="label-data">
                            {{'Client' | translate}}
                        </p>
                        <address>
                            <span>{{document?.Customer?.Name  | titlecase}}</span><br>
                            {{document?.Customer?.Address  | titlecase}}<br>
                            <p *ngIf="document?.Customer?.Phones.length > 0">
                                <strong>{{'Phone' | translate}}:</strong>
                                {{document?.Customer?.Phones[0].Number}}
                            </p>
                            <p *ngIf="document?.NombVendedor">
                              <strong>{{'Salesmam' | translate}}:</strong>
                              {{document?.NombVendedor}}
                          </p>
                        </address>
                    </div>
                    <div class="col-md-2">
                        <div *ngIf="!isQuotation else quotationNumber">
                            <p class="label-data">
                                {{'InvoiceNumber' | translate}}
                            </p>
                            <span>{{document?.Serie}}</span>
                        </div>
                        <ng-template #quotationNumber>
                            <p class="label-data">
                                {{'QuotationNumber' | translate}}
                            </p>
                            <span> {{document.Id}}</span>
                        </ng-template>
                    </div>
                    <div class="col-md-3">
                        <p class="label-data">
                            {{'Date' | translate}}
                        </p>
                        <div>
                            <span *ngIf="!isQuotation else quotationDate">
                                {{document?.Fecha | date:'MMMM dd, yyyy'}}
                            </span><br>
                            <ng-template #quotationDate>
                                <span>
                                    {{document?.Fecha | date:'MMMM dd, yyyy'}}
                                </span><br>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-md-2">
                          <p class="label-data">
                              {{'State' | translate}}
                          </p>
                          <span> {{document.Aprobada ? 'Aprobada' : 'No Aprobada'}}</span>
                  </div>

                </div>

                <div *ngIf="isQuotation else isInvoice">
                    <div class="table-responsive m-t">
                        <table class="table invoice-table">
                            <thead>
                                <tr>
                                    <th>{{'Item' | translate}}</th>
                                    <th>{{'Quantity'| translate}}</th>
                                    <th>{{'UnitPrice' | translate}}</th>
                                    <th>{{'PackingUnit' | translate}}</th>
                                    <th>{{'Discount' | translate}}</th>
                                    <th>{{'TotalPrice' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of document?.DocumentItems">
                                    <td>
                                        <div>{{item?.Description | titlecase}}</div>
                                        <small>{{item?.Detail | titlecase}}</small>
                                    </td>
                                    <td>{{item?.Quantity}}</td>
                                    <td>{{item?.Price | currency:'USD':'symbol':'1.2-4'}}</td>
                                    <td>{{item?.PackagingType}}</td>
                                    <td>{{item?.Discount}}%</td>
                                    <td>{{getItemQuotationTotal(item) | currency:'USD':'symbol':'1.2-3'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div><!-- /table-responsive -->

                    <table class="table invoice-total">
                        <tbody>
                            <tr>
                                <td><strong>{{'Total' | translate}} :</strong></td>
                                <td>{{ subtotal }}</td>
                            </tr>
                            <tr>
                              <td><strong>{{'Tax'| translate}} :</strong></td>
                              <td>{{ tax }}</td>
                          </tr>
                          <tr>
                              <td><strong>{{'Total' | translate}} :</strong></td>
                              <td>{{ total }}</td>
                          </tr>
                        </tbody>
                    </table>
                </div>

                <ng-template #isInvoice>
                    <div class="table-responsive m-t" style="border: none;">
                        <table class="table invoice-table">
                            <thead>
                                <tr>
                                    <th>{{'Item'| translate}}</th>
                                    <th>{{'Quantity'| translate}}</th>
                                    <th>{{'UnitPrice' | translate}}</th>
                                    <th>{{'PackingUnit' | translate}}</th>
                                    <th>{{'Discount' | translate}}</th>
                                    <th>{{'Tax'| translate}}</th>
                                    <th>{{'TotalPrice' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of document?.DocumentItems">
                                    <td>
                                        <div> {{item?.Description | titlecase}} </div>
                                        <small>{{item?.Detail | titlecase}}</small>
                                    </td>
                                    <td>{{getItemQty(item)}}</td>
                                    <td>{{getItemPrice(item) | currency:'USD':'symbol':'1.2-3'}}</td>
                                    <td>{{item?.PackagingType}}</td>
                                    <td>{{getItemDiscountValue(item)   | currency:'USD':'symbol':'1.2-3'}}</td>
                                    <td>{{getItemTaxValue(item)   | currency:'USD':'symbol':'1.2-3'}}</td>
                                    <td>{{getItemTotal(item) | currency:'USD':'symbol':'1.2-3'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div><!-- /table-responsive -->

                    <table class="table invoice-total">
                        <tbody>
                            <tr>
                                <td><strong>{{'Subtotal' | translate}} :</strong></td>
                                <td>{{ subtotal }}</td>
                            </tr>
                            <tr>
                                <td><strong>{{'Discount'| translate}} :</strong></td>
                                <td>{{ discount }}</td>
                            </tr>
                            <tr>
                                <td><strong>{{'Tax'| translate}} :</strong></td>
                                <td>{{ tax }}</td>
                            </tr>
                            <tr>
                                <td><strong>{{'Total' | translate}} :</strong></td>
                                <td>{{ total }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="text-right" *ngIf="!isQuotation">
                        <button class="btn btn-primary"><i class="fa fa-dollar"></i> {{'MakePayment'| translate}}</button>
                    </div> -->
                </ng-template>
            </div>
        </div>
    </div>
</div>
