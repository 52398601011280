import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class CreditcardsService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }


  getCreditCardList() {
    const url = this.getApiQuery(`POS/CreditCards`);
    return this._httpClient.get(url, { 
      headers: this.getAuthorizationHeader() 
    });
  }

  getCardPlan(valor:string){
    let params: HttpParams = new HttpParams();
    params = params.append('valor',valor); 
    const url = this.getApiQuery(`Invoices/getcreditcardplan`);
    return this._httpClient.get(url, { 
      params: params,
      headers: this.getAuthorizationHeader() 
    });
  }

  getEmisorCard(){
    let params: HttpParams = new HttpParams();
    //params = params.append('valor',valor); 
    const url = this.getApiQuery(`Invoices/getemisorcard`);
    return this._httpClient.get(url, { 
      //params: params,
      headers: this.getAuthorizationHeader() 
    });

  }
}
