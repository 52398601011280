import { Component, OnInit, Input, OnDestroy, ViewChild, Injector, Injectable } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { LocationcustomersService, SweetAlertService, PopModalService, DataService, NavigationService, SessionService } from '../../../services';

import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { Subject, Subscription, Observable } from 'rxjs';


import { Location }  from '@angular/common'
import { prmsCard }  from './board-card/board-card.component';
import { prmsBars }  from './board-chartbar/board-chartbar.component';
import { prmsLine }  from './board-chartline/board-chartline.component';
import { prmsPie }   from './board-chartpie/board-chartpie.component';
import { prmsTable } from './board-table/board-table.component';



@Component({
  selector: 'board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css'],
})
export class BoardComponent implements OnInit, OnDestroy {

  ngUnsubscribe = new Subject();
  private subscription: Subscription;
  titleComponent: string;

  public dataCard_Ven  : prmsCard;
  public dataBars_Ven  : prmsBars;
  public dataLine_Ven  : prmsLine;
  public dataPie_Ven   : prmsPie;
  public dataTable_Ven : prmsTable;

  constructor(private _formBuilder: FormBuilder,
    private _translateService: TranslateService,
    private location: Location,
    ) {
  }
  ngOnInit() {
    this.titleComponent="Sales";
    this.loadDataCard();
  }

  ngAfterViewInit(): void {
  }
  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }

  loadDataCard(){

    /*-------------------Data para Card--------------*/
    this.dataCard_Ven={
      title     : 'Ventas',
      subtitle  : 'Total de ventas',
      value     : 1892.92,
      button    : 'Mensuales',
      percent   : 86
    }

    /*-----------------Data para Chart Bars-----------*/
    this.dataBars_Ven={
      title     : 'Ventas',
      subtitle  : '',
      barChartData: [
        { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
        { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
      ],
      barChartLabels: [ '2006', '2007', '2008', '2009', '2010', '2011', '2012' ]
    }

    /*-----------------Data para Chart Line-----------*/
    this.dataLine_Ven={
      title     : 'Ventas',
      subtitle  : '',
      lineChartData: [
        { data: [85, 72, 78, 75, 77, 75], label: 'Crude oil prices' },      
      ],
      lineChartLabels: ['January', 'February', 'March', 'April', 'May', 'June']
    }

    /*-----------------Data para Chart Pie-----------*/
    this.dataPie_Ven={
      title     : 'Ventas',
      subtitle  : '',
      pieChartData: [30, 50, 20],
      pieChartLabels: ['SciFi', 'Drama', 'Comedy']
    }

    /*-----------------Data para Table-Grids-----------*/
    this.dataTable_Ven={
      title     : 'Ventas',
      subtitle  : 'Total de ventas',
      columns   : [
        { field: "Code",     headerText: "Code",       width: "40" },
        { field: "Name",     headerText: "Nombres",    width: "100" }
      ],
      data: [
        {Code:'921',Name:'Perico de los palotes'},
        {Code:'922',Name:'Hola Mundo'}
      ]
    }


  }


}
