import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CustomersService,SessionService,SweetAlertService } from '../../../../services';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { enableRipple } from '@syncfusion/ej2-base';
import { takeUntil} from 'rxjs/operators';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

export interface TypeTipo{
  id:number;
  name:string;
}

export interface prmsTable {
  title     : string;
  subtitle  : string;
  columns   : any;
  data      : any;
}

enableRipple(false);

@Component({
  selector: 'board-table',
  templateUrl: './board-table.component.html',
  styleUrls: ['./board-table.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BoardtableComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  public pageSettings: Object;
  public customAttributes: Object;

  @Input() data : prmsTable;
  public columns: any;
  public dataGrid: Array<any> = [];

  @ViewChild('grid')  public grid: GridComponent;

  constructor(private _formBuilder: FormBuilder){
    //console.log(this.data);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    //console.log(this.data);
    this.pageSettings = {
      pageSize: 20,
      pageCount: 3
    };
    this.customAttributes = {class: 'customcss'};
    //this.refresGrid(1,this.PageSize);
    this.columns=this.data.columns;


    this.refreshWidth(this.data.columns);

    this.dataGrid=this.data.data;

    
    //this.grid.refresh();
  }
  refreshWidth(columns){
    this.columns=columns;
    for(let i of this.columns){
      i.width=i.width*1.9;
    }
      //console.log(this.columns);

      window.setTimeout(() => {
        if (this.grid.columns){
        var Xcolumns = this.grid.columns;
        for (var i = 0; i < Xcolumns.length; i++) {
          //var item: any = Object.assign({}, this.grid.columns[i]);
          var item = this.grid.columns[i];
          //console.log(item);
          item['width'] = this.columns[i].width;
          if (this.columns[i].width>100){
            item['minWidth'] = this.columns[i].width;
          }
          item['maxWidth'] = this.columns[i].width;
        }
        this.grid.refreshColumns();
      }
      }, 100);
      
  }
  refresh(data:any){
    this.refreshWidth(data.columns);
    this.dataGrid=data.data;
    //console.log(this.data);
    if (this.dataGrid){
      this.grid.refresh();
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


}
