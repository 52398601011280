import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Injectable, Injector, Input, Output, EventEmitter, Inject, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataStateChangeEventArgs, GridComponent, InfiniteScrollService, InfiniteScrollSettingsModel, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { HostListener } from '@angular/core';
import { BaseService } from '../../../services/base/base.service';
import { HttpParams } from '@angular/common/http';

export interface apiInfo {
  api       : string;
  parameter : string;
  field     : string;
}
export interface prmsFilter {
  field     : string;
  value     :string;
}

export interface paramsSearch{
  api               : string;
  sizePage          : number;
  width             : string;
  height            : string;
  keyCode?          : string;
  apiInfo?          : apiInfo;
  aditionalFilters? : object;
}
export interface keyboard {
  key:string;
}
export interface tpData {
  result:Array<any>,
  count:number,
  HasNextPage:boolean
}

@Injectable({
  providedIn: 'root'
})
export class ListgridService extends BaseService {
  constructor(private _injector: Injector) {
    super(_injector);
  }

  public getDataAPi(skip: any,url:string,filter? : string,prmsFilter?:object)  {
    //let Ini= Math.floor((skip/20));
    //Ini++;

    let params: HttpParams = new HttpParams();
    //if (filter)     { params = params.append('SearchTerm',filter); }
    //params = params.append('PageNumber',skip.toString());
    //params = params.append('PageSize','20'); 
    //console.log(prmsFilter);
    if (prmsFilter){
      for (let ifield in prmsFilter){
        params = params.append(ifield ,prmsFilter[ifield]);
      }
    }

    url = this.getApiQuery(url);
    return this._httpClient.get(url, {headers: this.getAuthorizationHeader(), params: params}).pipe(map((data: any) => {		
        return data;
    }))
  }

  public getDataInfo(url:string,parameter:string,field : string)  {

    let params: HttpParams = new HttpParams();
    params = params.append(parameter,field); 
    url = this.getApiQuery(url);
    return this._httpClient.get(url, {headers: this.getAuthorizationHeader(), params: params}).pipe(map((data: any) => {		
        return data;
    }))
  }

}

@Component({
  selector: 'app-listGrid',
  templateUrl: './listGrid.component.html',
  styleUrls: ['./listGrid.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  
})
export class listGridComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();

  @ViewChild('grid') public grid: GridComponent;

  public dialogPopover;
  @ViewChild('tplPopover',{static: false}) tplPopover: TemplateRef<any>;


  public params : paramsSearch;
  public columns : object;

  public setFocus: any;
  public data : tpData;
  public pageSettings: Object;
  public filter : string='';
  public title:string="Seleccionar..";
  public rowSelect:any;
  public heightAG : string;
  public selectionOptions : SelectionSettingsModel;
  public textDefault: string;
  public scroll: any;
  public isLoading:boolean=false;
  public selRow:number;
  public isKey:boolean;
  public skip:number;
  public InfoHtml : any;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogConfig : any,
              private _data: ListgridService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<listGridComponent>)
    {

    }


  ngOnInit(): void {
      //console.log(this.dialogConfig);
      this.params=this.dialogConfig.params;
      this.columns=this.dialogConfig.columns;
      this.selectionOptions = { mode: 'Both' }; //Cell //Both
      this.heightAG='calc('+this.params.height+' - 165px)';

      this.pageSettings = {
        pageSize: this.params.sizePage,
      };
      this.filter='';
      this.textDefault="";
      if (this.params.keyCode){
        this.textDefault=this.params.keyCode;
        this.filter=this.params.keyCode;
      }
      
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    this.skip=1;
    this.getData(this.skip,this.params.api,this.filter,this.params.aditionalFilters);
  }

  public getData(page: any,url:string,filter? : string,prmsFilter?:object){ 
    const take = 20;
    const skip = page;
    this._data.getDataAPi(skip,url,filter,prmsFilter).subscribe((response:any) => {
      if (response != null) {
          let dataAll:Array<any>=[];
          if (response.hasOwnProperty("Result")){
            dataAll=response['Result'];
          }else{
            dataAll=response;
          }
          let data = {
            result: dataAll ,// response['Result'],
            count: parseInt(response['TotalRows'], 10),
            HasNextPage: response.HasNextPage
          }
          this.data=data;
      }
    });
  }

  onKeydown(event,value){
    //console.log(event);
    if (event.key=='ArrowDown'){
      window.setTimeout(() => {
        this.grid.selectRow(0); 
      }, 0);
    }
  }
  keyPressed(e) {
    if (e.key === 'Enter') {
      e.cancel = true;
      this.onAceptar();
    }
  }

  onRecordDoubleClick(event){
    let cIndex=event.cellIndex;
    const field: string = this.grid.getColumns()[cIndex].field;
    this.onAceptar();
  }

  rowSelected(event){
    this.isKey=true;
    let row= event.rowIndex;
    if (row==this.data.result.length-1){
    }
    if (event.data){
      this.rowSelect=event.data;
      this.selRow=event.rowIndex;
    }
  }
  cellSelected($event){
    //console.log($event);
  }
  onClickInf(data){
    //console.log(data);
    if (this.params){
      if (this.params.apiInfo){
          let valueField=data[this.params.apiInfo.field];
          let parameter=this.params.apiInfo.parameter;
          let api=this.params.apiInfo.api;
          /*
          this.InfoHtml=`
            <b>${data.Name}</b></br>
            Código  : ${data.Code}</br>
            Precio1 : ${data.Price1U}</br>
          `;
          */
          this._data.getDataInfo(api,parameter,valueField).subscribe((response:any) => {
            if (response) {
              //console.log(response);
              this.InfoHtml=response;
            }else{
              this.InfoHtml=`
                <b>Alerta!!</b></br>
                No hay información adicional de este registro</br>
              `;
            }
            this.dialogPopover = this.dialog.open(this.tplPopover, { width: '380px', height: 'Auto' ,panelClass: 'custom-dialog-containerPopover',backdropClass: 'backdropBackground' ,disableClose: false});
          }, error => {
            this.InfoHtml=`
              <b>Alerta!!</b></br>
              No hay información adicional de este registro</br>
            `;
            this.dialogPopover = this.dialog.open(this.tplPopover, { width: '380px', height: 'Auto' ,panelClass: 'custom-dialog-containerPopover',backdropClass: 'backdropBackground',disableClose: false});
          });
      }
    }
  }
  onAcceptPopover(){
    this.dialogPopover.close(null);
  }
  onAceptar(){
    this.dialogRef.close(this.rowSelect);
  }
  onClose(){
    this.dialogRef.close(null);
  }

}
