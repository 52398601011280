<div class="table-responsive" style="border: none;">
  <table class="footable table table-stripped table-hover toggle-arrow-tiny footable-loader tablet breakpoint">
    <thead>
      <tr>
        <th data-toggle="true" class="footable-show footable-first-column" *ngFor="let header of tableHeaders | keyvalue">{{header.value| translate}}</th>
        <!-- <th data-hide="all" style="display: none;">Description</th>
        <th data-hide="phone" class="footable-visible footable-sortable">{{'Price'| translate}}</th>
        <th data-hide="phone,tablet" class="footable-sortable" style="display: none;">{{'Stock' | translate }}</th>
        <th class="text-right footable-visible footable-last-column" data-sort-ignore="true">{{'Actions' | translate}}</th> -->
      </tr>
    </thead>
    <tbody>
      <tr class="footable-even" style="" *ngFor="let item of dataSource | keyvalue; trackBy: trackById" role="row">
        <td class="footable-visible footable-first-column client-link">
          <a data-toggle="tab" class="client-link">{{item.key}}</a>
        </td>

        <td *ngFor="let i of item | keyvalue">{{i.value}}</td>

        <!-- <td style="display: none;">
          {{item?.value}}
        </td>
        <td class="footable-visible">
          {{product?.Price | currency:'USD':'symbol':'1.2-3'}}
        </td>
        <td class="" style="display: none;">
          {{product?.Stock}}
        </td> -->

        <td class="text-right footable-visible footable-last-column">
          <div class="btn-group-sm">
            <button class="btn-white btn btn-md"  *ngFor="let button of buttons; trackBy: trackById"  tooltip="{{ button?.tooltip | translate}}" placement="button?.tooltipPlacement" (click)="button?.fn()"><i class="button?.Icon"></i></button>
            <!-- <button class="btn-white btn btn-md"  tooltip="{{'View' | translate}}" placement="bottom" (click)="showProductInfo(product)"><i class="fa fa-eye"></i></button>
            <button class="btn-white btn btn-md" tooltip="{{'AddCart' | translate}}" placement="bottom" (click)="add(product)"><i class="fa fa-shopping-cart"></i></button> -->
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="6" class="footable-visible">
          <!-- <pagination-controls class="pagination bg-pagination pull-right" previousLabel="{{'Previous' | translate}}" nextLabel="{{'Next' | translate}}" (pageChange)="page = $event" *ngIf="products.length > itemsPerPage"></pagination-controls> -->
          <!-- <pagination  [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"></pagination> -->

          <!-- <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item"><a class="page-link" (click)="previous()">Previous</a></li>
              <li class="page-item"><a class="page-link" (click)="pageChanged('1')">1</a></li>
              <li class="page-item"><a class="page-link" (click)="pageChanged('2')">2</a></li>
              <li class="page-item"><a class="page-link" (click)="pageChanged('3')">3</a></li>
              <li class="page-item"><a class="page-link" (click)="next()">Next</a></li>
            </ul>
          </nav> -->
        </td>
      </tr>
    </tfoot>
  </table>
</div>
