export class CustomerLocation{
  CustomerID: number;
  CustomerLocationID: number;
  City: string;
  Name: string;
  Address1: string;
  Phone: string;
  Lat: number;
  Long: number;

  Contact?  : string;

  CountryCode: string;
  ZipCode: string;
  Address2: string;
  Mobil: string;
  Fax: string;
  Comment: string;
  Blocked: boolean;
  ModifiedDate: Date;
  Info: string;
  UserID: number;
  Windows_s?: string;
  Windows_e?: string;
  Windows2_s?: string;
  Windows2_e?: string;
}

