import { NgModule } from '@angular/core';

import { ButtonModule, CheckBoxModule, RadioButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';

/*import { ListViewAllModule } from '@syncfusion/ej2-angular-lists';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { SidebarModule, MenuAllModule, TreeViewAllModule,TreeViewModule ,NodeSelectEventArgs} from '@syncfusion/ej2-angular-navigations';
*/
import { GridAllModule,GroupService,InfiniteScrollService ,PagerModule} from '@syncfusion/ej2-angular-grids';
// 


import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { MaskedTextBoxModule } from '@syncfusion/ej2-angular-inputs';


import { GridModule, EditService, ToolbarService, SortService, PageService } from '@syncfusion/ej2-angular-grids';
import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { TreeViewModule,ToolbarModule } from '@syncfusion/ej2-angular-navigations';

//import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
//import { RowDDService } from '@syncfusion/ej2-angular-treegrid';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';

@NgModule({
    declarations: [

    ],
    imports: [
      ButtonModule, CheckBoxModule, RadioButtonModule, SwitchModule,
      NumericTextBoxModule,
      RichTextEditorAllModule,
      TabModule,
      //DropDownListModule,
      //DialogModule,
      TextBoxModule,
      TimePickerModule,
      /*
      ListViewAllModule,
      DropDownListModule,
      SidebarModule, MenuAllModule, TreeViewAllModule,
      TreeViewModule,
      */
      GridAllModule,
      UploaderModule,

      GridModule,
      DatePickerAllModule,
      DropDownListModule,
      MaskedTextBoxModule,
      ComboBoxModule,
      TreeViewModule,
      //TreeGridModule,
      DropDownListAllModule,
      ToolbarModule,
     PagerModule
    ],
    exports:[

      ButtonModule, CheckBoxModule, RadioButtonModule, SwitchModule,
      NumericTextBoxModule,
      RichTextEditorAllModule,
      TabModule,
      //DropDownListModule,
      //DialogModule,
      TextBoxModule,
      TimePickerModule,
      /*
      ListViewAllModule,
      DropDownListModule,
      SidebarModule, MenuAllModule, TreeViewAllModule,
      TreeViewModule,
      */
      GridAllModule,
      UploaderModule,
      DropDownListModule,
      MaskedTextBoxModule,
      GridModule,
      DatePickerAllModule,
      ComboBoxModule,
      //TreeGridModule,
      DropDownListAllModule,
      ToolbarModule,
      PagerModule
    ],
    providers: [EditService, ToolbarService, SortService,PageService,GroupService]
  })
  export class SyncModule { }
