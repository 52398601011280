import { Component, OnInit, ViewChild, OnDestroy, AfterContentInit, Input, ViewEncapsulation, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { Navigation } from '../../../helpers';
import { BtnReturnComponent } from '../../widgets/btn-return/btn-return.component';
import { IDocument } from 'src/app/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//import { Stimulsoft } from '../../../../assets/js/stimulsoft.viewer.pack';

export interface paramsViewerText{
  title? : string;
  modal?:boolean;
  width?: number;
  height?: number;
  dataReport:any;
}

declare var Stimulsoft: any;

@Component({
  selector: 'viewer-text',
  templateUrl: './viewer-text.component.html',
  styleUrls: ['./viewer-text.component.css'],
  encapsulation: ViewEncapsulation.Emulated

})

  export class ViewertextComponent implements OnInit, OnDestroy, AfterContentInit {


    viewer: any = null;
    report: any = null;
    title: string;
    modal:boolean;
    public reportText:any;
    private ngUnsubscribe = new Subject();
    public dataReport: any;

    navigation: Navigation;
    @ViewChild('returnBtn') btn: BtnReturnComponent;


    constructor(public dialogInstance: MatDialogRef<ViewertextComponent>,
      @Inject(MAT_DIALOG_DATA) public data: paramsViewerText){
      this.title=data.title;
      this.modal=this.data.modal;
      this.dataReport=this.data.dataReport;

    }
    ngOnInit() {
      }

      ngAfterContentInit() {
        //this.btn.navigation = this.navigation;
        this.renderReport();
      }
      renderReport() {
        this.dataReport=this.data.dataReport;
      }

      ngOnDestroy() {
        //this.lazyLoaderService.removeScript('reports');
        //this.lazyLoaderService.removeScript('viewer');

        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
      }


    closeDialog(){
      this.dialogInstance.close();
    }

  }
