import { Routes } from '@angular/router';

//import { StarterViewComponent } from './modules/appviews/starterview.component';

import { LoginComponent } from './modules/appviews/login.component';
import { BlankLayoutComponent } from './components/common/layouts/blankLayout.component';
import { BasicLayoutComponent } from './components/common/layouts/basicLayout.component';
import { AuthService } from './services/guards/auth.service';
import { NotfoundComponent } from './notfound/notfound.component';
import { AdminSalesmanGuardService, GoToLandingGuardService,
  SalesGuardModule, CommerceModuleGuard,
  NoLoginGuardService, ValidUserGuardService } from './services';
import { LandingComponent } from './modules/appviews/landing/landing.component';
import { LogisticModuleGuard } from './services/guards/logistic-guard.service';
import { CrmModuleGuard } from './services/guards/crm-guard.service';
import { AccountreceivableModuleGuard } from './services/guards/accountreceivable-guard.service';
import { AccountpayableModuleGuard } from './services/guards/accountpayable-guard.service';
import { InventoryGuardService } from './services/guards/inventory-guard.service';
import { APP_SETTINGS } from './appsettings';
import { FreeformComponent } from './freeform/freeform.component';


  export const ROUTES: Routes = [
    // Landing page
    {
      path: '', component: BlankLayoutComponent,
      children: [
        { path: '', component: LandingComponent, canActivate: [GoToLandingGuardService] },
        { path: 'store', component: BasicLayoutComponent, canActivate: [NoLoginGuardService]},
        { path: 'login', component: LoginComponent },
        { path: 'export', component: FreeformComponent},
      ]
    },
    {
      path: 'app', component: BasicLayoutComponent, canActivate: [AuthService],
      children: [
        
        ///{ path: 'home', component: StarterViewComponent, canActivate: [AuthService] },

        { path: '', redirectTo: 'sales', pathMatch: 'full' },
        { path: 'home', loadChildren: () => import('./modules/sales/sales.module').then(m => m.SalesModule), canActivate: [AuthService] },

        //{ path: '', redirectTo: 'dashboards/board', pathMatch: 'full' },
        //{ path: 'home', redirectTo: 'dashboards/board', pathMatch: 'full' },

        //{ path: 'home', redirectTo: APP_SETTINGS.endpoints.routehome, pathMatch: 'full' },
        //{ path: 'home', loadChildren: () => import('./modules/dashboards/dashboards.module').then(m => m.DashboardsModule), canActivate: [AuthService]},
                
        { path: 'dashboards', loadChildren: () => import('./modules/dashboards/dashboards.module').then(m => m.DashboardsModule),canActivate: [AuthService] },
        { path: 'reports', loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule), canActivate: [ValidUserGuardService] },
        { path: 'sales', loadChildren: () => import('./modules/sales/sales.module').then(m => m.SalesModule), canActivate: [SalesGuardModule] },
        { path: 'commerce', loadChildren: () => import('./modules/commerce/commerce.module').then(m => m.CommerceModule), canActivate: [CommerceModuleGuard] },
        { path: 'purchase', loadChildren: () => import('./modules/purchase/purchase.module').then(m => m.PurchaseModule), canActivate: [CommerceModuleGuard] },
        { path: 'purchasing', loadChildren: () => import('./modules/purchasing/purchasing.module').then(m => m.PurchasingModule), canActivate: [CommerceModuleGuard] },
        { path: 'logistic', loadChildren: () => import('./modules/logistic/logistic.module').then(m => m.LogisticModule), canActivate: [LogisticModuleGuard] },
        { path: 'crm', loadChildren: () => import('./modules/crm/crm.module').then(m => m.CrmModule), canActivate: [CrmModuleGuard] },
        { path: 'accounting', loadChildren: () => import('./modules/accounting/accounting.module').then(m => m.AccountingModule), canActivate: [CrmModuleGuard] },
        { path: 'accountreceivable', loadChildren: () => import('./modules/accountreceivable/accountreceivable.module').then(m => m.AccountreceivableModule), canActivate: [AccountreceivableModuleGuard] },
        { path: 'accountpayable', loadChildren: () => import('./modules/accountpayable/accountpayable.module').then(m => m.AccountpayableModule), canActivate: [AccountpayableModuleGuard] },
        { path: 'system', loadChildren: () => import('./modules/system/system.module').then(m => m.SystemModule), canLoad: [AdminSalesmanGuardService] },
        { path: 'finance', loadChildren: () => import('./modules/finance/finance.module').then(m => m.FinanceModule), canLoad: [AdminSalesmanGuardService] },
        { path: 'dynamic', loadChildren: () => import('./modules/dynamic/dynamic.module').then(m => m.DynamicModule), canLoad: [AdminSalesmanGuardService] },        
        { path: 'inventory', loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule), canLoad: [InventoryGuardService] },
        { path: '404', component: NotfoundComponent },
        { path: '**', component: NotfoundComponent }
      ]
    }
  ];
