import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CustomersService,SessionService,SweetAlertService } from '../../../../services';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { enableRipple } from '@syncfusion/ej2-base';
import { takeUntil} from 'rxjs/operators';

enableRipple(false);

@Component({
  selector: 'board-process',
  templateUrl: './board-process.component.html',
  styleUrls: ['./board-process.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BoardprocessComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();


  constructor(private _formBuilder: FormBuilder){
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


}
