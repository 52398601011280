import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Input
} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";

@Component({
  selector: "confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationModalComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  @Input() params: any;
  @Input() Message: string;

  constructor(
    public dialogInstance: MatDialogRef<ConfirmationModalComponent>,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if (!this.Message) {
      this._translateService.get(`ConfirmMsg`).subscribe((message: string) => { this.Message = message; });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {}

  closeDialog() {
    let result: object = {
      success: false,
      data: null,
    };
    this.dialogInstance.close(result);
  }

  onConfirm() {
    let result: object = {
      success: true,
      data: null,
    };
    this.dialogInstance.close(result);
  }

  onDecline() {
    this.closeDialog();
  }
}
