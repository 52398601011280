import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { IDocument, Invoice, InvoiceItem, PagedResult } from '../../models';
import { DocumentResourceParameters } from '../../helpers';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { APP_SETTINGS } from 'src/app/appsettings';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService extends BaseService {

  constructor(private _injector: Injector) {
    super(_injector);
  }

  printPOS(fact: string) {
    let sfact =  btoa(fact);
    //const url = `http://127.0.0.1:5000/PrintPOS?fact=${sfact}`;
    const httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers':
          'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
      })
    };

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); // ... Set content type to JSON
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT');
    headers.append('Access-Control-Allow-Origin', 'http://localhost:4200');
    headers.append('Access-Control-Allow-Headers', "X-Requested-With, Content-Type, Origin, Authorization, Accept, Client-Security-Token, Accept-Encoding");
    //let options = new RequestOptions({ headers: headers });

    const httpOptions =  { headers: headers };

    const url = APP_SETTINGS.endpoints.printService_url;
    return this._httpClient.post(url,sfact, httpOptions);
  }

  getInvoicesReportByUser(idCustomer: string): Observable<any> {
    const url = this.getApiQuery(`Customer/InvoicesReport?Id_Customer=${idCustomer}`);
    return this._httpClient.get(url, {headers: this.getAuthorizationHeader()});
  }

  getSalesDoc(docId: string, docType: string): any {
    const url = this.getApiQuery('Invoices/GetSalesDoc');
    let params = new HttpParams();
    params = params.append('docId', docId);
    params = params.append('docType', docType);
    return this._httpClient.get(url, {headers: this.getAuthorizationHeader(), params: params});
  }

  getInvoiceItems(docId: string): Observable<InvoiceItem[]> {
    const url = this.getApiQuery('Invoices/items');
    let params = new HttpParams();
    params = params.append('docId', docId);
    return this._httpClient.get<InvoiceItem[]>(url, {headers: this.getAuthorizationHeader(), params: params});
  }

  getInvoiceItems2(docId: string): Observable<any> {
    const url = this.getApiQuery('Invoices/items');
    let params = new HttpParams();
    params = params.append('docId', docId);
    return this._httpClient.get<any>(url, {headers: this.getAuthorizationHeader(), params: params});
  }

  getInvoicesByCustomer(idCustomer: string): Observable<Invoice[]> {
    const url = this.getApiQuery(`Invoices/customer?id=${idCustomer}`);
     return this._httpClient.get<Invoice[]>(url, {headers: this.getAuthorizationHeader()});
  }

  getInvoices(resourceParameters: DocumentResourceParameters): Observable<PagedResult<Invoice>> {
    const url = this.getApiQuery('Invoices/Pagging');
    let params = new HttpParams();
    //console.log("getInvoices");
    if (resourceParameters.UserId) {
      params = params.append('UserId', resourceParameters.UserId);
    }

    if (resourceParameters.From) {
      params = params.append('From', resourceParameters.From);
    }

    if (resourceParameters.To) {
      params = params.append('To', resourceParameters.To);
    }

    if (resourceParameters.DocId) {
      params = params.append('DocId', resourceParameters.DocId);
    }

    if (resourceParameters.Filter) {
      params = params.append('Filter', resourceParameters.Filter);
    }

    params = params.append('PageNumber', resourceParameters.PageNumber.toString());
    params = params.append('PageSize', resourceParameters.PageSize.toString());

    return this._httpClient.get<PagedResult<Invoice>>(url, {headers: this.getAuthorizationHeader(), params: params});
  }

  getInvoicesMRT(PageNumber: number, PageSize:number,Desde:string,Hasta:string,): Observable<PagedResult<Invoice>> {
    let params = new HttpParams();
    //if (Desde) { params = params.append('From', Desde); }
    //if (Hasta) { params = params.append('To', Hasta); }
    //params = params.append('PageNumber', PageNumber.toString());
    //params = params.append('PageSize', PageSize.toString());
    const url = this.getApiQuery('Invoices/GetReportInvoices?From=2021-11-22&To=2021-11-22&PageNumber=1&PageSize=20');
    return this._httpClient.get<PagedResult<Invoice>>(url,
      {
        headers: this.getAuthorizationHeader(),
        //params: params
      });
  }

  GetInvoiceByID(docId:any): Observable<any> {
    const url = this.getApiQuery('Invoices/GetInvoiceByID');
    let params = new HttpParams();
    params = params.append('docId', docId);
    return this._httpClient.get<any>(url, {headers: this.getAuthorizationHeader(), params: params});
  }

}
