import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormValidationService } from 'src/app/services/base/form-validation.service';

@Component({
  selector: 'error-message',
  template: `<span *ngIf="errorMessage !== null" class="alert form-error" role="alert">{{ errorMessage | translate }}</span>`
})
export class ControlMessagesComponent {
  @Input() control: FormControl;
  constructor() { }

  get errorMessage() {
    for (let propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        return FormValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }
    return null;
  }
}
 