import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { UserComponent } from './user/user.component';
import { AgmCoreModule } from '@agm/core';
import { ProfileComponent } from './profile/profile.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PipesModule } from '../../pipes/pipes.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { SharedModule } from '../shared/shared.module';
import { dashboardRoutes } from './dashboards.routes';
import { SyncModule } from './../../sync.module';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule} from '@angular/forms';

import { DatePipe } from '@angular/common';


import { BoardComponent } from './board/board.component';
import { BoardcardComponent } from './board/board-card/board-card.component';
import { BoardchartbarComponent } from './board/board-chartbar/board-chartbar.component';
import { BoardchartpieComponent } from './board/board-chartpie/board-chartpie.component';
import { BoardchartlineComponent } from './board/board-chartline/board-chartline.component';
import { BoardtableComponent } from './board/board-table/board-table.component';
import { BoardprocessComponent } from './board/board-process/board-process.component';

import { BoardsalesComponent } from './board-sales/board-sales.component';

//import { ChartsModule } from 'ng2-charts';

//import { NgChartsModule } from 'ng2-charts';

import { ChartScrollableComponent } from './board/chart-scroll/chart-scrollable.component';

//import { NgChartsModule } from 'ng2-charts';

import { ChartsModule } from 'ng2-charts';
//import { ChartDataLabels } from 'chartjs-plugin-datalabels';


@NgModule({
  declarations: [
    UserComponent,
    ProfileComponent,
    BoardComponent,
    BoardcardComponent,
    BoardchartbarComponent,
    BoardchartpieComponent,
    BoardchartlineComponent,
    BoardtableComponent,
    BoardsalesComponent,
    BoardprocessComponent,
    ChartScrollableComponent,
  ],
  imports: [
    SharedModule,
    NgxPaginationModule,
    AgmCoreModule,
    BsDatepickerModule,
    TooltipModule,
    CarouselModule,
    ModalModule,
    ButtonsModule,
    PipesModule,
    WidgetsModule,
    
    
    //NgChartsModule,
    RouterModule.forChild(dashboardRoutes),
    SyncModule,
    FormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),

    ChartsModule,
    //ChartDataLabels,

  ],

  exports: [
    ProfileComponent,
    UserComponent,
    BoardComponent,
    BoardcardComponent,
    BoardchartbarComponent,
    BoardchartpieComponent,
    BoardchartlineComponent,
    BoardtableComponent,
    BoardsalesComponent,
    BoardprocessComponent,

    ChartsModule,
  ],
  providers: [
    DatePipe,
  ]
})

export class DashboardsModule { }
