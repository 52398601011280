import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Settings } from '../../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsResolver implements Resolve<Settings> {
  constructor(private settingsService: SettingsService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Settings | Observable<Settings> | Promise<Settings> {
    return this.settingsService.getStimulsoftKey();
  }
}

