import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from '@angular-devkit/core/node_modules/rxjs';
import { ReportExport } from './../../models/system/Exportreport';

@Injectable({
  providedIn: 'root'
})
export class ExportreportService extends BaseService {
  
  

  constructor(injector: Injector) {
    super(injector);
  }


  getReports() {
    const url = this.getApiQuery(`Reports/GetReports`);
    return this._httpClient.get<ReportExport[]>(url, { headers: this.getAuthorizationHeader() });
  }

  RenderReportGET(Id:any,Filters:any): Observable<any[]> {
    let params: HttpParams = new HttpParams();

    if (Id) { params = params.append('Id',Id.toString()); }
    if (Filters) { params = params.append('Filters',Filters); }

    let url = this.getApiQuery(`Reports/RenderReport`);
    return this._httpClient.get<any>(
        url,
        { 
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );

  }

  RenderReport(Id:any,Filters:any): Observable<string> {
    let url = this.getApiQuery(`Reports/RenderReport`);
    let objectP = {
      Id      : Id,
      Filters : Filters
    }
    return this._httpClient.post<string>(url, objectP, {
      headers: this.getAuthorizationHeader()
    });
  }

  RenderReportZip(Id:any,Filters:any): Observable<string> {
    let url = this.getApiQuery(`Reports/RenderReportZip`);
    let objectP = {
      Id      : Id,
      Filters : Filters
    }
    let heads=this.getAuthorizationHeader();
    //heads=heads.set('Accept-Encoding','gzip');
    //console.log(heads);
    heads=heads.append('Accept-Encoding','gzip,deflate,compress');
    console.log(heads);
    return this._httpClient.post<string>(url, objectP, {
      headers: heads
    });
  }

  //{ headers: { 'Accept-Encoding': 'gzip' } }




  /*
  getList(PageNumber: number, PageSize:number,SearchTerm:string) : Observable<any[]> {
    let params: HttpParams = new HttpParams();

    
    if (SearchTerm) { params = params.append('SearchTerm',SearchTerm.toString()); }
    if (PageNumber) { params = params.append('PageNumber',PageNumber.toString()); }
    if (PageSize)   { params = params.append('PageSize',PageSize.toString()); }

    let url = this.getApiQuery(`Account/Pagging`);
    return this._httpClient.get<User[]>(
        url,
        { 
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }
  */
}
