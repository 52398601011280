import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogboxComponent } from '../dialog-box/dialog-box.component';
import { Location } from '@angular/common';

@Component({
  selector: "page-heading",
  templateUrl: "./page-heading.component.html",
  styleUrls: ["./page-heading.component.css"],
})
export class PageHeadingComponent implements OnInit {
  @Input() pageTitle = "";
  @Output() protected clickBack = new EventEmitter<object>();
  @Input() showBack: boolean;
  @Input() backDefaultModal: boolean;
  public showBackBtn: boolean = false;
  constructor(public dialog: MatDialog, private location: Location) {
  }

  onBack() {
    if (this.backDefaultModal) {
      let parameters = {
        title: "Important",
        message: "ConfirmExit",
      };
      let dialogConfig = new MatDialogConfig();
      dialogConfig.backdropClass = "g-transparent-backdrop";
      dialogConfig.data = parameters;
      dialogConfig.panelClass = "custom-dialog-containerBox";
      dialogConfig.disableClose = false;
      dialogConfig.width = "380px";
      dialogConfig.height = "170px";

      let result = this.dialog.open(DialogboxComponent, dialogConfig);
      result.afterClosed().subscribe((result) => {
        if (result.success) {
          this.location.back();
        }
      });
    } else {
      this.clickBack.emit();
    }
  }
  ngOnInit() {
    this.showBackBtn = false;
    if (this.showBack) {
      this.showBackBtn = true;
    }
  }
}
