<!--class="media-preview"-->
<div [style.width]="Width" [style.height]="Height" >
    <div style="width: 100%;
                height: calc(100% - 30px);
                display: flow-root;
                overflow-y: scroll;
                border: 1px solid #dddddd;"> 

        <div *ngFor="let item of uploader.queue; let i = index" style="padding: 9px; height: 40px; width: calc(100% - 10px); display: flex;margin: 3px; border: 1px solid #dddddd;background: white;">
            <!--
            <img 
                style="width: 200px"
                [style.height]="HeightImg" 
                src
                mediaPreview 
                [media]="item?.file?.rawFile" class="media-object img-lg"
            />
            -->
            <div class="limit"  style="height: 40px; width: 100%;"> 
                {{ item?.file?.name }}
            </div>
            <div>
                <button type="button" class="btn btn-danger btn-xs" style="height: 24px;width: 24px; margin-top: -5px;"
                    (click)="item.remove()">
                    <i class="ti-trash"></i>
                    <!--
                    {{'Delete' | translate}}
                    -->
                </button>
            </div>
            <div>
                <button *ngIf="doc_AnexDown?.length > 0" type="button" class="btn btn-danger btn-xs" style="height: 24px;width: 24px; margin-top: -5px;margin-left: 3px;"
                    (click)="downloadFile(i)">
                    <i class="ti-import"></i>
                    <!--
                    {{'Delete' | translate}}
                    -->
                    </button>

            </div>
        </div>  
    </div>
       
    <div class="m-t-sm">
        <input 
            [disabled]="ControlEnabled" 
            type="file" 
            id="fileID" 
            #file
            name="file"
            ng2FileSelect
            [uploader]="uploader" 
            class="inputfile2"
            [accept]="allowedMimeType.toString()"
            multiple />  
        <label class="label" for="fileID">
            <i class="ti-clip"></i>
            {{'Attach' | translate}}
        </label>
    </div>    
</div>
