import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Salesmans } from '../../models/Sales/Salesmans';


@Injectable({
  providedIn: 'root'
})
export class SalesmansService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }


  getListSalesmans() {
    const url = this.getApiQuery(`Customer/Salesmans`);
    return this._httpClient.get<Salesmans[]> (url, { headers: this.getAuthorizationHeader() });
  }
}
