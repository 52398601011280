export class ShiftChange {
    ID_CAMTUR: number;
    SOLICITA: string;
    NOMBSOLICITA: string;
    ACEPTA: string;
    NOMBACEPTA: string;
    AUTORIZA: string;
    FECHACAM: Date;
    FECHADEV: Date;
    TURNOCAM: number;
    TURNODEV: number;
    HORARIOCAM: string;
    HORARIODEV: string;
}
