<div class="modal-header">
  <button (click)="onClose()" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1">
    <i class="ti-close"></i>
  </button>
  <h2 class="modal-title">{{ title | translate }}</h2> 
</div>

<div class="wrapper-content">
  <div class="row" style="margin-bottom: 5px;">
    <div class="col-sm-12">
      <input #idInput (keydown)="onKeydown($event,idInput.value)" type="text"
        placeholder="{{'Escriba la información a buscar..' | translate}}" [value]="textDefault" class="input form-control" />
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <!--
        [pageSettings]='pageSettings'
        [infiniteScrollSettings]='infiniteOptions'
      -->
      <ejs-grid 
        #grid 
        [dataSource]='data' 
         
        [selectionSettings]='selectionOptions'

        enableInfiniteScrolling='true' 
        

        (rowSelected)='rowSelected($event)' 
        (dataStateChange)='dataStateChange($event)'
        (keyPressed)="keyPressed($event)"
        (recordDoubleClick)="onRecordDoubleClick($event)"
        [height]="heightAG" 
        width='100%'>
        <e-columns>
          <ng-template #template ngFor let-column [ngForOf]="columns">
            <e-column 
              [field]="column.field" 
              [headerText]="column.headerText" 
              [allowEditing]="column.allowEditing"
              [width]="column.width"
              [format]="column.format"
              [textAlign]="column.textAlign" >
            </e-column>
          </ng-template>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="onClose()">{{ 'Cancel' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="onAceptar()">{{ 'Accept' | translate }}</button>
  </div>
</div>

<!--
                      (keyPressed)="keyPressed($event)"
              (rowSelected)='rowSelectedDispach($event)'
[ngStyle]="heightAG"
      -->