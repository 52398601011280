
export class Supplier {
    Address:string;
    City:string;
    Code:string;
    CompanyName:string;
    Email:string;
    Id:number;
    Name:string;
    Phone:string;
    TaxId:string;
    supplier:string;
}

export interface Supplier2{
    Id:number;
    SupplierID: number; 
    Code: string;
    TaxId: string;
    Blocked: boolean;
    PersonType?: number;
    CompanyName: string;
    Name: string;
    Address: string;
    Address2: string;
    ZipCode: string;
    State: string;
    City: string;
    Phone: string;
    Mobil: string;
    Fax: string;
    Email: string;			
    CheckPayment: boolean;
    CompanyID: number;      
    CountryCode: string;
    GroupID: number;
    CreationDate: Date;
    CreditLimit: number;      
    EmployeeID: number;
    VATPercentage: number;
    RentPercentage: number;        
    Reference: string;        
    Info: string;      
    WWW: string;
    Contact1: string;
    Contact2: string;
    Contact1Phone: string;
    Contact2Phone: string;
    }

    