import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { ShiftChange } from '../../models';
import { PersonnelResourceParameters } from '../../helpers';


@Injectable({
  providedIn: 'root'
})
export class PersonnelShiftChangeService extends BaseService {

  constructor(injetor: Injector) {
    super(injetor);
   }

   getShiftChanges(params?: PersonnelResourceParameters): Observable<Array<ShiftChange>> {
     const url = params.SearchTerm != null ? this.getApiQuery(`RRHH/Employee/SCHEDULE?FROM=${params.From}&TO=${params.To}&ID_PER=${params.SearchTerm}`):this.getApiQuery(`RRHH/Employee/SCHEDULE?FROM=${params.From}&TO=${params.To}`);
     return this._httpClient.get<Array<ShiftChange>>(url, {headers: this.getAuthorizationHeader()});
   }

   getShiftChange(searchTerm: string): Observable<ShiftChange> {
    const url = this.getApiQuery(`RRHH/Employee/SCHEDULE?ID_CAMTUR=${searchTerm}`);
    return this._httpClient.get<ShiftChange>(url, {headers: this.getAuthorizationHeader()});
   }
}
