import { Component } from '@angular/core';
import { DataService, LanguageService, SettingsService } from './services';
import { Settings } from './models';
import { LocalStorageService } from 'angular-web-storage';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  api_url: string;
  environmentName: string;
  constructor(
    private languageService: LanguageService, 
    private settingsService: SettingsService,
    private localStorageService: LocalStorageService,
    private _Activatedroute:ActivatedRoute,
    private _router:Router,
    private _dataService: DataService,) 
  {
    this.languageService.setDefaultLang();
    this.settingsService.subscribe(this, this.setSettings);
  }
  ngOnInit() {
  }
  ngAfterViewChecked() {
    if (this._router.url.indexOf('invoices')==-1 &&
        this._router.url.indexOf('cases')==-1 &&
        this._router.url.indexOf('newcase')==-1) {
          this._dataService.selFilter=null;
        }
  }

  onActivate(e){
    //console.log(e)    
    /*
    this._Activatedroute.paramMap.subscribe(params => { 
      console.log(params);
   });
   */    
  }

  setSettings(caller: any, newSettings: Settings) {
    const thisCaller = caller as AppComponent;
    thisCaller.api_url = newSettings.api_url;
  }

  

}
