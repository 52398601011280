
<page-heading pageTitle="{{ 'Waytopay' | translate }}">
    <div class="title-action col-lg-1 col-sm-1 col-xs-1 pull-right" style="padding-right: 1px">
        <button *ngIf="dialogInstance" type="button" class="btn btn-blue" style="padding: 6px;width: 40px;" tabindex="-1" (click)="closeDialog()">
            <i class="ti-close"></i>
        </button>
    </div>
</page-heading>

<div class="ibox ">
    <div class="ibox-content">
        <div class="row">
            <div class="col-sm-6 sinpadding " >
                <div class="row" style="margin-left: 0px;margin-right: 0px;">
                    <div class="col-sm-3" >
                        <button type="button" class="btn btn-yellow btnPag"  (click)="selectFPago('EFE')" (dblclick)="asignValue('EFE')" >
                            {{'Cash' | translate}}
                           <label class="btnlbl">{{TotalEFE | currency:"$":"symbol" : '1.2-2'}}</label>
                        </button>
                    </div>
                    <div class="col-sm-3" >
                        <button type="button" class="btn btn-yellow btnPag"  (click)="selectFPago('CHE')" [disabled]="!accessCHE">
                            {{'Check' | translate}}
                           <label class="btnlbl">{{TotalCHE | currency:"$":"symbol" : '1.2-2'}}</label>
                        </button>
                    </div>
                    <div class="col-sm-3" >
                        <button type="button" class="btn btn-yellow btnPag"  (click)="selectFPago('TAR')" [disabled]="!accessTAR">
                            {{'Card' | translate}}
                           <label class="btnlbl">{{TotalTAR | currency:"$":"symbol" : '1.2-2'}}</label>
                        </button>
                    </div>
                    <div class="col-sm-3" >
                        <button type="button" class="btn btn-yellow btnPag"  (click)="selectFPago('CXC')" [disabled]="!accessCXC" (dblclick)="asignValue('CXC')">
                            {{'Credit' | translate}}
                           <label class="btnlbl">{{TotalCXC | currency:"$":"symbol" : '1.2-2'}}</label>
                        </button>
                    </div>

                    <div class="col-sm-3" >
                        <button type="button" class="btn btn-yellow btnPag" (click)="selectFPago('TRB')" [disabled]="!accessTRB">
                            {{'Transfer' | translate}}
                           <label class="btnlbl">{{TotalTRB | currency:"$":"symbol" : '1.2-2'}}</label>
                        </button>
                    </div>
                    <div class="col-sm-3" >
                        <button type="button" class="btn btn-yellow btnPag"  (click)="selectFPago('RET')" [disabled]="!accessRET">
                            {{'Retention' | translate}}
                           <label class="btnlbl">{{TotalRET | currency:"$":"symbol" : '1.2-2'}}</label>
                        </button>
                    </div>
                    <div class="col-sm-3" >
                        <button type="button" class="btn btn-yellow btnPag"  (click)="selectFPago('NCC')" [disabled]="!accessNCC">
                            {{'Credit note' | translate}}
                           <label class="btnlbl">{{TotalNCC | currency:"$":"symbol" : '1.2-2'}}</label>
                        </button>
                    </div>
                    <div class="col-sm-3" >
                        <button type="button" class="btn btn-yellow btnPag"  (click)="selectFPago('ANT')" [disabled]="!accessANT">
                            {{'Advance' | translate}}
                           <label class="btnlbl">{{TotalANT | currency:"$":"symbol" : '1.2-2'}}</label>
                        </button>
                    </div>



                </div>
                <!--
                <ejs-grid
                    #gridFPago
                    id="gridFPago"
                    [dataSource]='listFPago'
                    (rowSelected)='rowSelectedFPago($event)'
                    height='148px'>
                    <e-columns>
                        <e-column field='Itemno' headerText='No'     minWidth= 30  width=35  maxWidth=40 textAlign='Right'></e-column>
                        <e-column field='FPago'  headerText='Pago'   minWidth= 150 width=160 maxWidth=170></e-column>
                        <e-column field='Valor'  headerText='Valor'  minWidth= 100 width=100 maxWidth=150 textAlign= 'Right' editType= 'numericedit' format= 'C2'></e-column>
                        <e-column field='Ref'    headerText='Ref'    minWidth= 30  width=35  maxWidth=40 textAlign='Right'   isPrimaryKey='true' [allowEditing]='false' [visible]='false'></e-column>
                        </e-columns>
                </ejs-grid>
                -->
            </div>
            <div class="col-sm-6">
                <!--
                <div class="row">
                    <label class="col-sm-8 col-form-label" style="text-align: right">{{'Suma Liquida:' | translate}}</label>
                    <label class="col-sm-4 col-form-label" style="text-align: right">{{SubTotal | number : '1.2-2'}}</label>
                </div>
                <div class="row">
                    <label class="col-sm-8 col-form-label" style="text-align: right">{{'Descuento Final:' | translate}}</label>
                    <label class="col-sm-4 col-form-label" style="text-align: right">{{Discount | number : '1.2-2'}}</label>
                </div>
                <div class="row">
                    <label class="col-sm-8 col-form-label" style="text-align: right">{{'ICE:' | translate}}</label>
                    <label class="col-sm-4 col-form-label" style="text-align: right">{{Ice | number : '1.2-2'}}</label>
                </div>
                <div class="row">
                    <label class="col-sm-8 col-form-label" style="text-align: right">{{'IVA:' | translate}}</label>
                    <label class="col-sm-4 col-form-label" style="text-align: right">{{Iva | number : '1.2-2'}}</label>
                </div>
                <div class="row">
                    <label class="col-sm-8 col-form-label" style="text-align: right">{{'Total:' | translate}}</label>
                    <label class="col-sm-4 col-form-label" style="text-align: right">{{Total | number : '1.2-2'}}</label>
                </div>
                -->

                <div class="tabs-container">
                    <div class="tabs-right">
                        <ul class="nav nav-tabs">
                            <li>
                                <a class="nav-link active" data-toggle="tab" href="#tab-8" id="li-tab8">
                                    <i class="fa fa-dollar" aria-hidden="true" style="font-size: 16px; color: rgb(0, 13, 71);"></i>
                                </a>
                            </li>
                            <li>
                                <a class="nav-link" data-toggle="tab" href="#tab-9">
                                    <i class="fa fa-address-book-o" aria-hidden="true" style="font-size: 16px; color: rgb(0, 13, 71);"></i>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div id="tab-8" class="tab-pane active">
                                <div class="panel-body">
                                    <div style="display: grid;padding: 3px;height: 120px;">
                                        <div class="row">
                                            <label class="col-sm-8 col-form-label" style="text-align: right">{{'Amounttobepaid' | translate}}</label>
                                            <label class="col-sm-4 col-form-label" style="text-align: right">{{Total | number : '1.2-2'}}</label>
                                        </div>
                                        <div class="row">
                                            <label class="col-sm-8 col-form-label" style="text-align: right">{{'Totalpayment' | translate}}</label>
                                            <label class="col-sm-4 col-form-label" style="text-align: right">{{totalPagos | number : '1.2-2'}}</label>
                                        </div>
                                        <div class="row">
                                            <label class="col-sm-8 col-form-label" style="text-align: right">{{'DifferenceChange' | translate}}</label>
                                            <label class="col-sm-4 col-form-label" style="text-align: right">{{Diferencia | number : '1.2-2'}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-9" class="tab-pane">
                                <div class="panel-body">
                                    <div style="display: flow-root;padding: 3px;height: 120px; overflow-x: hidden;">
                                        <div class="row">
                                            <label class="col-sm-3 col-form-label">{{'Dna' | translate}}</label>
                                            <label class="col-sm-9 col-form-label limit" style="font-weight: 400;">{{selCustomer.RUC}}</label>
                                        </div>
                                        <div class="row">
                                            <label class="col-sm-3 col-form-label">{{'Company' | translate}}</label>
                                            <label class="col-sm-9 col-form-label limit" style="font-weight: 400;">{{selCustomer.Name}}</label>
                                        </div>
                                        <div class="row">
                                            <label class="col-sm-3 col-form-label">{{'Address' | translate}}</label>
                                            <label class="col-sm-9 col-form-label limit" style="font-weight: 400;">{{selCustomer.Address}}</label>
                                        </div>
                                        <div class="row">
                                            <label class="col-sm-3 col-form-label">{{'Phones' | translate}}</label>
                                            <label class="col-sm-9 col-form-label limit" style="font-weight: 400;">{{selCustomer.Phones}}</label>
                                        </div>
                                        <div class="row">
                                            <label class="col-sm-3 col-form-label">{{'Email' | translate}}</label>
                                            <label class="col-sm-9 col-form-label limit" style="font-weight: 400;">{{selCustomer.Email}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

        </div>
        </div>
        <div class="row">
            <div class="form-group row" style="margin-top:10px;margin-left: 0px;margin-bottom: 1px;">
                <label class="col-md-12 col-form-label">{{'CFP' | translate}}</label>

            </div>
        </div>
        <div class="row" style="height:220px;">
            <div class="col-sm-12">


                <div id="divEfe" *ngIf="isVisibleEfe">
                    <div class="form-group row" style="margin-top: 20px;">
                        <label class="col-md-2 col-form-label">{{'Cash' | translate}}</label>
                        <div class="col-md-2">
                            <ejs-numerictextbox
                                format="c2"
                                (change)="onChangeEfe($event)"
                                min='0'
                                [(value)]="efeTotal"
                                >
                            </ejs-numerictextbox>
                            <!--
                            <input id="efectivo" [(ngModel)]="efeTotal" [value]="efeTotal" class="form-control text-uppercase" type="text"/>
                            -->
                        </div>
                        <!--[(ngModel)]="invoice.Id_Vend"-->
                    </div>
                    <div class="form-group row" style="margin-top: 20px;">
                        <label class="col-md-2 col-form-label">{{'CashRegister' | translate}}</label>
                        <div class="col-md-5">
                            <select id="caja" [(ngModel)]="cajaId" class="form-control"  >
                                <option value="0"  disabled selected>{{ 'SelectCash' | translate }}</option>
                                <option [ngValue]="item.Id" *ngFor="let item of listCash">{{item.Name | titlecase}}</option>
                            </select>
                        </div>
                    </div>

                </div>
                <div id="divChe" *ngIf="isVisibleChe">
                    <div class="form-group" style="padding-left:0px;margin-bottom: 7px;">
                        <a class="btn2 btn2-white btn2-bitbucket" (click)="insertItem()">
                            <i class="ti-plus"></i>
                        </a>
                        <a class="btn2 btn2-white btn2-bitbucket" (click)="deleteItem()">
                            <i class="ti-minus"></i>
                        </a>
                    </div>
                    <!--
                        (actionBegin)='actionBegin_Che($event)'
                        (beginedit)='beginEdit_Che($event)'
                        (dataBound)='dataBound_Che($event)'

                    -->
                    <ejs-grid
                        #gridChe
                        id="gridChe"
                        [dataSource]='dataChe'

                        [editSettings]='editSettings'
                        [selectionSettings]='selectionOptions'
                        [allowResizing]='true'
                        [enableHover]='false'
                        (rowSelected)='rowSelected_Che($event)'

                        (actionComplete)='actionComplete_Che($event)'
                        (cellSelected)='cellSelected_Che($event)'
                        (keyPressed)="keyPressed_Che($event)"
                        (recordDoubleClick)="doubleClick_Che($event)"

                        (cellSave)='celsave_Che($event)'
                        (cellSaved)='celsaved_Che($event)'
                        (cellEdit)='cellEdit_Che($event)'


                        height='150px'>
                        <!--[edit]='dpParams'-->
                        <e-columns>
                            <e-column field='Itemno'    headerText='No'               minWidth= 30  width=35  maxWidth=40  textAlign='Right'   isPrimaryKey='true'></e-column>
                            <e-column field='Cuenta'    headerText='Cuenta Corriente' minWidth= 150 width=160 maxWidth=170 editType= 'dropdownedit' [edit]='cheCliParams'></e-column>
                            <e-column field='Numero'    headerText='Número de cheque' minWidth= 100 width=100 maxWidth=150 editType= 'numericedit' [edit]='numericParams' textAlign= 'left' ></e-column>
                            <e-column field='Girado'    headerText='Girado'           minWidth= 100 width=100 maxWidth=150 type="date"  format='MM/dd/yyyy' [edit]='dpParams' textAlign= 'left' ></e-column>
                            <e-column field='Valor'     headerText='Valor'            minWidth= 100 width=100 maxWidth=150 editType= 'numericedit' [edit]='floatParams' format= 'N2' textAlign= 'Right'></e-column>
                            <e-column field='IdCheCli'  headerText='IdCheCli'         minWidth= 80  width=85  maxWidth=90  [allowEditing]='false' [visible]=isVisibleCols ></e-column>
                            </e-columns>
                    </ejs-grid>
                </div>
                        <!--
                        -->

                <div id="divTar" *ngIf="isVisibleTar">
                    <div class="form-group" style="padding-left:0px;margin-bottom: 7px;">
                        <a class="btn2 btn2-white btn2-bitbucket" (click)="insertItem()">
                            <i class="ti-plus"></i>
                        </a>
                        <a class="btn2 btn2-white btn2-bitbucket" (click)="deleteItem()">
                            <i class="ti-minus"></i>
                        </a>
                    </div>
                    <!--
                        (actionBegin)='actionBegin_Tar($event)'
                        (beginedit)='beginEdit_Tar($event)'
                        (dataBound)='dataBound_Tar($event)'
                    -->
                    <ejs-grid
                        #gridTar
                        id="gridTar"
                        [dataSource]='dataTar'

                        [editSettings]='editSettings'
                        [selectionSettings]='selectionOptions'
                        [allowResizing]='true'
                        [enableHover]='false'
                        (rowSelected)='rowSelected_Tar($event)'

                        (actionComplete)='actionComplete_Tar($event)'
                        (cellSelected)='cellSelected_Tar($event)'
                        (keyPressed)="keyPressed_Tar($event)"
                        (recordDoubleClick)="doubleClick_Tar($event)"

                        (cellSave)='celsave_Tar($event)'
                        (cellSaved)='celsaved_Tar($event)'
                        (cellEdit)='cellEdit_Tar($event)'

                        height='150px'>
                        <!--
                            <e-column field='Emisor'      headerText='Emisor'         minWidth= 150 width=160 maxWidth=170 editType= 'dropdownedit' [edit]='tarEmiParams'textAlign= 'left' editType= 'numericedit'></e-column>                            
                            <e-column field='IdEmisor'    headerText='IdEmisor'       minWidth= 80  width=85  maxWidth=90  [allowEditing]='false' [visible]=isVisibleCols ></e-column>
                        -->
                        <e-columns>
                            <e-column field='Itemno'      headerText='No'             minWidth= 30  width=35  maxWidth=40  textAlign='Right'   isPrimaryKey='true'></e-column>
                            <e-column field='Plan'        headerText='Plan'           minWidth= 150 width=160 maxWidth=170 editType= 'dropdownedit' [edit]='tarPlanParams'></e-column>
                            <e-column field='NumeroTar'   headerText='Número Tarjeta' minWidth= 150 width=160 maxWidth=170 editType= 'numericedit' [edit]='numericParams' textAlign= 'left'  ></e-column>
                            <e-column field='NumeroVau'   headerText='Número Vaucher' minWidth= 150 width=160 maxWidth=170 editType= 'numericedit' [edit]='numericParams' textAlign= 'left'  ></e-column>
                            <e-column field='Valor'       headerText='Valor'          minWidth= 100 width=100 maxWidth=150 editType= 'numericedit' [edit]='floatParams' format= 'N2' textAlign= 'Right'></e-column>
                            <e-column field='IdPlan'      headerText='IdPlan'         minWidth= 80  width=85  maxWidth=90  [allowEditing]='false' [visible]=isVisibleCols ></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
                <div id="divCre" *ngIf="isVisibleCre">
                    <div class="form-group" style="padding-left:0px;margin-bottom: 7px;">
                        <a class="btn2 btn2-white btn2-bitbucket" (click)="insertItem()">
                            <i class="ti-plus"></i>
                        </a>
                        <a class="btn2 btn2-white btn2-bitbucket" (click)="deleteItem()">
                            <i class="ti-minus"></i>
                        </a>
                    </div>
                    <!--
                        (actionBegin)='actionBegin_Cre($event)'
                        (beginedit)='beginEdit_Cre($event)'
                        (dataBound)='dataBound_Cre($event)'
                    -->
                    <ejs-grid
                        #gridCre
                        id="gridCre"
                        [dataSource]='dataCre'

                        [editSettings]='editSettings'
                        [selectionSettings]='selectionOptions'
                        [allowResizing]='true'
                        [enableHover]='false'
                        (rowSelected)='rowSelected_Cre($event)'

                        (actionComplete)='actionComplete_Cre($event)'
                        (cellSelected)='cellSelected_Cre($event)'
                        (keyPressed)="keyPressed_Cre($event)"
                        (recordDoubleClick)="doubleClick_Cre($event)"

                        (cellSave)='celsave_Cre($event)'
                        (cellSaved)='celsaved_Cre($event)'
                        (cellEdit)='cellEdit_Cre($event)'


                        height='150px'>
                        <e-columns>
                            <e-column field='Itemno'    headerText='No'             minWidth= 30  width=35  maxWidth=40  textAlign='Right' isPrimaryKey='true'></e-column>
                            <e-column field='Vence'     headerText='Vence'          minWidth= 100 width=100 maxWidth=150 textAlign= 'left'  type="date"  format='MM/dd/yyyy' [edit]='dpParams'></e-column>
                            <e-column field='Tiempo'    headerText='Tiempo'         minWidth= 100 width=100 maxWidth=150 textAlign='left' editType= 'dropdownedit' [edit]='creTimeParams' ></e-column>
                            <e-column field='NLetras'   headerText='Letras'         minWidth= 40  width=55  maxWidth=60  editType= 'numericedit' [edit]='numericParamsLet' textAlign='center'  ></e-column>
                            <e-column field='Garante'   headerText='Garante'        minWidth= 150 width=160 maxWidth=170></e-column>
                            <e-column field='CIGarante' headerText='C.I. Garante'   minWidth= 100 width=120 maxWidth=130></e-column>
                            <e-column field='Financ'    headerText='Financ'         minWidth= 100 width=120 maxWidth=130 editType= 'numericedit' [edit]='floatParams'></e-column>
                            <e-column field='Valor'     headerText='Valor'          minWidth= 100 width=100 maxWidth=150 editType= 'numericedit' [edit]='floatParams' format= 'N2' textAlign= 'Right'></e-column>
                            <e-column field='idTiempo'  headerText='idTiempo'       minWidth= 80  width=85  maxWidth=90  [allowEditing]='false' [visible]=isVisibleCols ></e-column>
                            </e-columns>
                    </ejs-grid>
                </div>
                <div id="divTRB" *ngIf="isVisibleTrb">
                    <div class="form-group" style="padding-left:0px;margin-bottom: 7px;">
                        <a class="btn2 btn2-white btn2-bitbucket" (click)="insertItem()">
                            <i class="ti-plus"></i>
                        </a>
                        <a class="btn2 btn2-white btn2-bitbucket" (click)="deleteItem()">
                            <i class="ti-minus"></i>
                        </a>
                    </div>
                    <ejs-grid
                        #gridTrb
                        id="gridTrb"
                        [dataSource]='dataTrb'

                        [editSettings]='editSettings'
                        [selectionSettings]='selectionOptions'
                        [allowResizing]='true'
                        [enableHover]='false'
                        (rowSelected)='rowSelected_Trb($event)'

                        (actionComplete)='actionComplete_Trb($event)'
                        (cellSelected)='cellSelected_Trb($event)'
                        (keyPressed)="keyPressed_Trb($event)"
                        (recordDoubleClick)="doubleClick_Trb($event)"

                        (cellSave)='celsave_Trb($event)'
                        (cellSaved)='celsaved_Trb($event)'
                        (cellEdit)='cellEdit_Trb($event)'


                        height='150px'>
                        <!--[edit]='dpParams'-->
                        <e-columns>
                            <e-column field='Itemno'    headerText='No'               minWidth= 30  width=35  maxWidth=40  textAlign='Right'   isPrimaryKey='true'></e-column>
                            <e-column field='Banco'     headerText='Banco'            minWidth= 150 width=160 maxWidth=170 editType= 'dropdownedit' [edit]='trbBanParams'></e-column>
                            <e-column field='Fecha'     headerText='Fecha'            minWidth= 100 width=100 maxWidth=150 type="date"  format='MM/dd/yyyy' [edit]='dpParams' textAlign= 'left' ></e-column>
                            <e-column field='Numero'    headerText='Transferencia No.'      minWidth= 100 width=100 maxWidth=150 editType= 'numericedit' [edit]='numericParams' textAlign= 'left' ></e-column>
                            <e-column field='Valor'     headerText='Valor'            minWidth= 100 width=100 maxWidth=150 editType= 'numericedit' [edit]='floatParams' format= 'N2' textAlign= 'Right'></e-column>
                            <e-column field='IdBanco'   headerText='IdBanco'          minWidth= 80  width=85  maxWidth=90  [allowEditing]='false' [visible]=isVisibleCols ></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>

            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="form-group p-h-sm text-center">
                <button type="submit" mat-button class="btn btn-blue btn-block" style="width: 150px;"
                    (click)="onAccept()"><i class="ti-save" style="vertical-align: initial;"></i>
                     {{'Save' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>




