import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Injectable, Injector, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataStateChangeEventArgs, GridComponent, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { fromEvent, Observable, Subject, Subscription, observable } from 'rxjs';
import {  of,  merge, combineLatest } from 'rxjs';
import { map, startWith, switchMap, tap, debounceTime, filter, scan, withLatestFrom, mergeMap, takeUntil, takeWhile, distinctUntilChanged, skipUntil, exhaustMap, endWith } from 'rxjs/operators';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';

import { FormControl } from '@angular/forms';
import { BaseService } from '../../../services/base/base.service';
import { HttpParams } from '@angular/common/http';


export interface paramsSearch{
  api           : string;
  sizePage      : number;
  width         : string;
  height        : string;
}
export interface keyboard {
  key:string;
}
export interface ILookup {
  Id: number,
  Name: string
}

@Injectable({
  providedIn: 'root'
})
export class DatagridService extends BaseService {
  constructor(private _injector: Injector) {
    super(_injector);
  }

  public GetSupplier(skip: any,url:string,filter? : string)  {

    let params: HttpParams = new HttpParams();
    if (filter)     { params = params.append('SearchTerm',filter); }
    params = params.append('PageNumber',skip.toString());
    params = params.append('PageSize','20'); 
    //let url = 'Customer/Pagging';
    url = this.getApiQuery(url);
    return this._httpClient.get(url, {headers: this.getAuthorizationHeader(), params: params}).pipe(map((data: any) => {		
      console.log(data.Result);
      return data.Result;
      /*
      return  {
         result: data.Result.map((reg:any)=>{
          return {
            id: reg.Id,
            name:reg.Name,
          }
        })
      }
      */

    }))
  }
}
@Component({
  selector: 'app-search3',
  templateUrl: './search3.component.html',
  styleUrls: ['./search3.component.css'],
  //encapsulation: ViewEncapsulation.Emulated
  //providers: [InfiniteScrollService]
})
export class Search3Component implements OnInit, OnDestroy {

  ngUnsubscribe = new Subject();
  private subscription: Subscription;

  //public params: paramsSearch;
  @ViewChild('idInput',{static: false}) input: ElementRef;

  private ObjSubjectRx$: Subject<any> = new Subject<any>();

  public params : paramsSearch;
  public columns : object;

  public setFocus: any;
  public data : object;

  public pageSettings: Object;
  public state: DataStateChangeEventArgs;
  public filter : string='';
  public title:string="Buscar..";
  public rowSelect:any;
  public heightAG : string;
  public selectionOptions : SelectionSettingsModel;

  searchText = new FormControl({ Id: 2, Name: 'ana' });
  filteredLookups$: Observable<ILookup[]>;
  private lookups: ILookup[] = [];
  private nextPage$ = new Subject();
  private _onDestroy = new Subject();

  items = Array.from({length: 100}).map((_, i) => `Item #${i}`);

  constructor(@Inject(MAT_DIALOG_DATA) public dialogConfig : any,
              public dialogRef: MatDialogRef<Search3Component>,
              private _data: DatagridService)
    {

    }


  ngOnInit(): void {
      //console.log(this.dialogConfig);
      this.params=this.dialogConfig.params;
      this.columns=this.dialogConfig.columns;
      this.heightAG='calc('+this.params.height+' - 200px)';

      this.pageSettings = {
        pageSize: this.params.sizePage,
      };
      this.state = { skip: 1, take: this.params.sizePage };
      this.filter='';
      this.init();
  }

 
  ngAfterViewInit() {
 
  }
  onKeydown(event,value){
 
  }
  keyPressed($event){

  }
 

  closeDialog(){
    this.dialogRef.close(null);
  }
  onAceptar(){
    this.dialogRef.close(this.rowSelect);
  }
  onCancel(){
    this.dialogRef.close(null);
  }

  init(){
    this.lookups = [{ Id: 1994, Name: 'ana' }, { Id: 1989, Name: 'narcis' }]
    for (let i = 1; i < 100; i++) {
      this.lookups.push({ Id: i, Name: 'test' + i })
    }

    const filter$ = this.searchText.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      filter(q => typeof q === "string"));
    this.filteredLookups$ = filter$.pipe(
      switchMap(filter => {
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          exhaustMap(_ => 
             this.getProducts(filter, currentPage)
          ),
          tap(() => currentPage++),
          takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      })); 

  }
  displayWith(lookup) {
    return lookup ? lookup.name : null;
  }

  onScroll() {
    //Note: This is called multiple times after the scroll has reached the 80% threshold position.
    this.nextPage$.next();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  
  private getProducts(startsWith: string, page: number):Observable<any> { //: Observable<ILookup[]>
    const take = 20;
    const skip = page;
    //const filtered = this.lookups.filter(option => option.Name.toLowerCase().startsWith(startsWith.toLowerCase()))
    //console.log(`skip: ${skip}, take: ${take}`);
    //console.log(filtered.slice(skip, skip + take));
    return this._data.GetSupplier(skip,'Supplier/Pagging',startsWith);
  }
}

