export interface BranchOffice {
  Id: number;
  Name: string;
  City: string;
  Address: string;
  Contact: string;
  Phone: string;
  Serie: string;
}
export class TributarioEC {
  Contabilidad: boolean;
  ContribuyenteEspecial: boolean;
  ResolucionCE: string;
  EsMicroempresa: boolean;
  AgenteRetencionRes:string;
  MontoMaximoConsumidorFinal:number;
  EsFE: boolean; //Es Facturacion Electronica
  EsRIMPE:boolean;
  AmbienteFE: number;
}
export class Company {
  Id: number;
  RUC:string;
  CompanyName: string;
  BusinessName : string;
  Address: string;
  BusinessAddress: Date;
  City: string;
  Country: string;
  Phone: string;
  Email: string;
  LogoUrl: string;
  Wallpaper: string;
  Branches: Array<BranchOffice>;
  DatosTributarios: TributarioEC;
  LogoUrl64: string;
  Wallpaper64: string;
}

export class Companys {
  Id: number;
  RUC:string;
  Name: string;
  Address : string;
  wwwroot : string;
}


/*
export class CompanyEC extends Company {
  DatosTributarios: TributarioEC;
}
*/
