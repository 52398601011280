import { ResourceParameters } from './ResourceParamteres';

export class DocumentResourceParameters extends ResourceParameters {
  UserId: string;
  From: string;
  To: string;
  DocId?: string;
  Filter?: string;
  Id_Per?:string;
  SearchTerm?: string;
}
