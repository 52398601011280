<!--class="media-preview"-->
<div [style.width]="Width" [style.height]="Height" >
    <div style="width: 100%;
                height: calc(100% - 10px);
                display: flex;
                overflow-x: scroll;
                border: 1px solid #dddddd;"> 

        <div *ngFor="let item of uploader.queue" style="margin: 3px; border: 1px solid #dddddd;">
            <img 
                style="width: 200px"
                [style.height]="HeightImg" 
                src
                mediaPreview 
                [media]="item?.file?.rawFile" class="media-object img-lg"
            />
            <div>
                <button type="button" class="btn btn-danger btn-xs" style="height: 28px;width: 100%;"
                    (click)="item.remove()">
                    <i class="ti-trash"></i>
                    {{'Delete' | translate}}
                </button>
            </div>
        </div>  
    </div>
       
    <div class="media">
        <input 
            [disabled]="isControlEnabled" 
            type="file" 
            id="fileID" 
            #file
            name="file"
            ng2FileSelect
            [uploader]="uploader" 
            class="inputfile2"
            [accept]="allowedMimeType.toString()"
            multiple />  
        <label class="label" for="fileID">
            <i class="ti-clip"></i>
            {{'Select' | translate}}
        </label>
    </div>    
</div>
