import { Injectable, Injector } from '@angular/core';
import { CustomerLocation } from '../../models';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocationcustomersService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }


  getLocationCustomers(CustomerID: number): Observable<CustomerLocation[]> {
    const url = this.getApiQuery('ControlEditor/GetCustomerLocations');
    let params = new HttpParams();
    if (CustomerID) {
      params = params.append('CustomerID', CustomerID.toString());
    }
    return this._httpClient.get<CustomerLocation[]>(url, { headers: this.getAuthorizationHeader(), params: params });
  }

  LocationCreate(record: CustomerLocation) {
    const url = this.getApiQuery('ControlEditor/SaveCustomerLocations');
    return this._httpClient.post(
      url,
      record,
      { headers: this.getAuthorizationHeader() }
    );
  }
  LocationUpdate(record: CustomerLocation) {
    const url = this.getApiQuery('ControlEditor/SaveCustomerLocations');
    return this._httpClient.post(
      url,
      record,
      { headers: this.getAuthorizationHeader() }
    );
  }

}
