import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  smoothlyMenu
} from '../../../app.helpers';
import {
  LoginService,
  CartService,
  NavigationService,
  SessionService,
  LanguageService,
  Language,
  DataService,
  UsersService
} from '../../../services';

import {
  InvoiceItem
} from '../../../models';

import {
  Subject, Subscription
} from 'rxjs';
import {
  takeUntil
} from 'rxjs/operators';
import { APP_SETTINGS } from '../../../appsettings';

import { ProcessbackgroundService } from '../../../services/helpers/process-background';


declare let jQuery: any;

@Component({
  selector: 'topnavbar',
  templateUrl: 'topnavbar.template.html'
})
export class TopNavbarComponent implements OnInit, OnDestroy {
  // public fields
  cartItems: InvoiceItem[] = [];
  counter: number = 0;
  languages: Language[];
  currentLanguage: Language;
  userRole: string;
  isCartEnabled: boolean;
  public userData$: Subscription;
  public userData: any;

  // private fields
  private ngUnsubscribe = new Subject();
  isDefaultUser: boolean;

  public isLoading: boolean=false;

  constructor(private _navigationService: NavigationService,
    private _sessionService: SessionService,
    private _loginService: LoginService,
    private _cartService: CartService,
    private languageService: LanguageService,
    private dataService: DataService,
    private _usersService: UsersService,
    private _process:ProcessbackgroundService ) {
  }

  ngOnInit() {
    this._process.loading.subscribe(value => {
      //console.log(value);
      this.isLoading=value;
    });

    this.counter = 0;
    this.cartItems = [];
    this._usersService.getUserLastCart().pipe(takeUntil(this.ngUnsubscribe))

    .subscribe(result => {
      if(result) {
        var cartItems = JSON.parse(result.toString());
      }
      this._cartService.updateCartItems(cartItems);
    });

    this._cartService.cartItems$.pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(items => {
      this.counter = items.length;
      this.cartItems = items;
    });

    this.isDefaultUser = this._sessionService.getSessionInfo().Extras.name === '';
    this.dataService.setIsDefaultCustomer(this.isDefaultUser);

    this.dataService.defaultCustomerBehaviour.subscribe(result => {
      this.isDefaultUser = result;
    });

    this.isCartEnabled = APP_SETTINGS.features.enableSalesModule && APP_SETTINGS.features.enableCommerceModule;
    this.languageService.getLanguages()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(languages => {
        this.languages = languages;
        this.selectLanguage(this.languageService.getBrowserLang());
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleNavigation(): void {
    jQuery('body').toggleClass('mini-navbar');
    smoothlyMenu(this.isDefaultUser);
  }

  logout() {
    if (this._sessionService.isLoggedIn()) {
      this._loginService.logout()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(response => {
          this.dataService.setIsDefaultCustomer(true);
          this.returnToLogin();
        }, error => {
          this.dataService.setIsDefaultCustomer(true);
          this.returnToLogin();
        });
    }
  }

  login() {
    this._navigationService.navigateToLogin();
  }

  goHome() {
    this._navigationService.navigateToLanding();
  }

  private returnToLogin() {
    this._sessionService.clearSession();
    this._navigationService.navigateToLanding();
    smoothlyMenu(this.isDefaultUser);
  }

  goToCart(isMandatoryNavigation: boolean) {
    if (this.counter == 0) {
      this._navigationService.navigateToShoppingCart();
    }
    if (isMandatoryNavigation = ! null && isMandatoryNavigation) {
      this._navigationService.navigateToShoppingCart();
    }
  }

  trackById(index, item) {
    return index;
  }

  selectLanguage(language: string) {
    if (language && language !== undefined) {
      this.currentLanguage = this.languages.find(x => x.id === language);
      this.languageService.updateLanguge(this.currentLanguage.id);
    }
  }
}
