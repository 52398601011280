import { Injectable, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

//import * as XLSX from 'xlsx';
//import * as XLSX from 'xlsx-js-style';
//import { saveAs } from 'file-saver';
import { InventoryService } from '../inventory/inventory.service';
import { ExportreportService } from '../system/export_report.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ProcessbackgroundService {
  private ObjSubjectRx$: Subject<any> = new Subject<any>();
  private ObjSubjectRxRep1$: Subject<any> = new Subject<any>();
  private ObjSubjectRxRep2$: Subject<any> = new Subject<any>();
  public isLoading : boolean=false;

  private loadingObserver = new BehaviorSubject(false);
  loading = this.loadingObserver.asObservable();

  public DataforGrid : any;

  constructor(private _InventoryService:InventoryService,
  private _ExportreportService:ExportreportService) {
  }
  public iconLoadingEnabled(){
    this.isLoading=true;
    this.loadingObserver.next(this.isLoading);
  }
  public iconLoadingDisabled(){
    this.isLoading=false;
    this.loadingObserver.next(this.isLoading);
  }

 
  public Export_Report(Id:any,Name:string,filters:any,Unzip:boolean=false){
    if (Unzip==false){
        this._ExportreportService.RenderReport(Id,filters).subscribe((response : any) => {
          if (response) {
            var json = JSON.parse(response);
            this.exportJSon(Name,json)
            let dataSend={
              success:true,
            }
            this.ObjSubjectRxRep1$.next(dataSend);        
          }
        }, error => {
          let dataSend={
            success:false,
          }
          this.ObjSubjectRxRep1$.next(dataSend);        
          console.error('An error has occured')
        });
    }    
    if (Unzip==true){
      this._ExportreportService.RenderReportZip(Id,filters).subscribe((response : any) => {
        console.log(response);
        if (response) {
          var json = JSON.parse(response);
          this.exportJSon(Name,json)
          let dataSend={
            success:true,
          }
          this.ObjSubjectRxRep1$.next(dataSend);        
        }
      }, error => {
        let dataSend={
          success:false,
        }
        this.ObjSubjectRxRep1$.next(dataSend);        
        console.error('An error has occured')
      });
  }    

    this.ObjSubjectRxRep1$.next(false);
    return this.ObjSubjectRxRep1$.asObservable();
  }

  public exportJSon(xlsName:string,json:any){
    /*
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook : XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");
    XLSX.writeFile(workbook, xlsName+".xlsx");
    */
  }

  public GetDataExport(Id:any,Name:string,filters:any,Unzip:boolean=false){
    if (Unzip==false){
        this._ExportreportService.RenderReport(Id,filters).subscribe((response : any) => {
          if (response) {
            var json = JSON.parse(response);
            this.DataforGrid=json;
            let dataSend={
              success:true,
              data: json
            }
            this.ObjSubjectRxRep1$.next(dataSend);        
          }
        }, error => {
          this.DataforGrid=null;
          let dataSend={
            success:false,
          }
          this.ObjSubjectRxRep1$.next(dataSend);        
          console.error('An error has occured')
        });
    }    
    if (Unzip==true){
      this._ExportreportService.RenderReportZip(Id,filters).subscribe((response : any) => {
        console.log(response);
        if (response) {
          var json = JSON.parse(response);
          this.DataforGrid=json;
          let dataSend={
            success:true,
            data: json
          }
          this.ObjSubjectRxRep1$.next(dataSend);        
        }
      }, error => {
        this.DataforGrid=null;
        let dataSend={
          success:false,
        }
        this.ObjSubjectRxRep1$.next(dataSend);        
        console.error('An error has occured')
      });
  }    

    this.ObjSubjectRxRep1$.next(false);
    return this.ObjSubjectRxRep1$.asObservable();
  }

}




/*

  public GetObserver(item:number,message:string) : Observable<any[]>  {
    window.setTimeout(() => {
      let dataSend={
        success:true,
        item:item,
        mydata:message
      }
      this.ObjSubjectRx$.next(dataSend);
    },5000);
    return this.ObjSubjectRx$.asObservable();
  }


  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }


public ProcessRep1(item:number,message:string) : Observable<any[]>  {

    //this._TopNavbarComponent0.loadingEnabled();

    window.setTimeout(() => {
      var wscols = [
        {wch:10},
        {wch:30},
        {wch:15},
        {wch:15}
      ];

      //let list = Array.from({length: 100000}).map((_, i) => i);
      let vehiculos=['BMW',
        'Mercedes-Benz',
        'Audi',
        'Lexus',
        'Renault',
        'Ford',
        'Opel',
        'Seat'];

        let Meses=['Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ];

      let lists = Array.from({ length: 10 }).map((val, idx) => {
        return {
            Id: `No. ${idx + 1}`,
            vehiculo: vehiculos[this.getRandomInt(8)],
            Mes: Meses[this.getRandomInt(12)],
            Ventas: this.getRandomInt(1000) };
      });

      lists.push({Id:'',vehiculo:'',Mes:'',Ventas:0});

      var wsrows =  [
        {hpt: 20}, // row 1 sets to the height of 20 in points
        {hpx: 16}, // row 2 sets to the height of 16 in pixels
      ];

      //const XLSX = require('sheetjs-style');
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lists);

      worksheet["!cols"] = wscols;
      worksheet["!rows"] = wsrows;

      worksheet['A1'].s = {
        font: {
            name: 'arial',
            sz: 18,
            bold: true,
            color: "#F2F2F2"
        },
      }

      for (let i in worksheet) {
        if (typeof(worksheet[i]) != "object") continue;
        let cell = XLSX.utils.decode_cell(i);

        worksheet[i].s = { // styling for all cells
            border: {
                right: {
                    style: "thin",
                    color: "000000"
                },
                left: {
                    style: "thin",
                    color: "000000"
                },
            }
        };
        if (cell.r == 0 ) { // first row
          worksheet[i].s.border.bottom = { // bottom border
                style: "thin",
                color: "000000"
            };
          worksheet[i].s.font={bold:true}
          worksheet[i].s.fill={patternType:'solid',fgColor: {rgb:'b2b2b2'}, bgColor: {rgb:'b2b2b2'}}
        }

        if (cell.r == 10 ) { // first row
          worksheet[i].s.border.bottom = { // bottom border
                style: "thin",
                color: "000000"
            };
          }

      }

      worksheet['C12'] = { v: 'Total' };
      //worksheet['!rows'].addRow([])
      //if(!worksheet.C12.c) worksheet.C12.c = [];
      //worksheet.A12.push({a:"Hoja1", t:"I'm a little comment, short and stout!"});
      worksheet['D12'] = { f: 'SUM(D3:D10)' };

      const workbook : XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");
      XLSX.writeFile(workbook, "Rep1.xlsx");


      let dataSend={
        success:true,
        item:item,
        mydata:message
      }
      //this._TopNavbarComponent0.loadingDisabled();
      this.ObjSubjectRxRep1$.next(dataSend);
    }, 10000);
    this.ObjSubjectRxRep1$.next(false);
    return this.ObjSubjectRxRep1$.asObservable();
  }


  public Export_Articulos(filters:any){
    console.log(filters);
    this._InventoryService.getProdList().subscribe(
      (response:any) => {
        console.log(response);
        if (response != null) {

          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(response.ReportRows);
          const workbook : XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");
          XLSX.writeFile(workbook, "Rep_Art.xlsx");

          let dataSend={
            success:true,
            //item:item,
            //mydata:message
          }
          this.ObjSubjectRxRep1$.next(dataSend);

        }
    }, error => console.error('An error has occured'));


    this.ObjSubjectRxRep1$.next(false);
    return this.ObjSubjectRxRep1$.asObservable();

  }



  public ProcessRep2(item:number,message:string) : Observable<any[]>  {
    //setInterval(() => {
    window.setTimeout(() => {
      let vehiculos=['BMW',
        'Mercedes-Benz',
        'Audi',
        'Lexus',
        'Renault',
        'Ford',
        'Opel',
        'Seat'];

        let Meses=['Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ];

      let lists = Array.from({ length: 100 }).map((val, idx) => {
        return {
            Id: `No. ${idx + 1}`,
            vehiculo: vehiculos[this.getRandomInt(8)],
            Mes: Meses[this.getRandomInt(12)],
            Ventas: this.getRandomInt(1000) };
      });
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lists);

      for (var i in ws) {
        console.log(ws[i]);
        if (typeof ws[i] != 'object') continue;
        let cell = XLSX.utils.decode_cell(i);

        ws[i].s = {
          // styling for all cells
          font: {
            name: 'arial',
          },
          alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: '1', // any truthy value here
          },
          border: {
            right: {
              style: 'thin',
              color: '000000',
            },
            left: {
              style: 'thin',
              color: '000000',
            },
          },
        };

        if (cell.c == 6) {
          // first column
          ws[i].s.numFmt = 'DD-MM-YYYY'; // for dates
          ws[i].z = 'DD-MM-YYYY';
        } else {
          ws[i].s.numFmt = '00'; // other numbers
        }

        if (cell.r == 0) {
          // first row
          ws[i].s.border.bottom = {
            // bottom border
            style: 'thin',
            color: '000000',
          };
        }

        if (cell.r % 2) {
          // every other row
          ws[i].s.fill = {
            // background color
            patternType: 'solid',
            fgColor: { rgb: 'b2b2b2' },
            bgColor: { rgb: 'b2b2b2' },
          };
        }
      }



      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'ScoreSheet.xlsx');

      let dataSend={
        success:true,
        item:item,
        mydata:message
      }
      this.ObjSubjectRxRep2$.next(dataSend);
    },5000);
    this.ObjSubjectRxRep2$.next(false);
    return this.ObjSubjectRxRep2$.asObservable();
  }

   processBatch01(): Promise<boolean> {
    return new Promise((resolve)=> {
      setInterval(() => {
        resolve(true);
      },100000);
    });
  }
  useprocessBatch01(){
    this.processBatch01().then( (success) => {
      console.log('bajado..');
    });
  }
*/













      /*
      var cell_ref = XLSX.utils.encode_cell({c:0,r:0});
      var cell={
        s: {
          //{ font:{ bold: true }}
          //v: data[0][0],
          fill: {
            patternType: "none", // none / solid
            fgColor: {rgb: "FF000000"},
            bgColor: {rgb: "FFFFFFFF"}
          },
          font: {
            name: 'Times New Roman',
            sz: 16,
            color: {rgb: "#FF000000"},
            bold: false,
            italic: false,
            underline: false
          },
          border: {
            top: {style: "thin", color: {auto: 1}},
            right: {style: "thin", color: {auto: 1}},
            bottom: {style: "thin", color: {auto: 1}},
            left: {style: "thin", color: {auto: 1}}
          }
        }
      }
      worksheet[cell_ref] = cell;
      //-----------------------------------------
            debugger
      for (let i in worksheet) {
        if (typeof(worksheet[i]) != "object") continue;
        let cell = XLSX.utils.decode_cell(i);

        worksheet[i].s = { // styling for all cells
            font: {
                name: "arial",
                bold: true,
            },
            alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: '1', // any truthy value here
            },
            border: {
                right: {
                    style: "thin",
                    color: "000000"
                },
                left: {
                    style: "thin",
                    color: "000000"
                },
            }
        };

        if (cell.c == 0) { // first column
          worksheet[i].s.numFmt = "DD/MM/YYYY HH:MM"; // for dates
          worksheet[i].z = "DD/MM/YYYY HH:MM";
        } else {
          worksheet[i].s.numFmt = "00.00"; // other numbers
        }

        if (cell.r == 0 ) { // first row
          worksheet[i].s.border.bottom = { // bottom border
                style: "thin",
                color: "000000"
            };
        }

        if (cell.r % 2) { // every other row
          worksheet[i].s.fill = { // background color
                patternType: "solid",
                fgColor: { rgb: "b2b2b2" },
                bgColor: { rgb: "b2b2b2" }
            };
        }
    }

      */
