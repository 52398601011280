import { Component, OnInit, Input } from '@angular/core';
import { Contact } from '../../../models/Sales/Contact';
import {MouseEvent} from '@agm/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styles: []
})
export class MapComponent implements OnInit {

  @Input() customer?: Contact;
  @Input() Lat?: number;
  markers: marker[] = [];

  constructor() {
  }

  ngOnInit() {
  }

  showLocation() {
    if (this.customer != null) {
      if (this.customer.Lat != null  && this.customer.Long != null ) {
        this.markers.push({
          lat: this.customer.Lat,
          lng: this.customer.Long,
          label: this.customer.Name,
          draggable: true
        });
      }
    }
  }

  ngAfterViewInit() {
    if (this.customer != null) {
      if (this.customer.Lat != null  && this.customer.Long != null ){
        console.log(this.customer.Long);
        this.markers.push({
          lat: this.customer.Lat,
          lng: this.customer.Long,
          label: this.customer.Name,
          draggable: true
        });
      }
    }
  }

  mapClicked(event: MouseEvent) {
    if (this.markers.length < 1) {
      this.markers.push({
        lat: event.coords.lat,
        lng: event.coords.lng,
        label: this.customer.Name,
        draggable: true
      });
    }
  }

  clickedMarker(label: string, index: number) {
    //console.log(`clicked the marker: ${label || index}`)
  }

  markerDragEnd(event: MouseEvent) {
    this.customer.Lat = event.coords.lat;
    this.customer.Long =event.coords.lng;
  }
}

interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}

