<div style="height: -webkit-fill-available;">
  <ejs-grid #gridEvent id="gridEvent"
    [dataSource]='dataEvent'
    [selectionSettings]='selectionOptions'
    height='100%'
    width='100%'>
    <e-columns>
        <e-column headerText='Date'         field='Id'     width='70' type="date" format='MM/dd/yyyy'>  </e-column>
        <e-column headerText='Time'         field='Fecha'     width='70' type="date" format='HH:mm' textAlign='Left'></e-column>
    </e-columns>
  </ejs-grid>
</div>

<!--
Hola
{{dataEvent | json }}
-->

<!--
<div fxLayout="column" fxFlexFill>
  <div fxFlex="30" style="background: red">-</div>
  <div fxFlex="70">
		<ejs-grid #gridEvent id="gridEvent"
              [dataSource]='dataEvent'
              [selectionSettings]='selectionOptions'
              height='100%'
              width='100%'>
              <e-columns>
                  <e-column headerText='Date'         field='RegDate'     width='70' type="date" format='MM/dd/yyyy'>  </e-column>
                  <e-column headerText='Time'         field='RegDate'     width='70' type="date" format='HH:mm' textAlign='Left'></e-column>
              </e-columns>
            </ejs-grid>
	</div>  
</div>
-->
