import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceItem } from '../models';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { APP_SETTINGS } from '../appsettings';
import { CustomBaseCurrencyPipe } from './custom-base-currency.pipe';


// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({
  name: 'itemsSubtotal'
})
export class ItemsSubtotalPipe extends CustomBaseCurrencyPipe {

  calculate(items: Array<InvoiceItem>, display: 'narrow' | 'wide') {
    if (items) {
      let subtotal = 0.00;
      items.forEach(item => {
        subtotal += this.Round(item.Price * (item.Quantity > 0 ? item.Quantity : 0) * (1 - item.Discount / 100),2);
      });
      return formatCurrency(subtotal,
        this.locale,
        getCurrencySymbol(APP_SETTINGS.formats.currencyCode, display),
        APP_SETTINGS.formats.currencyCode,
        APP_SETTINGS.formats.digitsInfo);

    } else {
      return null;
    }
  }

}
