import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Injectable, Injector } from '@angular/core';
import { SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Subject, Subscription } from 'rxjs';
import { ProcessbackgroundService } from '../services/helpers/process-background';
import { ExportreportService } from '../services/system/export_report.service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-freeform',
  templateUrl: './freeform.component.html',
  styleUrls: ['./freeform.component.css'],
  encapsulation: ViewEncapsulation.None
  //providers: [InfiniteScrollService]
})
export class FreeformComponent implements OnInit, OnDestroy {

  ngUnsubscribe = new Subject();
  private subscription: Subscription;
  public selectionOptions: SelectionSettingsModel;
  public dataEvent : Array<any> = [];

  constructor(
    private _ExportreportService:ExportreportService,
    private _process:ProcessbackgroundService )
  {
    this.dataEvent=this._process.DataforGrid;
  }


  ngOnInit(): void {
    this.selectionOptions = { type: 'Multiple', mode: 'Both' };
    
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
  }


}
