import { Component, OnInit, ViewChild, OnDestroy, AfterContentInit, Input, ViewEncapsulation, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { Navigation } from '../../../helpers';
import { BtnReturnComponent } from '../../widgets/btn-return/btn-return.component';
import { IDocument } from 'src/app/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//import { Stimulsoft } from '../../../../assets/js/stimulsoft.viewer.pack';

export interface paramsViewer{
  templateMRT? : string;
  title? : string;
  modal?:boolean;
  width?: number;
  height?: number;
  url?:string;
  aditionalFilters?: object;
  newGUID?:boolean;
  dataReport?:any;
  onBack?:boolean;
  dialogButtom?:any;
}

declare var Stimulsoft: any;

@Component({
  selector: 'viewer-modals',
  templateUrl: './viewer-modals.component.html',
  styleUrls: ['./viewer-modals.component.css'],
  encapsulation: ViewEncapsulation.Emulated

})

  export class ViewermodalsComponent implements OnInit, OnDestroy, AfterContentInit {


    viewer: any = null;
    report: any = null;
    title: string;
    modal:boolean;
    private ngUnsubscribe = new Subject();
    public dataReport: any;

    navigation: Navigation;
    @ViewChild('returnBtn') btn: BtnReturnComponent;

    private readonly reportsJsUrl = '../../../../assets/js/stimulsoft.reports.pack.js';
    private readonly viewerJsUrl = '../../../../assets/js/stimulsoft.viewer.pack.js';

    constructor(
      public dialogInstance: MatDialogRef<ViewermodalsComponent>,
      @Inject(MAT_DIALOG_DATA) public data: paramsViewer){
        console.log(data);
        this.title=data.title;
        this.modal=this.data.modal;
        this.dataReport=this.data.dataReport;
    }
    ngOnInit() {
        //this.navigation = this.navigationService.getNavigationItem('viewer');
        // Cargando los scripts de stimulsoft que serán obtenidos por lazy loading

        /*

        this.lazyLoaderService.loadScript(this.reportsJsUrl, 'reports')
        .then(() => {})
        .catch(() => {});

        this.lazyLoaderService.loadScript(this.viewerJsUrl, 'viewer')
        .then(() => this.renderReport())
        .catch(() => {});
        */
        
      }

      ngAfterContentInit() {
        //this.btn.navigation = this.navigation;
        this.renderReport();
      }

      ngOnDestroy() {
        //this.lazyLoaderService.removeScript('reports');
        //this.lazyLoaderService.removeScript('viewer');

        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
      }


    renderReport() {
      // const key = this.route.snapshot.data.settings.stimulsoft_Key;
      // Stimulsoft.Base.StiLicense.Key = key ? key : '';

      const key = '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHmS44uBPQ+lBz6gKY7P+72PalJ3IuKZGV1qoMjJyP7LWS8ObT' +
        'S6QkyMz3OoZ2iLR/1IYrXF7ixYijvuJMKdOKTENLG+CYAYQ5X+zMKC0Q+yMHPzRa+Dkp335T5LquJmorIfI3BGiIcD' +
        'MVzSjsBWqCJZ1x0n7gqlus+qr7jU3oldJ1thsM7H80dxlJgua+mbgwAlOMsxb3+4Q8N3qF35C+eiGe1Or4vSnN4sUa' +
        'bqMeRU/gLkTX5uF/npWu1R0X0CGjzI21dEJsGqerwYNbqcj+W/7chRyrGN0cQ8lKjj5A/kaM6zTu1z4POWHM+m9uik' +
        'kyaJVuadI6155NMpG9WJpqrV2WAOFUoOIvBHJwhblDlFKRH3jWugyJV3AmWvvqlV56JTPfYMExw5q8kWV/QIpIYcMZ' +
        'QMlTs5+h3VjwQ/T1IxDrmCu5fTmg5sOqmwHMkc/t6+XkpdfHg6zz1YAT6ADnnK0cf2/TYGZjPaw3ADGXUNbeIe9hcf' +
        '7qR+BgzUfAHJNUu6qHqm2Q9YgkIrbIkTAllvxxkLX6zNUwNPXDKkmlOCH//T3191+IjCWq9ODuloZBWNMs/47Ow/53' +
        'FMBCl9wakE3VTP+hvbZB0MUxyo09SwC57xCtaVDXIujrkJzDhBSi/ZiNWRrBG4AP+PEKu/Dq/IFxwPPMY68EG4xeNV' +
        'kGF4k0syYIdpILBOQPbqSSSpm+SqXUD/gfkUGDqfsGAOMn9Otb2zqjTEoHp06Lmy/72/Kr/KQPC2LzhhSN1mI253Z2' +
        'w=';
        Stimulsoft.Base.StiLicense.Key = key ? key : '';

      //const EmployeeRecord: EmployeeRecord = this.route.snapshot.data.employeeRecord;

      const options = new Stimulsoft.Viewer.StiViewerOptions();
      // options.toolbar.visible = false;
      options.toolbar.showAboutButton = false;
      options.toolbar.showBookmarksButton = false;
      options.toolbar.showButtonCaptions = false;
      options.toolbar.showDesignButton = false;
      options.toolbar.showEditorButton = false;
      options.toolbar.showFindButton = false;
      options.toolbar.showFullScreenButton = false;
      options.toolbar.showMenuMode = false;
      options.toolbar.showOpenButton = false;
      options.toolbar.showParametersButton = false;
      options.toolbar.showZoomButton = false;
      options.appearance.scrollbarsMode = true;
      //options.width = "1000px";
      options.height = "calc(100% - 40px)";

      const viewer = new Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
      viewer.renderHtml('idviewer');

      const report = new Stimulsoft.Report.StiReport();
      //report.loadFile('./assets/reports/HojaVida.mrt');
      report.loadFile(this.data.templateMRT);
      viewer.report = report;

      report.dictionary.clear();
      const dataSet = new Stimulsoft.System.Data.DataSet('data');
      //asi se va a llamar en el reporte el dataset que viene

      const data = JSON.stringify(this.dataReport);
      dataSet.readJson(data);//EmployeeRecord);


      report.regData(dataSet.dataSetName, dataSet.dataSetName, dataSet);
      report.dictionary.synchronize();

      //report.renderAsync();

    }
    closeDialog(){
      this.dialogInstance.close();
    }

  }
