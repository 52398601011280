import { Phone } from './Phone';

export class Customer {
  //---------Tab 1--------//
  CustomerID: number;
  TaxId?: string;
  Code?: string;
  Name?: string;
  CompanyName?: string;  //no viene en el service
  Zone?: string;
  Address?: string;
  CompanyAddress?: string;
  City?: string;
  State?: string;
  CountryCode?: string; //no viene en el service
  SalesPeopleID: number;
  GroupID: number;
  DefaultPrice: number;
  //---------Tab 2--------//
  Email?: string;
  Phone?: string; //tel1
  Phone2?: string; //tel2
  WWW?: string;

  TaxIdTypeEC: number;

  Address2?: string;
  AdvancePaymentAcc?: string;
  Blocked: boolean;
  BlockedDate: Date;
  BlockedReason?: string;
  CEOBirthday: Date;
  CEOFullName?: string;
  CEOGender?: string;
  CEOSalutationID: number;
  CCEmisor: boolean;
  CheckPayment: boolean;
  CollectorID: number;
  CompanyID: number;
  ConsignationAcc?: string;
  ContractCode?: string;
  CorpName?: string;
  CreationDate: Date;
  CreditCode?: string;
  CreditEnd: Date;
  CreditLimit: number;
  CreditPrice: number;
  CreditStart: Date;
  CurrentBlockType: number;
  CustomerAcc?: string;
  CustomerCatID: number;
  CustomerTypeID: number;
  DefaultPaymentID: number;
  DeliveryTypeID: number;
  demographic?: string;
  DueDays: number;
  EmployeeID: number;
  EUTaxID?: string;
  IsIssuing: boolean;
  Fax?: string;
  FirstName?: string;
  Flag: boolean;
  FullNameSalutationID: number;
  IndustrySectorID: number;
  Info?: string;
  InsuredLimit: number;
  InternalService: boolean;
  LastName?: string;
  ModifiedDate: Date;
  NoPoints: boolean;
  NrCopyInvoices: number;
  NrCopyShippDocs: number;
  OnlyCash: boolean;
  PersonType: number;

  POBox?: string;
  PoBoxPlace?: string;
  PoBoxZipCode?: string;
  Remaind: boolean;
  RETENER: boolean;
  RETFUENTE: boolean;
  RETIVA: boolean;
  Route: number;
  SendInvoices: boolean;
  TaxExempt: boolean;
  ToDelete: boolean;
  ToUpdate: boolean;
  UserID: number;
  UserName?: string;
  UserPass?: string;
  VATACC?: string;
  VisitDay: number;
  VisitOrder: number;
  VisitWeek: number;
  WholeSalerID: number;
  ZipCode: string;
  Latitude: number;
  Longitude: number;

  //Dna:string;
  //Pais:string;
  //Provincia:string;
  //Vendedor:number;

  Contact1:string;
  PhoneContact1:string;
  Depart1:string;
  Contact2:string;
  PhoneContact2:string;
  Depart2:string;
  Details:string;
  Note:string;

  tipoindustria?: string;
  tiponegocio?: string;
  tipocomercio?: number;
  tamanoempresa?: string;
  calificacion?: number;
  representante?: string;
  representanteid?: string;
  representantedob?: Date;
  numeroempleados?:number;
  actividad?:string;
  TipoPer?:number;

  C1REF:string;
  C2REF:string;
  TWITTER?: string;
  FACEBOOK?: string;
  WHATSAPP?: string;
  SKYPE?: string;
  INSTAGRAM: string;
  EMAILFE:string;

  CodCont: string;
}

export class Customer3 {
  Address2?: string;
  Code?: string;
  CompanyName?: string;
  CorpName?: string;
  CustomerID?: number;
  DefaultPrice?: number;
  Zone?: number;
  GroupID?: number;
  Phone2?: string;
  WWW?: string;
  Name?: string;
  Address?: string;
  City?: string;
  Phone?: string;
  Email?: string;

  TaxId?: string;
  CountryCode?: string;
  SalesPeopleID: number;
  State?: string;


  Contact1?:string;
  PhoneContact1?:string;
  Depart1?:string;
  Contact2?:string;
  PhoneContact2:string;
  Depart2:string;
  Details?:string;
  Notes?:string;

}

