import { Pipe } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { InvoiceItem } from '../models';
import { APP_SETTINGS } from '../appsettings';
import { CustomBaseCurrencyPipe} from './custom-base-currency.pipe'

// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({
  name: 'itemsDiscount'
})
export class ItemsDiscountPipe extends CustomBaseCurrencyPipe {

  calculate(items: Array<InvoiceItem>, display: 'narrow' | 'wide') {
    if (items) {
      let discount = 0.00;
      items.forEach(item => {
        if(item.Discount>0)
        {
          discount +=  (item.Price * (item.Quantity > 0 ? item.Quantity : 1 )) * (item.Discount / 100);
        }
      });

      return formatCurrency(discount,
        this.locale,
        getCurrencySymbol(APP_SETTINGS.formats.currencyCode, display),
        APP_SETTINGS.formats.currencyCode,
        APP_SETTINGS.formats.digitsInfo);
    } else {
      return null;
    }
  }
}
