import { Injectable } from '@angular/core';
import { BaseResult } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class UtilService {


  constructor() {
  }


  //*******************************************************************************************************/
  //*                                     Validacion de Cedula/RUC                                        */
  //*                                            Fuente                                                   */
  //* https://github.com/macool/jquery-ecuador-id-validator/blob/master/lib/original_script_cjperez.html  */
  //*                                                                                                     */
  //*******************************************************************************************************/

  public validarDocumento(numeroDoc:string): BaseResult  {

    let numero : string  = numeroDoc;
    if (!numero){
      return {
         Successful : false,
         Message    : "Escriba número de documento!!"
      }
    }

    let suma   : number  = 0;
    let residuo: number  = 0;
    let pri    : boolean = false;
    let pub    : boolean = false;
    let nat    : boolean = false;
    let modulo : number  = 11;
    let numeroProvincias : number = 24;

    /* Verifico que el campo no contenga letras */
    let ok : number = 1;
    for (let i=0; i<numero.length && ok==1 ; i++){
       let n = parseInt(numero.charAt(i));
       if (isNaN(n)) ok=0;
    }
    if (ok==0){
       //alert("No puede ingresar caracteres en el número");
       return {
         Successful : false,
         Message    : "No puede ingresar caracteres en el número"
       };
    }

    if (numero.length < 10 ){
       //alert('El número ingresado no es válido');
       return {
        Successful : false,
        Message    : "El número ingresado no es válido"
      }
    }

        // No  hay un algoritmo valido para nuevas cedulas y RUC de tipos de  empresa SAS y SCC
        return {
          Successful : true,
          Message    : "Numero de documento es correcto"
        }

    /* Los primeros dos digitos corresponden al codigo de la provincia */
    let provincia : number = Number(numero.substr(0,2));
    if (provincia < 1 || provincia > numeroProvincias){
        //alert('El código de la provincia (dos primeros dígitos) es inválido');
        return {
          Successful : false,
          Message    : "El código de la provincia (dos primeros dígitos) es inválido"
        }
    }

    /* Aqui almacenamos los digitos de la cedula en variables. */
    let d1 : number = Number(numero.substr(0,1));
    let d2 : number = Number(numero.substr(1,1));
    let d3 : number = Number(numero.substr(2,1));
    let d4 : number = Number(numero.substr(3,1));
    let d5 : number = Number(numero.substr(4,1));
    let d6 : number = Number(numero.substr(5,1));
    let d7 : number = Number(numero.substr(6,1));
    let d8 : number = Number(numero.substr(7,1));
    let d9 : number = Number(numero.substr(8,1));
    let d10: number = Number(numero.substr(9,1));

    /* El tercer digito es: */
    /* 9 para sociedades privadas y extranjeros   */
    /* 6 para sociedades publicas */
    /* menor que 6 (0,1,2,3,4,5) para personas naturales */

    if (d3==7 || d3==8){
       //alert('El tercer dígito ingresado es inválido');
       return {
        Successful : false,
        Message    : "El tercer dígito ingresado es inválido"
      }
    }

    /* Solo para personas naturales (modulo 10) */
    let p1 : number;
    let p2 : number;
    let p3 : number;
    let p4 : number;
    let p5 : number;
    let p6 : number;
    let p7 : number;
    let p8 : number;
    let p9 : number;

    if (d3 < 6){
       nat = true;
       p1 = d1 * 2;  if (p1 >= 10) p1 -= 9;
       p2 = d2 * 1;  if (p2 >= 10) p2 -= 9;
       p3 = d3 * 2;  if (p3 >= 10) p3 -= 9;
       p4 = d4 * 1;  if (p4 >= 10) p4 -= 9;
       p5 = d5 * 2;  if (p5 >= 10) p5 -= 9;
       p6 = d6 * 1;  if (p6 >= 10) p6 -= 9;
       p7 = d7 * 2;  if (p7 >= 10) p7 -= 9;
       p8 = d8 * 1;  if (p8 >= 10) p8 -= 9;
       p9 = d9 * 2;  if (p9 >= 10) p9 -= 9;
       modulo = 10;
    }

    /* Solo para sociedades publicas (modulo 11) */
    /* Aqui el digito verficador esta en la posicion 9, en las otras 2 en la pos. 10 */
    else if(d3 == 6){
       pub = true;
       p1 = d1 * 3;
       p2 = d2 * 2;
       p3 = d3 * 7;
       p4 = d4 * 6;
       p5 = d5 * 5;
       p6 = d6 * 4;
       p7 = d7 * 3;
       p8 = d8 * 2;
       p9 = 0;
    }

    /* Solo para entidades privadas (modulo 11) */
    else if(d3 == 9) {
       pri = true;
       p1 = d1 * 4;
       p2 = d2 * 3;
       p3 = d3 * 2;
       p4 = d4 * 7;
       p5 = d5 * 6;
       p6 = d6 * 5;
       p7 = d7 * 4;
       p8 = d8 * 3;
       p9 = d9 * 2;
    }

    suma = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9;
    residuo = suma % modulo;

    /* Si residuo=0, dig.ver.=0, caso contrario 10 - residuo*/
    let digitoVerificador = residuo==0 ? 0: modulo - residuo;

    /* ahora comparamos el elemento de la posicion 10 con el dig. ver.*/
    if (pub==true){
       if (digitoVerificador != d9){
          //alert('El ruc de la empresa del sector público es incorrecto.');
          return {
            Successful : false,
            Message    : "El ruc de la empresa del sector público es incorrecto"
          }
       }
       /* El ruc de las empresas del sector publico terminan con 0001*/
       if ( numero.substr(9,4) != '0001' ){
          //alert('El ruc de la empresa del sector público debe terminar con 0001');
          return {
            Successful : false,
            Message    : "El ruc de la empresa del sector público debe terminar con 0001"
          }
       }
    }
    else if(pri == true){
       if (digitoVerificador != d10){
          //alert('El ruc de la empresa del sector privado es incorrecto.');
          return {
            Successful : false,
            Message    : "El ruc de la empresa del sector privado es incorrecto"
          }
       }
       if ( numero.substr(10,3) != '001' ){
          //alert('El ruc de la empresa del sector privado debe terminar con 001');
          return {
            Successful : false,
            Message    : "El ruc de la empresa del sector privado debe terminar con 001"
          }
       }
    }

    else if(nat == true){
       if (digitoVerificador != d10){
          //alert('El número de cédula de la persona natural es incorrecto.');
          return {
            Successful : false,
            Message    : "El número de cédula de la persona natural es incorrecto"
          }
       }
       if (numero.length >10 && numero.substr(10,3) != '001' ){
          //alert('El ruc de la persona natural debe terminar con 001');
          return {
            Successful : false,
            Message    : "El ruc de la persona natural debe terminar con 001"
          }
       }
    }
    return {
      Successful : true,
      Message    : "Numero de documento es correcto"
    }
  }

  RoundIt(num, decimalPlaces) {
   var d = decimalPlaces || 0;
   var m = Math.pow(10, d);
   var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
   var i = Math.floor(n), f = n - i;
   var e = 1e-8; // Allow for rounding errors in f
   var r = (f > 0.5 - e && f < 0.5 + e) ?
               ((i % 2 == 0) ? i : i + 1) : Math.round(n);
   return d ? r / m : r;
 }

 Round(n, dp) {
    const h = +('1'.padEnd(dp + 1, '0')) // 10 or 100 or 1000 or etc
    return Math.round(n * h) / h
 }

 zfill(number, width) {
   var numberOutput = Math.abs(number); /* Valor absoluto del número */
   var length = number.toString().length; /* Largo del número */
   var zero = "0"; /* String de cero */

   if (width <= length) {
       if (number < 0) {
            return ("-" + numberOutput.toString());
       } else {
            return numberOutput.toString();
       }
   } else {
       if (number < 0) {
           return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
       } else {
           return ((zero.repeat(width - length)) + numberOutput.toString());
       }
   }
 }
 allTrim(value:string){
   if (!value) value='';
   if (value==null) value='';
   value=value.replace(/ /g, "");
   return value;
 }
  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
}
