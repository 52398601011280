import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItem, SortedMenuItem } from '../../../models';
import {
  DataService,
  InvoiceProcedence,
  NavigationService } from '../../../services';
  import { Subject } from 'rxjs';
  import { takeUntil } from 'rxjs/operators';

  // declare let $: any;
  @Component({
    selector: 'dashboard-items',
    templateUrl: './dashboard-items.component.html',
    styleUrls: []
  })
  export class DashboardItemsComponent implements OnInit, OnDestroy {

    items: MenuItem[] = [];
    sortedItems: SortedMenuItem[];
    ngUnsubscribe = new Subject();

    constructor(private _navigationService: NavigationService, private _dataService: DataService) { }

    ngOnInit() {
      this._navigationService.getOnlyMenuItemsWithoutParent()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((items) => {
        this.items = items;
      });

      this._navigationService.getMenuSorted()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe( items => {
        this.sortedItems = items;
      });
    }

    ngOnDestroy() {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }

    openItem(item: MenuItem) {
      this._dataService.invoiceProcedence = InvoiceProcedence.starterView;
      const route = `/app/${item.Area.toLocaleLowerCase()}/${item.Controller.toLowerCase()}`
      this._navigationService.navigate(route);
    }

    trackById(index, item) {
      return item.id;
    }
  }
