export class CustomRow {
  rowIndex?: number;
}

export class Requisite extends CustomRow {
  Type: string;
  Unique: boolean;
  Description: string;
}

export class JobPosition extends CustomRow {
  ID_CAR: number;
  Name: string;
  Departament: string;
  Area: string;
  Description: string;
  Requirements: Requisite[];
  JobsSubordinates: JobPosition[];
}