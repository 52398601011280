import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CustomersService, SweetAlertService,EquipmentModelService, LocationcustomersService } from '../../../services';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { Customer,CustomerLocation,EquipmentModel } from '../../../models';
import { CustomerEquipment } from '../../../models/Sales/CustomerEquipment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { Item } from '@syncfusion/ej2-navigations';

export interface General {
  id?       : number;
  name?     : string;
}

@Component({
  selector: 'equipment-customer',
  templateUrl: './equipment-customer.component.html',
  styleUrls: ['./equipment-customer.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EquipmentcustomerComponent implements OnInit, OnDestroy {

  @Input() Equipment: CustomerEquipment;// Contact;
  @Input() Process: string;
  @Input() dialogInstance: MatDialogRef<EquipmentcustomerComponent, any>

  ngUnsubscribe = new Subject();

  bsSettings: Partial<BsDatepickerConfig>;
  private theme = 'theme-red';
  from: Date = new Date();

  public listPrecio : Array<General> = [];
  public listModel : Array<EquipmentModel> = [];
  public listLocations : Array<CustomerLocation> = [];

  action: string;
  titleComponent: string;

  public Form: FormGroup;
  public AcquisitionDate     : any;
  public WarrantyEndDate     : any;
  public LastManteinanceDate : any;
  public NextManteinanceDate : any;
  public EquipmentModelID    : number;
  public CustomerLocationID  : number;

  CtrTo_Id                  : FormControl = new FormControl('');
  CtrTo_SerialNo            : FormControl = new FormControl('');
  CtrTo_Name                : FormControl = new FormControl('');
  CtrTo_Info                : FormControl = new FormControl('');
  CtrTo_EquipmentModelID    : FormControl = new FormControl('', Validators.required);
  CtrTo_Manufacturer        : FormControl = new FormControl('');
  CtrTo_AcquisitionDate     : FormControl = new FormControl('');
  CtrTo_WarrantyEndDate     : FormControl = new FormControl('');
  CtrTo_LastManteinanceDate : FormControl = new FormControl('');
  CtrTo_NextManteinanceDate : FormControl = new FormControl('');
  CtrTo_CustomerLocationID  : FormControl = new FormControl('');

  //public branch : Branch;

  constructor(private _formBuilder: FormBuilder,
    private _customersService: CustomersService,
    private _modelService: EquipmentModelService,
    private swalService: SweetAlertService,
    private _translateService: TranslateService,
    private datePipe: DatePipe,
    private _locationService: LocationcustomersService,) {

    this.bsSettings = Object.assign({}, { containerClass: this.theme });
    
    this.initCtr();

  }



  ngAfterViewInit(): void {

  }

  ngOnInit() {


    let labelBtnAction='Update';
    let labelTitle='EditEquipment';
    if (this.Equipment == null) {
      labelBtnAction='Register';
      labelTitle='NewEquipment';
    }
    this._translateService.get(labelBtnAction)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: string) => {
          this.action = result;
    });
    this._translateService.get(labelTitle)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: string) => {
          this.titleComponent = result;
    });

    this.setCtr();

    //this.dataBranch=[];

    //console.log(this.Equipment);

    if (this.Equipment) {
      if (this.Equipment.CustomerID) {
          this._locationService.getLocationCustomers(this.Equipment.CustomerID).subscribe(
            (response:Array<CustomerLocation>) => {
              //console.log(response);
              if (response != null) {
                this.listLocations = response;
              }
            }, error => console.error('An error has occured')
          );
      }
    }

    this._modelService.getListEquipmentModel().subscribe(
      (response:Array<EquipmentModel>) => {
        if (response != null) {
          this.EquipmentModelID=0;
          this.listModel = response;
          console.log(this.Equipment);
          if (this.Process=="EDIT") {
            this.EquipmentModelID=this.Equipment.EquipmentModelID;
            this.CtrTo_EquipmentModelID.setValue(this.Equipment.EquipmentModelID);
            //console.log(this.listModel);
          }
        }
    }, error => console.error('An error has occured'));

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onRegister() {
      //const rdata: Branch = new Branch();
      //rdata.Address='';

      //(this.grid.dataSource as object[]).unshift(rdata);
      //this.grid.refresh();
      let result: object = {
        success:true,
        record:this.getForm()
      }
      this.dialogInstance.close(result);
  }


  private cleanForm() {
    this.Form.reset('');
  }
  closeDialog() {
    let result: object = {
      success:false,
      record:null
    }
    this.dialogInstance.close(result);
  }

  isResolve(control:FormControl): {[s:string]:boolean}{
    let forma:any = this;
    let modelID=forma.controls['EquipmentModelID'].value;
    let valid = null;
    if ( modelID==0 ) {
      valid = { error : true, msg: "" };
    }
    return valid;
  }

  onChangeModel(id){
    if (id){
      let index = this.listModel.findIndex(item => item.EquipmentModelID == id);
      if (index!=null && index>=0){
        let ItemSel = this.listModel[index];
        //this.Form.setValue(ItemSel,"Manufacturer");
        this.Form.controls["Manufacturer"].setValue(ItemSel.Manufacturer);
      }
    }
  }

  private initCtr(){
    this.Form = this._formBuilder.group({
      'EquipmentCustomerID'   : this.CtrTo_Id,
      'SerialNo'              : this.CtrTo_SerialNo,
      'Name'                  : this.CtrTo_Name,
      'Info'                  : this.CtrTo_Info,
      'EquipmentModelID'      : this.CtrTo_EquipmentModelID,
      'Manufacturer'          : this.CtrTo_Manufacturer,
      'AcquisitionDate'       : this.CtrTo_AcquisitionDate,
      'WarrantyEndDate'       : this.CtrTo_WarrantyEndDate,
      'LastManteinanceDate'   : this.CtrTo_LastManteinanceDate,
      'NextManteinanceDate'   : this.CtrTo_NextManteinanceDate,
      'CustomerLocationID'    : this.CtrTo_CustomerLocationID,
    });
    this.Form.controls['EquipmentCustomerID'].disable();
    
    this.Form.setValidators([
      this.isResolve.bind(this.Form)
    ]);
    
  }

  private setCtr(){
    //console.log(this.Equipment);
    if (this.Equipment.EquipmentCustomerID != null) {
      this.CtrTo_Id.setValue(this.Equipment.EquipmentCustomerID);
      this.CtrTo_SerialNo.setValue(this.Equipment.SerialNo);
      this.CtrTo_Name.setValue(this.Equipment.Name);
      this.CtrTo_Info.setValue(this.Equipment.Info);

      
      this.EquipmentModelID=this.Equipment.EquipmentModelID;
      this.CtrTo_EquipmentModelID.setValue(this.Equipment.EquipmentModelID);
      //console.log(this.Equipment.EquipmentModelID);
      //console.log(this.CtrTo_EquipmentModelID.value);

      this.CtrTo_Manufacturer.setValue(this.Equipment.Manufacturer);
      this.CtrTo_Info.setValue(this.Equipment.Info);

      this.CtrTo_AcquisitionDate.setValue(this.Equipment.AcquisitionDate);
      this.AcquisitionDate=new Date(this.Equipment.AcquisitionDate);

      this.CtrTo_WarrantyEndDate.setValue(this.Equipment.WarrantyEndDate);
      this.WarrantyEndDate=new Date(this.Equipment.WarrantyEndDate);

      this.CtrTo_LastManteinanceDate.setValue(this.Equipment.LastManteinanceDate);
      this.LastManteinanceDate=new Date(this.Equipment.LastManteinanceDate);

      this.CtrTo_NextManteinanceDate.setValue(this.Equipment.NextManteinanceDate);
      this.NextManteinanceDate=new Date(this.Equipment.NextManteinanceDate);

      this.CtrTo_CustomerLocationID.setValue(this.Equipment.CustomerLocationID);
      this.CustomerLocationID=this.Equipment.CustomerLocationID;
    } else {
      //console.log('vacio');
      this.AcquisitionDate=new Date();
      this.CtrTo_AcquisitionDate.setValue(this.AcquisitionDate);

      this.WarrantyEndDate=new Date();
      this.CtrTo_WarrantyEndDate.setValue(this.WarrantyEndDate);

      this.LastManteinanceDate=new Date();
      this.CtrTo_LastManteinanceDate.setValue(this.LastManteinanceDate);

      this.NextManteinanceDate=new Date();
      this.CtrTo_NextManteinanceDate.setValue(this.NextManteinanceDate);
    }
  }

  private getForm(): CustomerEquipment {
    const record = new CustomerEquipment();
    record.EquipmentCustomerID  = this.CtrTo_Id.value;
    record.SerialNo             = this.CtrTo_SerialNo.value;
    record.Name                 = this.CtrTo_Name.value;
    record.Info                 = this.CtrTo_Info.value;
    record.EquipmentModelID     = this.CtrTo_EquipmentModelID.value;
    record.Manufacturer         = this.CtrTo_Manufacturer.value;
    record.AcquisitionDate      = this.CtrTo_AcquisitionDate.value;
    record.WarrantyEndDate      = this.CtrTo_WarrantyEndDate.value;
    record.LastManteinanceDate  = this.CtrTo_LastManteinanceDate.value;
    record.NextManteinanceDate  = this.CtrTo_NextManteinanceDate.value;
    record.CustomerLocationID   = this.CtrTo_CustomerLocationID.value;
    return record;
  }


}
