import {
  Directive,
  ElementRef,
  HostListener,
} from "@angular/core";

@Directive({
  selector: "[twoDecimals]",
})

export class TwoDecimalsDirective {
   private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
   private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {}
  @HostListener("keypress", ["$event"]) onKeyPress(event: KeyboardEvent) {
    return this.validateNumeric(event);
  }

  validateNumeric(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
