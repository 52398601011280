import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import { WaitComponent } from './wait.component';

@Injectable({ providedIn: 'root' })
export class WaitService {
  private ObjSubjectRx$: Subject<any> = new Subject<any>();
  dialogConfig = new MatDialogConfig();
  public prms: object;
  public modal: MatDialogRef<WaitComponent>;

  constructor(private router: Router,
    private dialog: MatDialog,
  ) {
  }
  public Show(message: string) {
    //this.dialogConfig.backdropClass = 'g-transparent-backdrop';
    this.dialogConfig.panelClass = 'custom-dialog-containerWait';
    this.dialogConfig.disableClose = true;
    this.dialogConfig.width = 'auto'; //parameter.params.width;// '800px',
    this.dialogConfig.height = '45px';//parameter.params.height;//'600px',
    this.dialogConfig.data = {
      message:message
    }
    this.modal = this.dialog.open(WaitComponent, this.dialogConfig);
  }
  public close(){
    this.modal.close();
  }

}
