import { Component, OnInit, OnDestroy, AfterContentInit, ViewChild, LOCALE_ID, Inject, Output, EventEmitter } from '@angular/core';
import { DataService, NavigationService, InvoicesService, QuotationService, PrinterService,UtilService,SessionService, SweetAlertService, PopModalService } from '../../../services';
import { InvoiceItem, IDocument, IFact } from '../../../models';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { retry, takeUntil } from 'rxjs/operators';
import { Navigation } from '../../../helpers';
import { BtnReturnComponent } from '../../widgets/btn-return/btn-return.component';
import { ItemsSubtotalPipe, ItemsDiscountPipe, ItemsTaxPipe, ItemsTotalPipe } from '../../../pipes';
import { Location } from '@angular/common'
import { TextReportService, TypeDictionary } from '../../../services/textreport/textreport.service';

import { DataMRTService } from '../viewer/viewer.component';
import { ViewermodalsComponent,paramsViewer } from '../../widgets/viewer-modals/viewer-modals.component';
import { ViewertextComponent } from '../../widgets/viewer-text/viewer-text.component';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Config } from '../../../models/system/User';


@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.css']
})
export class DocumentDetailComponent implements OnInit, OnDestroy, AfterContentInit {

  document: IDocument;
  isQuotation: boolean;

  subtotal: string
  subtotalPipe: ItemsSubtotalPipe;

  discount: string;
  discountPipe: ItemsDiscountPipe;

  tax: string;
  taxPipe: ItemsTaxPipe;

  total: string;
  totalPipe: ItemsTotalPipe;

  documentType: string;
  private ngUnsubscribe = new Subject();

  private navigation: Navigation;
  public docPrint : string = '';
  public docformat: string = '';
  public Id_Doc : string='';

  @ViewChild('returnBtn')btn: BtnReturnComponent;
  public isTabs:boolean=false;

  //@Output() messageEvent = new EventEmitter<any>();
  
  constructor(private _dataService: DataService,
    private _navigationService: NavigationService,
    private _translateService: TranslateService,
    private math: UtilService,
    public _TextReportService: TextReportService,
    private _sessionService: SessionService,
    private invoiceService: InvoicesService,
    private quotationService: QuotationService,
    @Inject(LOCALE_ID) private locale: string,
    // private printerService: PrinterService,
    private location: Location,
    private _dataMRT: DataMRTService,
    private swalService: SweetAlertService,
    public dialog: MatDialog,
    private _Fact: InvoicesService,
    private _notificationService: PopModalService,) {

      this.subtotalPipe = new ItemsSubtotalPipe(this.locale);
      this.discountPipe = new ItemsDiscountPipe(this.locale);
      this.taxPipe = new ItemsTaxPipe(this.locale);
      this.totalPipe = new ItemsTotalPipe(this.locale);

    

      //console.log(this._dataService.document);
      console.log("cargando report..")
      if (this._dataService.document != null) {
        this.document = this._dataService.document;
        this.isQuotation = this.document.Type === 'Quotation';
        this.Id_Doc=_dataService.document.Id;

        if (this.isQuotation) {
          this._translateService.get('Quotation')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(result => {
            this.documentType = result;
          });

          this.invoiceService.getSalesDoc(_dataService.document.Id,'quotation')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(salesDoc => {
          if (salesDoc != null )
             {
                if(salesDoc.DocumentItems.length > 0)
                {
                  this._dataService.document.SalesDoc = salesDoc;
                  this._dataService.document.DocumentItems = salesDoc.DocumentItems;
                  this.reCalculate();
                }
            }
          });
          // this.quotationService.getQuotationById(_dataService.document.Id)
          // .pipe(takeUntil(this.ngUnsubscribe))
          // .subscribe(quotation => {
          //   if (quotation != null && quotation.DocumentItems.length > 0) {
          //     this._dataService.document.Customer.Email = quotation.Email;
          //     if(quotation.Phone)
          //     {
          //       this._dataService.document.Customer.Phones = [{"Number":quotation.Phone}];
          //     }
          //     this._dataService.document.NombVendedor = this._sessionService.getSessionInfo().Extras.name;
          //     this._dataService.document.Customer.RUC = quotation.TaxID;
          //     this._dataService.document.Customer.City = quotation.City;
          //     this._dataService.document.Customer.Name = quotation.NombCli;
          //     this._dataService.document.Customer.Address = quotation.Address;
          //     this._dataService.document.Customer.Id = quotation.IdCliente;
          //     this._dataService.document.DocumentItems = quotation.DocumentItems;
          //     this.reCalculate();
          //   }
          // });

          // this.quotationService.getQuotationItems(_dataService.document.Id)
          // .pipe(takeUntil(this.ngUnsubscribe))
          // .subscribe(documentItems => {
          //   if (documentItems != null && documentItems.length > 0) {
          //     this._dataService.document.DocumentItems = documentItems;
          //     this.reCalculate();
          //   }
          // });
        } else {
          this._translateService.get('Invoice')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(result => {
            this.documentType = result;
          });

          this.invoiceService.getSalesDoc(_dataService.document.Id,'invoice')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(salesDoc => {
            //console.log(salesDoc);
            if (salesDoc != null )
              {
                  //this.document = this._dataService.document;
                  if(salesDoc.DocumentItems.length > 0)
                  {
                    this._dataService.document.SalesDoc = salesDoc;
                    this._dataService.document.DocumentItems = salesDoc.DocumentItems;

                    this.reCalculate();
                  }
              }
            });

          // this.invoiceService.getInvoiceItems(_dataService.document.Id)
          // .pipe(takeUntil(this.ngUnsubscribe))
          // .subscribe(documentItems => {
          //   if (documentItems != null && documentItems.length > 0) {
          //     this._dataService.document.DocumentItems = documentItems;
          //     this.reCalculate();
          //   }
          // });
        }
      } else {
        this._navigationService.navigateToShoppingCart();
      }
    }

    ngOnInit() {
      this.reCalculate();
      this.navigation = this._navigationService.getNavigationItem('document');
      //console.log(this.navigation);
    }

     ngAfterContentInit() {
    //   this.btn.navigation = this.navigation;
     }

    ngOnDestroy() {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }

    getItemDiscountValue(item: InvoiceItem): number {
      if (item != null) {
        return (item.Price * item.Quantity) * ( item.Discount / 100);
      } else {
        return 0;
      }
    }

    getItemQty(item: InvoiceItem): number {

      if (item != null) {
        var factor = 1;
        if (item.Packaging){
          factor = item.Packaging[0].Factor;
        }
        return item.Quantity / factor ;
      } else {
        return 0;
      }
    }

    getItemPrice(item: InvoiceItem): number {

      if (item != null) {
        var factor = 1;
        if (item.Packaging){
          factor = item.Packaging[0].Factor;
        }
        return item.Price *  factor ;
      } else {
        return 0;
      }
    }

    getItemTaxValue(item: InvoiceItem): number {
      if (item != null) {
        return this.math.Round((item.Price * item.Quantity) * ( item.Discount / 100) ,2) * (item.VAT / 100);
      } else {
        return 0;
      }
    }

    getItemTotal(item: InvoiceItem): number {
      if (item != null) {
        //const tax = this.getItemTaxValue(item);
        //const discount = this.getItemDiscountValue(item);
        //const total = this.math.Round((item.Price * item.Quantity) - discount,2) + tax;
        const total = this.math.Round((item.Price * item.Quantity * (1 - item.Discount / 100)),2);
        return total;
      } else {
        return 0;
      }
    }

    getItemQuotationTotal(item: InvoiceItem): number {
      if (item != null) {
        const total = this.math.Round((item.Price * item.Quantity * (1 - item.Discount / 100)),2);
        return total;
      } else {
        return 0;
      }
    }

    private reCalculate() {
      if (this.document != null) {
        this.subtotal = this.subtotalPipe.transform(this.document.DocumentItems, 'narrow');
        this.discount = this.discountPipe.transform(this.document.DocumentItems, 'narrow');
        this.tax = this.taxPipe.transform(this.document.DocumentItems, 'narrow');
        this.total = this.totalPipe.transform(this.document.DocumentItems, 'narrow');
      }

    }

    generatePrinText(){
        console.log(this.document);
        //debugger
        //console.log(this.document);

        let FSuman    : number = 0;
        let FBaseIva  : number = 0;
        let FDescuento: number = 0;
        let FBaseIva0 : number = 0;
        let FIva      : number = Number(this.document['IVATotal']);
        let FTotal    : number = 0;

        if (!FIva){
          //FIva=this.document['SalesDoc']['SalesDocHeader']['TaxDue'];
        }
        //debugger
        for (let item of this.document.DocumentItems){
          FSuman = FSuman + Number(item.Subtotal);
          if (item.VAT==0) {FBaseIva0=FBaseIva0+Number(item.Subtotal);}
          if (item.VAT!=0) {FBaseIva=FBaseIva+Number(item.Subtotal);}
        }
        FTotal = FSuman + FIva;

        this.document['FSuman']     = FSuman;
        this.document['FBaseIva']   = FBaseIva;
        this.document['FDescuento'] = FDescuento;
        this.document['FBaseIva0']  = FBaseIva0;
        this.document['FIva']       = FIva;
        this.document['FTotal']     = FTotal;

        //this.document['ToPay']      = FTotal;

        this.document['NomVend']    = '';
        //this.document['Clave']      = this.document['CIRUC'];
        this.document['DocKey']     = '';
        this.document['Address']    = '';
        this.document['Phone']      = '';
        //this.document['CLAVESRI']   = this.document['CIRUC'];
        /*
        let phone="";
        if (this.document['Phone']){
            for ( let item of this.document['Phone']){
              { phone=phone+item.Number+" "; }
            }
        }
        this.document['Phone']     = phone;
        */


        if (this.document['SalesDoc']){
          if (this.document.SalesDoc['SalesDocHeader']){
            if (this.document.SalesDoc.SalesDocHeader['Salesman']){
              this.document['NomVend'] = this.document.SalesDoc.SalesDocHeader.Salesman;
            }
            if (this.document.SalesDoc.SalesDocHeader['DocKey']){
              this.document['DocKey']   = this.document.SalesDoc.SalesDocHeader.DocKey;
            }
            if (this.document.SalesDoc.SalesDocHeader['CustomerAddress']){
              this.document['Address'] = this.document.SalesDoc.SalesDocHeader.CustomerAddress;
            }
            if (this.document.SalesDoc.SalesDocHeader['CustomerPhone']){
              this.document['Phone'] = this.document.SalesDoc.SalesDocHeader.CustomerPhone;
            }
            if (this.document.SalesDoc.SalesDocHeader['CashChange']){
              this.document['CashChange'] = this.document.SalesDoc.SalesDocHeader.CashChange;
              this.document['CashReceived'] = this.document.SalesDoc.SalesDocHeader.CashReceived;

            }

          }
        }
        //console.log(this.document);

        let dictionary: TypeDictionary = {
          dictionaryTableHead : {
            //-------------Headers--------------
            //CIRUC       : 'RUCCLI',  //document.CIRUC-->template.RUCCLI
            //NombCli     : 'NOMCLI', //document.NombCli-->template.NOMCLI
            CustomerName  : 'NOMCLI',
            CustomerTaxID : 'RUCCLI',
            Address       : 'DIRCLI',//'DIR  CLI'
            NomVend       : "VEND",
            Fecha         : 'FFECHA',
            Id            : 'IDFACT',
            Serie         : 'NUMFACT',
            //DocKey        : 'CLAVE',//'CLAVE',
            CashChange    : 'FCAMBIO',
            CashReceived  : 'EFETOTAL',
            Phone         : 'TELCLI',
            CLAVESRI      : 'CLAVE'
          },
        dictionaryTableDetail : {
            //-------------Detail--------------
            Id          : 'IDPROD',  //document.Code-->template.ICODIGO
            Code        : 'ICODIGO',  //document.Code-->template.ICODIGO
            Quantity    : 'ICANT',    //document.Quantity-->template.ICANT
            Price       : 'IPRECIO',  //document.Price-->template.IPRECIO
            Description : 'PRODDESC', //document.Description-->template.PRODDESC
            Packaging   : 'UNIDAD',   //document.Packaging-->template.UNIDAD
            Subtotal    : 'ISUBTOTAL',//document.Subtotal-->template.ISUBTOTAL
            Discount    : 'PRODD',    //document.Subtotal-->template.ISUBTOTAL
            WarehouseNo : 'BODEGA',    //document.Subtotal-->template.ISUBTOTAL
        },
        dictionaryTableFooter : {
            //-------------Footer--------------
            CIRUC       : 'RUCCLI',
            Cash        : 'FPAGO',

            //FSuman      : 'FSUMAN',
            //Subtotal    : 'FSUMAN',
            //FBaseIva    : 'FBASEIVA',
            Taxable     : 'FBASEIVA',
            FDescuento  : 'FDESCUENTO',
            FBaseIva0   : 'FBASEIVA0',
            //FIva        : 'FIVA',
            TaxDue      : 'FIVA',
            //FTotal      : 'FTOTAL',
            ToPay       : 'FTOTAL',
            NomVend     : 'VEND',
            //Clave       : 'RUCCLI',
            //RUCCLI      : 'CIRUC',
            DocSerie    : 'NUMFACT',
            DocNumber   : 'IDFACT',
            Subtotal    : {
              FieldTemplate: 'FSUMAN',
              Node: 'SalesDocHeader'
            },
            CashChange    : 'FCAMBIO',
            CashReceived  : 'EFETOTAL',
            Notes       : 'NOTE'
            //a:'FPAGOC'
        }

      }
      //console.log(this.docPrint);
      return  this._TextReportService.generateReport(this.docformat,this.document,this.document.DocumentItems,dictionary,this.document.SalesDoc.SalesDocHeader);

    }

    PrintPOS() {

      if(this.document.Type=='NVE'){
        this.docformat = this._sessionService.getSessionInfo().Extras.docformatpv;
      } else {
        this.docformat = this._sessionService.getSessionInfo().Extras.docformat;
      }

      if(this.docformat && this.docformat.length > 0) {
        //AQUI APLICAR EL FORMATO PARA REEEMPLAZAR
        //this.invoiceService.printPOS(docformat).subscribe(
        //this.document.Total  = this.total as unknown as number;

        //console.log(this.document);

        this.docPrint=this.generatePrinText();
        console.log(this.docPrint);
        this.invoiceService.printPOS(this.docPrint).subscribe(
          (response:any) => {
              if (response) {
                //alert("OK");
              }else{
                //alert("KO");
              }
        });

      } else {
        if (this.document.Type=='NVE' || this.document.Type=='FAC') {
          this.onViewerFAC();
        } else if(this.document.Type=='Quotation'){
          this.onViewerPRF();
        }
        else {
          if (this.document.DocumentItems) {
            // this.printerService.configuration.printable = 'printable'
            // this.printerService.print();
            console.log('ViewerOld');
            this._navigationService.navigateToReportViewer(this.document.Type);
          }
        }
      }
    }


    onViewerText(){

      this.docformat = this._sessionService.getSessionInfo().Extras.docformat;
      //console.log(this._sessionService.getSessionInfo().Extras.docformat);
      //this.docformat =  this.tierSample();
      if(this.docformat && this.docformat.length > 0) {
        //this._Fact.GetInvoiceByID(this.document.Id).subscribe( document  => {
        //  console.log(document);
        //  this.document=document;
          this.docPrint=this.generatePrinText();

          let parameters: paramsViewer={
            title       : 'Printer Preview',
            modal       : true,
            dataReport  : this.docPrint,
            onBack      : true
          }

          let dialogConfig = new MatDialogConfig();
          dialogConfig.backdropClass='g-transparent-backdrop';
          dialogConfig.data= parameters;
          dialogConfig.panelClass= 'custom-dialog-containerBox';
          dialogConfig.disableClose=false;
          dialogConfig.width='400px';
          dialogConfig.height='600px';

          let result=this.dialog.open(ViewertextComponent, dialogConfig);
          result.afterClosed().subscribe(result => {
            if (result){
              if (result.success){
              }
            }
          });

        //});
      }
    }

    onViewerFAC(){
      //console.log(this._dataService.document);
      /*
      let Params : paramsViewer ={
        title           : `Invoice`,
        templateMRT     : './assets/reports/invoice.mrt',
        url             : '',
        newGUID         : true,
        onBack          : !this.isTabs,
        aditionalFilters: {
        }
      }
      */
      let Params : paramsViewer ={
        title           : `Invoices`,
        templateMRT     : './assets/reports/invoice.mrt',
        url             : 'Invoices/GetSalesDoc',
        onBack          : !this.isTabs,
        aditionalFilters: {
          docId         : this.Id_Doc,
          docType       : 'invoice'
        }
      }

      let logo64=this._sessionService.getSessionEnterprise().LogoUrl64;
      this._dataService.document.SalesDoc.ReportHeader.Logo=logo64;

      this._dataMRT.paramsViewer = Params;
      this._dataMRT.dataReport = this._dataService.document.SalesDoc as IDocument;
      if (this.isTabs==false){
        this._navigationService.navigateToViewer();
      } else {
          //this.messageEvent.emit(this._dataMRT);
          this._dataMRT.getDataAPi().subscribe(
            (response:any) => {
              if (response != null) {
                  Params.dataReport=response as IDocument;

                  let dialogConfig = new MatDialogConfig();
                  dialogConfig.backdropClass='g-transparent-backdrop';
                  dialogConfig.data= Params;
                  dialogConfig.panelClass= 'custom-dialog-containerBox';
                  dialogConfig.disableClose=false;
                  dialogConfig.width='900px';
                  dialogConfig.height='600px';
                  
                  const dialog = this._notificationService._dialog.open(ViewermodalsComponent, dialogConfig);
                  dialog.afterClosed()
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(() => {
                      //this.loadData();
                  });
              }
            }
          );
    
    

      }
      console.log('ViewerNew');
            /*
      let rawData = logo64.split("base64,");
      let imglogo64=null;
      if (rawData.length > 1) {
        imglogo64 = rawData[1];
      }
      console.log(imglogo64);
      */

    }

    onViewerPRF(){
      let Params : paramsViewer ={
        title           : `Quotation`,
        templateMRT     : './assets/reports/quotation.mrt',
        url             : '',
        newGUID         : true,
        onBack          : true,
        aditionalFilters: {
        }
      }
      this._dataMRT.paramsViewer = Params;
      this._dataMRT.dataReport = this._dataService.document.SalesDoc as IDocument;
      this._navigationService.navigateToViewer();
    }

    onVerRide()
    {
      const SERVERFE = this._sessionService.getSessionInfo().Extras.enterprise.Config.filter(function (config) {
        return config.Name === "SERVERFE";
      })[0]?.Value;
      if (typeof SERVERFE === 'string' && SERVERFE.toUpperCase().startsWith('HTTP')) {
        if (this.document['SalesDoc']){
          if (this.document.SalesDoc['SalesDocHeader']){
            if (this.document.SalesDoc.SalesDocHeader['DocKey']){
              const urlToOpen = `${SERVERFE}PHP/Documento.php?ID=${this.document.SalesDoc.SalesDocHeader['DocKey']}`;
              window.open(urlToOpen, "_blank");
            }
          }
        }
      } else this.onViewerRide();
    }

    onViewerRide(){
      //console.log(this._dataService.document);
      let Params : paramsViewer ={
        title           : `Invoice`,
        templateMRT     : './assets/reports/ride.mrt',
        url             : '',
        newGUID         : true,
        onBack          : true,
        aditionalFilters: {
        }
      }
      let logo64=this._sessionService.getSessionEnterprise().LogoUrl64;
      this._dataService.document.SalesDoc.ReportHeader.Logo=logo64;

      this._dataMRT.paramsViewer = Params;
      this._dataMRT.dataReport = this._dataService.document.SalesDoc as IDocument;
      console.log(this._dataMRT.dataReport);

      this._navigationService.navigateToViewer();
    }

    onBack(){
      this.location.back();
    }

    tierSample(){
      let cad=`
{HEAD}
LABORATORIO BIOTANICALS DEL ECUADOR SA
RUC:1708617004001
KM 15 1/20 VIA DAULE LOTIZACION INDUSTRIAL
PASCUALES AV. ROSABIN MZ55 SOLAR 18
EMAIL:VENTAS@LABORATORIOBIOTANICALS.COM
TELEFONOS: 043906995-103

CLIENTE:[NOMCLI]
C/R:[RUCCLI       ] [FFECHA     ][FHORA]
[SEPARADOR]
DESCRIPCION  CANT.   V.U   DESCUE   V.T
--------------------------------------------
[COMPRESO]
{DETAIL}
[PRODDESC                             ]
[ICODIGO]   [#3  ]  [#4  ] [PRODD] [#5  ]
{FOOT}
[NORMAL]
--------------------------------------------
SUBTO 12%:[#13   ] SUBTO 0%:[#12    ]
IVA   12%:[#14   ] SUBTO   :[#10    ]
ICE:  [ICETOTAL  ] TOTAL   :[#16    ]
ID:[NUMFACT         ]FPAGO:[FPAGOC]
[IDFACT]
DOCUMENTO NO VALIDO PARA TRIBUTACION
DESCARGA FACTURA ELECTRONICA EN:
www.super1001.ec
USUARIO/CLAVE CLIENTE:[RUCCLI         ]
[PAPERCUT]
{VAR}
#3=ICANT:#0.0:5:1
#4=IPRECIO:#0.00:5:2
#5=ISUBTOTAL:#0.00:6:2
#6=IIVASN:&:1:1
#7=FPDESCUENTO:#0:4:2
#8=FPRECARGO:#0:4:2
#10=FSUMAN:#,##0.00:10:2
#11=FDESCUENTO:#,##0.00:10:2
#12=FBASEIVA0:#,##0.00:10:2
#13=FBASEIVA:#,##0.00:10:2
#14=FIVA:#,##0.00:10:2
#15=FRECARGO:#,##0.00:10:2
#16=FTOTAL:#,##0.00:10:2
#17=FCAMBIO:#1,##0.00:10:2
#19=NCOPIAS:0 `;
      return cad;
    }
  }
