import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import { Search2Component } from './search2.component';

@Injectable({ providedIn: 'root' })
export class SearchService {
  private ObjSubjectRx$: Subject<any> = new Subject<any>();
  dialogConfig = new MatDialogConfig();
  public data: object;
  public modal: MatDialogRef<Search2Component>;

  constructor(private router: Router,
    private dialog: MatDialog,
  ) {
  }

  public Show(parameter: any): Observable<any> {
    this.dialogConfig.backdropClass = 'g-transparent-backdrop';
    this.dialogConfig.panelClass = 'custom-dialog-container';
    this.dialogConfig.disableClose = false;
    this.dialogConfig.width = parameter.params.width;// '800px',
    this.dialogConfig.height = parameter.params.height;//'600px',
    this.dialogConfig.data = parameter;
    this.modal = this.dialog.open(Search2Component, this.dialogConfig);
    this.modal.componentInstance.getData().subscribe(result => {
      this.ObjSubjectRx$.next(result);
    });
    return this.ObjSubjectRx$.asObservable();
  }

}
