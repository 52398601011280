<div class="modal-header">
  <button (click)="onClose()" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1">
    <i class="ti-close"></i>
  </button>
  <h2 class="modal-title">{{ title | translate }}</h2> 
</div>

  <div class="row">
    <div class="col-sm-12" >
      <ejs-grid 
        #grid
        id="grid1500" 
        [dataSource]='data' 
         
        [selectionSettings]='selectionOptions'
        
        (rowSelected)='rowSelected($event)' 
        
        (cellSelected)='cellSelected($event)'

        (keyPressed)="keyPressed($event)"
        (recordDoubleClick)="onRecordDoubleClick($event)"
        [height]="heightAG" 
        width='100%'>
        <e-columns>
          <ng-template #template ngFor let-column [ngForOf]="columns">
            <e-column 
              [field]="column.field" 
              [headerText]="column.headerText" 
              [allowEditing]="column.allowEditing"
              [width]="column.width"
              [format]="column.format"
              [textAlign]="column.textAlign" >
              
              <ng-template #template let-data>
                <div *ngIf="column.href" >
                  <a style="color: rgb(4, 2, 85);" >{{data[column.field]}}</a>
                </div>
                <div *ngIf="!column.href" >
                  <div *ngIf="column.format && column.isdate" >
                    {{data[column.field] | date : column.format}}
                  </div>   
                  <div *ngIf="column.format && !column.isdate" >
                    {{data[column.field] | number : column.format}}
                  </div>   

                  <div *ngIf="!column.format" >
                    {{data[column.field]}}
                  </div>   

                </div>
                <div *ngIf="column.inf" >
                  <i style="font-size: 13px;" class="ti-comment-alt" 
                    (click)="onClickInf(data)">
                  </i>
                </div>

              </ng-template>
              
            </e-column>
          </ng-template>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="onClose()">{{ 'Cancel' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="onAceptar()">{{ 'Accept' | translate }}</button>
  </div>



<ng-template #tplPopover>
  <div style="background: #ffffd0;">
      <div class="modal-header" style="padding: 8px;">
          <div [innerHTML]="this.InfoHtml"></div>
      </div>
      <div class="modal-footer" style="padding-top: 5px;padding-bottom: 5px;">
          <button class="btn btn-default btn-circle" 
            (click)="onAcceptPopover()" 
            type="button">
            <i class="fa fa-check"></i>
          </button>
      </div>
  </div>
</ng-template>
