import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Injectable, Injector, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { forkJoin, Observable, Subject } from 'rxjs';
import { FileService, SweetAlertService } from 'src/app/services';
import { APP_SETTINGS } from '../../../appsettings';

const URL = `${APP_SETTINGS.endpoints.image_upload_service}`;

@Component({
  selector: 'upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadfileComponent implements OnInit {
  @ViewChild('imagePreview') imagePreview: ElementRef;
  
  @Input() Api : string;
  @Input() Width : string;
  @Input() Height : string;
  @Input() MaxSize : number;
  
  private ObjSubjectRx$: Subject<any> = new Subject<any>();
  
  isLoading: boolean = false;
  isChecking: boolean = false;
  isForSell: boolean = false;
  isSubmitted = false;
  isControlEnabled = true;
  imgPlaceholder: string | ArrayBuffer = "assets/images/placeholder-image.png";

  imagesUrls = '';
  public uploader: FileUploader;
  public allowedMimeType = ['image/jpeg', 'image/png'];
  public style:string;
  public HeightImg:string;
  public response:string;
  public imageUrl: string | ArrayBuffer;

  constructor(
    private _filesService: FileService,
    private swalService: SweetAlertService) {
    this.isControlEnabled=false;
    
    this.style='150px;';
    this.imageUrl= this.imgPlaceholder;
  }
  ngOnInit() {
    this.HeightImg=`calc(${this.Height} - 65px)`;
    this.setupFileUpload();
  }

  ngAfterViewInit() {
  }

  setupFileUpload() {
    this.uploader = new FileUploader({
      url: URL,
      allowedMimeType: this.allowedMimeType,
      maxFileSize: this.MaxSize  //1*1024*1024 //1MB
    });
    /*
    this.uploader.response.subscribe( res => {
      this.response = res;
      console.log(this.response);
    });
    */
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      if (this.uploader.queue.length > 1) {
        this.uploader.removeFromQueue(this.uploader.queue[0]);
      }
    }
   
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
        let message = '';
        switch (filter.name) {
          case 'fileSize':
            message = 'Warning ! \nThe uploaded file \"' + item.name + '\" is ' + this.formatBytes(item.size) + ', this exceeds the maximum allowed size of ' + this.formatBytes(1024);
            break;
          default:
            message = 'Error trying to upload file '+item.name;
            break;
        }
        this.swalService.getInstance(message, 'error').then(() => {});
    };
    
  }

  formatBytes(bytes, decimals?) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }        

  loadFile(urlFile:string){
    let urls=[urlFile];

    const observables = urls.map(url => this._filesService.getBlobThumbnail(url));
    const source = forkJoin(observables);
    const files: Array<File> = [];

    source.subscribe(
      response => {
        response.forEach((blob, index) => {

          const fullName = urls[index];
          const name = fullName.split('/');
          const file = new File([blob], name.pop(), { type: 'image/jpeg' });
          files.push(file);
        });
      },
      err => { },
      () => {
        this.uploader.addToQueue(files);
      }
    );

  }
  uploadFile(folder:string,EnterpriseNumber?:number) : Observable<any>{
    const fileList = this.uploader.queue;
    console.log(this.imgPlaceholder);

    fileList.forEach(function (fileItem) {
      fileItem.withCredentials = false;
    });
    if (fileList.length > 0) {
      let errorInFiles = true;
      this.uploader.setOptions({
        additionalParameter: { 
          folderName : folder,
          EnterpriseNumber : EnterpriseNumber
        }
      });
      this.uploader.uploadAll();
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        console.log(response);
        const obj = JSON.parse(response);
        if (!obj.error) {
          //console.log(obj);
          this.imagesUrls = this.imagesUrls.concat(obj.url.toLowerCase(), ';');
          errorInFiles = false;


        }
      };

      this.uploader.onCompleteAll = () => {
          let fileReader = new FileReader();
          fileReader.onloadend = (e) => {
              let imageData = fileReader.result;
              let response={
                success:true,//!errorInFiles,
                files:this.imagesUrls,
                base64:imageData
              }
              this.ObjSubjectRx$.next(response);
          }
          fileReader.readAsDataURL(this.uploader.queue[0]._file);
      /*
        let response={
          success:true,//!errorInFiles,
          files:this.imagesUrls
        }
        this.ObjSubjectRx$.next(response);
       */ 
      }
    } else {
      let response={
        success:true,
        files:this.imagesUrls
      }
      window.setTimeout(() => {
        this.ObjSubjectRx$.next(response);
      }, 0);
    }
    return this.ObjSubjectRx$.asObservable();
  }

  removeFile() {
      this.imagePreview.nativeElement.src = this.imgPlaceholder;
  }
}
