import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { PersonnelResourceParameters } from '../../../helpers';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import {format} from 'date-fns';

@Component({
  selector: 'app-personnel-from-to',
  templateUrl: './personnel-from-to.component.html',
  styleUrls: ['./personnel-from-to.component.css'],
})
export class PersonnelFromToComponent implements OnInit, OnDestroy {

  @Input() autocompleteServiceName = 'Personnel';
  @Input() autocompleteDisplayName: string;
  @Input() autocompleteDisplayId: string;
  @Input() selectedItem?: any;

  @Output() data = new EventEmitter<PersonnelResourceParameters>();
  @Output() onSelectedItem = new EventEmitter<any>();
  @Output() onClearSelection = new EventEmitter<void>();

  from: Date = new Date();
  to: Date = new Date();
  selectedEmployeeName: string = null;
  ngUnsubscribe = new Subject();
  minDate: Date = new Date(1999, 12, 31);
  maxDate: Date = new Date(2050, 12, 31);
  bsConfig: BsDatepickerConfig;

  constructor() {
  }

  ngOnInit() {
    this.bsConfig = Object.assign({dateInputFormat: 'YYYY-MM-DD', isAnimated: true, adaptivePosition: true})
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  clearSelectedEmployee() {
    this.selectedEmployeeName = null;
    this.selectedItem = null;
  }

  clearFromPicker() {
    this.to = null;
  }

  clearToPicker() {
    this.to = null;
  }

  trackById(index, item) {
    return index;
  }

  selectEmployee(data) {
    this.selectedEmployeeName = data.EmployeeID;
    this.onSelectedItem.emit(data);
  }

  getData() {
    const resourceParameters = new PersonnelResourceParameters();
    resourceParameters.From = format(this.from, 'yyyy-MM-dd');
    resourceParameters.To = format(this.to, 'yyyy-MM-dd');
    resourceParameters.SearchTerm =  this.selectedEmployeeName;

    this.data.emit(resourceParameters);
  }

  onFromValueChange(value: Date) {
    if (value >= this.minDate && value <= this.maxDate) {
      this.from = value;
    }
  }

  onToValueChange(value: Date) {
    if (value >= this.minDate && value <= this.maxDate) {
      this.to = value;
    }
  }
}
