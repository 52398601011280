import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Company, Companys } from '../../models/system/Company';

import { BehaviorSubject, Observable } from '@angular-devkit/core/node_modules/rxjs';
import { HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService {
  
  constructor(injector: Injector) {
    super(injector);
  }


  createRecord(record: Company) {
    console.log('Ok Grabando..')
    console.log(record);
    const url = this.getApiQuery('crm/create');
    return this._httpClient.post(
      url,
      record,
      { headers: this.getAuthorizationHeader() }
    );
  }

  updateRecord(record: any) {
    const url = this.getApiQuery('Enterprise/Enterprise');  
    return this._httpClient.post(
      url,
      record,
      { headers: this.getAuthorizationHeader() }
    );
  }

  getRecord(ID:Number) : Observable<Company> {
    const url = this.getApiQuery(`Enterprise/Enterprise`);
    let params: HttpParams = new HttpParams();
    if (ID) { params = params.append('ID',ID.toString()); }

    return this._httpClient.get<Company>(
        url,
        { 
          params: params,
          headers: this.getAuthorizationHeader() 
        }
      );
     }

  getList(PageNumber: number, PageSize:number,SearchTerm:string) : Observable<any[]> {
      let params: HttpParams = new HttpParams();
  
      
      if (SearchTerm) { params = params.append('Search',SearchTerm.toString()); }
      if (PageNumber) { params = params.append('PageNumber',PageNumber.toString()); }
      if (PageSize)   { params = params.append('PageSize',PageSize.toString()); }
  
      let url = this.getApiQuery(`Enterprise/GetEnterprises`);
      return this._httpClient.get<Companys[]>(
          url,
          { 
            params: params,
            headers: this.getAuthorizationHeader()
          }
      );
    }
}
