import { Injectable } from '@angular/core';
import { Product, IDocument, Contact, Employee, PersonnelAction, PersonnelPermit, ShiftChange,RoutePlan,ExtendedServiceRequest, Supplier2, User } from '../../models';
import { BehaviorSubject } from 'rxjs';
import { Pago } from '../../models/accountreceivable/PayCustomer';
import { Retencion } from 'src/app/models/accountreceivable/withholdingtaxes';
import { Company, Companys } from 'src/app/models/system/Company';
import { PersonnelVacation } from 'src/app/models/Reports/Vacations';
import { AdvancepaymentRequest } from 'src/app/models/RRHH/Advancepayment-request';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  product: Product = null;

  document: IDocument;

  customer: Contact = new Contact();
  
  productsSelectedCategory: string;

  productsSelectedSubCategory: string;

  productsSelectedBrand: string;

  productsSelectedModel: string;

  productsSelectedPage: number;

  productsSearchtext: string;

  invoiceProcedence: InvoiceProcedence;

  employee: Employee = null;

  personnelAction: PersonnelAction = null;

  personnelPermit: PersonnelPermit = null;
  personnelVacation:PersonnelVacation=null;
  personnelAdvancepayment:AdvancepaymentRequest=null;

  personnelShiftChange: ShiftChange = null;

  defaultCustomerBehaviour = new BehaviorSubject<boolean>(false);

  isDefaultCustomer: boolean;
  checkoutData     : any;
  routePlan        : RoutePlan;
  case             : ExtendedServiceRequest;
  supplier         : Supplier2;
  dataReport       : any;
  PayCustomer      : Pago;
  retencion        : Retencion;
  TypeDoc          : string;
  DocId            : number;
  paramsViewer     : any; 
  version          : string;
  ProductID        : string;

  user             : User = null;
  selFilter        : any = null;

  Company          : Companys;
  docGuide         : any;
  invoiceForGuide  : IDocument;
  adjustment       : any;
  

  constructor() {
   }

  setIsDefaultCustomer(_isDefaultCustomer: boolean) {
    this.isDefaultCustomer = _isDefaultCustomer;
    this.defaultCustomerBehaviour.next(this.isDefaultCustomer);
  }

  clearCheckoutData() {
    this.checkoutData = null;
  }

  
}

export enum InvoiceProcedence {
  starterView = 0,
  customersView = 1
}

export enum PaymentType {
  cash = 'CAJ',
  check = 'CHE',
  credit = 'CXC',
  creditCard = 'TAR',
  retentionApplied = 'RET'
}

