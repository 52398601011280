import { Component, OnInit, ViewChild, ElementRef, Renderer2, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { LandingGeneratorService } from '../../../services';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('cmsContainer') private  container: ElementRef;
  @ViewChild('#navbar') private  navbar: ElementRef;

  constructor(public _renderer: Renderer2, private changeDetector: ChangeDetectorRef, private router: Router, private landingService: LandingGeneratorService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.landingService.clearComponets();
  }

  ngAfterViewInit() {

    this.landingService.renderLandingPage(this.container, null);
  }
}
