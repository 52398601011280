import { Permissions } from './Permissions';
import { Warehouse } from './Sales/Warehouse';

export class SessionInfo {
    AccessToken: string;
    Caja: string;
    Company: string;
    Date: Date;
    DocumentFormat: string;
    IdUser: string;
    Id_Vend: string;
    Name: string;
    Permissions: Permissions;
    Werehouses: Warehouse[];
}
