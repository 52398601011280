import { Injectable, Injector } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from './base/base.service';
import { BaseResult, WebResult } from '../models';
import { Observable } from 'rxjs';
import { APP_SETTINGS } from '../appsettings';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }

  authenticate(username: string, password: string): Observable<WebResult> {

    if (APP_SETTINGS.endpoints.enableSSLLocal) {

      const url = this.getApiQuery('Account/weblogin', true);
      const clientHeaders = new HttpHeaders({ 'Content-Type': 'application/json; utf-8' });

      const params = {'username' : username , 'password' : password};

      // .set('params', JSON.stringify(params))

      const body = new HttpParams()
      .set('params', JSON.stringify(params))
      .set('path', 'Account/weblogin');
      
      return this._httpClient.post<WebResult>(url, body.toString(), {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        

    } else {
    const url = this.getApiQuery('Account/weblogin');
    let params = new HttpParams();
    params = params.append('username', username);
    params = params.append('password', password);
    // params = params.append('path', 'Account/weblogin');

    
    const clientHeaders = new HttpHeaders({'Content-Type': 'application/json; utf-8'});
    const auth = this._httpClient.post<WebResult>(url, null, {headers: clientHeaders, params: params});
    //console.log(auth);
    // return this._httpClient.post<WebResult>(url, null, {headers: clientHeaders, params: params});
    // return this._httpClient.post<WebResult>(url, null, {params: params});
    return auth;
    }
  }

  logout(): Observable<BaseResult> {
    const url = this.getApiQuery('Account/logout');
    return this._httpClient.post<BaseResult>(url, null, {headers: this.getAuthorizationHeader()});
    // return this._httpClient.post<BaseResult>(url, null);
  }

  updateLocalUser(): Observable<BaseResult> {
    const url = this.getApiQueryLocal('Account/create');
    return this._httpClient.post<BaseResult>(url,  null, {headers: this.getAuthorizationHeader()});
  }

  getLocalQuotationUser(Rolid: string) {
    const url = this.getApiQueryLocal('Quotations/getlocal');
    return this._httpClient.post(url, Rolid, { headers: this.getAuthorizationHeader() });
  }

}
