import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, ViewChild, Inject ,Injectable} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CustomersService, DataService, LocationcustomersService, EquipmentcustomersService, PopModalService, SweetAlertService, NavigationService, ToastrService, UtilService, SessionService } from '../../../services';
import { BaseResult, Customer,CustomerLocation } from '../../../models';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { GridComponent,SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';

import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { LocationcustomerComponent } from '../location-customer/location-customer.component';
import { EquipmentcustomerComponent } from '../equipment-customer/equipment-customer.component';
import { Zone } from '../../../models/logistic/Zone';
import { ZoneService } from '../../../services/logistic/zone.service';

import { Salesmans } from '../../../models/Sales/Salesmans';
import { SalesmansService } from '../../../services/sales/Salesmans.service';

import { Groups } from '../../../models/Sales/Groups';
import { GroupsService } from '../../../services/sales/Groups.service';

import { Country } from '../../../models/Sales/country';
import { CountryService } from '../../../services/sales/country.service';
import { CustomerEquipment } from '../../../models/Sales/CustomerEquipment';
import { SearchGridService } from '../../widgets/searchGrid/searchGrid.service';


import { enableRipple } from '@syncfusion/ej2-base';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { DialogboxComponent } from '../../widgets/dialog-box/dialog-box.component';

import { APP_SETTINGS } from '../../../appsettings';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import moment from 'moment';

export interface General {
  Id?       : number;
  Name?     : string;
}

export interface TypeTax {
  id?       : number;
  code      : string;
  name?     : string;
}


export interface Day {
  Num         : number;
  Name        : string;
  Acceso      : boolean;
  formCtrlAcc : string;
}

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'new-customerbasic',
  templateUrl: './new-customerbasic.component.html',
  styleUrls: ['./new-customerbasic.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
  //styles: []
})
export class NewCustomerBasicComponent implements OnInit, OnDestroy {

  @Input() customer: Customer;// Contact;
  @Input() dialogInstance: MatDialogRef<NewCustomerBasicComponent, any>
  @Input() dataModal: any;

  //@Input() dialogInstance: MatDialogRef<NewCustomerComponent, any>

  @ViewChild('gridLocation') public gridLocation: GridComponent;
  @ViewChild('gridEquipment') public gridEquipment: GridComponent;
  //public dataModal: any

  //@ViewChild('toolsRTE')   public rteObj: RichTextEditorComponent;

  /*
    public tools: ToolbarModule = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase','SuperScript', 'SubScript', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateTable', 'CreateLink', 'Image', 'FileManager', '|', 'ClearFormat', 'Print',
            'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
    };
*/

  public selectionOptions: SelectionSettingsModel;

  ngUnsubscribe = new Subject();

  action: string;
  titleComponent: string;

  public Form: FormGroup;
  /*
  dnaControl: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(13)]));
  nameControl: FormControl = new FormControl('', Validators.required);
  addressControl: FormControl = new FormControl('', Validators.required);
  emailControl: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.email]));
  phoneNumberControl: FormControl = new FormControl('', Validators.compose([Validators.minLength(0), Validators.maxLength(14)]));
  */

  CtrTo_Id                : FormControl = new FormControl();
  CtrTo_Code              : FormControl = new FormControl("",[
    //Validators.pattern('[a-zA-Z0-9]*'),
    //Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9  !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~€£¥₩])(?=.*?[A-Z 0-9]).{8,}$"),
    Validators.required,
    Validators.maxLength(10)
  ]);
  CtrTo_TaxId             : FormControl = new FormControl('',Validators.required);
  CtrTo_Address           : FormControl = new FormControl();
  CtrTo_CompanyName       : FormControl = new FormControl();
  CtrTo_Name              : FormControl = new FormControl('',Validators.required);
  CtrTo_Zone              : FormControl = new FormControl();
  CtrTo_City              : FormControl = new FormControl();
  CtrTo_State             : FormControl = new FormControl();
  CtrTo_CountryCode       : FormControl = new FormControl();
  CtrTo_SalesPeopleID     : FormControl = new FormControl();
  CtrTo_GroupID           : FormControl = new FormControl();
  CtrTo_DefaultPrice      : FormControl = new FormControl();
  CtrTo_Email             : FormControl = new FormControl('',Validators.required);
  CtrTo_Phone             : FormControl = new FormControl();
  CtrTo_Phone2            : FormControl = new FormControl();
  CtrTo_WWW               : FormControl = new FormControl();
  CtrTo_Contact1          : FormControl = new FormControl();
  CtrTo_PhoneContact1     : FormControl = new FormControl();
  CtrTo_Depart1           : FormControl = new FormControl();
  CtrTo_Contact2          : FormControl = new FormControl();
  CtrTo_PhoneContact2     : FormControl = new FormControl();
  CtrTo_Depart2           : FormControl = new FormControl();
  CtrTo_Details           : FormControl = new FormControl();
  CtrTo_Note              : FormControl = new FormControl();
  CtrTo_Latitude          : FormControl = new FormControl();
  CtrTo_Longitude         : FormControl = new FormControl();
  CtrTo_VisitDay          : FormControl = new FormControl();
  CtrTo_TaxIdTypeEC       : FormControl = new FormControl();

  CtrTo_TipInd            : FormControl = new FormControl();
  CtrTo_TipNeg            : FormControl = new FormControl();
  CtrTo_TipCom            : FormControl = new FormControl();
  CtrTo_TamEmp            : FormControl = new FormControl();
  CtrTo_CalEmp            : FormControl = new FormControl();

  CtrTo_RepLeg            : FormControl = new FormControl();
  CtrTo_RepCed            : FormControl = new FormControl();
  CtrTo_BirthD            : FormControl = new FormControl();
  CtrTo_TipPer            : FormControl = new FormControl();
  CtrTo_NumEmp            : FormControl = new FormControl();

  CtrTo_FaceBook          : FormControl = new FormControl();
  CtrTo_WhatsApp          : FormControl = new FormControl();
  CtrTo_Twitter           : FormControl = new FormControl();
  CtrTo_Instagram         : FormControl = new FormControl();
  CtrTo_EmailFe           : FormControl = new FormControl();
  CtrTo_C1Ref             : FormControl = new FormControl();
  CtrTo_C2Ref             : FormControl = new FormControl();

  CtrTo_CodCont           : FormControl = new FormControl();
  
  public isSubmitted      : boolean = false;

  public listSalesmans : Array<Salesmans> = [];
  public listGroups : Array<Groups> = [];
  public listZone : Array<Zone> = [];
  public listPrecio : Array<General> = [];
  public listCountry : Array<Country> = [];
  public listTaxTypeEC : Array<TypeTax> = [];

  public listSecEco : Array<General> = [];
  public listTipNeg : Array<General> = [];
  public listAmbNeg : Array<General> = [];
  public listTamNeg : Array<General> = [];
  public listCalifi : Array<General> = [];
  public listTipPer : Array<General> = [];

  public TipoIndustria: string;

  public dataLocation : Array<CustomerLocation> = [];
  public dataEquipment : Array<CustomerEquipment> = [];

  public maxLength = 1000;
  public textArea: HTMLElement;
  public myCodeMirror: any;
  public activeTab = 'datos';
  public varDefaultPrice : number;
  public IDCountryCode: number;
  public IDdefaultCountry:number;

  public listDays: Array<Day> = [];
  public showBack:boolean=true;
  public modal:boolean=false;
  bsSettings: Partial<BsDatepickerConfig>;
  public birthdate     : any;
  private theme = 'theme-red';

  constructor(private _formBuilder: FormBuilder,
    private _customersService: CustomersService,
    private swalService: SweetAlertService,
    private _notificationService: PopModalService,
    private _translateService: TranslateService,
    private _locationService: LocationcustomersService,
    private _equipmentService: EquipmentcustomersService,
    private _dataService: DataService,
    private _zoneService: ZoneService,
    private _SalesmansService: SalesmansService,
    private _GroupsService: GroupsService,
    private _CountryService: CountryService,
    private _navigationService: NavigationService,
    private location: Location,
    public dialog: MatDialog,
    private _toastrService: ToastrService,
    public _UtilService : UtilService,
    private _sessionService: SessionService,
    public searchGrid: SearchGridService,
    //public dialogInstance: MatDialogRef<NewCustomerComponent>,
    //@Inject(MAT_DIALOG_DATA) public dataModal: any
    ) {
      //console.log(this.dataModal);

    this.bsSettings = Object.assign({}, { containerClass: this.theme });
    this.birthdate=new Date();

    this.initCtr();
  }



  ngAfterViewInit(): void {
    //console.log(this.dataModal);
    window.setTimeout(() => {
      let element = document.getElementById('li-tab1') as HTMLElement;
      element.click();
    }, 10);
  }

  public keyPressAlphaNumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  public dataBoundLocation(event) {
    // here we are selecting the row after the refresh Complete
    if (this.dataLocation){
      this.gridLocation.selectRow(0);
    }
  }
  public dataBoundEquipment(event) {
    // here we are selecting the row after the refresh Complete
    if (this.dataEquipment){
      this.gridEquipment.selectRow(0);
    }
  }
  DoubleclickLocation($event){
    this.EditLocation();
  }

  ngOnInit() {

    let labelBtnAction='Update';
    let labelTitle='EditCustomer';
    if (this._dataService.customer == null) {
      labelBtnAction='Register';
      labelTitle='NewCustomer';
    }
    //this.dialogInstance
    //console.log(this.dataModal);
    if (this.dataModal){
      if (this.dataModal.modal){
        this._dataService.customer = null
        this.showBack=false;
        this.modal=true;
        labelBtnAction='Register';
        labelTitle='NewCustomer';
        //this.CtrTo_Code.setValue(this.dataModal.data);
        this.CtrTo_TaxId.setValue(this.dataModal.data);
        //console.log(this.dataModal.data);
      }
    }
    this._translateService.get(labelBtnAction)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: string) => {
          this.action = result;
    });
    this._translateService.get(labelTitle)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: string) => {
          this.titleComponent = result;
    });

    this.selectionOptions = { type: 'Multiple', mode: 'Both' };

    this.listPrecio = this._sessionService.getPrices();

    this.varDefaultPrice=1;


    this.listTaxTypeEC = [
      { id : 1, code:'04', name: 'Ruc' },
      { id : 2, code:'05', name: 'Cédula' },
      { id : 3, code:'06', name: 'Pasaporte' },
      { id : 4, code:'07', name: 'Venta a Consumidor Final*' },
      { id : 5, code:'08', name: 'Identificación del Exterior*' },
    ];

    this.listTamNeg = [
      { Id : 1,  Name: 'MICRO' },
      { Id : 2,  Name: 'PEQUEÑA' },
      { Id : 3,  Name: 'MEDIANA' },
      { Id : 4,  Name: 'GRANDE' }
    ];
    this.listTamNeg = [];

    this.listCalifi = [
      { Id : 1,  Name: 'A' },
      { Id : 2,  Name: 'B' },
      { Id : 3,  Name: 'C' },
      { Id : 4,  Name: 'D' }
    ];

    this.listTipPer = [
      { Id : 1,  Name: 'NATURAL' },
      { Id : 2,  Name: 'JURIDICA' },
    ];

    this._SalesmansService.getListSalesmans().subscribe(
      (response:Array<Salesmans>) => {
        if (response != null) {
          //this.listTruck.id = response['id'];
          this.listSalesmans = response;
        }
    }, error => console.error('An error has occured'));


    this._zoneService.getListZones().subscribe(
      (response:Array<Zone>) => {
        if (response != null) {
          //this.listTruck.id = response['id'];
          this.listZone = response;
        }
    }, error => console.error('An error has occured'));

    this._GroupsService.getListGroups().subscribe(
      (response:Array<Groups>) => {
        if (response != null) {
          //this.listTruck.id = response['id'];
          this.listGroups = response;
        }
    }, error => console.error('An error has occured'));

    this._CountryService.getListCountry().subscribe(
      (response:Array<Country>) => {
        if (response != null) {
          //this.listTruck.id = response['id'];
          this.listCountry = response;
          for (let item of response){
            if (item.CODPAIS==APP_SETTINGS.setups.defaultCountry){
              this.IDCountryCode=item.ID_PAIS;
              this.IDdefaultCountry=item.ID_PAIS;
              break;
            }
          }

        }
    }, error => console.error('An error has occured'));


    this.dataLocation=[];
    this.dataEquipment=[];

    let Dia:Array<any>=[
      { id :  1, name : 'Lunes' },
      { id :  2, name : 'Martes' },
      { id :  4, name : 'Miercoles' },
      { id :  8, name : 'Jueves' },
      { id : 16, name : 'Viernes' },
      { id : 32, name : 'Sabado' },
      { id : 64, name : 'Domingo' },
    ];
    for (let i = 0; i <= 6; i++) {
      let item:Day={
         Num:Dia[i].id,
         Name:Dia[i].name, //'Lunes',
         Acceso:false,
         formCtrlAcc:Dia[i].name//'Lunes'
      }
      this.listDays.push(item);
      this.Form.addControl(item.formCtrlAcc,this._formBuilder.control(item.Acceso));
    }
    this.loadCountry();

    if (this._dataService.customer) {
      this.loadData();
      //this.setCtr();
      //this.loadDataLocation();
      //this.loadDataEquipment();
    }
    if (this._dataService.customer != null) {
      this.Form.controls['Code'].disable();
    }

  }

  private loadData(){
    let contactCrud=this._dataService.customer;
    console.log(contactCrud);
    //contactCrud.CustomerId=278102;
    this._customersService.getContactById(contactCrud.CustomerId).subscribe( //contactCrud.CustomerId
      (response:any) => {
        if (response != null) {
          //this.customer =response;
          console.log(response);
          let contact=response;
          //let contact=this._dataService.customer;
          let tempcustomer : Customer = new Customer();
          tempcustomer.CustomerID = contact.CustomerId;
          tempcustomer.TaxId = contact.RUC;
          tempcustomer.Name= contact.Name;
          tempcustomer.Address= contact.Address;
          tempcustomer.Email= contact.Email;
          tempcustomer.City= contact.Ciudad;
          tempcustomer.DefaultPrice= contact.TipoPrecio;
          tempcustomer.GroupID = contact.Group;
          tempcustomer.Zone = contact.Zone.toString();
          tempcustomer.SalesPeopleID = contact.Id_Vend;
          tempcustomer.State = contact.Estado;
          tempcustomer.CompanyName = contact.CompanyName;
          tempcustomer.CountryCode = contact.Pais;
          tempcustomer.Code = contact.Id;
          tempcustomer.WWW= contact.Website;
          tempcustomer.Note= contact.Notes;
          tempcustomer.Latitude= contact.Lat;
          tempcustomer.Longitude= contact.Long;
          tempcustomer.Details= contact.Observa;
          tempcustomer.Contact1= contact.Contact1;
          tempcustomer.Contact2= contact.Contact2;
          tempcustomer.DefaultPrice=contact.TipoPrecio;
          tempcustomer.VisitDay=contact.VisitDay;
          tempcustomer.TaxIdTypeEC=contact.TaxIdTypeEC;

          tempcustomer.tipoindustria=contact.tipoindustria;
          tempcustomer.tiponegocio=contact.tiponegocio;
          tempcustomer.tipocomercio=contact.tipocomercio;
          tempcustomer.tamanoempresa=contact.tamanoempresa;
          tempcustomer.calificacion=contact.calificacion;
          tempcustomer.representante=contact.representante;
          tempcustomer.representanteid=contact.representanteid;
          tempcustomer.representantedob = contact.representantedob;
          tempcustomer.numeroempleados=contact.numeroempleados;
          tempcustomer.actividad=contact.actividad;
          tempcustomer.TipoPer=contact.TipoPer;

          tempcustomer.TWITTER=contact.TWITTER;
          tempcustomer.FACEBOOK=contact.FACEBOOK;
          tempcustomer.WHATSAPP=contact.WHATSAPP;
          tempcustomer.INSTAGRAM=contact.INSTAGRAM;
          tempcustomer.EMAILFE=contact.EMAILFE;
          tempcustomer.C1REF=contact.C1REF;
          tempcustomer.C2REF=contact.C2REF;


          if (tempcustomer.DefaultPrice==0){
            tempcustomer.DefaultPrice=1;
          }
          let i=0;
          if (contact.Phones){
            for ( let phone of contact.Phones){
              i++;
              if (i==1) { tempcustomer.Phone=phone.Number; }
              if (i==2) { tempcustomer.Phone2=phone.Number; }
            }
          }


          this.customer=tempcustomer;
          console.log(this.customer);
          this.setCtr();
          //this.SetDia(9);
          this.SetDia(this.CtrTo_VisitDay.value); //33
          for (let i = 0; i <= 6; i++) {
            //this.Form.addControl(this.listDays[i].formCtrlAcc,this._formBuilder.control(this.listDays[i].Acceso));
            //this._formBuilder.control(this.listDays[i].Acceso)
            this.Form.controls[this.listDays[i].Name].setValue(this.listDays[i].Acceso);
          }

          this.loadDataLocation();
          this.loadDataEquipment();
        }
      });



  }
  private loadDataLocation(){
    //console.log(this.customer);
    if (this.customer.CustomerID){
        this._locationService.getLocationCustomers(this.customer.CustomerID).subscribe(
          (response:Array<CustomerLocation>) => {
            if (response != null) {
              this.dataLocation = response;
              //this.TitleCase(this.data);
              window.setTimeout(() => {
                if (!this.gridLocation.dataSource) return;
              }, 0);
            }
          }, error => console.error('An error has occured'));
    }
  }

  private loadDataEquipment(){
    //console.log(this.customer);
    if (this.customer.CustomerID){
        this._equipmentService.getEquipmentCustomers(this.customer.CustomerID).subscribe(
          (response:Array<CustomerEquipment>) => {
            if (response != null) {
              this.dataEquipment = response;
              //this.TitleCase(this.data);
              window.setTimeout(() => {
                if (!this.gridEquipment.dataSource) return;
                //this.onFilterData();
              }, 0);
            }
          }, error => console.error('An error has occured'));
    }
  }
  private loadCountry(){
      this._customersService.getCountry().subscribe(
        (response:any) => {
          console.log(response);
          if (response != null) {
            this.listSecEco = response.tipoindustria;
            this.listTipNeg = response.tiponegocio;
            this.listAmbNeg = response.tipocomercio;
            this.listTamNeg = response.tipoempresa;
          }
        }, error => console.error('An error has occured'));
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  allTrim(value:string){
    var cad='';
    var result='';
    if (value){
      cad=value.toString();
      result=cad.replace(/\s/g, "");
    }
    return result;
  }
  onLostFocus_CedRuc(event){
    //console.log(event.target.value);
    let CedRuc=event.target.value;
    let result: BaseResult;
    if(this.CtrTo_CountryCode.value == this.IDdefaultCountry){
      if (this.allTrim(CedRuc)!=""){
        result=this._UtilService.validarDocumento(CedRuc);
        if (result.Successful){
          //this.swalService.getInstance(result.Message, 'success').then(() => {});
          this._customersService.getCustomersByTaxId2(this.CtrTo_TaxId.value)
          .subscribe((response: any) => {
            if (response) {
              this._translateService.get('Cedula/RUC ya existe '+response.Name).subscribe((errorMessage: string) => {
                  this._toastrService.showError(errorMessage, 'Error');
              });
            }
          });
        }else{
          //this.swalService.getInstance(result.Message, 'error').then(() => {});
          this._toastrService.showError(result.Message, 'Error');
        }
      }
    }
  }
  onLostFocus_Code(event){
    let Code=event.target.value;
    if (this.allTrim(Code)!=''){
    this._customersService.getCustomersById(Code)
      .subscribe((response: any) => {
        if (response) {
          this._translateService.get('Código ya existe..').subscribe((errorMessage: string) => {
              this._toastrService.showError(errorMessage, 'Error');
          });
        }
      });
    }
  }

  isFormControlValid(controlName: string) {
    let value=(!this.isSubmitted || !this.Form.controls[controlName].errors) &&
    (this.Form.controls[controlName].valid || this.Form.controls[controlName].untouched);
    return value;
  }

  onValidationCtrl():boolean{
    this.isSubmitted = true;
    if (this.Form.invalid) {
      this._translateService.get('ErrorForm').subscribe((errorMessage: string) => {
        this._toastrService.showError(errorMessage, 'Error');
      });
      if (this.CtrTo_Name.invalid){
        window.setTimeout(() => {
          let element = document.getElementById('li-tab2') as HTMLElement;
          element.click();
        }, 10);
      }

      /*-------Si al menos se validó el primer tab se chequea en el segundo tab --*/
      if (this.CtrTo_Email.invalid){
        window.setTimeout(() => {
          let element = document.getElementById('li-tab2') as HTMLElement;
          element.click();
        }, 10);
      }
      return true;
    }
    return false;
  }

  onSave() {

    if (this.onValidationCtrl()){
      return;
    }
    this.isSubmitted = false;

    let contact=this._dataService.customer;

    if (contact == null) { //--------------Nuevo--------------
      //console.log(this.getForm());
      //console.log(this.dataLocation);
      /*
      let CustomerFULL = {
        customer: this.getForm(),
        location: this.dataLocation,
        equipment: this.dataEquipment
      }
      console.log(CustomerFULL);
      */
      //console.log('nuevo');
      this._customersService.getCustomersByTaxId2(this.CtrTo_TaxId.value)
      .subscribe((response: any) => {
        if (response) {
          this._translateService.get('Cedula/RUC ya existe..').subscribe((errorMessage: string) => {
              this._toastrService.showError(errorMessage, 'Error');
          });
        } else {
          this._customersService.getCustomersById(this.CtrTo_Code.value)
          .subscribe((response: any) => {
            if (response) {
              this._translateService.get('Código ya existe..').subscribe((errorMessage: string) => {
                  this._toastrService.showError(errorMessage, 'Error');
              });
            } else {
                let pasar:boolean=true;
                if (this.CtrTo_CountryCode.value == this.IDdefaultCountry) {
                  let result=this._UtilService.validarDocumento(this.CtrTo_TaxId.value);
                  if (!result.Successful){
                      this._toastrService.showError(result.Message, 'Error');
                      pasar=false;
                  }
                }
                if (pasar){
                    this._customersService.createRecord(this.getForm())
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((response: any) => {
                      if (response) {
                        let id=Number(response);
                        for ( let location of this.dataLocation){
                          location.CustomerID= id;
                          this._locationService.LocationCreate(location)
                              .pipe(takeUntil(this.ngUnsubscribe))
                              .subscribe((response: any) => {})
                        }
                        for ( let equipment of this.dataEquipment){
                            equipment.CustomerID= id;
                            this._equipmentService.EquipmentCreate(equipment)
                                .pipe(takeUntil(this.ngUnsubscribe))
                                .subscribe((response: any) => {})
                        }
                        this.swalService.getInstance(`Customer ${this.CtrTo_Name.value} was registered succesfully`, 'success').then(() => {});
                        if (!this.modal){
                          this._navigationService.navigateToListCustomer();
                        }else {
                          //this.closeDialog();
                          //this.dialogRef.close(this.rowSelect);
                          this.dialogInstance.close(this.getForm());
                        }
                      } else {
                        this.swalService.getInstance('An error has occurred', 'error').then(() => {});
                      }
                    }, error => {
                      this.swalService.getInstance('An error has occurred', 'error').then(() => {});
                  });

                }


            }
          });
        }
      });



    } else {  //-------------Editar--------------
      //console.log('editar');
      let CustomerID = Number(contact.CustomerId)
      //console.log(this.getForm());

      this._customersService.updateRecord(this.getForm())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        if (response) {
          this.swalService.getInstance(`Customer ${this.CtrTo_Name.value} was updated succesfully`, 'success').then(() => {});
          //this.cleanForm();
          /////this.dialogInstance.close();
          if (!this.modal){
            this._navigationService.navigateToListCustomer();
          }else {
            this.closeDialog();
          }
        } else {
          this.swalService.getInstance('An error has occurred', 'error').then(() => {});
          //this.dialogInstance.close();
        }
      }, error => {
        this.swalService.getInstance('An error has occurred', 'error').then(() => {});
      });

    }
  }


  private cleanForm() {
    this.Form.reset('');
  }

  closeDialog() {
    this.dialogInstance.close();
  }


  private initCtr(){
    this.Form = this._formBuilder.group({
        'CustomerID'        :this.CtrTo_Id,
        'Code'              :this.CtrTo_Code,
        'TaxId'             :this.CtrTo_TaxId,
        'CompanyName'       :this.CtrTo_CompanyName,
        'Name'              :this.CtrTo_Name,
        'Address'           :this.CtrTo_Address,
        'Zone'              :this.CtrTo_Zone,
        'City'              :this.CtrTo_City,
        'CountryCode'       :this.CtrTo_CountryCode,
        'State'             :this.CtrTo_State,
        'SalesPeopleID'     :this.CtrTo_SalesPeopleID,
        'GroupID'           :this.CtrTo_GroupID,
        'DefaultPrice'      :this.CtrTo_DefaultPrice,
        'Email'             :this.CtrTo_Email,
        'Phone'             :this.CtrTo_Phone,
        'Phone2'            :this.CtrTo_Phone2,
        'WWW'               :this.CtrTo_WWW,
        'Contact1'          :this.CtrTo_Contact1,
        'PhoneContact1'     :this.CtrTo_PhoneContact1,
        'Depart1'           :this.CtrTo_Depart1,
        'Contact2'          :this.CtrTo_Contact2,
        'PhoneContact2'     :this.CtrTo_PhoneContact2,
        'Depart2'           :this.CtrTo_Depart2,
        'Details'           :this.CtrTo_Details,
        'Note'              :this.CtrTo_Note,
        'latitude'          :this.CtrTo_Latitude,
        'longitude'         :this.CtrTo_Longitude,
        'VisitDay'          :this.CtrTo_VisitDay,
        'TaxIdTypeEC'       :this.CtrTo_TaxIdTypeEC,

        'tipoindustria'     :this.CtrTo_TipInd,
        'tiponegocio'       :this.CtrTo_TipNeg,
        'tipocomercio'      :this.CtrTo_TipCom,
        'tamanoempresa'     :this.CtrTo_TamEmp,
        'calificacion'      :this.CtrTo_CalEmp,

        'representante'     :this.CtrTo_RepLeg,
        'representanteid'   :this.CtrTo_RepCed,
        'birthdate'         :this.CtrTo_BirthD,
        'TipoPer'           :this.CtrTo_TipPer,
        'numemp'            :this.CtrTo_NumEmp,

        'FACEBOOK'          :this.CtrTo_FaceBook,
        'WHATSAPP'          :this.CtrTo_WhatsApp,
        'TWITTER'           :this.CtrTo_Twitter,
        'INSTAGRAM'         :this.CtrTo_Instagram,
        'EMAILFE'           :this.CtrTo_EmailFe,
        'C1REF'             :this.CtrTo_C1Ref,
        'C2REF'             :this.CtrTo_C2Ref,

        'CodCont'           :this.CtrTo_CodCont

    });
    //this.Form.controls['CustomerID'].disable();
  }

  private setCtr(){
    console.log(this.customer);
    if (this.customer != null) {
      this.CtrTo_Id.setValue(this.customer.CustomerID);
      this.CtrTo_Code.setValue(this.customer.Code);
      this.CtrTo_TaxId.setValue(this.customer.TaxId);
      this.CtrTo_CompanyName.setValue(this.customer.CompanyName);
      this.CtrTo_Name.setValue(this.customer.Name);
      this.CtrTo_Address.setValue(this.customer.Address);
      this.CtrTo_Zone.setValue(this.customer.Zone);
      this.CtrTo_City.setValue(this.customer.City);
      this.CtrTo_State.setValue(this.customer.State);
      this.CtrTo_CountryCode.setValue(this.customer.CountryCode);
      this.CtrTo_SalesPeopleID.setValue(this.customer.SalesPeopleID);
      this.CtrTo_GroupID.setValue(this.customer.GroupID);
      this.CtrTo_DefaultPrice.setValue(this.customer.DefaultPrice);
      this.CtrTo_Email.setValue(this.customer.Email);
      this.CtrTo_Phone.setValue(this.customer.Phone);
      this.CtrTo_Phone2.setValue(this.customer.Phone2);
      this.CtrTo_WWW.setValue(this.customer.WWW);
      this.CtrTo_Contact1.setValue(this.customer.Contact1);
      this.CtrTo_PhoneContact1.setValue(this.customer.PhoneContact1);
      this.CtrTo_Depart1.setValue(this.customer.Depart1);
      this.CtrTo_Contact2.setValue(this.customer.Contact2);
      this.CtrTo_PhoneContact2.setValue(this.customer.PhoneContact2);
      this.CtrTo_Depart2.setValue(this.customer.Depart2);
      this.CtrTo_Details.setValue(this.customer.Details);
      this.CtrTo_Note.setValue(this.customer.Note);
      this.CtrTo_Latitude.setValue(this.customer.Latitude);
      this.CtrTo_Longitude.setValue(this.customer.Longitude);
      this.CtrTo_VisitDay.setValue(this.customer.VisitDay);
      this.CtrTo_TaxIdTypeEC.setValue(this.customer.TaxIdTypeEC);

      this.CtrTo_TipInd.setValue(this.customer.tipoindustria);
      this.CtrTo_TipNeg.setValue(this.customer.tiponegocio);
      this.CtrTo_TipCom.setValue(this.customer.tipocomercio);//this.customer.actividad); //Number(this.customer.tipocomercio)
      this.CtrTo_TamEmp.setValue(this.customer.tamanoempresa);
      this.TipoIndustria=this.customer.tipoindustria;

      this.CtrTo_RepLeg.setValue(this.customer.representante);
      this.CtrTo_RepCed.setValue(this.customer.representanteid);
      this.CtrTo_BirthD.setValue(new Date(this.customer.representantedob));
      this.CtrTo_CalEmp.setValue(this.customer.calificacion);
      this.CtrTo_NumEmp.setValue(this.customer.numeroempleados);


      this.CtrTo_WhatsApp.setValue(this.customer.WHATSAPP);
      this.CtrTo_FaceBook.setValue(this.customer.FACEBOOK);
      this.CtrTo_Twitter.setValue(this.customer.TWITTER);
      this.CtrTo_Instagram.setValue(this.customer.INSTAGRAM);
      this.CtrTo_EmailFe.setValue(this.customer.EMAILFE);
      this.CtrTo_C1Ref.setValue(this.customer.C1REF);
      this.CtrTo_C2Ref.setValue(this.customer.C2REF);

      this.CtrTo_CodCont.setValue(this.customer.CodCont);

      console.log(this.CtrTo_TipCom.value);

      //this.CtrTo_CalEmp.setValue(2);

      this.CtrTo_TipPer.setValue(this.customer.TipoPer);
      //this.CtrTo_NumEmp.setValue(this.customer.birthdate);


      //console.log(this.customer);
      //console.log(this.CtrTo_VisitDay.value);
    }
  }

  private getForm(): Customer {
    const record = new Customer();
    record.CustomerID     = this.CtrTo_Id.value;
    record.Code           = this.CtrTo_Code.value;
    record.TaxId          = this.CtrTo_TaxId.value;
    record.CompanyName    = this.CtrTo_CompanyName.value;
    record.Name           = this.CtrTo_Name.value;
    record.Address        = this.CtrTo_Address.value;
    record.Zone           = this.CtrTo_Zone.value;
    record.City           = this.CtrTo_City.value;
    record.State          = this.CtrTo_State.value;
    record.CountryCode    = this.CtrTo_CountryCode.value;
    record.SalesPeopleID  = this.CtrTo_SalesPeopleID.value;
    record.GroupID        = this.CtrTo_GroupID.value;
    record.DefaultPrice   = this.CtrTo_DefaultPrice.value;
    record.Email          = this.CtrTo_Email.value;
    record.Phone          = this.CtrTo_Phone.value;
    record.Phone2         = this.CtrTo_Phone2.value;
    record.WWW            = this.CtrTo_WWW.value;
    record.Contact1       = this.CtrTo_Contact1.value;
    record.PhoneContact1  = this.CtrTo_PhoneContact1.value;
    record.Depart1        = this.CtrTo_Depart1.value;
    record.Contact2       = this.CtrTo_Contact2.value;
    record.PhoneContact2  = this.CtrTo_PhoneContact2.value;
    record.Depart2        = this.CtrTo_Depart2.value;
    record.Details        = this.CtrTo_Details.value;
    record.Note           = this.CtrTo_Note.value;
    record.Latitude       = this.CtrTo_Latitude.value;
    record.Longitude      = this.CtrTo_Longitude.value;
    record.VisitDay       = this.GetDia();
    record.TaxIdTypeEC    = this.CtrTo_TaxIdTypeEC.value;

    record.tipoindustria  = this.CtrTo_TipInd.value;
    record.tiponegocio    = this.CtrTo_TipNeg.value;
    record.tipocomercio   = this.CtrTo_TipCom.value;
    record.tamanoempresa  = this.CtrTo_TamEmp.value;

    record.representante  = this.CtrTo_RepLeg.value;
    record.representanteid= this.CtrTo_RepCed.value;
    record.representantedob= new Date(moment(this.CtrTo_BirthD.value).local().toISOString());
    record.calificacion   = this.CtrTo_CalEmp.value;
    record.numeroempleados= this.CtrTo_NumEmp.value;
    //record.actividad      = this.CtrTo_TipCom.value;
    record.TipoPer        = this.CtrTo_TipPer.value;

    record.TWITTER        = this.CtrTo_Twitter.value;
    record.FACEBOOK       = this.CtrTo_FaceBook.value;
    record.WHATSAPP       = this.CtrTo_WhatsApp.value;
    record.INSTAGRAM      = this.CtrTo_Instagram.value;
    record.EMAILFE        = this.CtrTo_EmailFe.value;
    record.C1REF          = this.CtrTo_C1Ref.value;
    record.C2REF          = this.CtrTo_C2Ref.value;
    record.CodCont        = this.CtrTo_CodCont.value;

    //record.birthdate      = this.CtrTo_NumEmp.value;

    return record;
  }

  AddLocation(){
    if (!this._dataService.customer) {
        //alert("Cliente nuevo");

    }
    console.log(this._dataService.customer);
    let itemRecord : CustomerLocation = new CustomerLocation();
    if (this._dataService.customer) {
      itemRecord.CustomerID = this.customer.CustomerID;
    } else {
      itemRecord.CustomerID = null;
    }

    //console.log(itemRecord);
    const dialog = this._notificationService._dialog.open(LocationcustomerComponent, { width: '1000px', height: '550px' });
    dialog.componentInstance.dialogInstance = dialog;
    dialog.componentInstance.Location = itemRecord;
    dialog.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result:any) => {
        console.log(this._dataService.customer);
        if (result.success){
          if (this._dataService.customer) {
                let location : CustomerLocation = new CustomerLocation();
                location = <CustomerLocation>result.record;
                if (this.customer) {
                    location.CustomerID= this.customer.CustomerID;
                    this._locationService.LocationCreate(location)
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe((response: any) => {
                          if (response) {
                            //this.swalService.getInstance(`${this.CtrTo_DriverName.value} was updated succesfully`, 'success').then(() => {});
                          } else {
                            this.swalService.getInstance('An error has occurred', 'error').then(() => {});
                          }
                        }, error => {
                          this.swalService.getInstance('An error has occurred', 'error').then(() => {});
                    });

                }else
                {
                  location.CustomerID=null;
                }
                //console.log(location);

          }
          (this.gridLocation.dataSource as object[]).unshift(result.record);
          this.gridLocation.refresh();
        }

      });
  }
  EditLocation(){
    const selectedRow: number = this.gridLocation.getSelectedRowIndexes()[0];
    if (this.gridLocation.getSelectedRowIndexes().length) {

        let itemRecord : CustomerLocation = new CustomerLocation();
        itemRecord = <CustomerLocation>this.gridLocation.getCurrentViewRecords()[selectedRow];
        //console.log(itemRecord);

        const dialog = this._notificationService._dialog.open(LocationcustomerComponent, { width: '1000px', height: 'Auto' });
        dialog.componentInstance.dialogInstance = dialog;
        dialog.componentInstance.Location = itemRecord;
        dialog.afterClosed()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result:any) => {
            if (result.success){

              let location : CustomerLocation = new CustomerLocation();
              location = <CustomerLocation>result.record;
              location.CustomerID= this.customer.CustomerID;
              //console.log(location);

              this._locationService.LocationUpdate(location)
                  .pipe(takeUntil(this.ngUnsubscribe))
                  .subscribe((response: any) => {
                    if (response) {
                      //this.swalService.getInstance(`${this.CtrTo_DriverName.value} was updated succesfully`, 'success').then(() => {});
                    } else {
                      this.swalService.getInstance('An error has occurred', 'error').then(() => {});
                    }
                  }, error => {
                    this.swalService.getInstance('An error has occurred', 'error').then(() => {});
              });


              this.dataLocation[selectedRow]=result.record;
              //(this.gridBranch.dataSource as object[]).unshift(result.record);
              this.gridLocation.refresh();
            }
          });
    }
  }
  DelLocation(){
    const selectedRow: number = this.gridLocation.getSelectedRowIndexes()[0];
    if (this.gridLocation.getSelectedRowIndexes().length) {
        (this.gridLocation.dataSource as object[]).splice(selectedRow, 1);
    } else {
        alert('No records selected for delete operation');
    }
    this.gridLocation.refresh();
  }


  //--------------------------Equipment------------------------//
  AddEquipment(){
    let itemRecord : CustomerEquipment = new CustomerEquipment();
    if (this._dataService.customer) {
      itemRecord.CustomerID = this.customer.CustomerID;
    } else {
      itemRecord.CustomerID = null;
    }

    const dialog = this._notificationService._dialog.open(EquipmentcustomerComponent, { width: '1000px', height: '580px' });
    dialog.componentInstance.dialogInstance = dialog;
    dialog.componentInstance.Equipment = itemRecord;
    dialog.componentInstance.Process = 'ADD';
    //console.log(itemRecord);
    dialog.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result:any) => {
        if (result.success){
          console.log(this._dataService.customer);
          if (this._dataService.customer) {
              let equipment : CustomerEquipment = new CustomerEquipment();
              equipment = <CustomerEquipment>result.record;
              if (this.customer) {
                equipment.CustomerID= this.customer.CustomerID;

                this._equipmentService.EquipmentCreate(equipment)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((response: any) => {
                      if (response) {
                        //this.swalService.getInstance(`${this.CtrTo_DriverName.value} was updated succesfully`, 'success').then(() => {});
                      } else {
                        this.swalService.getInstance('An error has occurred', 'error').then(() => {});
                      }
                    }, error => {
                      this.swalService.getInstance('An error has occurred', 'error').then(() => {});
                });

              }else
              {
                equipment.CustomerID==null;
              }

              //console.log(equipment);

            }
            (this.gridEquipment.dataSource as object[]).unshift(result.record);
            this.gridEquipment.refresh();
          }
      });
  }
  EditEquipment(){
    const selectedRow: number = this.gridEquipment.getSelectedRowIndexes()[0];
    if (this.gridEquipment.getSelectedRowIndexes().length) {

        let itemRecord : CustomerEquipment = new CustomerEquipment();
        itemRecord = <CustomerEquipment>this.gridEquipment.getCurrentViewRecords()[selectedRow];

        console.log(itemRecord);

        const dialog = this._notificationService._dialog.open(EquipmentcustomerComponent, { width: '1000px', height: 'Auto' });
        dialog.componentInstance.dialogInstance = dialog;
        dialog.componentInstance.Equipment = itemRecord;
        dialog.componentInstance.Process = 'EDIT';
        dialog.afterClosed()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result:any) => {
            if (result.success){

              let equipment : CustomerEquipment = new CustomerEquipment();
              equipment = <CustomerEquipment>result.record;
              equipment.CustomerID= this.customer.CustomerID;

              this._equipmentService.EquipmentUpdate(equipment)
                  .pipe(takeUntil(this.ngUnsubscribe))
                  .subscribe((response: any) => {
                    if (response) {
                      //this.swalService.getInstance(`${this.CtrTo_DriverName.value} was updated succesfully`, 'success').then(() => {});
                    } else {
                      this.swalService.getInstance('An error has occurred', 'error').then(() => {});
                    }
                  }, error => {
                    this.swalService.getInstance('An error has occurred', 'error').then(() => {});
              });


              this.dataEquipment[selectedRow]=result.record;
              //(this.gridBranch.dataSource as object[]).unshift(result.record);
              this.gridEquipment.refresh();
            }
          });
    }
  }
  DelEquipment(){
    const selectedRow: number = this.gridEquipment.getSelectedRowIndexes()[0];
    if (this.gridEquipment.getSelectedRowIndexes().length) {
        (this.gridEquipment.dataSource as object[]).splice(selectedRow, 1);
    } else {
        alert('No records selected for delete operation');
    }
    this.gridEquipment.refresh();
  }

  onLoadCodCont($event){
    //Accounting/COA?code=2.01.04.01
    let parameters = {
      params  : {
        api           : 'Accounting/COA/Pagging',
        sizePage      : 20,
        width         : '700px',
        height        : '500px',
        aditionalFilters  : {
          Filter: `ID_PLANC=*?*` // el *?* es remplanzado por el inputbox del modal
        }
      },
      columns :[
        { field: "ID_PLANC", headerText: "ID_PLANC", width: "100" },
        { field: "NOMBRE", headerText: "NOMBRE", width: "250" }
      ]
    }
    let dialog=this.searchGrid.Show(parameters);
    dialog.afterClosed().subscribe(result => {
      if (result){
        console.log(result);
        //this.EmployeesID=result.Id;
        //this.CtrTo_CodCont.setValue(result.Name);
        this.CtrTo_CodCont.setValue(result.ID_PLANC);
      }
    });
  }

  GetDia() {
    //debugger
    let Dia:number = 0
    for (let i = 0;i<=6;i++){
        let acceso=this.Form.controls[this.listDays[i].Name].value
        if (acceso){
            Dia = Dia +  this.listDays[i].Num
        }
    }
    return Dia
  }
  SetDia(Dias:number) {
    //debugger
    for (let i = 6;i>=0;i--){
      this.listDays[i].Acceso=false;
      if (Dias-this.listDays[i].Num>=0){
        switch (this.listDays[i].Num) {
          case 128:
            this.listDays[i].Acceso=true; break;
          case 64:
            this.listDays[i].Acceso=true; break;
          case 32:
            this.listDays[i].Acceso=true; break;
          case 16:
            this.listDays[i].Acceso=true; break;
          case 8:
            this.listDays[i].Acceso=true; break;
          case 4:
            this.listDays[i].Acceso=true; break;
          case 2:
            this.listDays[i].Acceso=true; break;
          case 1:
            this.listDays[i].Acceso=true; break;
          default:
            break;
          }
        Dias = Dias - this.listDays[i].Num;
      }
    }
  }



}




  /*
  private generateCustomer(): Contact {
    const customer = new Contact();
    customer.Id = this.dnaControl.value;
    customer.Name = this.nameControl.value;
    customer.RUC = this.dnaControl.value;
    customer.Address = this.addressControl.value;
    customer.Email = this.emailControl.value;
    if (this.phoneNumberControl.value !== null && this.phoneNumberControl.value !== null) {
      const phone = new Phone();
      phone.Number = this.phoneNumberControl.value;
      customer.Phones.push(phone);
    }
    customer.Id_Vend = 0; // preguntar si se asigna el mismo vendedor que lo registr[o]
    customer.TipoPrecio = 1;
    return customer;
  }
  */

    /*
    this.dnaControl.setValue(this.customer.RUC != null ? this.customer.RUC : '');
    this.nameControl.setValue(this.customer.Name);
    this.addressControl.setValue(this.customer.Address);
    this.emailControl.setValue(this.customer.Email);
    if (this.customer.Phones != null) {
      this.phoneNumberControl.setValue(this.customer.Phones.length > 0 ? this.customer.Phones[0].Number : '');
    }
    this.phoneNumberControl.valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data: string) => {
      if (data.length > 14) {
        data = data.substring(0, data.length - 1);
      }
    });
    */

        /*
    this.customerForm = this._formBuilder.group({
      'dna': this.dnaControl,
      'name': this.nameControl,
      'address': this.addressControl,
      'email': this.emailControl,
      'phoneNumber': this.phoneNumberControl
    });
    */
