  export class PersonnelAction {
      ID_ACCPER: number;
      ID_TIPACCP: number;
      TIPONOMB: string;
      TIPONUM: number;
      FECHA: Date;
      ORIGEN: string;
      ID_PER: string;
      NOMBRES: string;
      APELLIDOS: string;
      CEDU_PER: string;
      IESS_PER: string;
      VIGENCIA: Date;
      NOTA: string;
      PROCESOACT: string;
      SUBPROACT: string;
      PROCESOPRO: string;
      SUBPROPRO: string;
      LUGARACT: string;
      LUGARPRO: string;
      SUELDOACT: number;
      SUELDOPRO: number;
      PARPREACT: string;
      PARPREPRO: string;
      ORIGENREF: string;
      ORIGENFEC: Date;
      RESP1: string;
      RESP2: string;
      RESP3: string;
      USUARIO: string;
      ANULADO: boolean;
      NUMCAUCION: number;
      REEMPLAZO: string;
      AUSDES: Date;
      AUSHAS: Date;
      AUSNOTA: string;
      SERIE: number;
  }


  export class PersonnelActionType {
    ID_TIPACCP: number;
    NOMBRE: string;
    REF: string;
  }


