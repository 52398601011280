import { Area } from "../Area";
import { DocumentBase } from "../DocumentBase";

export class Quotation extends DocumentBase {
    Area?: Area;
    City: string;
    Address: string;
    Email: string;
    Phone: string;
    TaxID: string;
}
