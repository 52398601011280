<div class="modal-header">
  <button (click)="onClose()" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1">
    <i class="ti-close"></i>
  </button>
  <h2>{{ title | translate}}</h2>
</div>

<div class="wrapper-content">
  <div class="row">
    <div class="col-sm-12 col-sm-12 m-b-sm m-t-sm">
      <ng-container *ngIf="isLoading">
        <div class="iconoInput">
          <i class="fa fa-cog fa-spin loader-modal-grid" aria-hidden="true">
          </i>
          Loading..
        </div>
      </ng-container>

      <input #idInput (keydown)="onKeydown($event,idInput.value)" type="text"
        placeholder="{{'TypeToSearch' | translate}}" [value]="textDefault" class="form-control" />
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <!--
        [pageSettings]='pageSettings'
        [infiniteScrollSettings]='infiniteOptions'
        enableInfiniteScrolling='true' 
        (scroll)="onScroll($event)"
      -->
      <!--
        [ngClass]="{'sk-loading':isLoading}"
      <div class="sk-spinner sk-spinner-three-bounce" *ngIf="isLoading">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>
      -->

      <!--(dataStateChange)='dataStateChange($event)'-->
      <ejs-grid #grid id="grid1400" [dataSource]='data' [selectionSettings]='selectionOptions'
        (dataBound)='dataBound($event)' (rowSelected)='rowSelected($event)' (cellSelected)='cellSelected($event)'
        (keyPressed)="keyPressed($event)" (recordDoubleClick)="onRecordDoubleClick($event)" [height]="heightAG"
        width='100%'>
        <e-columns>
          <ng-template #template ngFor let-column [ngForOf]="columns">
            <e-column [field]="column.field" [headerText]="column.headerText" [allowEditing]="column.allowEditing"
              [width]="column.width" [format]="column.format" [textAlign]="column.textAlign">

              <ng-template #template let-data>
                <div *ngIf="column.href">
                  <a style="color: rgb(4, 2, 85);">{{data[column.field] | titlecase}}</a>
                </div>
                <div *ngIf="!column.href">
                  <div *ngIf="column.format">
                    {{data[column.field] | number : column.format}}
                  </div>
                  <div *ngIf="!column.format">
                    {{data[column.field] | titlecase}}
                  </div>
                </div>
                <div *ngIf="column.inf">
                  <i style="font-size: 13px;" class="ti-comment-alt" (click)="onClickInf(data)">
                  </i>
                </div>
              </ng-template>

            </e-column>
          </ng-template>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="onClose()">{{ 'Cancel' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="onAceptar()">{{ 'Accept' | translate }}</button>
  </div>
</div>

<ng-template #tplPopover>
  <div style="background: #ffffd0;">
    <div class="modal-header" style="padding: 8px;">
      <div [innerHTML]="this.InfoHtml"></div>
    </div>
    <div class="modal-footer" style="padding-top: 5px;padding-bottom: 5px;">
      <button class="btn btn-default btn-circle" (click)="onAcceptPopover()" type="button">
        <i class="fa fa-check"></i>
      </button>
    </div>
  </div>
</ng-template>