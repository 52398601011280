/**
 * A single tab page. It renders the passed template
 * via the @Input properties by using the ngTemplateOutlet
 * and ngTemplateOutletContext directives.
 */

import { Component, ComponentFactoryResolver, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { InvoiceComponent } from '../../../modules/sales/invoice/invoice.component';
import { DocumentDetailComponent } from '../../../modules/reports/document-detail/document-detail.component';

import { DynamicInvoicesDirective } from './dynamic-invoices.directive';
import { DataService } from '../../../services/helpers/data.service';

@Component({
  selector: 'my-tab',
  styles: [
    `
    .pane{
      padding: 0px 15px 2px 15px;
    }
  `
  ],
  template: `
    <div [hidden]="!active" class="pane" #tab id='tab'>
      <ng-content></ng-content>
      <ng-template dynamic-invoices #container></ng-template>
      <ng-container  *ngIf="template"
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ person: dataContext }"
      >
      </ng-container>
      
      
    </div>
  `
})
export class TabComponent {
  @Input('tabTitle') title: string;
  @Input('tabtype') tabtype: string;
  @Input() active = false;
  @Input() isCloseable = false;
  @Input() template;
  @Input() dataContext;
  @Input() type;
  @ViewChild('tab', {read: ViewContainerRef,static: false}) tab;
  @ViewChild(DynamicInvoicesDirective) DynamicInvoices: DynamicInvoicesDirective;
  @Output() messageEventP = new EventEmitter<string>();
  public message: string;

  constructor( private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private _dataService: DataService )
  {
  }
  
  ngAfterViewInit() {
    window.setTimeout(() => {
      if (this.type=='FAC' || this.tabtype=='FAC'){
        const factory = this.componentFactoryResolver.resolveComponentFactory(InvoiceComponent);
        const viewContainerRef = this.DynamicInvoices.viewContainer;
        const componentRef = viewContainerRef.createComponent(factory);
        this._dataService.TypeDoc="FAC";
        componentRef.instance.isTabs=true;
        componentRef.instance.isInvoice=true;
        componentRef.instance.messageEvent.subscribe(document => {
          this.messageEventP.emit(document);
        });        
      }
      if (this.type=='PRN'){
        const factoryPRN = this.componentFactoryResolver.resolveComponentFactory(DocumentDetailComponent);
        const viewContainerRef = this.DynamicInvoices.viewContainer;
        const componentRef = viewContainerRef.createComponent(factoryPRN);        
        componentRef.instance.isTabs=true;
        //componentRef.instance.messageEvent.subscribe(document => {
          //this.messageEventP.emit(document);
        //});
      }
    }, 100);
  }
  
  receiveMessage($event) {
    this.message = $event;
  }

}
