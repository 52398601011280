import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import * as moment from "moment";

export interface TypeField{
  Name:string;
  Len:number;
  Format?:string;
  Interger?:number;
  Decimals?:number;
  Value?:string;
  FieldTable?:string;
  FieldTemplate?:string;
  FieldComodin?:string;
  isComodin?:boolean;
}
export interface TypeDictionary{
  dictionaryTableHead:any;
  dictionaryTableDetail:any;
  dictionaryTableFooter:any;
}
@Injectable({
  providedIn: 'root'
})

export class TextReportService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }

  private replace(cadena:string,cadold:any,cadnew:any):string {
    let cad = cadena;
    return cad.replace(cadold,cadnew);
  }

  private replaceFieldValue(cadena:string,cadold:any,cadnew:any):string {
    let cad = cadena;
    return cad.replace(cadold,cadnew);
  }


  private getFields(block:any,iVars:any):Array<TypeField>{
    //let headerTemp:string=block;
    let StFields = this.replace(block,/\n|\r/g, '');
    let iFields=StFields.split("[");
    let blockFields:Array<TypeField>=[];
    //debugger
    for (let field of iFields){
      if (field){
        if (field.search(']')>0) {
          let Campo=field.substring(0,field.search(']'));
          let Campo2=Campo.trim();//.replace(/ /g,'');
          let Campo3='';
          let Format='';
          let Ent=0;
          let Dec=0;
          let isComodin=false;
          let Comodin='';
          for (let xvar of iVars){
            if (xvar[0]==Campo2.trim()){
              Campo3=xvar[1];
              Campo2=xvar[1];
              Format=xvar[2];
              Ent=xvar[3];
              Dec=xvar[4];
              Comodin='['+Campo+']';
              isComodin=true;
              break;
            }
          }
          if (Campo3==''){
            Campo3="[" + Campo + "]";
          }else{
            Campo3="[" + Campo3 + "]";
          }
          let fieldSep:TypeField = {
            FieldTemplate : Campo3,
            Name          : Campo2,
            Len           : Campo.length+2,
            Format        : Format,
            Interger      : Ent,
            Decimals      : Dec,
            FieldComodin  : Comodin,
            isComodin     : isComodin,
          }
          blockFields.push(fieldSep);
        }
      }
    }
    
    return blockFields;
  }
  private comodinToTemplate(block:any,iVars:any){
    let blockTemp:string=block;
    for (let field of iVars){
      if (blockTemp.search(field[0])>0){
        let Ini=blockTemp.search(field[0])+field[0].length;
        let parcialBlock=blockTemp.substring(Ini,blockTemp.length);
        let Fin=parcialBlock.search(']');
        blockTemp=blockTemp.substring(0, Ini)+ blockTemp.substring(Ini+Fin);
      }
      blockTemp=this.replace(blockTemp,field[0],field[1]);
    }
    return blockTemp;
  }
  private titleCase(str) {
      return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
  }
  /*
  public isDate(sDate){
    if(sDate.toString() == parseInt(sDate).toString()) return false; 
    var tryDate = new Date(sDate);
    let x= tryDate != 'Invalid Date';
    return (tryDate && tryDate.toString() != "NaN" && tryDate != "Invalid Date"); 
  }
  */


  private isDate(dateStr:string) {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateStr.match(regex) === null) {
      //return false;
    }
  
    const [day, month, year] = dateStr.split('/');
  
    // 👇️ format Date string as `yyyy-mm-dd`
    const isoFormattedStr = `${year}-${month}-${day}`;
  
    const date = new Date(isoFormattedStr);
  
    const timestamp = date.getTime();
  
    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
      return false;
    }
  
    return date.toISOString().startsWith(isoFormattedStr);
  }
  
  private isThere(obj, target):any{
    if (obj){
      for (const [key, value] of Object.entries(obj)) {
        if (key === target) {//if (value === target || key === target) {
          console.log(value);
          
          return value;
        } else if (typeof value === 'object') {
          const result = this.isThere(value, target);
          if(!result){return null;}
        }
      }
    }
    
  };
  
  isThere2(obj,target) {
   for(let prop in obj) {
  	if(typeof(obj[prop]) == "object"){
    	this.isThere2(obj[prop],target);
    } else {
    	if(prop == target) {
          console.log(obj[prop]);
      	  return obj[prop];
      }
    }
  }
  }

  
   findNestedObject(object , keyToMatch = "", valueToMatch = "",Node='') {
    if (object) {
      const entries = Object.entries(object);
  
      for (let i = 0; i < entries.length; i += 1) {
        const [objectKey, objectValue] = entries[i];
        
        if (Node!=''){
          //debugger
          //console.log(Node);
          //console.log(objectKey);
          //console.log(object);
          //console.log(objectValue);

          if (objectKey==Node){
            return objectValue;
          }
          
        } else {
          if (objectKey === keyToMatch) {
            return object;
          }
        }
 
        if (typeof objectValue === 'object' ) {
          //console.log(objectKey);
          const child = this.findNestedObject(objectValue, keyToMatch, valueToMatch,Node);
  
          if (child !== null) {
            return child;
          }
        }
      }
    }
  
    return null;
  };
  private valueToTemplate(block:any,fields:Array<TypeField>,dictionaryTable:any,data:any){//,dataAlter2:any=null){
    //let Fill=this.replace(block,/\[|\]/g, '');
    let Fill=block;
    //debugger;
    for (let field of fields){
      Object.keys(dictionaryTable)
      .forEach(keyDict => {
        let Node='';
        let fieldDict='';
        if (typeof dictionaryTable[keyDict] === 'object' ) {
          //let namefieldDict=dictionaryTable[keyDict].FieldTemplate;  
          //console.log(dictionaryTable[keyDict]);
          let keyDictTemp=dictionaryTable[keyDict].FieldTemplate;
          Node=dictionaryTable[keyDict].Node;
          //console.log(Node);
          //keyDict=keyDictTemp;
          fieldDict=keyDictTemp;
        } else {
          fieldDict=dictionaryTable[keyDict];
        }
        if (field.Name.toUpperCase()==fieldDict.toUpperCase()){
              if (field.Name=='NOMCLI'){
                //debugger
              }
              if (field.Name=='BODEGA'){
                //debugger
              }

              let value:string='';
              if (data){
                if (data.hasOwnProperty(keyDict)) {
                  if (data[keyDict]){
                    value = data[keyDict].toString();
                  }
                } else {

                  //console.log(Node);
                  let obj=this.findNestedObject(data,keyDict,'',Node);
                  //console.log(obj);
                  if (obj){
                    if (obj[keyDict]!=null) value=obj[keyDict];
                  }
                  //value=this.isThere(data,keyDict);

                  //debugger
                }
              }/*
              if (value==null || value=='') {
                if (dataAlter2){
                  if (dataAlter2.hasOwnProperty(keyDict)) {
                    if (dataAlter2[keyDict]){
                      value = dataAlter2[keyDict].toString();
                    }
                  }
                }
                */
                if (value==null) {value='';}
              //}
              let type='string';
              //--Por aqui aplicar espacios y formato si los tiene a value--
              if (field.Format){
                if (field.Interger>0 && field.Decimals>0){
                  let valueFormat = Number(value).toFixed(field.Decimals);
                  value=valueFormat;
                  type='number';
                }
                if (field.Interger>0 && field.Decimals==0){
                  let valueFormat = Number(value).toFixed(0);;
                  value=valueFormat;
                  type='number';
                }
              } //else if (moment(value, moment.ISO_8601, true).isValid()) { //(this.isDate(value)){
                //  type='date';
                //  value=moment(value).format("MM/DD/YYYY");
                //}  
              else {
                type='string';
                //if (value) value=this.titleCase(value);
              }
              if (field.Len>0){
                //debugger
                if (value.length<field.Len){
                  if (type=='string') {
                    let tam=Number(field.Len);//-Number(value.length);
                    value=value.padEnd(tam,' ');
                  }
                  if (type=='number') {
                    let tam:number=(Number(field.Interger)+Number(field.Decimals));//-Number(value.length);
                    if (tam>field.FieldComodin.length) {tam=field.FieldComodin.length;}
                    value=value.padStart(tam,' ');
                  }
                }else{
                  if (value.length>field.Len) value=value.substring(0,field.Len);
                }
              }
              Fill=this.replaceFieldValue(Fill,field.FieldTemplate,value);
              //console.log(Fill);
          }
        
      });
    }
    /*
    for (let item of this.document.DocumentItems){
        Object.keys(item)
          .forEach(e => {
            console.log(`key=${e}  value=${item[e]}`)
        });
    }
    */
    return Fill;
  }

  public generateReport(template:string,data:any,items:any,dictionary:TypeDictionary,dataAlter2:any=null){
    console.log(template);
    //console.log(this.data);
    //console.log(items);

    //-------------Separando los bloques----------------
    let Report=template.split('{DETAIL}');
    Report[0]=this.replace(Report[0],'{HEAD}', '');
    let header=(Report[0]);

    Report=Report[1].split('{FOOT}');
    Report[0]=this.replace(Report[0],'{DETAIL}', '');
    let detail=(Report[0]);

    Report=Report[1].split('{VAR}');
    Report[0]=this.replace(Report[0],'{FOOT}', '');
    let footer=(Report[0]);

    //----Capturando Bloque de  Variables Comodin#---------
    Report[1]=this.replace(Report[1],'{VAR}', '');
    let vars=(Report[1]);
    let xVars=vars.split('\n');
    let iVars:Array<string>=[];
    for (let i=1 ; i<xVars.length-1;i++){
      let cad = this.replace(xVars[i],/\n|\r/g, '');
      cad = this.replace(cad,'=', ':');
      let zVar:any=cad.split(":");
      iVars.push(zVar);
    }
    //console.log(iVars);

    //-----Capturando caracteristica de Variables ----------
    let headerFields=this.getFields(header,iVars);
    let detailFields=this.getFields(detail,iVars);
    let footerFields=this.getFields(footer,iVars);
    //console.log(headerFields);
    //console.log(detailFields);
    //console.log(footerFields);
    
    //------Remplazando variables comodin en template------
    let headerTemp=this.comodinToTemplate(header,iVars);
    let detailTemp=this.comodinToTemplate(detail,iVars);
    let footerTemp=this.comodinToTemplate(footer,iVars);
    //console.log(headerTemp); 
    //console.log(detailTemp);
    //console.log(footerTemp);

    //------Se crea un diccionario porque This.Document------
    //----Tiene variables diferentes a las del Template------
    let dictionaryTableHead = dictionary.dictionaryTableHead;
    let dictionaryTableDetail = dictionary.dictionaryTableDetail;
    let dictionaryTableFooter = dictionary.dictionaryTableFooter;
    
    //console.log(detailFields);
    //console.log(data);
    let headerFill=this.valueToTemplate(headerTemp,headerFields,dictionaryTableHead,data);
    let detailFill:string='';
    for (let item of items){
      //debugger
      detailFill= detailFill + this.valueToTemplate(detailTemp,detailFields,dictionaryTableDetail,item);
      //detailFill = this.replace(detailFill,/\n|\r/g, '');
      //detailFill=detailFill+`\n`;
    }
    let footerFill=this.valueToTemplate(footerTemp,footerFields,dictionaryTableFooter,data);//,dataAlter2);

    //console.log(headerFill)
    //console.log(detailFill);
    //console.log(footerFill);
    let printerTxt = headerFill + detailFill + footerFill;
    //let printerTxt =  detailFill;

    return printerTxt;
  }



}
