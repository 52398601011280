<page-heading pageTitle="{{ titleComponent | translate}}" [showBack]="this.showBack" [backDefaultModal]="true">

  <div *ngIf="this.showBack">
    <button class="btn btn-action btn-primary" (click)="onSave()" type="submit">
      <i class="ti-save"></i><span> {{'Save' | translate}}</span></button>
  </div>

  <div *ngIf="this.modal" class="title-action col-lg-1 col-sm-1 col-xs-1 pull-right" style="padding-right: 1px">
    <button ype="button" class="btn btn-blue" style="padding: 6px;width: 30px;height:30px; margin-top: 2px;"
      tabindex="-1" (click)="closeDialog()">
      <i class="ti-close"></i>
    </button>
  </div>
</page-heading>

<div class="wrapper-content">
  <form [formGroup]="Form" class="form-horizontal form">
    <div class="row">
      <div class="col-md-12">
        <div class="tabs-container">
          <ul class="nav nav-tabs" role="tablist">
            <li><a class="nav-link active" data-toggle="tab" href="#tab-1" id="li-tab1">Datos</a></li>
            <li><a class="nav-link" data-toggle="tab" href="#tab-2" id="li-tab2">Contacto</a></li>
          </ul>
          <div class="tab-content">
            <div role="tabpanel" id="tab-1" class="tab-pane active">
              <div class="panel-body">
                <div class="form-group row">
                  <label class="col-md-2 control-label">{{'ID' | translate}}</label>
                  <div class="col-md-4">
                    <input id="CustomerID" formControlName="CustomerID" class="form-control text-uppercase" type="text"
                      readonly />
                  </div>
                  <label class="col-md-2 control-label">{{'Code' | translate}}</label>
                  <div class="col-md-4">
                    <input id="Id" formControlName="Code" maxlength="10" (keypress)="keyPressAlphaNumeric($event)"
                      class="form-control text-uppercase" type="text" (focusout)="onLostFocus_Code($event)" />
                    <span [ngClass]="{'hide' : isFormControlValid('Code')}" class="alert form-error2"
                      role="alert">{{'RequiredField' | translate}} </span>
                  </div>
                </div>

                <div class="form-group row" style="float: left;">
                  <label class="col-md-2 control-label">{{'TId' | translate}}</label>
                  <div class="col-md-4">
                    <select formControlName="TaxIdTypeEC" class="form-control">
                      <option [value]="item.code" *ngFor="let item of listTaxTypeEC">{{item.name | titlecase }}</option>
                    </select>
                  </div>
                  <label class="col-md-2 control-label">{{'TaxId' | translate}}</label>
                  <div class="col-md-4 input-group" style="padding-left: 15px;padding-right: 15px;">
                    <input id="TaxId" formControlName="TaxId" class="input form-control text-uppercase" type="text"
                      maxlength="15" (focusout)="onLostFocus_CedRuc($event)" />
                    <span [ngClass]="{'hide' : isFormControlValid('TaxId')}" class="alert form-error2"
                      role="alert">{{'RequiredField' | translate}} </span>
                    <span class="input-group-btn">
                      <button type="button" class="btn btn-white" (click)="onLostFocus_CedRuc($event)">
                        <i class="ti-search"></i>
                      </button>
                    </span>
                  </div>

                </div>

                <div class="form-group row">
                  <label class="col-md-2 control-label">{{'BusinessName' | translate}}</label>
                  <div class="col-md-7">
                    <input id="Name" formControlName="Name" class="form-control text-uppercase" type="text"
                      maxlength="100" />
                    <span [ngClass]="{'hide' : isFormControlValid('Name')}" class="alert form-error2"
                      role="alert">{{'RequiredField' | translate}} </span>
                  </div>
                  <label class="col-md-1 control-label">{{'Zone' | translate}}</label>
                  <div class="col-md-2">
                    <select id="Zone" formControlName="Zone" class="form-control">
                      <option value="0" disabled selected>{{ 'SelectZone' | translate }}</option>
                      <option [value]="item.Id" *ngFor="let item of listZone">{{item.Name | titlecase }}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-2 control-label">{{'Tradename' | translate}}</label>
                  <div class="col-md-7">
                    <input id="CompanyName" formControlName="CompanyName" class="form-control full-width text-uppercase"
                      type="text" />
                  </div>
                  <label class="col-md-1 control-label">{{'email' | translate}}</label>
                  <div class="col-md-2">
                    <input id="Email" formControlName="Email" class="form-control text-uppercase" type="text"
                      maxlength="200" />
                    <span [ngClass]="{'hide' : isFormControlValid('Email')}" class="alert form-error2"
                      role="alert">{{'RequiredField' | translate}} </span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-2 control-label">{{'Address' | translate}}</label>
                  <div class="col-md-10">
                    <input id="Address" formControlName="Address" class="form-control full-width text-uppercase"
                      type="text" />
                  </div>
                </div>


                <div class="form-group row">
                  <label class="col-md-2 control-label">{{'City' | translate}}</label>
                  <div class="col-md-3">
                    <input id="City" formControlName="City" class="form-control text-uppercase" type="text" />
                  </div>
                  <label class="col-md-1 control-label">{{'State' | translate}}</label>
                  <div class="col-md-3">
                    <input id="State" formControlName="State" class="form-control text-uppercase" type="text" />
                  </div>
                  <label class="col-md-1 control-label">{{'Country' | translate}}</label>
                  <div class="col-md-2">
                    <select id="CountryCode" name="CountryCode" formControlName="CountryCode"
                      [(ngModel)]="IDCountryCode" class="form-control">
                      <option [value]="item.ID_PAIS" *ngFor="let item of listCountry">{{item.NOMBRE | titlecase }}
                      </option>
                    </select>

                  </div>
                </div>
             
              </div>
            </div>

            <div role="tabpanel" id="tab-2" class="tab-pane">
              <div class="panel-body">
                <div class="form-group row">
                  <label class="col-md-2 control-label">{{'email' | translate}}</label>
                  <div class="col-md-4">
                    <input id="Email" formControlName="Email" class="form-control text-uppercase" type="text"
                      maxlength="200" />
                    <span [ngClass]="{'hide' : isFormControlValid('Email')}" class="alert form-error2"
                      role="alert">{{'RequiredField' | translate}} </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-2 control-label">{{'ContactName' | translate}} 1</label>
                  <div class="col-md-3">
                    <input id="Contact1" formControlName="Contact1" class="form-control text-uppercase" type="text" />
                  </div>

                  <label class="col-md-1 control-label">{{'Phone' | translate}}</label>
                  <div class="col-md-2">
                    <input id="Phone" formControlName="Phone" class="form-control text-uppercase" type="text" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-2 control-label">{{'Observations' | translate}}</label>
                  <div class="col-md-10">
                    <input id="Details" formControlName="Details" class="form-control text-uppercase" type="text" />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="this.modal" class="form-group p-h-sm text-center">
    <button type="submit" mat-button class="btn btn-yellow" (click)="onSave()" placement="bottom">
      <i style="font-size: 22px;margin-left: -1px;" class="ti-save"></i>
      {{'Save' | translate}}
    </button>
  </div>
</div>