<page-heading pageTitle="{{ (!isQuotation ? 'Invoice' : 'Quotation' )| translate }}" [showBack]="!isTabs"
    (clickBack)="onBack()">
    <button *ngIf="!isQuotation" class="btn btn-white btn-action" (click)="onDialogNewInvoice(tplNewInvoice)"
        type="submit" tooltip="{{ 'NewInvoice' | translate }}" placement="bottom">
        <i class="ti-file"></i>
    </button>

    <button *ngIf="!isQuotation" class="btn btn-white btn-action" (click)="loadQuotation(tplProform)" type="submit"
        tooltip="{{ 'LoadQuotation' | translate }}" placement="bottom">
        <i class="ti-layers"></i>
    </button>

    <button *ngIf="!isQuotation" class="btn btn-white btn-action" (click)="onPayments()" type="submit"
        tooltip="{{ 'SaveInvoice' | translate }}" placement="bottom">
        <i class="ti-save"></i>
    </button>

    <button *ngIf="isQuotation" class="btn btn-white btn-action" (click)="onSaveQuotation()" type="submit"
        tooltip="{{ 'SaveQuotation' | translate }}" placement="bottom">
        <i class="ti-save"></i>
    </button>
</page-heading>

<!--

-->
<!--
<div class="contenedor">
    <img class="img-responsive" src="assets/images/bignum2.gif">
    <div class="texto-encima">Factura</div>
    <div class="centrado">000-000-0000001</div>
  </div>
-->

<div class="ibox-content">
    <form [formGroup]="Form">
        <div class="row">
            <div class="col-sm-3" *ngIf="!isQuotation">
                <div class="form-group">
                    <h5 class="Noesp href" (click)="expression()"><b>{{ NameDocNo | translate }}</b></h5>
                    <input class="form-control" style="text-align: right" value={{this.SerieFormat}} readonly>
                </div>
                <div class="form-group">
                    <h5 class="Noesp"><b>{{ 'UniqueId' | translate }}</b></h5>
                    <input class="form-control" style="text-align: right" value={{this.maxID}} readonly>
                </div>

                <div class="form-group">
                    <div style="display:flex;margin-top: -3px;">
                        <h5 class="Noesp">{{ 'Serie' | translate }}</h5>
                        <h5 class="alert  form-error2" style="margin-top: 4px;" role="alert"
                            [ngClass]="{'hide' : isFormControlValid('idSerie')}">
                            {{'RequiredField' | translate}}
                        </h5>
                    </div>
                    <!-- -->
                    <select id="series" class="form-control" [(ngModel)]="idSerie"
                        (ngModelChange)="onChangeSerie($event)" formControlName="idSerie">
                        <!--
                        <option disabled selected>{{ 'SelectSerial' | translate }}</option>
                        -->
                        <option [ngValue]="item.id" *ngFor="let item of listSeries">{{item.formatSerie | titlecase}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <h5 class="Noesp">{{ 'Date' | translate }}</h5>
                    <!--
                        [(bsValue)]="from"
                        (bsValueChange)="onFromDateChange($event)"
                    -->
                    <div class="input-group date">
                        <span class="input-group-addon" (click)="dp.toggle()" [class.disabled]="!this.Session_ModiFecha"
                            [attr.aria-expanded]="dp.isOpen"><i class="fa fa-calendar"></i></span>
                        <input id="date_added" type="text" class="form-control" #dp="bsDatepicker" bsDatepicker
                            [disabled]="!this.Session_ModiFecha" [(bsValue)]="invoice.Fecha" [bsConfig]="bsSettings">
                    </div>
                </div>
                <!--[(ngModel)]="invoice.Id_Vend"-->
                <div class="form-group">
                    <div style="display:flex;margin-top: -3px;">
                        <h5 class="Noesp">{{ 'Seller' | translate }}</h5>
                        <h5 class="alert  form-error2" style="margin-top: 4px;" role="alert"
                            [ngClass]="{'hide' : isFormControlValid('Id_Vend')}">
                            {{'RequiredField' | translate}}
                        </h5>
                    </div>
                    <select id="Id_Vend" class="form-control" [(ngModel)]="invoice.Id_Vend" formControlName="Id_Vend">
                        <!--
                        <option value="0"  disabled selected>{{ 'SelectSalesMan' | translate }}</option>
                        -->
                        <option [value]="item.Id" *ngFor="let item of listSales">{{item.Name | titlecase}}</option>
                    </select>


                </div>
                <div class="form-group">
                    <h5 class="Noesp">{{ 'Price' | translate }}</h5>
                    <!--[(ngModel)]="invoice.TipoPrecio"-->
                    <div class="input-group">
                    <select id="Price"   class="form-control" [(ngModel)]="invoice.TipoPrecio" (ngModelChange)="onSelectTypePrice($event)" formControlName="TipoPrecio">
                        <!--
                        <option value="0"  disabled selected>{{ 'SelectPrice' | translate }}</option>
                        -->
                        <option [value]="item.id" *ngFor="let item of listPrice">{{item.name}}</option>
                    </select>
                    <span class="input-group-btn">
                        <button type="button" class="btn btn-white" (click)="onCalcPrice($event)" tooltip="{{ 'Recalcular Precios' | translate }}" >
                          <i class="ti-loop"></i>
                        </button>
                      </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <div style="display:flex;margin-top: -3px;">
                        <h5 class="Noesp">{{ 'Customer' | translate }}</h5>
                        <h5 class="alert  form-error2" style="margin-top: 4px;" role="alert"
                            [ngClass]="{'hide' : isFormControlValid('Customer')}">
                            {{'RequiredField' | translate}}
                        </h5>
                    </div>

                    <!--[(ngModel)]="selCustomer.Name"  readonly -->
                    <div class="animated input-group">
                        <input type="text" id="Customer" #idInput (keydown)="onKeydown($event,idInput.value)"
                            formControlName="Customer" class="form-control text-uppercase" />
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-yellow" (click)="loadCustomers()">
                                <i class="ti-search"></i>
                            </button>
                        </span>
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-yellow" style="margin-left: 5px;"
                                (click)="newCustomer()">
                                <i class="fa fa-address-book-o"></i>
                            </button>
                        </span>

                    </div>
                </div>
                <div class="row">
                    <label class="col-sm-3 col-form-label">{{'Dna' | translate}}</label>
                    <label class="col-sm-9 col-form-label text-uppercase limit ">{{selCustomer.RUC}}</label>
                </div>
                <div class="row">
                    <label class="col-sm-3 col-form-label">{{'Company' | translate}}</label>
                    <label class="col-sm-9 col-form-label text-uppercase limit">{{selCustomer.Name}}</label>
                </div>
                <div class="row">
                    <label class="col-sm-3 col-form-label">{{'Address' | translate}}</label>
                    <label class="col-sm-9 col-form-label text-uppercase limit">{{selCustomer.Address}}</label>
                </div>
                <div class="row">
                    <label class="col-sm-3 col-form-label">{{'Phones' | translate}}</label>
                    <label class="col-sm-9 col-form-label text-uppercase limit">{{selCustomer.Phones}}</label>
                </div>
                <div class="row">
                    <label class="col-sm-3 col-form-label">{{'Email' | translate}}</label>
                    <label class="col-sm-9 col-form-label text-uppercase limit">{{selCustomer.Email}}</label>
                </div>
            </div>
            <div class="col-sm-3" *ngIf="isQuotation">
                <div class="form-group">
                    <h5 class="Noesp"><b>{{ 'QuotationNo' | translate }}</b></h5>
                    <input class="form-control" style="text-align: right" value={{this.maxID}} readonly>
                </div>
            </div>

        </div>
    </form>

    <div class="row">
        <div class="form-group" style="padding-left:15px;">
            <a class="btn2 btn2-white btn2-bitbucket" (click)="insertProd()" tooltip="{{ 'InsertProd' | translate }}"
                placement="bottom">
                <i class="ti-plus"></i>
            </a>
            <a class="btn2 btn2-white btn2-bitbucket" (click)="deleteRow()" tooltip="{{ 'DeleteProd' | translate }}"
                placement="bottom">
                <i class="ti-minus"></i>
            </a>
            <a class="btn2 btn2-white btn2-bitbucket" *ngIf="!isQuotation" (click)="onDialogSaveAs()"
                tooltip="{{ 'SaveasQuotation' | translate }}" placement="bottom" style="margin-left: 10px;">
                <i class="ti-receipt"></i>
                <!--
                    <i class="fa fa-file-text-o" style="font-size: 16px;"> </i>
                    -->
            </a>

            <!--
                <a class="btn2 btn2-white btn2-bitbucket" (click)="onDialogNewInvoice(tplNewInvoice)" tooltip="{{ 'New Invoice' | translate }}" placement="bottom" style="margin-left: 20px;">
                    <i class="ti-file"></i>
                </a>
                <a class="btn2 btn2-white btn2-bitbucket" (click)="onQuotation(tplProform)" tooltip="{{ 'Load Quotation' | translate }}" placement="bottom">
                    <i class="ti-layers"></i>
                </a>
                <a class="btn2 btn2-white btn2-bitbucket" (click)="onPayments()" tooltip="{{ 'Save Invoice' | translate }}" placement="bottom">
                    <i class="ti-save"></i>
                </a>
                -->

        </div>
        <div class="form-group" style="padding-right:15px;padding-left:15px;">
            <!--
            [toolbar]='toolbarOptions'
            <e-column headerText='..'  width=65  [commands]='commands'></e-column>

            /*--------------No poner estas dos da el error de ----------*/
            [selectionSettings]='selectionOptions' //
            (rowSelected)='rowSelected($event)'
            (actionBegin)='begin($event)'
            (dataStateChange)='dataStateChange($event)'
            (actionBegin)='actionBegin($event)'
            (beginedit)='beginEdit($event)'
            (dataBound)='dataBound($event)'

            /*-----------------------------------------------------------*/
            -->

            <ejs-grid
                #grid
                id="grid"
                [dataSource]='itemInvoice'

                [editSettings]='editSettings'
                [selectionSettings]='selectionOptions'
                [allowResizing]='true'
                [enableHover]='false'
                (rowSelected)='rowSelected($event)'

                (actionBegin)='actionBegin($event)'
                (beginEdit)='beginEdit($event)'

                (actionComplete)='actionComplete($event)'
                (cellSelected)='cellSelected($event)'
                (keyPressed)="keyPressed($event)"
                (recordDoubleClick)="doubleClick($event)"

                (cellSave)='cellsave($event)'
                (cellSaved)='cellsaved($event)'
                (cellEdit)='cellEdit($event)'

                (queryCellInfo)="queryCellInfo($event)"
                [height]='415' [rowHeight]='25'
                >
                <!--foreignKeyValue='Unidad' foreignKeyField='Id'  foreignKeyField='UnityId'  [edit]='txtParams' editType= 'numericedit' [editParams]="{'ShowSpinButton':true} format= 'C2'"-->
                <!--
                    <e-column field='Price'             headerText='Precio'         minWidth= 100 width=100 maxWidth=150    editType= 'numericedit' [edit]='floatParams' format= 'C3' textAlign= 'Right'></e-column>
                    <e-column field='Price'             headerText='Precio'         minWidth= 100 width=100 maxWidth=150    editType= 'numericedit' [edit]='txtParams' format= 'C3' textAlign= 'Right'></e-column>
                -->
                <e-columns>

                    <e-column field='Itemno'            headerText='No'             minWidth= 45  width=55  maxWidth=55     [allowEditing]='false' textAlign='Right'   isPrimaryKey='true'  [customAttributes]='customAttributes'></e-column>
                    <e-column field='Code'              headerText='Codigo'         minWidth= 90  width=130 maxWidth=140    ></e-column>
                    <e-column field='CodBar'            headerText='CodBar'         minWidth= 90  width=130 maxWidth=140    ></e-column>
                    <e-column field='Description'       headerText='Descripción'    minWidth= 250 width=250 maxWidth=300    [allowEditing]='false' ></e-column>

                    <e-column field='Price'             headerText='Precio'         minWidth= 100 width=100 maxWidth=150    editType= 'numericedit' [edit]='floatParams' format= 'C3' textAlign= 'Right' ></e-column> <!-- [allowEditing]='Session_EditPrice'  [allowEditOnDblClick]="false"-->

                    <e-column field='Quantity'          headerText='Cantidad'       minWidth= 80  width=85  maxWidth=90     editType= 'numericedit' [edit]='floatParamsCant' format= 'N2' textAlign= 'Right'  ></e-column> <!--type='number'-->

                    <e-column  field='Unity'            headerText='Unidad'         minWidth= 150 width=150 maxWidth=180    >
                        <ng-template #editTemplate let-data>
                            <ejs-dropdownlist #UnityCmb id="UnityCmb" name="UnityCmb" [dataSource]="Unity3 | async"
                                [fields]='cmbFieldsUnity' (change)="selectUnyt($event)">
                            </ejs-dropdownlist>
                        </ng-template>
                    </e-column>
                    <!-- (created)="onCreate($event)"-->
                    <!--
                    <e-column field='Unity'             headerText='Unidad'         minWidth= 150 width=150 maxWidth=180    editType= 'dropdownedit' [edit]='unityParams' ></e-column>
                    -->

                    <!--
                    <e-column field='Unity'             headerText='Unidad'         minWidth= 150 width=150 maxWidth=180
                        foreignKeyValue="PackagingName" foreignKeyField="Id" [dataSource]="Unity3 | async"  editType= 'dropdownedit' (change)="selectUnyt($event)">
                    </e-column>
                    -->

                    <e-column field='UnityId'           headerText='UnidadId'       minWidth= 100 width=110 maxWidth=120    [allowEditing]='false' [visible]=isVisibleCols ></e-column>
                    <e-column field='Discount'          headerText='%Desc'          minWidth= 80  width=85  maxWidth=90     editType= 'numericedit' [edit]='floatParamsPos' format= 'N2' textAlign= 'Right'  ></e-column>  <!--[allowEditing]='Session_EditDiscount'-->
                    <e-column field='Subtotal'          headerText='Subtotal'       minWidth= 100 width=100 maxWidth=150    [allowEditing]='false' textAlign= 'Right' editType= 'numericedit'  format= 'C4'></e-column>
                    <!--
                    <e-column field='Storage'           headerText='Bod.'           minWidth= 80  width=85  maxWidth=90     editType= 'numericedit' [edit]='numericParamsBod' textAlign= 'Right'  ></e-column>
                    <e-column field='Storage'           headerText='Bod.'           minWidth= 100 width=110 maxWidth=120    editType= 'dropdownedit' [edit]='storeParams' textAlign= 'Center'></e-column>
                    -->
                    <e-column field='WarehouseName' headerText='Bodega' minWidth=100 width=100 maxWidth=200
                        editType='dropdownedit' [edit]='WarehouseParams' textAlign='Left'></e-column>
                    <e-column field='WarehouseNo' headerText='Storage' minWidth=80 width=85 maxWidth=90
                        [allowEditing]='false' [visible]=isVisibleCols></e-column>
                    <e-column field='Stock' headerText='Stock' minWidth=100 width=100 maxWidth=150
                        [allowEditing]='false' editType='numericedit' [edit]='floatParams' format='N2'
                        textAlign='Right'></e-column>
                    <!--
                    <e-column  field='Storage'            headerText='Bod.'         minWidth= 90  width=95  maxWidth=100  >
                        <ng-template #editTemplate let-data>
                            <ejs-dropdownlist id="Storage" name="Storage" [dataSource]="this.StoreGen" [fields]='cmbFieldsStore' (change)="selectStore($event)">
                            </ejs-dropdownlist>
                        </ng-template>
                    </e-column>
                    -->

                    <e-column field='IVA'               headerText='%Iva'           minWidth= 100 width=110 maxWidth=120    editType= 'dropdownedit' [edit]='taxesParams' ></e-column> <!--[allowEditing]='Session_IvaChange'-->
                    <e-column field='IvaId'             headerText='IdIva'          minWidth= 80  width=85  maxWidth=90     [allowEditing]='false' [visible]=isVisibleCols ></e-column>
                    <e-column field='IvaPorc'           headerText='Iva%'           minWidth= 80  width=85  maxWidth=90     [allowEditing]='false' [visible]=isVisibleCols ></e-column>
                    <e-column field='TotIva'            headerText='Prec.Iva'       minWidth= 80  width=85  maxWidth=90     [allowEditing]='false' format= 'N2' textAlign= 'Right' ></e-column>



                </e-columns>
            </ejs-grid>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-8">
            <div class="form-group">
                <h5 class="Noesp"><b>{{ 'Nota:' | translate }}</b></h5>
                <textarea class="form-control" name="Nota" [(ngModel)]="invoice.Nota" rows="4"></textarea>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="row">
                <label class="col-sm-7 col-form-label" style="text-align: right">{{'NetSum' | translate}}</label>
                <label class="col-sm-5 col-form-label" style="text-align: right">{{SubTotal | number : '1.2-2'}}</label>
            </div>
            <div class="row">
                <label class="col-sm-7 col-form-label" style="text-align: right">{{'FinalDiscount' | translate}}</label>
                <label class="col-sm-5 col-form-label" style="text-align: right">{{Discount | number : '1.2-2'}}</label>
            </div>
            <div class="row">
                <label class="col-sm-7 col-form-label" style="text-align: right">{{'ICE' | translate}}</label>
                <label class="col-sm-5 col-form-label" style="text-align: right">{{Ice | number : '1.2-2'}}</label>
            </div>
            <div class="row">
                <label class="col-sm-7 col-form-label" style="text-align: right">{{'Tax' | translate}}</label>
                <label class="col-sm-5 col-form-label" style="text-align: right">{{Iva | number : '1.2-2'}}</label>
            </div>
            <div class="row">
                <label class="col-sm-7 col-form-label" style="text-align: right">{{'Total' | translate}}</label>
                <label class="col-sm-5 col-form-label" style="text-align: right">{{Total | number : '1.2-2'}}</label>
            </div>

        </div>

    </div>

</div>



<ng-template #tplProform>

    <page-heading pageTitle="{{ 'QuotationNo' | translate }}">
        <button type="button" class="btn btn-primary" tabindex="-1" (click)="closeDialogPRF()">
            <i class="ti-close"></i>
        </button>
    </page-heading>

    <div class="ibox ">
        <form [formGroup]="FormPrf">
            <div class="ibox-content">
                <div class="row form-group">
                    <label class="col-md-5 col-form-label">{{'QuotationNo' | translate}}</label>
                    <div class="col-md-7">
                        <input id="NPrf" formControlName="NPrf" class="form-control text-uppercase" type="text" />
                    </div>
                </div>
            </div>
            <div class="ibox-footer text-center">
                <button type="submit" mat-button class="btn btn-yellow m-r-sm" [disabled]="!FormPrf.valid"
                    (click)="onAcceptPRF()">
                    {{'Accept' | translate}}
                </button>
                <button type="submit" mat-button class="btn btn-yellow" (click)="onCancelPRF()">
                    {{'Cancel' | translate}}
                </button>
            </div>
        </form>
    </div>

</ng-template>

<ng-template #tplNewInvoice>

    <page-heading pageTitle="{{ 'NewInvoice' | translate }}">
        <button type="button" class="btn btn-primary" tabindex="-1" (click)="closeDialogNewFact()">
            <i class="ti-close"></i>
        </button>
    </page-heading>

    <div class="ibox ">
        <div class="ibox-content">
            <p class="p-w-sm">{{'DCNF' | translate}}</p>
        </div>
        <div class="ibox-footer text-center">
            <button type="submit" mat-button class="btn btn-yellow m-r-sm" (click)="onAcceptNewFact()">
                {{'Accept' | translate}}
            </button>
            <button type="submit" mat-button class="btn btn-yellow" (click)="onCancelNewFact()">
                {{'Cancel' | translate}}
            </button>
        </div>
    </div>

</ng-template>

<ng-template #tplDialog>
    <page-heading pageTitle="{{this.dialogTitle}}">
        <button type="button" class="btn btn-primary" tabindex="-1" (click)="onCloseDialogYN()">
            <i class="ti-close"></i>
        </button>
    </page-heading>

    <div class="ibox ">
        <div class="ibox-content">
            <p class="p-w-sm">{{this.dialogMessage}}</p>
        </div>
        <div class="ibox-footer text-center">
            <button type="submit" mat-button class="btn btn-yellow m-r-sm" (click)="onAcceptDialogYN()">
                {{'Accept' | translate}}
            </button>
            <button type="submit" mat-button class="btn btn-yellow" (click)="onCancelDialogYN()">
                {{'Cancel' | translate}}
            </button>
        </div>
    </div>
</ng-template>
