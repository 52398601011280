import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpParams } from '@angular/common/http';
import { Fact, IDocument, Type_invoice } from '../../models';
import { Observable } from 'rxjs';
import { Quotation } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }


  getSeries(DocType:string) {
    let params: HttpParams = new HttpParams();

    if (DocType) { params = params.append('DocType',DocType); }

    const url = this.getApiQuery(`Account/GetSeries`);
    return this._httpClient.get(url, {
      params: params,
      headers: this.getAuthorizationHeader()
    });
  }

  insertInvoice(invoice: Type_invoice) {
    const url = this.getApiQuery('Customer/InsertFactura');
    //let params = new HttpParams();
    //params = params.append('encodedFile', '');
    /*
    let json={
      invoice : invoice
    }
    */
    return this._httpClient.post(
      url,
      invoice,
      { headers: this.getAuthorizationHeader()}
    );
  }
  insertQuotation(invoice: Type_invoice) {
    const url = this.getApiQuery('Customer/InsertQuotation');
    return this._httpClient.post(
      url,
      invoice,
      { headers: this.getAuthorizationHeader()}
    );
  }

  InsertProforma(Quotation: Quotation) {
    const url = this.getApiQuery('Customer/InsertProforma');
    return this._httpClient.post(
      url,
      Quotation,
      { headers: this.getAuthorizationHeader()}
    );
  }

  InvoiceQuotation(Quotation: Quotation) {
    const url = this.getApiQuery('Customer/InvoiceQuotation');
    return this._httpClient.post(
      url,
      Quotation,
      { headers: this.getAuthorizationHeader()}
    );
  }


  GetInvoiceByID(docId:any): Observable<any> {
    const url = this.getApiQuery('Invoices/GetInvoiceByID');
    let params = new HttpParams();
    params = params.append('docId', docId);
    return this._httpClient.get<any>(url, {headers: this.getAuthorizationHeader(), params: params});
  }
  ProductStocks(ProductStock:any,quotationId:any): Observable<any> {
    const url = this.getApiQuery(`Products/ProductStocks?quotationId=${quotationId}`);
    //let params = new HttpParams();
    //params = params.append('ProductStock', ProductStock);
    //params = params.append('quotationId', quotationId);
    //return this._httpClient.get<any>(url, {headers: this.getAuthorizationHeader(), params: params});
    return this._httpClient.post(
      url,
      ProductStock,
      { headers: this.getAuthorizationHeader()}
    );
  }
}
