import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_SETTINGS } from '../../appsettings';
import { NavigationService } from '../helpers/navigation.service';
import { SessionService } from '../helpers/session.service';
import { NoLoginGuardService } from './no-login.guard.service';

@Injectable({
  providedIn: 'root'
})
export class GoToLandingGuardService implements CanActivate {

  constructor(private _navigationService: NavigationService, private sessionService: SessionService,
    private noLoginGuardService: NoLoginGuardService) {

  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const goLanding = APP_SETTINGS.features.enableLandingPage;
    if (goLanding) {
      return true;
    }

    const session = this.sessionService.getSessionInfo();
    //console.log(session);
    if (APP_SETTINGS.features.disableLogin && !session) {
      if (this.noLoginGuardService.canActivate()) {
        this._navigationService.navigateToStore();
      }
    }
    if (APP_SETTINGS.features.disableLogin && session) {
        this._navigationService.navigateToStore();
    } else {
      this._navigationService.navigateToLogin();
    }

    return false;
  }

}
