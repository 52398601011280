<p><i class="fa fa-map-marker"></i> {{customer?.Address}}</p>
<agm-map [latitude]="customer?.Lat"
         [longitude]="customer?.Long"
         style="height: 500px;" (mapClick)="mapClicked($event)">
    <agm-marker *ngFor="let marker of markers"
                (markerClick)="clickedMarker(marker.label,1)"
                [latitude]="marker.lat"
                [longitude]="marker.lng"
                [label]="marker.label"
                [markerDraggable]="marker.draggable"
                (dragEnd)="markerDragEnd($event)">
            <agm-info-window>
                <strong>{{marker?.label}}</strong>
            </agm-info-window>
    </agm-marker>
</agm-map>
