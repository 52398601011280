import {
  Directive,
  ElementRef,
  HostListener,
} from "@angular/core";

@Directive({
  selector: "[numericOnly]",
})

export class NumericOnlyDirective {
  constructor(private el: ElementRef) {}
  @HostListener("keypress", ["$event"]) onKeyPress(event: KeyboardEvent) {
    return this.validateNumeric(event);
  }

  validateNumeric(event: KeyboardEvent) {
    var inp = String.fromCharCode(event.keyCode);

    if (/^\d+$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
