import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../helpers/data.service';
import { NavigationService } from '../helpers/navigation.service';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PersonnelActionReportGuard implements CanActivate {

  constructor(private dataService: DataService, private navigationService: NavigationService) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.dataService.personnelAction) {
      if (this.dataService.personnelAction.ID_PER) {
        return true;
      } else {
        this.navigationService.navigateToPersonnelActions(null);
        return false;
      }
    } else {
      this.navigationService.navigateToPersonnelActions(null);
      return false;
    }
  }
}
