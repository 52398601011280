import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CustomersService,SessionService,SweetAlertService } from '../../../../services';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { enableRipple } from '@syncfusion/ej2-base';
import { takeUntil} from 'rxjs/operators';

import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label,BaseChartDirective, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';

import * as ChartDataLabels from 'chartjs-plugin-datalabels';

//import { BaseChartDirective }   from 'ng2-charts/ng2-charts';
//import * as pluginLabels from 'chartjs-plugin-labels';
//import { SingleDataSet, Label } from 'ng2-charts';
//import * as pluginLabels from 'chartjs-plugin-labels';
//import DatalabelsPlugin from 'chartjs-plugin-datalabels';
//import 'chart.piecelabel.js';
//import * as pluginLabels from 'chartjs-plugin-labels';




export interface TypeTipo{
  id:number;
  name:string;
}
export interface prmsPie {
  title           : string;
  subtitle?       : string;
  pieChartLabels  : Array<string>;
  pieChartData    : Array<any>;
}


enableRipple(false);

@Component({
  selector: 'board-chartpie',
  templateUrl: './board-chartpie.component.html',
  styleUrls: ['./board-chartpie.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BoardchartpieComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  public nav:any;
  public todos: any;
  @Input() data : prmsPie;
  
  @ViewChild("chart") chart: BaseChartDirective;
  //@ViewChild(BaseChartDirective) chart: BaseChartDirective;

  /*-----------------Chart PIE------------------*/

  public pieChartOptions:  ChartOptions;
  public pieChartLabels: Label[];
  public pieChartData: SingleDataSet;
  public pieChartType: ChartType;
  public pieChartLegend = true;
  public pieChartPlugins = [];
  

  //public chartPlugins = [pluginDataLabels];

  constructor(private _formBuilder: FormBuilder){
    
    this.pieChartOptions = {
      responsive: true,
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let datasets = ctx.chart.data.datasets;
            if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
              //let sum = datasets[0].data.reduce((a, b) => a + b, 0);
              ///let percentage = Math.round((value / sum) * 100) + '%';
              //let percentage = ((value / sum ) * 100).toFixed(2) + "%";
              //return percentage;
              let sum = 0;
              let dataArr: any[] = ctx.chart.data.datasets[0].data;
              dataArr.map((data: number) => {
                sum += data;
              });
              let percentage = ((value / sum ) * 100).toFixed(2) + "%";
              return percentage;

            } else {
              return 0;
            }
          },
          color: 'black',
        }
      }   


      //maintainAspectRatio: true,
      /*
      labels: [
        {
          render: 'label',
          position: 'outside'
        },
        {
          render: 'percentage'
        }
      ],
      */
      /*
      plugins: {
        legend: {
          display: true,
          position: 'top',
        },
        datalabels: {
          formatter: (value, ctx) => {
            if (ctx.chart.data.labels) {
              return ctx.chart.data.labels[ctx.dataIndex];
            }
          },
        },
      }
      */
      /*
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: ['green', 'white', 'red'],
          precision: 2
        }
      },
      */
      /*
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          label: function (tooltipItems, data) {
            return data.datasets[0].data[tooltipItems.index] + ' %';
          }
        }
      },
      */
      /*
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let sum = 0;
            let dataArr: any[] = ctx.chart.data.datasets[0].data;
            dataArr.map((data: number) => {
              sum += data;
            });
            let percentage = (value * 100 / sum).toFixed(2) + "%";
            return percentage;
          },
          color: '#fff',
        }
      }
      */
        
    };
    this.pieChartType = 'pie';
    this.pieChartLegend = true;
    this.pieChartPlugins = [ ChartDataLabels ];

    //this.pieChartLabels = ['January', 'February', 'March'];
    //this.pieChartData = [50445, 33655, 15900];
    //this.pieChartPlugins = [pluginLabels];
    //this.pieChartPlugins = [pluginLabels];
    //this.pieChartPlugins = [pluginLabels];
    
  }
  getData(){

    this.pieChartLabels = this.data.pieChartLabels;
    this.pieChartData   = this.data.pieChartData;

  }

  refresh(data:prmsPie){
    
    this.pieChartLabels = data.pieChartLabels;
    this.pieChartData   = data.pieChartData;
    window.setTimeout(() => {
    }, 100);
    
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.getData();
  }

  
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  chartClicked(e: any): void {
    console.log(e.active);
    console.log(e.event);
  }

  chartHovered(e: any): void {
    console.log(e);
  }

}
