import { Enterprise } from "../Enterprise";
import { IDocument } from "../IDocument";

export class Invoice extends IDocument{
    GlobalDiscount: number;
    Type: string;
    CIRUC: string;
    IVATotal: number;
    Total: number;
    Enterprise: Enterprise;
    NombCli: string;
    ToPay: number;
    Debt: number;
    Expires: Date;
    Address: string;
    Cash: number;
    Serie: string;
}
