  <mat-form-field class="full-width">
    <ng-container
    *ngTemplateOutlet="selectedItem ? selectedItemTemplate: notSelectedItemTemplate">
  </ng-container>

  <ng-template #notSelectedItemTemplate>
    <input #txt type="text" [placeholder]="placeholder" matInput [matAutocomplete]="auto" [formControl]="searchControl"/>
  </ng-template>

  <ng-template #selectedItemTemplate>
    <input #txt type="text" [placeholder]="placeholder" matInput [(ngModel)]="sanitizedSelection" (click)="clearSelection()"/>
  </ng-template>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayMember" (optionSelected)="raiseItemSelected($event)">
    <mat-option *ngIf="isLoading" class="is-loading">
      <mat-progress-bar mode="query"></mat-progress-bar>
    </mat-option>
    <ng-container *ngIf="!isLoading">
      <mat-option *ngFor="let item of dataSource; trackBy:trackById" [value]="item">
        <div class="ac-row-items">
          <i class="fa fa-user"></i>
          <div class="ac-column-items">
            <span>{{item[displayName]}}</span>
            <span class="small-item">{{item[displayId]}}</span>
          </div>
        </div>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <button mat-icon-button matSuffix (click)="clearSelection()" *ngIf="selectedItem">
    <i class="ti-close"></i>
  </button>
</mat-form-field>

