<!-- Wrapper-->
<div id="wrapper">

    <!-- Left navigation bar -->
    <navigation></navigation>

    <!-- Main page wrapper -->
    <div id="page-wrapper" class="white-bg">

        <!-- Top navigation -->
        <topnavbar></topnavbar>

        <!-- Main view/routes wrapper-->
        <router-outlet></router-outlet>

    </div>
    <!-- End page wrapper-->

</div>
<!-- End wrapper-->
