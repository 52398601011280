import { Component, OnInit } from '@angular/core';
import { DataService, NavigationService } from '../../../services';
import { Contact } from '../../../models';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styles: []
})
export class ProfileComponent implements OnInit {

  customer: Contact;
  constructor(private _dataService: DataService, private _navigationService: NavigationService) {
    this.customer = this._dataService.customer;
    if (this.customer.Id == null || this.customer.Id === undefined) {
      this._navigationService.navigateToCustomers();
    }
  }

  ngOnInit() {
  }

}
