import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { Attendance } from '../../models/RRHH/Attendance';
import { PersonnelResourceParameters } from '../../helpers';


@Injectable({
  providedIn: 'root'
})
export class AbsenceService extends BaseService {

  constructor(injetor: Injector) {
    super(injetor);
   }

   getAbsence(params: PersonnelResourceParameters): Observable<Array<Attendance>> {
     const url = this.getApiQuery(`RRHH/Employee/ABSENCE?FROM=${params.From}&TO=${params.To}&ID_PER=${params.SearchTerm}`);
     return this._httpClient.get<Array<Attendance>>(url, {headers: this.getAuthorizationHeader()});
   }


}
