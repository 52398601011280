import { Component, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { BtnReturnComponent } from '../../widgets/btn-return/btn-return.component';
import { Navigation, UserCredential } from '../../../helpers';
import { NavigationService, SessionService, SweetAlertService } from '../../../services';
import { UserService } from '../../../services/dashboards/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: []
})
export class UserComponent implements OnInit, AfterContentInit {

  idUser: string;
  usernameLabel: string;
  userForm: FormGroup;
  passwordControl: FormControl = new FormControl('', Validators.required);
  confirmPassword: FormControl = new FormControl('', Validators.required);
  roleControl: FormControl = new FormControl('', Validators.required);
  @ViewChild('returnBtn') btn: BtnReturnComponent;
  navigation: Navigation;
  ngUnsubscribe = new Subject();
  isLoading: boolean;

  constructor(private _formBuilder: FormBuilder, private _navigationService: NavigationService,
    private _userService: UserService, private _sessionService: SessionService,
    private _swalService: SweetAlertService) {

    this.userForm = this._formBuilder.group({
      'password': this.passwordControl,
      'confirmPassword': this.confirmPassword
    }, { validator: this.checkIfMatchingPasswords('password', 'confirmPassword') });
  }

  ngOnInit() {
    this.navigation = this._navigationService.getNavigationItem('user');
    this.usernameLabel = this._sessionService.getSessionInfo().Extras.name;
    this.idUser = this._sessionService.getSessionInfo().Extras.id_user;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterContentInit() {
    this.btn.navigation = this.navigation;
  }

  changePassword() {
    this.isLoading = true;
    const credential: UserCredential = new UserCredential();
    credential.UserId = Number(this.idUser);
    credential.Password = this.passwordControl.value;
    credential.Role = this._sessionService.getSessionInfo().Role;

    this._userService.changePassword(credential)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        this.isLoading = false;
        if (response) {
          this._swalService.getInstance('Contraseña actualizada con éxito.', 'success').then(() => { });
        } else {
          this._swalService.getInstance('Ocurrio un error al actualizar contraseña', 'error').then(() => { });
        }

        this.clearForm();

      }, error => console.error('An error has occured'));
  }

  clearForm() {
    this.userForm.reset();
  }

  private checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

}
