import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Injectable, Injector, Input, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';

export interface DialogData {
  title?    : string;
  message?  : string;
  default?  : any;
  type?     : string
  buttons?  : string;
  ok?       : boolean;
  cancel?   : boolean;
}
  
@Component({
  selector: 'input-box',
  templateUrl: './input-box.component.html',
  styleUrls: ['./input-box.component.css'],
  encapsulation: ViewEncapsulation.None
  //providers: [InfiniteScrollService]
})
export class InputboxComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  private subscription: Subscription;
  //@Input() dialogInstance: MatDialogRef<DialogboxComponent, any>
  //public data       : any;
  public title:string;
  public message:string;
  public default:any;
  public Form              : FormGroup;
  public CtrTo_Txt         : FormControl = new FormControl('', Validators.required);
  public isText:boolean;
  public isNumber:boolean;
  public type:string;

  constructor(
    public dialogInstance: MatDialogRef<InputboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _formBuilder: FormBuilder,)
  {
    this.title=data.title;
    this.message=data.message;
    this.default=data.default;
    this.default=data.default;
    this.type=data.type;
    this.isText=false;
    this.isNumber=false;
    if (this.type=="String"){ this.isText=true; }
    if (this.type=="Number"){ this.isNumber=true; }
  }
  ngOnInit(): void {
    this.Form = this._formBuilder.group({
      'txt'          : this.CtrTo_Txt
    });
    this.CtrTo_Txt.setValue(this.default);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngAfterViewInit() {
  }
  closeDialog() {
    let result: object = {
      success:false,
      data:null
    }
    this.dialogInstance.close(result);
  }
  onAccept(){  
    let result: object = {
      success   : true,
      data      : this.CtrTo_Txt.value
    }
    this.dialogInstance.close(result);
  }
  onCancel(){
    this.closeDialog();
  }
}
