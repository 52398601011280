import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpParams } from '@angular/common/http';
import { InvoiceItem, User, Users } from 'src/app/models';
import { Observable } from '@angular-devkit/core/node_modules/rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }

  getUserById(id: string) {
    const url = this.getApiQuery('Account/GetUserById');
    let params = new HttpParams();
    params = params.append('userId', id);
    return this._httpClient.get(url,
      {
        params: params,
        headers: this.getAuthorizationHeader()
      }
    );
  }

  GetUserFullByID(id: string) {
    const url = this.getApiQuery('Account/GetUserFullByID');
    let params = new HttpParams();
    params = params.append('UserId', id);
    return this._httpClient.get(url,
      {
        params: params,
        headers: this.getAuthorizationHeader()
      }
    );
  }

  getUserLastCart() {
    const url = this.getApiQuery(`Account/GetLastCart`);
    return this._httpClient.get<InvoiceItem[]>(url, { headers: this.getAuthorizationHeader() });
  }

  getList(PageNumber: number, PageSize:number,SearchTerm:string) : Observable<any[]> {
    let params: HttpParams = new HttpParams();

    
    if (SearchTerm) { params = params.append('SearchTerm',SearchTerm.toString()); }
    if (PageNumber) { params = params.append('PageNumber',PageNumber.toString()); }
    if (PageSize)   { params = params.append('PageSize',PageSize.toString()); }

    let url = this.getApiQuery(`Account/Pagging`);
    return this._httpClient.get<User[]>(
        url,
        { 
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }
  SaveUser(record: Users) {
    const url = this.getApiQuery('Account/SaveUser');
    return this._httpClient.post(
      url,
      record,
      { headers: this.getAuthorizationHeader() }
    );
  }


}
