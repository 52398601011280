import { Component, OnInit, Input, OnDestroy, ViewChild, Injector, Injectable } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { LocationcustomersService, SweetAlertService, PopModalService, DataService, NavigationService, SessionService, CashService } from '../../../services';

import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { APP_SETTINGS } from '../../../appsettings';
import { SearchGridService } from '../../widgets/searchGrid/searchGrid.service';
import { Salesmans } from '../../../../app/models/Sales/Salesmans';
import { SalesmansService } from '../../../../app/services/sales/Salesmans.service';

import { Dashboardsales } from '../../../services/dashboards/dashboardsales.service';

import { BoardchartpieComponent } from './../board/board-chartpie/board-chartpie.component';
import { BoardchartbarComponent } from './../board/board-chartbar/board-chartbar.component';
import { BoardtableComponent } from './../board/board-table/board-table.component';

import { Location } from '@angular/common'
import { prmsCard } from '../board/board-card/board-card.component';
import { prmsBars } from '../board/board-chartbar/board-chartbar.component';
import { prmsLine } from '../board/board-chartline/board-chartline.component';
import { prmsPie }  from '../board/board-chartpie/board-chartpie.component';
import { prmsTable } from '../board/board-table/board-table.component';

import { typ_Cash } from './../../../models/Sales/Invoice';




export interface typFilter {
  start?        : string;
  end?          : string;
  id_Vend       : string;
  caja          : string;
  //customerId?   : string;
  //customerName? : string;
}

@Component({
  selector: 'board-sales',
  templateUrl: './board-sales.component.html',
  styleUrls: ['./board-sales.component.css'],
})
export class BoardsalesComponent implements OnInit, OnDestroy {

  ngUnsubscribe = new Subject();
  private subscription: Subscription;
  titleComponent: string;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  public filter  :  typFilter   = {
    start         : '',
    end           : '',
    id_Vend       : '',
    caja          : ''
    //customerId    :'',
    //customerName  :'',
  };
  public datePickerRangeValue : Date[];
  public listSales            : Array<Salesmans> = [];
  public spin                 : boolean=false;

  @ViewChild("chartPieTC") chartPieTC: BoardchartpieComponent;
  @ViewChild("chartPieZN") chartPieZN: BoardchartpieComponent;
  @ViewChild("chartPieGP") chartPieGP: BoardchartpieComponent;

  @ViewChild("gridVentaTopTable") gridVentaTopTable: BoardtableComponent;
  @ViewChild("gridVentaTopVendTable") gridVentaTopVendTable: BoardtableComponent;
  @ViewChild("gridVentaTopCliTable") gridVentaTopCliTable: BoardtableComponent;

  @ViewChild("chartBarVentaPedido") chartBarVentaPedido: BoardchartbarComponent;
  @ViewChild("chartBarVentaDia") chartBarVentaDia: BoardchartbarComponent;


  public dataCard_Ven  : prmsCard;
  public dataBars_Ven  : prmsBars;
  public dataLine_Ven  : prmsLine;

  public showVentaCards         : boolean=false;
  public showVentaPieTC         : boolean=false;
  public showVentaTopTable      : boolean=false;
  public showVentaTopVendTable  : boolean=false;
  public showVentaTopCliTable   : boolean=false;
  public showVentaPedidoBar     : boolean=false;
  public showVentaDiaBar        : boolean=false;

  public showVentaPieZN         : boolean=false;
  public showVentaPieGP         : boolean=false;

  public data_VentaCards        : Array<prmsCard>=[];
  public data_VentaPieTC        : prmsPie;
  public data_VentaTopTable     : prmsTable;
  public data_VentaTopVendTable : prmsTable;
  public data_VentaTopCliTable  : prmsTable;
  public data_VentaPedidoBar    : prmsBars;
  public data_VentaDiaBar       : prmsBars;


  public data_VentaPieZN        : prmsPie;
  public data_VentaPieGP        : prmsPie;

  public listCash               : Array<typ_Cash>=[];
  public Session_User      : string = '';

  public dataTable_Ven : prmsTable;

  constructor(private _formBuilder: FormBuilder,
    private _translateService: TranslateService,
    private location: Location,
    private datePipe: DatePipe,
    public searchGrid: SearchGridService,
    private _SalesmansService: SalesmansService,
    private _dashBoardSales: Dashboardsales,
    private _cashService: CashService,
    private _sessionService: SessionService,
    ) {
  }
  ngOnInit() {
    this.titleComponent="DahsBoardSales";
    let start=new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let end=new Date(); //new Date("2022/12/31");
    start.setDate(1);
    this.Session_User         = this._sessionService.getSessionInfo().Extras.id_user;


    this.datePickerRangeValue = [new Date(), new Date()];
    this.datePickerRangeValue[0]= start;
    this.datePickerRangeValue[1]= end;
    this._SalesmansService.getListSalesmans().subscribe(
      (response:Array<Salesmans>) => {
        if (response != null) {
          this.listSales = response;
        }
    }, error => console.error('An error has occured'));

    this._cashService.getCashList(this.Session_User).subscribe((response : Array<typ_Cash>) => {
      if (response ) {
        this.listCash=response;
      }
    });


    this.filter.start=this.datePipe.transform(this.datePickerRangeValue[0], APP_SETTINGS.formats.dateFormat);
    this.filter.end=this.datePipe.transform(this.datePickerRangeValue[1], APP_SETTINGS.formats.dateFormat);
    this.filter.id_Vend='0';
    this.filter.caja='0';


  }


  ngAfterViewInit(): void {
    this.loadData(this.filter);
  }
  ngOnDestroy() {
  }

  clearDateRangePicker() {
    this.datePickerRangeValue = null;
  }
  onFilterData(){
    this.spin=true;
    if (this.datePickerRangeValue) {
      this.filter.start = this.datePipe.transform(this.datePickerRangeValue[0], APP_SETTINGS.formats.dateFormat);
      this.filter.end = this.datePipe.transform(this.datePickerRangeValue[1], APP_SETTINGS.formats.dateFormat);
    }
    //console.log(this.filter);
    this.loadData(this.filter);
  }
  clearCustomer(){
    //this.filter.customerName='';
    //this.filter.customerId='';
  }
  onLoadCustomer(){
    let parameters = {
      params  : {
        api           : 'Customer/Pagging',
        sizePage      : 20,
        width         : '700px',
        height        : '500px',
      },
      columns :[
        { field: "Id",      headerText: "Id",       width: "40" },
        { field: "Name",    headerText: "Nombres",  width: "100" },
        { field: "Address", headerText: "Dirección",width: "100"}
      ]
    }
    let dialog=this.searchGrid.Show(parameters);
    dialog.afterClosed().subscribe(result => {
      if (result){
        //this.filter.customerId=result.CustomerId;
        //this.filter.customerName=result.Name;
      }
    });
  }

  loadData(filter:any){
    //console.log(filter);

    this.showVentaCards         = false;
    this.showVentaPieTC         = false;
    this.showVentaTopTable      = false;
    this.showVentaTopVendTable  = false;
    this.showVentaTopCliTable   = false;
    this.showVentaPedidoBar     = false;
    this.showVentaDiaBar        = false;
    this.showVentaPieZN         = false;
    this.showVentaPieGP         = false;


    let filterVen=`id_vend=${this.filter.id_Vend}`;
    let filterCaj=`caja=${this.filter.caja}`;
    let filterAll=`${filterVen};${filterCaj}`;

    let top='10';
    /*-------------------Cards-------------*/
    this.data_VentaCards=[];
    this._dashBoardSales.GetVentaCards(this.filter.start,this.filter.end,top,filterAll).subscribe(
      (response:any) => {
        if (response != null) {
          window.setTimeout(() => {
            this.showVentaCards=true;
          }, 100);
          this.data_VentaCards=response;
        }
    }, error => console.error('An error has occured'));

    /*-------------Ventas por Tipos de Clientes-----*/
    this.data_VentaPieTC={
      title     : '',
      subtitle  : '',
      pieChartData: [],
      pieChartLabels: []
    }
    this._dashBoardSales.getVentaPie(this.filter.start,this.filter.end,'TC',top,filterAll).subscribe(
      (response:any) => {
        if (response != null) {
          window.setTimeout(() => {
            this.showVentaPieTC=true;
            this.data_VentaPieTC=response;
            window.setTimeout(() => {
              this.chartPieTC.refresh(this.data_VentaPieTC);
            }, 100);
          }, 100);
        }
    }, error => console.error('An error has occured'));

    /*-------------Producto mas vendido-----*/
    this.data_VentaTopTable={
      title     : '',
      subtitle  : '',
      columns   : [],
      data: []
    }
    this._dashBoardSales.getVentaTopTable(this.filter.start,this.filter.end,'TC',top,filterAll).subscribe(
      (response:any) => {
        if (response != null) {
          window.setTimeout(() => {
            this.showVentaTopTable=true;
            this.data_VentaTopTable=response;
            window.setTimeout(() => {
              this.gridVentaTopTable.refresh(this.data_VentaTopTable);
            }, 100);
          }, 100);
        } else {
          this.showVentaTopTable=true;
          this.gridVentaTopTable.refresh(this.data_VentaTopTable);
        }
    }, error => console.error('An error has occured'));



    /*-------------Top de Clientes--------*/
    this.data_VentaTopCliTable={
      title     : '',
      subtitle  : '',
      columns   : [],
      data: []
    }
    this._dashBoardSales.GetVentaTopCliTable(this.filter.start,this.filter.end,'TC',top,filterAll).subscribe(
      (response:any) => {
        if (response != null) {
          window.setTimeout(() => {
            this.showVentaTopCliTable=true;
            this.data_VentaTopCliTable=response;
            window.setTimeout(() => {
              this.gridVentaTopCliTable.refresh(this.data_VentaTopCliTable);
            }, 100);
          }, 100);
        }
    }, error => console.error('An error has occured'));


    /*-------------Clientes por Zonas-----*/
    this.data_VentaPieZN={
      title     : '',
      subtitle  : '',
      pieChartData: [],
      pieChartLabels: []
    }
    this._dashBoardSales.getVentaPie(this.filter.start,this.filter.end,'ZC',top,filterAll).subscribe(
      (response:any) => {
        if (response != null) {
          window.setTimeout(() => {
            this.showVentaPieZN=true;
            this.data_VentaPieZN=response;
            window.setTimeout(() => {
              this.chartPieZN.refresh(this.data_VentaPieZN);
            }, 100);
          }, 100);
        }
    }, error => console.error('An error has occured'));


    /*-------------Clientes por Grupos-----*/
    this.data_VentaPieGP={
      title     : '',
      subtitle  : '',
      pieChartData: [],
      pieChartLabels: []
    }
    this._dashBoardSales.getVentaPie(this.filter.start,this.filter.end,'GP',top,filterAll).subscribe(
      (response:any) => {
        if (response != null) {
          window.setTimeout(() => {
            this.showVentaPieGP=true;
            this.data_VentaPieGP=response;
            window.setTimeout(() => {
              this.chartPieGP.refresh(this.data_VentaPieGP);
            }, 100);
          }, 100);
        }
    }, error => console.error('An error has occured'));


    /*-----------------Data para Table Resumen VentaTopVendTable-----------*/
    this.data_VentaTopVendTable={
      title     : '',
      subtitle  : '',
      columns   : [],
      data: []
    }
    this._dashBoardSales.GetVentaTopVendTable(this.filter.start,this.filter.end,'TC',top,filterAll).subscribe(
      (response:any) => {
        if (response != null) {
          window.setTimeout(() => {
            this.showVentaTopVendTable=true;
            this.data_VentaTopVendTable=response;
            window.setTimeout(() => {
              this.gridVentaTopVendTable.refresh(this.data_VentaTopVendTable);
            }, 100);
          }, 100);
        }
    }, error => console.error('An error has occured'));

    /*-----------------Data para Proformas y Factura-----------*/
    this.data_VentaPedidoBar={
      title     : '',
      subtitle  : '',
      barChartData: [],
      barChartLabels: []
    }

    this._dashBoardSales.GetVentaPedidoBar(this.filter.start,this.filter.end,'TC',top,filterAll).subscribe(
      (response:any) => {
        if (response != null) {
          window.setTimeout(() => {
            this.showVentaPedidoBar=true;
            this.data_VentaPedidoBar=response;
            window.setTimeout(() => {
              this.chartBarVentaPedido.refresh()
            }, 100);
          }, 100);
        }
    }, error => console.error('An error has occured'));

    /*-----------------Data para Promedio Ventas Dia-----------*/
    this.data_VentaPedidoBar={
      title     : '',
      subtitle  : '',
      barChartData: [],
      barChartLabels: []
    }

    this._dashBoardSales.GetVentaDiaBar(this.filter.start,this.filter.end,'TC',top,filterAll).subscribe(
      (response:any) => {
        if (response != null) {
          window.setTimeout(() => {
            this.showVentaDiaBar=true;
            this.data_VentaDiaBar=response;
            window.setTimeout(() => {
              this.chartBarVentaDia.refresh();
            }, 100);
          }, 100);
        }
    }, error => console.error('An error has occured'));


    window.setTimeout(() => {
      this.spin=false;
    }, 1500);
  }
}

































/*-------------------Data para Card--------------*/
    /*
    this.dataCard_Ven={
      title     : 'Ventas',
      subtitle  : 'Total de ventas',
      value     : 1892.92,
      button    : 'Mensuales',
      percent   : 86
    }
    */
    /*-----------------Data para Chart Bars-----------*/
    /*
    this.dataBars_Ven={
      title     : 'Ventas',
      subtitle  : '',
      barChartData: [
        { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
        { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
      ],
      barChartLabels: [ '2006', '2007', '2008', '2009', '2010', '2011', '2012' ]
    }
    */
    /*-----------------Data para Chart Line-----------*/
    /*
    this.dataLine_Ven={
      title     : 'Ventas',
      subtitle  : '',
      lineChartData: [
        { data: [85, 72, 78, 75, 77, 75], label: 'Crude oil prices' },
      ],
      lineChartLabels: ['January', 'February', 'March', 'April', 'May', 'June']
    }
    */
    /*-----------------Data para Chart Pie-----------*/
    /*
    this.dataPie_Ven={
      title     : 'Ventas',
      subtitle  : '',
      pieChartData: [30, 50, 20],
      pieChartLabels: ['SciFi', 'Drama', 'Comedy']
    }
    */
    /*-----------------Data para Table-Grids-----------*/
    /*
    this.dataTable_Ven={
      title     : 'Ventas',
      subtitle  : 'Total de ventas',
      columns   : [
        { field: "Code",     headerText: "Code",       width: "40" , format : '', textAlign: 'left'},
        { field: "Name",     headerText: "Nombres",    width: "100", format : '', textAlign: 'left' },
        { field: "Value",    headerText: "Valor",      width: "100", format : 'N2', textAlign: 'Right'}
      ],
      data: [
        {Code:'921',Name:'Perico de los palotes',Value:12.22},
        {Code:'922',Name:'Hola Mundo',Value:212.36}
      ]
    }
    */
