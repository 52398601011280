export class Attendance {
  CEDU_PER: string;
  NOMBRES: string;
  FECHA: string;
  ENTRADA: string;
  SALIDA: string;
  ENT_TEMPRANO: string;
  ENT_TARDE: string;
  SAL_TEMPRANO: string;
  SAL_TARDE: string;
  PAUSAS: number;
  DET_PAUSA: string;
}

export class AttendanceRecord {
  ANO: string;
  AUTOMATICO: string;
  CHECK: string;
  DETALLE: string;
  FECHA: string;
  HOR: string;
  HORA: string;
  HORAEXTRA: boolean;
  NIGHTSHIF: boolean;
  DIA: string;
  ID: string;
  ID_PER: string;
  ID_POOL: number;
  ID_TEXTO: string;
  ID_USUARIO : string;
  MES: string;
  MINUTO: string;
  APRUEBA: number;
  OBSERVA: string;
  ZONA: string;
  TIPO: string;
  APROBADO: boolean;
}
