<page-heading pageTitle="{{'Cambiar Contraseña' | translate}}">
  <app-return-btn #returnBtn></app-return-btn>
</page-heading>

<div class="wrapper animated fadeInRight">
  <div class="ibox-content m-b-sm border-bottom white-bg"
    [ngClass]="{'sk-loading':isLoading}">
    <div class="sk-spinner sk-spinner-wave" *ngIf="isLoading">
      <div class="sk-rect1"></div>
      <div class="sk-rect2"></div>
      <div class="sk-rect3"></div>
      <div class="sk-rect4"></div>
      <div class="sk-rect5"></div>
    </div>
    <div class="row ibox-content no-borders">
      <form [formGroup]="userForm" class="col-md-5 col-centered">
        <div class="form-group">
          <label>Usuario</label>
          <label class="form-control full-width label-disabled" type="text">{{
            usernameLabel }}</label>
        </div>

        <div class="form-group">
          <label for="password">Nueva Contraseña</label>
          <input id="password" class="form-control full-width" type="password"
            formControlName="password" />
          <div class="alert alert-warning m-t-xs"
            *ngIf="!userForm.controls['password'].valid &&
            userForm.controls['password'].touched"><span class="font-bold">
              Campo Requerido
            </span></div>
        </div>

        <div class="form-group">
          <label for="confirmPassword">Confirme Contraseña</label>
          <input id="confirmPassword" class="form-control full-width"
            type="password" formControlName="confirmPassword" />
          <!-- <div class="alert alert-warning m-t-xs"
            *ngIf="userForm.controls['confirmPassword'].touched"><span
              class="font-bold">
              Campo Requerido
            </span></div> -->
          <div class="alert alert-warning m-t-xs"
            *ngIf="userForm.controls['confirmPassword']?.errors?.notEquivalent"><span
              class="font-bold">
              Las contraseñas no coinciden
            </span></div>
        </div>

        <!-- <div class="form-group">
          <label for="menu1">User role</label>
          <div class="dropdown">
            <button calss="dropdown-toggle" data-toggle="dropdown" id="menu1">
              Roles
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
              <li role="presentation"><a role="menuitem" tabindex="-1">Admin</a></li>
              <li role="presentation"><a role="menuitem" tabindex="-1">Supplier/Salesman</a></li>
              <li role="presentation"><a role="menuitem" tabindex="-1">Client</a></li>
            </ul>
          </div>
          <div class="alert alert-warning m-t-xs"
            *ngIf="!userForm.controls['userRole'].valid &&
            userForm.controls['userRole'].touched"><span class="font-bold">This
              is required</span></div>
        </div> -->
        <div class="form-group text-center" *ngIf="userForm.valid">
          <button type="submit" mat-button class="btn btn-blue"
            [disabled]="!userForm.valid" (click)="changePassword()">Aceptar</button>
        </div>
      </form>
    </div>
  </div>
</div>
