import { HttpHeaders, HttpClient } from '@angular/common/http';
import { SessionService } from '../helpers/session.service';
import { APP_SETTINGS } from '../../appsettings';
import { Injector } from '@angular/core';
// import { SettingsService } from '../settings.service';

export class BaseService {

  protected _sessionService: SessionService;
  protected _httpClient: HttpClient;
  // private _settingsService: SettingsService;

  constructor(private injector: Injector) {
    this._sessionService = this.injector.get(SessionService);
    this._httpClient = this.injector.get(HttpClient);
    // this._settingsService = injector.get(SettingsService);
  }

  protected getApiQueryLocal(path: string, post: boolean = false): string {
    let url = `${APP_SETTINGS.endpoints.api_url}/api/${path}`;

     if (post) {
        url = `${APP_SETTINGS.endpoints.api_local}/controller.php`;
     } else {
        url = `${APP_SETTINGS.endpoints.api_local}/controller.php?path=${path.replace('?', '&')}`;
     }
    return url;
  }

  protected getApiQuery(path: string, post: boolean = false): string {
    let url = `${APP_SETTINGS.endpoints.api_url}/api/${path}`;

    if (APP_SETTINGS.endpoints.enableSSLLocal) {
     if (post) {
        // url = `http://localhost:19395/controller.php?XDEBUG_SESSION_START=7533B726`;
        url = `${APP_SETTINGS.endpoints.api_local}/controller.php`;
     } else {
        // url = `http://localhost:19395/controller.php?XDEBUG_SESSION_START=7533B726&path=${path}`;
        url = `${APP_SETTINGS.endpoints.api_local}/controller.php?path=${path.replace('?', '&')}`;
     }
    }
    return url;
  }

  protected getPrintQuery(): string {
    let url = `${APP_SETTINGS.endpoints.printService_url}`;
    return url;
  }

  protected getAuthorizationHeader(headerKey: string = 'Content-Type', headerValue: string = 'application/json; utf-8'): HttpHeaders {
    if (this._sessionService.getSessionInfo() != null) {
      const clientHeaders = new HttpHeaders(
        {
          headerKey: headerValue,
          'Authorization': `BEARER ${this._sessionService.getSessionInfo().AccessToken}`
        });

        return clientHeaders;
      } else {
        console.log("Joder se ha perdido la session!!!");
        this._sessionService.clearSession();
        return null;
      }
    }
  }
