<!--class="media-preview"-->
<div [style.width]="Width" [style.height]="Height" >
    <div style="margin: 3px; border: 1px solid #dddddd;">
        <img 
          style="width: 100%;min-height: 155px;"
          [style.height]="HeightImg" 
          [src]='imgPlaceholder' 
          #imagePreview
          mediaPreview 
          alt="Image Preview"  
          [media]="uploader.queue.length> 0 ? uploader.queue[0]?.file?.rawFile : null"
          />
    </div>
    <div class="media" style="margin-top: 2px;">
        <input 
            [disabled]="isControlEnabled" 
            type="file" 
            id="fileID" 
            #file
            name="file"
            ng2FileSelect
            [uploader]="uploader" 
            class="inputfile2"
            [accept]="allowedMimeType.toString()" />  
        <label class="label" for="fileID">
            <i class="ti-clip"></i>
            {{'Select' | translate}}
        </label>
        <button 
            [disabled]="isControlEnabled" 
            type="button" 
            class="btn btn-danger btn-rounded" 
            (click)="removeFile()">
                <i class="ti-trash"></i> {{'Delete' | translate}}
        </button>
    </div>    
</div>
