import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { MapComponent } from './map/map.component';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { PageHeadingComponent } from './page-heading/page-heading.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DashboardItemsComponent } from './dashboard-items/dashboard-items.component';
import { CatalogComponent } from './catalog/catalog.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BtnReturnComponent } from './btn-return/btn-return.component';
import { DirectivesModule } from '../../directives/directives.module';
import { PersonnelFromToComponent } from './personnel-from-to/personnel-from-to.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OptionsScrollDirective} from './search3/options-scroll.directive';
import { Search3Component } from './../widgets/search3/search3.component';
import { SearchGridComponent } from './../widgets/searchGrid/searchGrid.component';
import { listGridComponent } from './../widgets/listGrid/listGrid.component';
import { UploadfileComponent } from './../widgets/upload-file/upload-file.component';
import { UploadfilesComponent } from './../widgets/upload-files/upload-files.component';
import { UploadallfilesComponent } from './../widgets/upload-allfiles/upload-allfiles.component';

import { FileUploadModule } from 'ng2-file-upload';
import { WaitComponent } from './../widgets/wait/wait.component';
import { SearchCompleteComponent } from './../widgets/search-complete/search-complete.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from "@angular/material/list";
import { DirectiveAccessor } from './search-complete/directive-accessor';
import { SyncModule } from './../../sync.module';

import { DinamicCrudComponent } from './../widgets/dinamic-crud/dinamic-crud.component';
import { DinamicFormComponent } from './../widgets/dinamic-crud/dinamic-form/dinamic-form.component';

import { DialogboxComponent } from './../widgets/dialog-box/dialog-box.component';
import { DialogfilterComponent } from './../widgets/dialog-filter/dialog-filter.component';
import { InputboxComponent } from './../widgets/input-box/input-box.component';
import { ViewermodalsComponent } from './../widgets/viewer-modals/viewer-modals.component';
import { ViewertextComponent } from './../widgets/viewer-text/viewer-text.component';
import { ViewerhtmlComponent } from './../widgets/viewer-html/viewer-html.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';

import { TabComponent } from './tabs/tab.component';
import { TabsComponent } from './tabs/tabs.component';
import { DynamicTabsDirective } from './tabs/dynamic-tabs.directive';
import { DynamicInvoicesDirective } from './tabs/dynamic-invoices.directive';


@NgModule({
  imports: [
    CommonModule,
    AgmCoreModule,
    TranslateModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    TooltipModule,
    DirectivesModule,
    BsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FileUploadModule,
    ScrollingModule,
    MatSelectInfiniteScrollModule,
    MatFormFieldModule,
    MatSelectModule,
    MatListModule,
    SyncModule
  ],
  declarations: [
    MapComponent,
    AutoCompleteComponent,
    PageHeadingComponent,
    DashboardItemsComponent,
    CatalogComponent,
    BtnReturnComponent,
    PersonnelFromToComponent,
    Search3Component,
    SearchGridComponent,
    listGridComponent,
    OptionsScrollDirective,
    UploadfileComponent,
    UploadfilesComponent,
    UploadallfilesComponent,
    DinamicCrudComponent,
    DinamicFormComponent,
    WaitComponent,
    SearchCompleteComponent,
    DialogboxComponent,
    ConfirmationModalComponent,
    InputboxComponent,
    DirectiveAccessor,
    ViewermodalsComponent,
    DialogfilterComponent,
    ViewertextComponent,
    ViewerhtmlComponent,
    TabComponent,
    TabsComponent,
    DynamicTabsDirective,
    DynamicInvoicesDirective,
  ],
  exports: [
    MapComponent,
    AutoCompleteComponent,
    PageHeadingComponent,
    DashboardItemsComponent,
    CatalogComponent,
    BtnReturnComponent,
    PersonnelFromToComponent,
    MatIconModule,
    MatButtonModule,
    UploadfileComponent,
    UploadfilesComponent,
    UploadallfilesComponent,
    DinamicCrudComponent,
    DinamicFormComponent,
    WaitComponent,
    SearchCompleteComponent,
    DirectiveAccessor,
    SearchGridComponent,
    DialogboxComponent,
    ConfirmationModalComponent,
    InputboxComponent,
    DialogfilterComponent,
    ViewertextComponent,
    ViewerhtmlComponent,
    TabComponent,
    TabsComponent,
    DynamicTabsDirective,
    DynamicInvoicesDirective,
  ],
  entryComponents: [
    Search3Component,
    SearchGridComponent,
    listGridComponent,
    WaitComponent,
    SearchCompleteComponent,
    DialogboxComponent,
    ConfirmationModalComponent,
    DialogfilterComponent,
    InputboxComponent,
    ViewermodalsComponent,
    ViewertextComponent,
    TabComponent,
  ]
})
export class WidgetsModule { }
