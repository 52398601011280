  <div class="ibox-title">
    <h5>{{data.title}}</h5>
  </div>
  <div class="ibox-content" style="max-width: 380;">
      <canvas *ngIf="pieChartData"
          baseChart 
          #chart
          height="200"
          [data]="pieChartData" 
          [labels]="pieChartLabels" 
          [chartType]="pieChartType"
          [options]="pieChartOptions"
          [plugins]="pieChartPlugins"
          [legend]="pieChartLegend">
      </canvas>
  </div>



