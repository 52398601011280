<page-heading pageTitle="{{ titleComponent | translate}}">
  <button *ngIf="dialogInstance" type="button" class="btn btn-primary" tabindex="-1" (click)="closeDialog()">
    <i class="ti-close"></i>
  </button>

</page-heading>

<div [ngClass]="!dialogInstance ? 'p-h-xl' : 'p-sm'">
  <div [ngClass]="!dialogInstance ? 'col-md-12 col-centered' : 'col-md-12
    col-centered'">
    <form [formGroup]="Form" class="form-horizontal form-new-design">

      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'Id' | translate}}</label>
        <div class="col-md-4">
          <input id="EquipmentCustomerID" formControlName="EquipmentCustomerID" class="form-control text-uppercase"
            type="text" />
        </div>
        <label class="col-md-2 col-form-label">{{'SerialNo' | translate}}</label>
        <div class="col-md-4">
          <input id="SerialNo" formControlName="SerialNo" class="form-control
            text-uppercase" type="text" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'Name' | translate}}</label>
        <div class="col-md-10">
          <input id="Name" formControlName="Name" class="form-control
            text-uppercase" type="text" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'Info' | translate}}</label>
        <div class="col-md-10">
          <input id="Info" formControlName="Info" class="form-control
            text-uppercase" type="text" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'EquipmentModelId' |
          translate}}</label>
        <!--
          required
          -->
        <div class="col-md-4">
          <select formControlName="EquipmentModelID" [(ngModel)]="EquipmentModelID"
            (ngModelChange)="onChangeModel($event)" class="form-control">
            <option value="0" disabled selected>{{ 'SelectEquipmentModel' |
              translate }}</option>
            <option [value]="item.EquipmentModelID" *ngFor="let item of
              listModel">{{item.Name | titlecase }}</option>
          </select>
        </div>

        <label class="col-md-2 col-form-label">{{'Manufacturer' | translate}}</label>
        <div class="col-md-4">
          <input id="Manufacturer" formControlName="Manufacturer" class="form-control text-uppercase" type="text" />
        </div>
      </div>

      <!--value="{{ AcquisitionDate | date:'yyyy-MM-dd HH:mm' }}"  -->
      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'AcquisitionDate' | translate}}</label>
        <div class="col-md-4">
          <div class="input-group date">
            <span class="input-group-addon" (click)="dpAdq.toggle()" [attr.aria-expanded]="dpAdq.isOpen"><i
                class="fa fa-calendar"></i></span>
            <input id="AcquisitionDate" formControlName="AcquisitionDate" type="text" class="form-control"
              #dpAdq="bsDatepicker" bsDatepicker [(bsValue)]="AcquisitionDate" [(ngModel)]="AcquisitionDate"
              [bsConfig]="bsSettings">
          </div>
        </div>

        <label class="col-md-2 col-form-label">{{'WarrantyEndDate' | translate}}</label>
        <div class="col-md-4">
          <div class="input-group date">
            <span class="input-group-addon" (click)="dpWar.toggle()" [attr.aria-expanded]="dpWar.isOpen"><i
                class="fa fa-calendar"></i></span>
            <input id="WarrantyEndDate" formControlName="WarrantyEndDate" type="text" class="form-control"
              #dpWar="bsDatepicker" bsDatepicker [(bsValue)]="WarrantyEndDate" [(ngModel)]="WarrantyEndDate"
              [bsConfig]="bsSettings">
          </div>
        </div>
      </div>


      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'LastManteinanceDate' |
          translate}}</label>
        <div class="col-md-4">
          <div class="input-group date">
            <span class="input-group-addon" (click)="dpLas.toggle()" [attr.aria-expanded]="dpLas.isOpen"><i
                class="fa fa-calendar"></i></span>
            <input id="LastManteinanceDate" formControlName="LastManteinanceDate" type="text" class="form-control"
              #dpLas="bsDatepicker" bsDatepicker [(bsValue)]="LastManteinanceDate" [(ngModel)]="LastManteinanceDate"
              [bsConfig]="bsSettings">
          </div>
        </div>

        <label class="col-md-2 col-form-label">{{'NextManteinanceDate' |
          translate}}</label>
        <div class="col-md-4">
          <div class="input-group date">
            <span class="input-group-addon" (click)="dpNext.toggle()" [attr.aria-expanded]="dpNext.isOpen"><i
                class="fa fa-calendar"></i></span>
            <input id="NextManteinanceDate" formControlName="NextManteinanceDate" type="text" class="form-control"
              #dpNext="bsDatepicker" bsDatepicker [(bsValue)]="NextManteinanceDate" [(ngModel)]="NextManteinanceDate"
              [bsConfig]="bsSettings">
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'Location' | translate}}</label>
        <div class="col-md-10">
          <select formControlName="CustomerLocationID" [(ngModel)]="CustomerLocationID" class="form-control">
            <option value="0" disabled selected>{{ 'SelectLocation' | translate
              }}</option>
            <option [value]="item.CustomerLocationID" *ngFor="let item of
              listLocations">{{item.Name | titlecase }}</option>
          </select>
        </div>

      </div>

      <div class="form-group p-h-sm text-center">
        <button type="submit" mat-button class="btn btn-yellow" [disabled]="!Form.valid" (click)="onRegister()"><i
            class="ti-save"></i> {{action | translate}}</button>
      </div>

    </form>
  </div>
</div>