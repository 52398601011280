import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef,
  Output,
  EventEmitter
} from '@angular/core';

import { TabComponent } from './tab.component';
import { DynamicTabsDirective } from './dynamic-tabs.directive';
import { DataService } from '../../../services/helpers/data.service';

@Component({
  selector: 'my-tabs',
  template: `
    <ul class="nav nav-tabs">
      <li *ngFor="let tab of tabs" (click)="selectTab(tab)" [class.active]="tab.active">
        <a>{{tab.title}}</a>
      </li>
      <!-- href="#" dynamic tabs -->
      <li *ngFor="let tab of dynamicTabs" (click)="selectTab(tab)" [class.active]="tab.active">
        <a style="display: inline-flex;">{{tab.title}} 
            <div style="margin-left: 10px; margin-right: -10px;">
            <span class="tab-close" *ngIf="tab.isCloseable" (click)="closeTab(tab)">  
                <i class="glyphicon glyphicon-remove-circle"></i>  
            </span>
            </div>
        </a>
      </li>
      <button class="btn btn-default" style="height: 25px; padding-top: 3px;" (click)="onOpen()"><i class="glyphicon glyphicon-plus"></i></button>
    </ul>
    <ng-content></ng-content>
    <ng-template dynamic-tabs #container></ng-template>
    
  `,
  styles: [
    `
    .tab-close {
      color: gray;
      text-align: right;
      cursor: pointer;
    }
    .nav-tabs > li > a {
      color: #161e2b;
      font-weight: 500;
      padding: 2px 12px 2px 12px;
    }
    
    `
    /*
    .glyphicon {
      top: 3px;
      margin-left: 4px;
      padding-left: 5px;
    }
    */
  ]
})

//https://stackblitz.com/edit/angular-dynamic-tabs?file=app%2Ftabs%2Ftabs.component.ts
//https://stackblitz.com/edit/angular-dynamic-component-example-createcomponent-on-click?file=src%2Fapp%2Fapp.component.ts
//https://codingpotions.com/angular-comunicacion-componentes/
//https://medium.com/@theriyasharma24/sending-data-between-angular-components-with-examples-6c452a99498d


export class TabsComponent implements AfterContentInit {
  dynamicTabs: TabComponent[] = [];
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @ViewChild(DynamicTabsDirective) dynamicTabPlaceholder: DynamicTabsDirective;
  @Output() messageEventP2 = new EventEmitter<string>();
  public NTab: number=1;
  constructor(private _componentFactoryResolver: ComponentFactoryResolver,
      private _dataService: DataService,) {
  }
  ngAfterContentInit() {
    const activeTabs = this.tabs.filter(tab => tab.active);
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
    this.messageEventP2.subscribe(document => {
      this._dataService.document = document;
      this.openTab('PRN', null, {}, true,'PRN');
    });
  }

  selectTab(tab: TabComponent) {
    this.tabs.toArray().forEach(tab => (tab.active = false));
    this.dynamicTabs.forEach(tab => (tab.active = false));
    tab.active = true;
  }

  closeTab(tab: TabComponent) {
    for (let i = 0; i < this.dynamicTabs.length; i++) {
      if (this.dynamicTabs[i] === tab) {
        this.dynamicTabs.splice(i, 1);
        let viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
        viewContainerRef.remove(i);
        this.selectTab(this.tabs.first);
        break;
      }
    }

    this.NTab=1;
    for (let i = 0; i < this.dynamicTabs.length; i++) {
      let tit=this.dynamicTabs[i].title;
      if (tit.substr(0,5)=='Venta'){
        this.NTab++;
        this.dynamicTabs[i].title='Venta '+this.NTab+'';
      }
    }
  }

  closeActiveTab() {
    const activeTabs = this.dynamicTabs.filter(tab => tab.active);
    if (activeTabs.length > 0) {
      this.closeTab(activeTabs[0]);
    }
  }


  public openTab(title: string, template, data, isCloseable = false,type) {
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(TabComponent);
    const viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
    const componentRef = viewContainerRef.createComponent(componentFactory);
    const instance: TabComponent = componentRef.instance as TabComponent;
    instance.title = title;
    instance.template = template;
    instance.dataContext = data;
    instance.isCloseable = isCloseable;
    instance.type = type;
    this.dynamicTabs.push(componentRef.instance as TabComponent);
    this.selectTab(this.dynamicTabs[this.dynamicTabs.length - 1]);
    componentRef.instance.messageEventP.subscribe(document => {
      this._dataService.document = document;
      this.openTab('PRN', null, {}, true,'PRN');
    });
  }

  onOpen() {
    this.NTab++;
    let tit='Venta '+this.NTab+'';
    this.openTab(tit, null, {}, true,'FAC');
  }
  

}
