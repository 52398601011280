export class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
        });
    }

    static shortNewGuid() {
        return 'xxxxxxxx-yxxx-4xxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
        });
    }
}
