


<page-heading pageTitle="{{ title | translate }}"
  [showBack]="this.isOnBack" 
  (clickBack)="onBack()">
</page-heading>


  <div class="row">
    <div class="wrapper wrapper-content animated fadeIn">
      <div id="idviewer">
          
      </div>
    </div>
  </div>


 <!--    
     <ng-container  *ngIf="conData">
        <div id="idviewer">
          
        </div>
      </ng-container>

    -->