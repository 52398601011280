import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {RouterModule} from '@angular/router';

import {StarterViewComponent} from './starterview.component';
import {LoginComponent} from './login.component';
import { HttpClientModule } from '@angular/common/http';
import { LoaderComponent } from './loader/loader.component';
import { LandingComponent } from './landing/landing.component';
import {TranslateModule} from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { WidgetsModule } from '../widgets/widgets.module';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    HttpClientModule,
    TranslateModule,
    WidgetsModule
  ],
  declarations: [
    StarterViewComponent,
    LoginComponent,
    LoaderComponent,
    LandingComponent
  ],
  providers: [ ],
  exports: [
    StarterViewComponent,
    LoginComponent,
    LoaderComponent,
    LandingComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppviewsModule {
}
