<!-- Wrapper-->
<div id="wrapper">

  <!-- Main page wrapper -->
  <div id="page-wrapper" class="white-bg container-fluid">

    <!-- Top navigation -->
    <!-- <topnavigationnavbar></topnavigationnavbar> -->
    <topnavbar></topnavbar>

    <!-- Main view/routes wrapper-->
    <router-outlet></router-outlet>

    <!-- Footer -->
    <!-- <footer></footer> -->

  </div>
  <!-- End page wrapper-->

</div>
<!-- End wrapper-->
