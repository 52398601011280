<page-heading pageTitle="{{ titleComponent | translate}}">
</page-heading>

<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-sm-5">
      <div class="form-group search-bg">
        <label class="control-label" for="date_added">{{'DateRange' | translate}}</label>
        <div class="input-group date">
          <span class="input-group-addon pointer" (click)="dpr.toggle()" [attr.aria-expanded]="dpr.isOpen">
            <i class="fa fa-calendar"></i>
          </span>
          <input class="form-control" bsDaterangepicker #dpr="bsDaterangepicker" placeholder="MM/DD/YYYY"
            [(ngModel)]="datePickerRangeValue">
          <span class="input-group-addon pointer" (click)="clearDateRangePicker()">
            <i class="ti-close"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <!--
          <div class="form-group search-bg">
              <label class="control-label">{{'Customer' | translate}}</label>
              <div class="input-group" >
                  <input #idInput type="text" [(ngModel)]="filter.customerName" placeholder="{{'Search' | translate}}" readonly class="input form-control" style="background: white;" />
                  <span class="input-group-addon pointer"  (click)="clearCustomer()">
                      <i class="ti-close"></i>
                    </span>
                  <span class="input-group-btn">
                      <button role="button" type="button" class="btn btn-yellow" style="width:42px;padding-top: 10px;padding-bottom: 10px;padding-left: 0px;padding-right: 0px;"
                      (click)="onLoadCustomer()">
                      <i class="ti-search" ></i>
                      </button>
                  </span>
              </div>
          </div>
          -->
      <div class="form-group search-bg">
        <label class="control-label">{{'Seller' | translate}}</label>
        <select id="id_Vend" class="form-control" [(ngModel)]="filter.id_Vend" style="height: 42px;">
          <option value="0" selected>{{ 'Allthesellers' | translate }}</option>
          <option [value]="item.Id" *ngFor="let item of listSales">{{item.Name | titlecase}}</option>
        </select>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="form-group search-bg">
        <label class="control-label">{{'CashRegister' | translate}}</label>
        <select id="caja" class="form-control" [(ngModel)]="filter.caja" style="height: 42px;">
          <option value="0" selected>{{ 'AllCash' | translate }}</option>
          <option [value]="item.Id" *ngFor="let item of listCash">{{item.Name | titlecase}}</option>
        </select>
      </div>
    </div>
    <div class="col-sm-1">
      <button class="btn btn-action btn-yellow btn-block" style="margin-top: 26px;"
        (click)="onFilterData()">
        <i *ngIf="this.spin" class="fa fa-refresh fa-spin fa-1x fa-fw" style="margin-left: -2.5px;"></i>
        <i *ngIf="!this.spin" class="fa fa-refresh" style="margin-left: -1.6px;"></i>
      </button>
    </div>
  </div>
  <div class="hr-line-dashed"></div>
  <div class="row m-b-sm">
    <div class="col-md-12">
      <div *ngIf="!this.showVentaCards" class="ibox float-e-margins border">
        <div class="ibox-title">
          <h5>Procesando datos..</h5>
        </div>
        <div class="ibox-content">
          <div style="display: flex; min-height: 150px; justify-content: center;align-items: center;">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw"
              style="margin-left: -2.5px;font-size: x-large; font-weight: 500;"></i>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="this.showVentaCards">
      <div class="col-lg-3" *ngFor="let item of data_VentaCards">
        <board-card [data]="item">
        </board-card>
      </div>
    </div>
  </div>

  <!-- Tipos de Clientes y Producto mas vendidos-->
  <div class="row m-b-xl">
    <div class="col-md-6">
      <board-process *ngIf="!this.showVentaPieTC">
      </board-process>
      <board-chartpie *ngIf="this.showVentaPieTC" #chartPieTC id="chartPieTC" [data]="data_VentaPieTC">
      </board-chartpie>
    </div>
    <div class="col-md-6">
      <board-process *ngIf="!this.showVentaTopTable">
      </board-process>
      <board-table *ngIf="this.showVentaTopTable" #gridVentaTopTable id="gridVentaTopTable"
        [data]="data_VentaTopTable">
      </board-table>
    </div>
  </div>

  <!-- Proformas y Facturas-->
  <div class="row m-b-xl">
    <div class="col-md-12">
      <board-process *ngIf="!this.showVentaPedidoBar">
      </board-process>
      <board-chartbar #chartBarVentaPedido id="chartBarVentaPedido" [data]="data_VentaPedidoBar"
        *ngIf="this.showVentaPedidoBar">
      </board-chartbar>
    </div>
  </div>

  <!-- Top de Clientes y Clientes por zonas-->
  <div class="row m-b-xl">
    <div class="col-md-6">
      <board-process *ngIf="!this.showVentaTopCliTable">
      </board-process>
      <board-table *ngIf="this.showVentaTopCliTable" #gridVentaTopCliTable id="gridVentaTopCliTable"
        [data]="data_VentaTopCliTable">
      </board-table>
    </div>
    <div class="col-md-6">
      <board-process *ngIf="!this.showVentaPieZN">
      </board-process>
      <board-chartpie *ngIf="this.showVentaPieZN" #chartPieZN id="chartPieZN" [data]="data_VentaPieZN">
      </board-chartpie>
    </div>
  </div>

  <!-- Ventas por Grupo y Promedio de Ventas por dia-->
  <div class="row m-b-xl">
    <div class="col-md-6">
      <board-process *ngIf="!this.showVentaPieGP">
      </board-process>
      <board-chartpie *ngIf="this.showVentaPieGP" #chartPieGP id="chartPieGP" [data]="data_VentaPieGP">
      </board-chartpie>
    </div>
    <div class="col-md-6">
      <board-process *ngIf="!this.showVentaDiaBar">
      </board-process>
      <board-chartbar #chartBarVentaDia id="chartBarVentaDia" [data]="data_VentaDiaBar" [showPercent]="'true'"
        *ngIf="this.showVentaDiaBar">
      </board-chartbar>


      <!--Aqui nuevo Card-->

      <!-- 
          <div class="row">
            <div *ngIf="!this.showVentaCards" class="ibox float-e-margins border">
              <div class="ibox-title">
                <h5>Procesando datos..</h5>
              </div>
              <div class="ibox-content">
                <div style="display: flex; min-height: 360px; justify-content: center;align-items: center;">
                  <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="margin-left: -2.5px;font-size: x-large; font-weight: 500;"></i>
                </div>
              </div>
            </div>
            <div *ngIf="this.showVentaCards">
                <board-card>
                </board-card>
            </div>
          </div>
          -->
    </div>
  </div>

  <!-- Resumen-->
  <div class="row m-b-xl">
    <div class="col-md-12">
      <board-process *ngIf="!this.showVentaTopVendTable">
      </board-process>
      <board-table *ngIf="this.showVentaTopVendTable" #gridVentaTopVendTable id="gridVentaTopVendTable"
        [data]="data_VentaTopVendTable">
      </board-table>
    </div>
  </div>
</div>