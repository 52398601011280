import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Zone } from '../../models/logistic/zone';


@Injectable({
  providedIn: 'root'
})
export class ZoneService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }


  getListZones() {
    const url = this.getApiQuery(`Customer/Zonas`);
    return this._httpClient.get<Zone[]> (url, { headers: this.getAuthorizationHeader() });

  }
}
