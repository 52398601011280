import { Injectable, TemplateRef, ElementRef } from '@angular/core';
import printJS, { Configuration } from 'print-js';

@Injectable({
  providedIn: 'root'
})
export class PrinterService {

  configuration: Configuration;
  constructor() {
    this.configuration = {
      printable: '',
      type: 'html',
      documentTitle: '',
      style: 'ibox-content p-xl invoice-total'
    }
  }

  print() {
    printJS(this.configuration);
  }
}
