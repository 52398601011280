import { Phone } from './Phone';

export class Contact {

  Id: string;
  Name: string;
  Phones: Phone[];
  RUC: string;
  City: string;
  Address: string;
  Email: string;
  Lat?: number;
  Long?: number;
  Id_Vend: number;
  TipoPrecio: number;
  Zone: number;
  Group: number;
  CupoCredito: number;
  Image64: string;
  CustomerId?:number;
  // ZipCode: string;

  constructor () {
    this.Phones = [];
  }
}

