<page-heading pageTitle="{{this.title | translate}}">
    <button type="button" class="btn btn-primary" tabindex="-1" (click)="closeDialog()">
        <i class="ti-close"></i>
    </button>
</page-heading>

<div class="ibox ">
    <div class="ibox-content">
        <p class="p-w-sm">{{this.message | translate}}</p>
    </div>
    <div class="ibox-footer text-center">
        <div *ngIf="isyesno">
            <button type="submit" mat-button class="btn btn-yellow m-r-sm" (click)="onAccept()">
                {{'Accept' | translate}}
            </button>
            <button type="submit" mat-button class="btn btn-yellow" (click)="onCancel()">
                {{'Cancel' | translate}}
            </button>
        </div>
        <div *ngIf="isyes">
            <button type="submit" mat-button class="btn btn-yellow m-r-sm" (click)="onAccept()">
                {{'Accept' | translate}}
            </button>
        </div>

    </div>
</div>