import { DocFile } from "../system/doc_anex";
import { CustomRow } from "./JobPosition";

export class EmploymentHistory {
  EmployerName: string;
  JobTittle: string;
  JobDescription?: any;
  StartDate: Date;
  EndDate: Date;
  Salary: number;
  ImmediateManager?: any;
  Reference?: any;
  Reasonforleaving: string;
  Contact?: any;
}

export class TrainingHistory {
  Topic: string;
  Institution: string;
  TrainingTime?: any;
  Status?: any;
  Reference: string;
}

export class Contact {
  Name: string;
  Address?: any;
  City?: any;
  Phone: string;
  Email?: any;
  ZipCode: string;
  Latitude: number;
  Longitude: number;
}

export class EmployeeReference {
  Company: string;
  JobPosition: string;
  Contact: Contact;
}

export class FormalEducation extends CustomRow {
  EducationLevel: string;
  Institution: string;
  Timespam: string;
  AcademicDegree: string;
  SpecialtyDegree?: string;
  Reference?: any;
}

export class EmergencyContact {
  Fullname: string;
  Relationship?: any;
  Birthday: Date;
  Occupation?: any;
  Reference?: any;
  WorkPhone?: any;
  Homephone: string;
  CellPhone?: any;
  Email?: any;
  Alive: boolean;
  Disabilities?: any;
}

export class Sibling {
  Fullname: string;
  Relationship?: any;
  Birthday: Date;
  Occupation?: any;
  Reference?: any;
  WorkPhone?: any;
  Homephone: string;
  CellPhone?: any;
  Email?: any;
  Alive: boolean;
  Disabilities?: any;
}

export class Burden extends CustomRow {
  Fullname: string;
  Relationship?: any;
  Birthday: Date;
  Occupation?: any;
  WorkplaceAddress: string;
  Reference?: any;
  WorkPhone?: any;
  Homephone: string;
  CellPhone?: any;
  Email?: any;
  Alive?: boolean;
  Disabilities?: any;
  DocId: string;
  Birthplace: string;
}

export class Disability extends CustomRow {
  DisabilityType: string;
  Percentage: string;
  UDID: string;
}

export class EmployeeRecord extends CustomRow {
  EmployeeID: string;
  SProcess?: any;
  SSubprocess?: any;
  STipoPer: string;
  EmploymentHistory: EmploymentHistory[];
  TrainingHistory: TrainingHistory[];
  EmployeeReferences: EmployeeReference[];
  FormalEducation: FormalEducation[];
  Records: number;
  x_secuencia: number;
  ID_PER: string;
  CEDU_PER: string;
  ID_TIP?: any;
  ID_CAR?: any;
  ProfilID: number;
  BrancheID: number;
  TerritoryID: number;
  IsResourcePlanning: boolean;
  UserID: number;
  CAJA: number;
  FING_PER?: any;
  SUEL_PER?: any;
  FECNAC: Date; // fecha de nacimiento
  CUENTA?: any;
  BANCO?: any;
  CODSEC?: any;
  ESTADO: string;
  FOTO: string;
  BUSINESSDEPARTMENTID?: any;
  APPLICATIONID?: any;
  Status: number;
  Position: number;
  DEPARTID: number;
  ID_AREA: number;
  SUP_ID_PER: string;
  Process: number;
  Subprocess: number;
  AttendanceId: string;
  MaritalStatus: number;
  ESTC_ID: number;
  Gender?: string;
  EthnicGroup?: string;
  BurdenQty: number;
  Siblings: Sibling[];
  GeneralData: any[];
  Name: string;
  Address?: string;
  City: string;
  Phone: string;
  Email: string;
  ZipCode: string;
  Latitude: number;
  Longitude: number;
  NOMBRES: string;
  APELLIDOS: string;
  SPAISDOM?: string; // pais del domicilio
  SPAISNAC: string; // pais de nacimiento
  SPRONAC: string; // provincia de nacimiento
  SCIUNAC: string; // ciudad de nacimiento
  CELULAR: string;
  SPRODOM: string; // provincia del domicilio
  SCIUDOM: string; // ciudad del domicilio
  CUENTACORRIENTE: boolean;
  //---------Tab 2--------//
  Burdens: Burden[];
  //---------Tab 3--------//
  TIPOSANGRE: string;
  EmergencyContacts: EmergencyContact[] = [];
  MedicalConditions: HRMedicalCondition[];
  Disabilities: Disability[];
  //---------Tab 4--------//
  Subordinates: HREmployeeLite[];
  DocFiles: DocFile[];
}

export class PersonnelPermit {
  ID_SOLPERM: number;
  LOCALIDAD: string;
  FECHA: Date;
  ID_PER: string;
  CARGO: string;
  ID_DEPART: number;
  ID_MOTPER: number;
  FDESDE: Date;
  FHASTA: Date;
  AUTORIZA: string;
  ANULADO: boolean;
  ESTADO: number;
  JUSTIFICAR: string;

  NOMB_PER : string;
  NOMB_RESP : string;
  PROCESS : string;
  SUBPROCE : string;
  REF:string;
  DOCFILES:string;// DocFile[];
  DocFiles: DocFile[];
  HORADES:String;
  HORAHAS:String;
}

export class HRMedicalCondition extends CustomRow {
  MediacalConditionName: string;
  Detail: string;
  Medication: string;
  Note: string;
}

export class Person {
  Name: string;
  Phone: string;
  constructor(name: string, phone: string) {
    this.Name = name;
    this.Phone = phone;
  }
}

export class HRReferenceRecord extends CustomRow {
  EmployerName: string;
  JobTittle: string;
  Contact: Person;
  StartDate: Date;
  EndDate: Date;
}

export class HREmployeeLite {
  EmployeeID: string;
  Fullname: string;
  NationalID: string;
  PersonnelType: string;
  WorkPhone: string;
  Email: string;
  AttendanceID: string;
}

export class HRJobPosition {
  CENPRO: string;
  DESC_CAR: string;
  HORMES: string;
  ID_CAR: string;
  NOMB_CAR: string;
  SALHOR: string;
}
