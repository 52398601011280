export class CustomerEquipment {
  EquipmentCustomerID: number;
  CustomerID: number;
  CustomerLocationID: number;
  SerialNo: string;
  LastManteinanceDate: Date;
  NextManteinanceDate: Date;
  AcquisitionDate: Date;
  WarrantyEndDate: Date;
  IPAddress: string;
  MAC: string;
  Salesreference: string;
  EquipmentModelID: number;
  Name?: string;
  Description: string;
  Selected: boolean;
  ReferenceCost: number;
  Info?: string;
  MaintenanceCycle: number;
  Blocked: boolean;
  Manufacturer: string;
  UserID: number;
}

