import { Injectable, Injector } from '@angular/core';
import { InvoiceItem } from '../../models';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from '../base/base.service';
import { APP_SETTINGS } from '../../appsettings';
import { UsersService } from '../system/users.service';

@Injectable({
  providedIn: 'root'
})
export class CartService extends BaseService {
  cartProductShowDetail: number;
  cartOrder: number;

  cartItems: InvoiceItem[] = [];
  cartItemsBehavior = new BehaviorSubject<InvoiceItem[]>([]);
  cartItems$ = this.cartItemsBehavior.asObservable();

  constructor(injector: Injector) {
    super(injector);
    this.cartOrder = APP_SETTINGS.setups.cart.order;
    this.cartProductShowDetail = APP_SETTINGS.setups.cart.showDetail;
  }

  sendCart(items: InvoiceItem[]) {
    const url = this.getApiQuery('Quotations/updatecart');
    return this._httpClient.post(url, items, { headers: this.getAuthorizationHeader() });
  }

  updateCartItems(items: any) {
    this.updateData(items);
  }

  addItemToCart(item: InvoiceItem,OneProductByLine:number=0) {
    if (this.cartProductShowDetail === 0) {
      item.Detail = '';
    }
    if (OneProductByLine==0){
      this.cartItems.unshift(item);
      this.updateData(this.cartItems);
    }else{
      let existe:boolean=false;
      this.cartItems.forEach(itemX=>{
        if (itemX.Id==item.Id) {
          itemX.Quantity=Number(itemX.Quantity)+Number(item.Quantity);
          existe=true;
        }
      });
      if (!existe){
        this.cartItems.unshift(item);
        this.updateData(this.cartItems);
      }
    }
  }

  removeItemFromCart(id: number) {
    this.cartItems.splice(id, 1);
    this.updateData(this.cartItems);
  }

  clearCart() {
    this.updateData([]);
  }

  updateData(data: any){
    this.cartItems = data;
    this.cartItemsBehavior.next(this.cartItems);
    this.sendCart(this.cartItems).toPromise().then(response => {});
  }

  addCantUItemCart(id:string,Quantity:number) {
    this.cartItems.forEach(item=>{
      if (item.Id==id) {
        item.Quantity=item.Quantity+Quantity;
      }
    })
  }


  selectPriceTypeByCustomer(priceType: number) {
    switch (priceType) {
      case 1:
        this.cartItems = this.cartItems.map(product => { product.Price = product.Price1; return product });
        break;
      case 2:
        this.cartItems = this.cartItems.map(product => { product.Price = product.Price2; return product });
        break;
      case 3:
        this.cartItems = this.cartItems.map(product => { product.Price = product.Price3; return product });
        break;
      case 4:
        this.cartItems = this.cartItems.map(product => { product.Price = product.Price4; return product });
        break;
      case 5:
        this.cartItems = this.cartItems.map(product => { product.Price = product.Price5; return product });
        break;
      case 6:
        this.cartItems = this.cartItems.map(product => { product.Price = product.Price6; return product });
        break;
      case 7:
        this.cartItems = this.cartItems.map(product => { product.Price = product.Price7; return product });
        break;
      case 8:
        this.cartItems = this.cartItems.map(product => { product.Price = product.Price8; return product });
        break;
    }
  }
}
