  <div class="ibox-title">
    <h5>{{data.title}}</h5>
  </div>
  <div class="ibox-content">
    <!--[colors]="lineChartColors" -->
      <canvas 
          baseChart 
          [datasets]="lineChartData" 
          [labels]="lineChartLabels" 
          [options]="lineChartOptions"
          
          [legend]="lineChartLegend" 
          [chartType]="lineChartType" 
          [plugins]="lineChartPlugins">
      </canvas>
  </div>



