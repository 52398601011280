import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CashService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }


  getCashList(userId:string) {
    let params: HttpParams = new HttpParams();

    if (userId) { params = params.append('idUsuario',userId); }

    const url = this.getApiQuery(`POS/UserCashAccounts`);
    return this._httpClient.get(url, { 
      params: params,
      headers: this.getAuthorizationHeader() 
    });
  }

  GetCashByUserID(UserId:string) {
    let params: HttpParams = new HttpParams();

    if (UserId) { params = params.append('UserId',UserId); }

    const url = this.getApiQuery(`Account/GetCashByUserID`);
    return this._httpClient.get(url, { 
      params: params,
      headers: this.getAuthorizationHeader() 
    });
  }

}
