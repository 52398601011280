import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Subcategory } from '../../models';
import { ProductResourceParameters } from '../../helpers';
import { Observable } from 'rxjs';
import { Category } from 'src/app/models/Products/Category';
import { GeneralProductOption } from 'src/app/models/Products/GeneralProducOption';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService extends BaseService {

  constructor(private _injector: Injector) {
    super(_injector);
  }

  getCategoriesNewProduct(): Observable<Category[]> {
    const url = this.getApiQuery('Inventory/Categorias');
    return this._httpClient.get<Category[]>(url, {headers: this.getAuthorizationHeader()});
  }

  getGroupsNewProduct(): Observable<Category[]> {
    const url = this.getApiQuery('Inventory/Grupos');
    return this._httpClient.get<any[]>(url, {headers: this.getAuthorizationHeader()});
  }

  getSizesNewProduct(): Observable<any[]> {
    const url = this.getApiQuery('Inventory/Tallas');
    return this._httpClient.get<any[]>(url, {headers: this.getAuthorizationHeader()});
  }

  getCategories(): Observable<Category[]> {
    const url = this.getApiQuery('Inventory/Categories');
    return this._httpClient.get<Category[]>(url, {headers: this.getAuthorizationHeader()});
  }

  getSubcategories (): Observable<string[]> {
    const url = this.getApiQuery('Inventory/Subcategories');
    return this._httpClient.get<string[]>(url, {headers: this.getAuthorizationHeader()});
  }

  getModels (): Observable<string[]> {
    const url = this.getApiQuery('Inventory/Modelos');
    return this._httpClient.get<string[]>(url, {headers: this.getAuthorizationHeader()});
  }

  getBrands (): Observable<GeneralProductOption[]> {
    const url = this.getApiQuery('Inventory/Marcas');
    return this._httpClient.get<GeneralProductOption[]>(url, {headers: this.getAuthorizationHeader()});
  }

  getSizes(): Observable<GeneralProductOption[]> {
    const url = this.getApiQuery(`Inventory/Tallas`);
    return this._httpClient.get<GeneralProductOption[]>(url, { headers: this.getAuthorizationHeader() });
  }

  getColors(): Observable<GeneralProductOption[]> {
    const url = this.getApiQuery(`Inventory/Colores`);
    return this._httpClient.get<GeneralProductOption[]>(url, { headers: this.getAuthorizationHeader() });
  }

  getSubcategoriesByParent (searchText: string): Observable<Category[]> {
    const url = this.getApiQuery(`Inventory/Categories/?grupo=${searchText}`);
    return this._httpClient.get<Category[]>(url, {headers: this.getAuthorizationHeader()});
  }

  getCategoriesByFilter(productResourceParameters: ProductResourceParameters): Observable<Array<Category>> {
    const url = this.getApiQuery('Inventory/Categories');
    return this._httpClient.post<Array<Category>>(url, {
      'FilterCategory': productResourceParameters.FilterCategory,
      'FilterBrand': productResourceParameters.FilterBrand,
      'FilterModel': productResourceParameters.FilterModel
    });
  }
  getSubcategoriesByFilter(productResourceParameters: ProductResourceParameters): Observable<Array<Subcategory>> {
    const url = this.getApiQuery(`Inventory/Subcategories`);
    return this._httpClient.post<Array<Subcategory>>(url, {
      'FilterCategory': productResourceParameters.FilterCategory,
      'FilterBrand': productResourceParameters.FilterBrand,
      'FilterModel': productResourceParameters.FilterModel
    });
  }

  getBrandsByFilter(productResourceParameters: ProductResourceParameters): Observable<Array<GeneralProductOption>> {
    const url = this.getApiQuery('Inventory/Marcas');
    return this._httpClient.post<Array<GeneralProductOption>>(url, {
      'FilterCategory': productResourceParameters.FilterCategory,
      'FilterBrand': productResourceParameters.FilterBrand,
      'FilterModel': productResourceParameters.FilterModel
    });
  }

  getCategoriesNCC(): Observable<Category[]> {
    const url = this.getApiQuery('AccountReceivable/CustomerCreditCategories');
    return this._httpClient.get<Category[]>(url, {headers: this.getAuthorizationHeader()});
  }
}
