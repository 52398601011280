import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Injectable, Injector, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { pipe, Subject, Subscription } from 'rxjs';
import { InvoiceService,CashService,BankService, InvoicesService, PopModalService, ProductsService, CreditcardsService, SweetAlertService, ToastrService, SessionService, UtilService, CustomersService, DataService } from '../../../services';
import { finalize, map, takeUntil } from 'rxjs/operators';
import 'rxjs/add/observable/of';
//import { forkJoin } from 'rxjs/observable/forkJoin';
import { Observable } from 'rxjs/Observable';
//import "rxjs/add/observable/zip";
//import { zip } from 'rxjs';

import 'rxjs/add/observable/fromEvent';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CommandModel, EditSettingsModel, GridComponent, SaveEventArgs, SelectionSettingsModel, RowSelectEventArgs, ToolbarItems, IEditCell, DataSourceChangedEventArgs, DataStateChangeEventArgs, Column, IIndex, columnChooserCancelBtnClick, extendObjWithFn, columnSelectionComplete, ExcelFilterBase } from '@syncfusion/ej2-angular-grids';
import { DropDownList, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { SearchService } from './../../widgets/search2/search2.service';
import { HttpParams } from '@angular/common/http';
import { BaseService } from '../../../services/base/base.service';
import { InvoiceItem, Packaging, Product, Tax, TrainingHistory,Guid, DocumentResponse, Type_quotation, IQuotation, Contact, PromoItem, IDocument } from '../../../models';
import { Type_fPagosNew, Type_invoice, typ_serieDoc,typ_Cash, typ_Bank, typ_Creditcard,typ_Warehouse, typ_CardPlan, typ_CustomerBank } from '../../../models';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { PaymentsComponent, Type_dataExtra } from './payments/payments.component';
//import { DropDownListComponent } from '@syncfusion/ej2-dropdowns';
//import { DropDownListComponent } from '@syncfusion/ej2-dropdowns';
//import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { TextBox } from '@syncfusion/ej2-inputs';
import { SalesmansService } from '../../../../app/services/sales/Salesmans.service';
import { Salesmans } from '../../../../app/models/Sales/Salesmans';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { formatNumber } from '@angular/common';
import { NumericTextBox } from '@syncfusion/ej2-inputs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from "moment";
import { ComboBox } from '@syncfusion/ej2-dropdowns';

import {  NavigationService } from '../../../services';

import { QuotationService } from '../../../services/sales/quotation.service';
import { WaitService } from '../../widgets/wait/wait.service';
//import { HostListener } from '@angular/core';

//import 'rxjs/add/observable/fromEvent';
import { SearchGridService } from '../../widgets/searchGrid/searchGrid.service';
import { DialogboxComponent } from '../../widgets/dialog-box/dialog-box.component';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { Invoice } from '../../../models';
import { APP_SETTINGS } from '../../../appsettings';
import { HostListener } from '@angular/core';
import { BankempService } from 'src/app/services/purchase/bankemp.service';
import { typ_BankEmp } from 'src/app/models/purchase/purchase';
import { InputboxComponent } from '../../widgets/input-box/input-box.component';
import { NewCustomerComponent } from '../new-customer/new-customer.component';
import { NewCustomerBasicComponent } from '../new-customerbasic/new-customerbasic.component';
import { Customer } from '../../../models';

//import { TabsComponent } from '../../widgets/tabs/tabs.component';

@Injectable({
  providedIn: 'root'
})
export class DatagridService extends BaseService {
  subscription: Subscription;
  constructor(private _injector: Injector) {
    super(_injector);
  }

  //*-------
  public GetData(state: any,url:string,filter? : string) : Observable<any[]>  {

    let Ini= Math.floor((state.skip/state.take));
    Ini++;
    let params: HttpParams = new HttpParams();
    if (filter)     { params = params.append('UserId',filter); }
    if (Ini)        { params = params.append('PageNumber',Ini.toString()); }
    if (state.take) { params = params.append('PageSize',state.take.toString()); }
    //let url = 'Customer/Pagging';
    url = this.getApiQuery(url);
    return this._httpClient.get<any[]>(url, {headers: this.getAuthorizationHeader(), params: params});
  }

  public GetCustomer(state: any,url:string,filter? : string) : Observable<any[]>  {

    let Ini= Math.floor((state.skip/state.take));
    Ini++;
    let params: HttpParams = new HttpParams();
    if (filter)     { params = params.append('SearchTerm',filter); }
    if (Ini)        { params = params.append('PageNumber',Ini.toString()); }
    if (state.take) { params = params.append('PageSize',state.take.toString()); }
    //let url = 'Customer/Pagging';
    url = this.getApiQuery(url);
    return this._httpClient.get<any[]>(url, {headers: this.getAuthorizationHeader(), params: params});
  }

  public GetProducts(state: any,url:string,filter? : string) : Observable<any[]>  {

    let Ini= Math.floor((state.skip/state.take));
    Ini++;
    let params: HttpParams = new HttpParams();
    if (filter)     { params = params.append('SearchTerm',filter); }
    if (Ini)        { params = params.append('PageNumber',Ini.toString()); }
    if (state.take) { params = params.append('PageSize',state.take.toString()); }
    //let url = 'Customer/Pagging';
    url = this.getApiQuery(url);
    return this._httpClient.get<any[]>(url, {headers: this.getAuthorizationHeader(), params: params});
  }
}

export interface listStock{
  Id       : number;
  Name     : string;
  Stock    : number;
}

export interface ProductStock{
  Id              : string;
  Stock           : number;
  WarehouseNumber : number;
}
/*----------Tipos que solo se usaran aqui en Invoice---------------*/
export interface ItemInvoice extends InvoiceItem {
  Itemno         : number,

  Unity?          : string,
  UnityId?        : number,

  IVA?            : string,
  IvaId?          : number,

  //WarehouseNo?        : number,
  WarehouseName?  : string,
  IvaPorc?        : number,
  TotIva?         : number,
  ivainc?         : boolean,

  _TaxesSel?      : Tax,
  _PackagingSel?  : Packaging,
  _Prod?          : Product,

  stockReal?      : number;
  QuantityPrf?    : number;
  isItemOfPrf?    : boolean;

  isPromo?        : boolean;
  Id_promo?       : string;
  ItemNoPromo?    : number;
  AplicaColor?    : boolean;
}
export interface TaxBases {
  StylexsaCode: string;
  Rate: number;
  Total:number;
  TIva:number
}

export interface selCustomer {
  Id        : string,
  RUC       : string,
  Name      : string,
  Address   : string,
  Phones    : string,
  Email     : string
}

/*----------Data que se pasa a Payments---------------*/
export interface dataInvoice{
  date              : Date;
  subTotal          : number;
  totDiscount       : number;
  totIva            : number;
  totIce            : number;
  total             : number;
  customerID        : string;
  listCash          : Array<typ_Cash>;
  listBank          : Array<typ_Bank>;
  listBankEmp       : Array<typ_BankEmp>;
  listCreditCard    : Array<typ_Creditcard>;
  selCustomer       : selCustomer;
  cashDefault       : number;
  enableInvoiceZero : boolean;
}

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css'],
  //encapsulation: ViewEncapsulation.None
})
export class InvoiceComponent implements OnInit, OnDestroy {

  ngUnsubscribe = new Subject();
  private subscription    : Subscription;
  private subscriptionGrid: Subscription;
  private ObjObserver     : Subscription;
  public action           : string;
  public titleComponent   : string;
  public bsSettings       : Partial<BsDatepickerConfig>;
  private theme           = 'theme-red';

  /*--------------------Config Grid--------------------*/
  @ViewChild('grid', { static: false }) public grid: GridComponent;
  public editSettings     : EditSettingsModel;
  public selectionOptions : SelectionSettingsModel;
  public toolbarOptions   : ToolbarItems[];
  public commands         : CommandModel[];
  /*---------------Variables Evenctos Grid--------------*/
  public orderData        : object;
  public setFocus         : any;
  public cellActive       : IIndex;
  public editParams       : any;
  public rowIndex         : number;
  public isKeyPressed     : any;
  public isCellSave       : any;
  public selRow           : number;
  public selCol           : number;
  public selItemNo        : number;
  public selRowEdit       : number;
  public selColEdit       : string;
  public unityParams      : IEditCell;
  public taxesParams      : IEditCell;
  public WarehouseParams  : IEditCell;
  public txtNumParams     : IEditCell;
  public txtParams        : IEditCell;
  public numericParams    : IEditCell;
  public numericParamsBod : IEditCell;
  public floatParams      : IEditCell;
  public floatParamsPos   : IEditCell;
  public floatParamsCant  : IEditCell;
  public UnityObj         : DropDownList;
  public TaxesObj         : DropDownList;
  public WarehouseObj     : DropDownList; //ComboBox;//DropDownList;
  public TxtNumObj        : NumericTextBox;//TextBox;
  public TxtObj           : TextBox;
  public stateElem        : HTMLElement;
  /*---------------Variables Data Grid--------------*/
  public Record           : any;
  public listSales        : Array<Salesmans> = [];
  public listPrice        : Array<any> = [];
  public itemInvoice      : Array<ItemInvoice>=[];
  public Unity            : { [key: string]: Object }[] =  [];
  public Unity2           : Array<Packaging>=[];
  //https://stackblitz.com/edit/dropdown-asyncpipe-1?file=src%2Fapp%2Fapp.component.html
  public Unity3           : Observable<any> = null;//Observable <{ [key: string]: Object }> =  this.getData(5);//Observable<any> = this.getData(5);
  public Unity4: object[] = []
  public cmbFieldsUnity   : Object = { text: 'PackagingName', value: 'Id' };
  public NameUnity        : string;

  public Taxes            : { [key: string]: Object }[] =  [];
  public Taxes2           : Array<Tax>=[];
  public TaxesGen         : Array<Tax>=[];
  public TaxesBases       : Array<TaxBases>=[];
  public Warehouse        : { [key: string]: Object }[] =  [];
  public WarehouseGen     : Array<typ_Warehouse>=[];
  public WarehouseOld     : number;
  public cmbFieldWarehouse: Object = { text: 'name', value: 'num' };
  public CustomerID       : string="";
  /*------------Variables Calculos Generales---------*/
  public SubTotal         : number=0;
  public Discount         : number=0;
  public Ice              : number=0;
  public Iva              : number=0;
  public Total            : number=0;
  public DecimalesSubtotalRendondeo: number=2;
  /*------------------Varias-------------------------*/
  public selCustomer      : selCustomer;
  public invoice          : Type_invoice;
  public listCash         : Array<typ_Cash>=[];
  public listBank         : Array<typ_Bank>=[];
  public listCreditCard   : Array<typ_Creditcard>=[];
  public listCustomerBank : Array<typ_CustomerBank>=[];
  public listBankEmp      : Array<typ_BankEmp>=[];

  public SerieDoc         : typ_serieDoc;
  public SerieFormat      : string;
  public maxID            : string;
  public listSeries       : Array<typ_serieDoc>=[];
  public idSerie          : number=0;
  public doubleAction     : boolean=true;
  //public isEditCell       : boolean=false;
  /*--------------Validacion en la Vista-------------*/
  public Form: FormGroup;
  public CtrTo_IdVend     : FormControl = new FormControl('', Validators.required);
  public CtrTo_TipoPrecio : FormControl = new FormControl('', Validators.required);
  public CtrTo_SelPrecio : FormControl = new FormControl('', Validators.required);
  public CtrTo_idSerie    : FormControl = new FormControl('', Validators.required);
  public CtrTo_Customer   : FormControl = new FormControl('', Validators.required);


  public FormPrf              : FormGroup;
  public CtrTo_NPrf           : FormControl = new FormControl('', Validators.required);
  public isSubmitted          : boolean = false;
  public isVisibleCols        : boolean = false;

//-----------------Variables de Session-----------------
  public Session_EnableReservedStock: boolean = false;
  public Session_User         : string = '';
  public Session_Empresa      : string = '';
  public Session_Serie        : number = 0;
  public Session_Punto        : number = 0;
  public Session_Sales        : number = 0;
  public Session_Salesman     : string = '';
  public Session_Cash         : number = 0;
  public Session_EditPrice    : boolean = true;
  public Session_SelectPrice  : boolean = true;
  public Session_EditDiscount : boolean = true;
  public Session_Bodegas      : Array<any>=[];
  public Session_BodDefault   : number = 1;
  public Session_BodNameDef   : string = '';
  public Session_AllowNegStock: boolean = true;
  public Session_AlertStock   : boolean = true;
  public Session_Allow0Price  : boolean = true;
  public Session_ModiFecha    : boolean = true;
  public Session_UserPvp      : boolean = false;
  public Session_IvaChange    : boolean = false;
  public Session_AddColSearch : boolean = false;
  public Session_OnlyIntCant  : boolean = false;
  public Session_USUACCPRE    : boolean = false;
  public isQuotation          : boolean = false;
  public isInvoice            : boolean = true;
  public Session_PrfUnique    : boolean = false;
  public Session_CalcNextPre  : boolean = true;
  public DocId                : number;
  public DocType              : string;
  public NameDocNo            : string ='InvoiceNo';
  public Customer = new Contact();
  public ItemNoPromo          : number=0;
  public UltimoColor          : string='';


  @ViewChild("Quantity",{static: false}) inputs;
  public dialogPrf;
  @ViewChild('tplProform',{static: false}) tplProform: TemplateRef<any>;

  public dialogNewInvoice;
  @ViewChild('tplNewInvoice',{static: false}) tplNewInvoice: TemplateRef<any>;

  public dialogYN;
  @ViewChild('tplDialog',{static: false}) tplDialog: TemplateRef<any>;
  public dialogTitle:string='';
  public dialogMessage:string='';

  public customAttributes: Object;

  @ViewChild('idInput',{static: false}) input: ElementRef;

  @ViewChild('UnityCmb') public UnityCmb: DropDownListComponent;
  //@ViewChild('UnityCmb') public UnityCmb: ComboBoxComponent;

  /*
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    let key = event.key;
    console.log(key);
  }
  */

  //@ViewChild(TabsComponent) tabsComponent;
  @Output() messageEvent = new EventEmitter<string>();
  public isTabs:boolean=false;

  constructor(
              public dialog: MatDialog,
              private _data: DatagridService,
              public search: SearchService,
              public searchGrid: SearchGridService,
              private _translateService: TranslateService,
              private _notificationService: PopModalService,
              private _ItemsFact: InvoicesService,
              private _SalesmansService: SalesmansService,
              private _productService: ProductsService,
              private _cashService: CashService,
              private _bankService: BankService,
              private _invoiceService: InvoiceService,
              private _creditcardsService: CreditcardsService,
              private swalService: SweetAlertService,
              private _toastrService: ToastrService,
              private _sessionService: SessionService,
              private _formBuilder: FormBuilder,
              private math: UtilService,
              private _quotationService: QuotationService,
              private _customersService: CustomersService,
              private waitService: WaitService,
              private _navigationService: NavigationService,
              private _dataService: DataService,
              private _BankempService: BankempService,
              ) {  }

  ngOnInit(): void {
    //this.subscription = Observable.fromEvent(document, 'keypress').subscribe(e => {
    //  console.log(e);
    //})
    //console.log(this.isTabs);
    let labelBtnAction='Update';
    let labelTitle='EditInvoice';
    if (this.Record == null) {
      labelBtnAction='Register';
      labelTitle='New Invoice';
    }
    this._translateService.get(labelBtnAction)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: string) => {
          this.action = result;
    });
    this._translateService.get(labelTitle)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: string) => {
          this.titleComponent = result;
    });
    this.isQuotation=false;
    this.isInvoice=true;
    this.DocId=0;
    this.DocType='FAC'
    this.Session_UserPvp = false;
    this.Session_IvaChange = true;

    if (this._dataService.TypeDoc=='PRF'){
      this.isQuotation=true;
      this.isInvoice=false;
      this.DocType='PRF'
      this.CtrTo_idSerie.clearValidators();
    } else {
      if(this._dataService.case != null){ // viene de un CRM-case
        // let nota = this._dataService.case.Problem;
        // if(nota != null){
        //   this.invoice.Nota = nota;
        // }
        this.CustomerID = this._dataService.case.CustomerID;
        this._customersService.getCustomersById(this.CustomerID).subscribe((response) => {
          if (response != null) {
            this.setCustomer(response);
          }
      });
      }
    }

    //console.log(this.isQuotation);

    this.initCtrl();
    this.setSession();
    this.initGrid();
    this.initGridCtrl();

    this.loadDataCtrl();
    this.newInvoice();
    this.loadDataCtrlComplete().then( (success) => {
        //console.log('bajado..');
        if (this.isInvoice){
          this.newInvoice();
        }
        if (this.isQuotation){
          if (this._dataService.DocId>0){
            this.DocId=this._dataService.DocId;
            this.maxID=this.DocId.toString();
            this.editQuotation(this._dataService.DocId);
          }
        }
    });


    //this.subscription = Observable.fromEvent(document, 'keypress').subscribe(e => {
      //console.log(e);
    //})
    this.customAttributes = {class: 'customcss'};

    this.subscriptionGrid = this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.TranslaterHeaders();
    });

    //**-----Si es Edicion----*/
    //let docId=147405;
    //this.editInvoice(docId);

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.subscriptionGrid.unsubscribe();
    //this.subscription.unsubscribe();
  }
  ngAfterViewInit() {
    this.cellActive= {
      rowIndex  : 0,
      cellIndex : this.getIndexColum('Code')
    };
    window.setTimeout(() => {
      //this.grid.selectCell(this.cellActive,false);
    }, 1500);
  }
  ngAfterContentChecked(){
  }

  TranslaterHeaders(){
    let Headers =[
      {header:'ItemNo',field:'Itemno' },
      {header:'Code',field:'Code' },
      {header:'Description',field:'Description' },
      {header:'Price',field:'Price' },
      {header:'Quantity',field:'Quantity' },
      {header:'Stock',field:'Stock' },
      {header:'Unity',field:'Unity' },
      {header:'Subtotal',field:'Subtotal' },
      {header:'Discount',field:'Discount' },
      {header:'Storage',field:'WarehouseName' },
      {header:'IVA',field:'IVA' }
    ]

    //{header:'PriceIVA',field:'PriceIVA' }
    for (let item of Headers){
      this._translateService.get(item.header)
          .subscribe((result: string) => {
            let column = this.grid.getColumnByField(item.field); // Get column object.
            column.headerText = result;
      });
    }
    window.setTimeout(() => {
      this.grid.refreshHeader();
    }, 0);

  }
  /*****************************************************************/
  /**                  Carga de datos en los Ctrls                **/
  /*****************************************************************/

  loadDataCtrl() {

    this.listPrice = this._sessionService.getPrices();

    // if(this._sessionService.getSessionPermision('PRECIO1'))
    // {
    //   this.listPrice.push({ id : 1, name: 'Precio 1' });
    //   if(this._sessionService.getSessionPermision('PRECIO2')) this.listPrice.push({ id : 2, name: 'Precio 2' });
    //   if(this._sessionService.getSessionPermision('PRECIO3')) this.listPrice.push({ id : 3, name: 'Precio 3' });
    //   if(this._sessionService.getSessionPermision('PRECIO4')) this.listPrice.push({ id : 4, name: 'Precio 4' });
    //   if(this._sessionService.getSessionPermision('PRECIO5')) this.listPrice.push({ id : 5, name: 'Precio 5' });
    //   if(this._sessionService.getSessionPermision('PRECIO6')) this.listPrice.push({ id : 6, name: 'Precio 6' });
    //   if(this._sessionService.getSessionPermision('PRECIO7')) this.listPrice.push({ id : 7, name: 'Precio 7' });
    //   if(this._sessionService.getSessionPermision('PRECIO8')) this.listPrice.push({ id : 8, name: 'Precio 8' });
    // }
    // else
    // {

    //   this.listPrice = [
    //     { id : 0, name: 'P.V.P'},
    //     { id : 1, name: 'Precio 1' },
    //     { id : 2, name: 'Precio 2' },
    //     { id : 3, name: 'Precio 3' },
    //     { id : 4, name: 'Precio 4' },
    //     { id : 5, name: 'Precio 5' },
    //     { id : 6, name: 'Precio 6' },
    //     { id : 7, name: 'Precio 7' },
    //     { id : 8, name: 'Precio 8' }
    //   ];
    // }
      this._cashService.getCashList(this.Session_User).subscribe((response : Array<typ_Cash>) => {
        if (response ) {
          this.listCash=response;
        }
      });
      this._bankService.getBankList().subscribe((response : Array<typ_Bank>) => {
        if (response ) {
          this.listBank=response;
        }
      });
      this._creditcardsService.getCreditCardList().subscribe((response : Array<typ_Creditcard>) => {
        if (response ) {
          this.listCreditCard=response;
        }
      });
      this._BankempService.getBanckEmp().subscribe((response : Array<typ_BankEmp>) => {
        if (response ) {
          this.listBankEmp=response;
        }
      });

  }

  loadDataCtrlComplete() : Promise<boolean> {
    return new Promise((resolve)=> {
        this._SalesmansService.getListSalesmans().subscribe((response:Array<Salesmans>) => {
            if (response != null) {
              this.listSales = response;
              this.invoice.Id_Vend=this.Session_Sales;
            }
            this._productService.getTaxesList().subscribe((response:any) => {
                  if (response) {
                    this.TaxesGen = response;
                    for (let item of this.TaxesGen){
                      let iTax: TaxBases = {
                        StylexsaCode:item.StylexsaCode,
                        Rate:item.Rate,
                        Total:0,
                        TIva:0
                      }
                      this.TaxesBases.push(iTax);
                    }
                    this.TaxesBases
                  }
                  this._productService.getStoreList().subscribe((response:Array<typ_Warehouse>) => {
                        if (response) {
                          this.WarehouseGen = response;
                          this.WarehouseGen.sort(function(a, b) {
                            return a.Id - b.Id; // ||  a.name.localeCompare(b.name);
                          });
                          this.Session_BodNameDef=this.WarehouseGen.find(e => Number(e.Id) === this.Session_BodDefault).Name
                          let StoreFilter=[];
                          for (let bodAcc of this.Session_Bodegas){
                            for (let bod of this.WarehouseGen){
                              if (bod.Id==bodAcc){
                                StoreFilter.push(bod);
                              }
                            }
                          }
                          if (StoreFilter.length>0){
                            this.WarehouseGen=StoreFilter;
                          }
                        }
                        console.log("Cascada de Subscribe OK");
                        resolve(true);

                  });
            });
        });

    });
  }


  setSession(){

    let serie:string='';
    let punto:string='';
    if (this._sessionService.getSessionInfo().Extras.serie){
      serie=this._sessionService.getSessionInfo().Extras.serie;
      punto=this._sessionService.getSessionInfo().Extras.serie;
      serie = serie.substring(0,serie.length-3);
      //punto=punto.slice(punto.length-3)
      punto=punto.substring(punto.length-3)
      this.Session_Serie=Number(serie);
      this.Session_Punto=Number(punto);
    }
    this.Session_User             = this._sessionService.getSessionInfo().Extras.id_user;

    //linea buena
    this.Session_Empresa          = this._sessionService.getSessionInfo().Extras.enterprise.Name;

    this.Session_Sales            = Number(this._sessionService.getSessionInfo().Extras.sales_id);
    this.Session_Salesman         = this._sessionService.getSessionInfo().Extras.name;
    this.Session_Cash             = Number(this._sessionService.getSessionInfo().Extras.cash_id);
    this.Session_EditPrice        = this._sessionService.getSessionInfo().Extras.permisisons.CanEditPrice == 1 ? true:false;
    this.Session_SelectPrice      = this._sessionService.getSessionInfo().Extras.permisisons.CanSelectPrice == 1 ? true:false;
    this.Session_EditDiscount     = this._sessionService.getSessionInfo().Extras.permisisons.CanEditDiscount == 1 ? true:false;
    this.Session_AllowNegStock    = this._sessionService.getSessionInfo().Extras.AllowNegativeStock == 1 ? true:false;
    this.Session_AlertStock       = this._sessionService.getSessionInfo().Extras.AlertInsufficientStock == 1 ? true:false;
    this.Session_Allow0Price      = this._sessionService.getSessionInfo().Extras.AllowZeroSalesPrice  == 1 ? true:false;

    this.Session_EnableReservedStock = APP_SETTINGS.setups.inventory.enableReservedStock;

    //this.Session_PrfUnique        = this._sessionService.getSessionInfo().Extras.permisisons.PRFUNIQUE == 1 ? true:false;
    //this.Session_PrfUnique=true;

    this.Session_PrfUnique        = this._sessionService.getSessionPermision('PRFUNIQUE');
    this.Session_ModiFecha        = this._sessionService.getSessionPermision('MODIFECHA');
    this.Session_UserPvp          = this._sessionService.getSessionPermision('PVP');
    this.Session_IvaChange        = this._sessionService.getSessionPermision('IVACHANGE');
    this.Session_AddColSearch     = APP_SETTINGS.features.visibleColsSearch;
    this.Session_OnlyIntCant      = APP_SETTINGS.features.onlyIntCant;

    this.Session_USUACCPRE        = this._sessionService.getSessionPermisionEnterprice('USUACCPRE');

    this.Session_CalcNextPre           = true;

    //this.Session_UserPvp          = false;
    //console.log(this.Session_UserPvp);
    //console.log(this.Session_PrfUnique);

    //this.Session_SelectPrice=false;
    if(this.Session_SelectPrice == false){
      this.Form.controls['TipoPrecio'].disable();
    }

    //this.Session_EditPrice=true;
    //this.Session_EditDiscount=true;
    //this.Session_IvaChange = false;

    //this.Session_AlertStock=true;
    //this.Session_Allow0Price=false;

    //console.log(this._sessionService.getSessionInfo().Extras.enterprise.Config);

    //linea buena
    //debugger
    var arr = this._sessionService.getSessionInfo().Extras.enterprise.Config.filter(function (config) {return config.Name == "SUBTOTRE" });
    if (arr && arr.length >0) { this.DecimalesSubtotalRendondeo = parseInt(arr[0].Value);}
    else this.DecimalesSubtotalRendondeo = 2;

    if (this.DecimalesSubtotalRendondeo==0){
      this.DecimalesSubtotalRendondeo=2;
    }

    if (this._sessionService.getSessionInfo().Extras.warehouses){
      let accBod : Array<any>=[];
      for (let acceso of this._sessionService.getSessionInfo().Extras.warehouses ){
        accBod.push(acceso.WarehouseNumber);
      }
      this.Session_Bodegas=accBod;
      for (let bod of this.Session_Bodegas){
        this.Session_BodDefault=bod;
        break;
      }
    }

  }
  /*****************************************************************/
  /**               Head-Intereaccion con el Usuario              **/
  /*****************************************************************/
  onKeydown(event,value){
    //console.log(event);
    if (event.key=='Enter'){
      this.loadCustomers();
    }
  }
  isNumber(value) {
    return !isNaN(value) && toString.call(value) === '[object Number]';
  }
  stringIsNumber(s) {
    var x = +s; // made cast obvious for demonstration
    return x.toString() === s;
  }
  newCustomer(){

    let parameters={
      title:'Importante!!',
      modal: true,
      data: this.CtrTo_Customer.value
    }
    let dialogConfig = new MatDialogConfig();
    dialogConfig.backdropClass='g-transparent-backdrop';
    dialogConfig.data= parameters;
    dialogConfig.panelClass= 'custom-dialog-containerBox';
    dialogConfig.disableClose=false;
    dialogConfig.width='900px';
    dialogConfig.height='600px';

    const dialog = this._notificationService._dialog.open(NewCustomerBasicComponent, dialogConfig);
    dialog.componentInstance.dialogInstance = dialog;
    dialog.componentInstance.dataModal = parameters;
    dialog.afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(result => {
      console.log(result);
      if(result)
      {

      this.CustomerID=result.CustomerId;
      this.invoice.IdCliente=result.CustomerId;
      this.invoice.CIRUC=result.TaxId;
      this.invoice.NombCli=result.Name;
      this.invoice.Address=result.Address;
      this.invoice.TelfCli=result.Phone;
      this.invoice.DireccionCli=result.Address;
      this.invoice.EmailCli=result.Email;
      this.invoice.Empresa=result.CompanyName;
      this.Customer = new Contact();
      this.Customer=result;

      let customer: selCustomer;
      customer = {
        Id : result.Id,
        RUC: result.TaxId,
        Name:result.Name,
        Address:result.Address,
        Phones:result.Phone,
        Email:result.Email
      }
      this.selCustomer=customer;
      this.Form.controls['Customer'].setValue(result.Name);
      }
    }
    );
  }
  loadCustomers(){
    //debugger
    //if (this.stringIsNumber(this.CtrTo_Customer.value)) { // (!isNaN(this.CtrTo_Customer.value)){
    //var esnumero = isNaN(this.CtrTo_Customer.value);
    if  (this.CtrTo_Customer.value != ""  &&  !isNaN(this.CtrTo_Customer.value)){
      this._customersService.getCustomersByTaxId2(this.CtrTo_Customer.value).subscribe((response:any) => {
        if (response) {
          this._customersService.getCustomersById(this.CtrTo_Customer.value).subscribe((response) => {
              if (response != null) {
                this.setCustomer(response);
              }
          });
        } else {
          this.dialogYN = this.onDialog('Importante!','Cliente no existe en la base de datos..\n Desea crearlo.?');
          this.dialogYN.afterClosed()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result:any) => {
            if (result){
              if (result.success){

                this.newCustomer();

                /*
                let result=this.dialog.open(NewCustomerComponent, dialogConfig);
                result.componentInstance.dataModal=parameters;
                this.dialog.afterClosed()
                  .pipe(takeUntil(this.ngUnsubscribe))
                  .subscribe(result => {
                    if (result){
                      if (result.success){

                      }
                    }
                });
                */


              } else {
                this.CtrTo_Customer.setValue('');
              }
            }
          });

        }
      });
    } else {

          let parameters = {
            params  : {
              api           : 'Customer/Pagging',
              sizePage      : 20,
              width         : '700px',
              height        : '500px',
            },
            columns :[
              { field: "Id",      headerText: "Id",       width: "40" },
              { field: "Name",    headerText: "Nombres",  width: "100" },
              { field: "Address", headerText: "Dirección",width: "100"}
            ]
          }
          let dialog=this.searchGrid.Show(parameters);
          dialog.afterClosed().subscribe(result => {
            if (result){
                this.setCustomer(result);
            }
          });
      }
          /*
          this.ObjObserver=this.search.Show(parameters).subscribe(result => {
            this._data.GetCustomer(result.state,parameters.params.api,result.filter).subscribe((response:any) => {
                if (response != null) {
                    let data = {
                      result: response['Result'],
                      count: parseInt(response['TotalRows'], 10)
                    }
                    this.search.modal.componentInstance.data=data;
                }
              });
            }
          );

          this.search.modal.afterClosed().subscribe(result => {
            if (result){
              this.setCustomer(result);
            }
            this.ObjObserver.unsubscribe();
          });
          */
  }
  setCustomer(result){
    console.log(result);
    if (result.Bloqueado){
        this._translateService.get('customerblock').subscribe((errorMessage: string) => {
          this._toastrService.showError(errorMessage, 'Error');
          this.CtrTo_Customer.setValue('');
          this.invoice.IdCliente='';
          this.CustomerID='';
        });
    }else{
        this.CustomerID=result.CustomerId;
        let i=0;
        let phone="";
        if (result.Phones){
          for ( let item of result.Phones){
            { phone=phone+item.Number+" "; }
          }
        }
        this.invoice.IdCliente=result.CustomerId;
        this.invoice.CIRUC=result.RUC;
        this.invoice.NombCli=result.Name;
        this.invoice.Address=result.Address;
        this.invoice.TelfCli=phone;
        this.invoice.DireccionCli=result.Address;
        this.invoice.EmailCli=result.Email;
        this.invoice.Empresa=result.CompanyName;
        this.invoice.TipoPrecio=result.TipoPrecio;
        if (this.invoice.TipoPrecio<=0){
          if (this.listPrice.length>0){
            this.invoice.TipoPrecio=this.listPrice[0].id;
          }
        } else {
          //if (this.Session_UserPvp) this.invoice.TipoPrecio=100; //100 Equivale a Precio PVP desde el API
          //console.log(this.invoice.TipoPrecio);
        }
        //this._dataService.customer = new Contact();
        //this._dataService.customer=result;
        let existPrice=false;
        for ( let item of this.listPrice){
          if (item.id==this.invoice.TipoPrecio){
           existPrice=true;
           break;
          }
        }
        if (!existPrice){
          if (this.listPrice.length>0){
            this.invoice.TipoPrecio=this.listPrice[0].id;
          }
        }

        this.Customer = new Contact();
        this.Customer=result;

        let customer: selCustomer;
        customer = {
          Id : result.Id,
          RUC: result.RUC,
          Name:result.Name,
          Address:result.Address,
          Phones:phone,
          Email:result.Email
        }
        this.selCustomer=customer;
        this.Form.controls['Customer'].setValue(result.Name);
        if (result.Id_Vend){
          this.invoice.Id_Vend=result.Id_Vend;
          this.Form.controls['Id_Vend'].setValue(result.Id_Vend);
          if (this.Session_Sales!=result.Id_Vend){
            this._translateService.get('novendor').subscribe((errorMessage: string) => {
              this._toastrService.showError(errorMessage, 'Error');

              const record = new Customer();
                record.CustomerID     = result.Id;
                record.Code           = result.Code;
                record.SalesPeopleID  = result.Id_Vend;
                /*
                record.TaxId          = result.;
                record.CompanyName    = this.CtrTo_CompanyName.value;
                record.Name           = this.CtrTo_Name.value;
                record.Address        = this.CtrTo_Address.value;
                record.Zone           = this.CtrTo_Zone.value;
                record.City           = this.CtrTo_City.value;
                record.State          = this.CtrTo_State.value;
                record.CountryCode    = this.CtrTo_CountryCode.value;
                record.SalesPeopleID  = this.CtrTo_SalesPeopleID.value;
                record.GroupID        = this.CtrTo_GroupID.value;
                record.DefaultPrice   = this.CtrTo_DefaultPrice.value;
                record.Email          = this.CtrTo_Email.value;
                record.Phone          = this.CtrTo_Phone.value;
                record.Phone2         = this.CtrTo_Phone2.value;
                record.WWW            = this.CtrTo_WWW.value;
                record.Contact1       = this.CtrTo_Contact1.value;
                record.PhoneContact1  = this.CtrTo_PhoneContact1.value;
                record.Depart1        = this.CtrTo_Depart1.value;
                record.Contact2       = this.CtrTo_Contact2.value;
                record.PhoneContact2  = this.CtrTo_PhoneContact2.value;
                record.Depart2        = this.CtrTo_Depart2.value;
                record.Details        = this.CtrTo_Details.value;
                record.Note           = this.CtrTo_Note.value;
                record.Latitude       = this.CtrTo_Latitude.value;
                record.Longitude      = this.CtrTo_Longitude.value;
                record.VisitDay       = this.GetDia();
                record.TaxIdTypeEC    = this.CtrTo_TaxIdTypeEC.value;
                */
              this._customersService.updateRecord(record)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((response: any) => {
                    if (response) {
                        console.log(response);
                    }
                  }, error => {
                    this.swalService.getInstance('An error has occurred', 'error').then(() => {});
              });

            });
          }
        }
    }

  }
  onChangeSerie(id){
    if (id>=0){
      if (this.listSeries.length>0){
        let index = this.listSeries.findIndex(item => item.id == id);
        this.getMaxId(this.listSeries[index].Serie,this.listSeries[index].Punto);
      }
    }
  }

  expression(){
    if(this.DocType=="FAC"){
      this.DocType ="NVE";
      this.NameDocNo ="SalesOrderNo";
    } else {
      this.DocType ="FAC";
      this.NameDocNo ="InvoiceNo";
    }
    this.getSeries(false);
  }

  getMaxId(serie:number,punto:number){
    let existe:boolean=false;
    for (let item of this.listSeries){
      if (item.Serie==serie && item.Punto==punto){
        let FSerie='';
        FSerie = FSerie + item.Serie.toString().padStart(3,'000')
        FSerie = FSerie + '-' + item.Punto.toString().padStart(3,'000')
        FSerie = FSerie + '-' + item.Numero.toString().padStart(9,'000000000')

        this.SerieFormat=FSerie;
        this.maxID=item.MaxID.toString();
        this.SerieDoc={
          DocType     : this.DocType,
          Serie       : item.Serie,
          Punto       : item.Punto,
          Numero      : item.Numero,
          MaxID       : item.MaxID,
          id          : 0,
          formatSerie : FSerie
        }
        existe=true;
        break;
      }
    }
    if (!existe){
      if (this.listSeries.length>0){
        let item=this.listSeries[0]

        this.idSerie=item.id
        let FSerie='';
        FSerie = FSerie + item.Serie.toString().padStart(3,'000')
        FSerie = FSerie + '-' + item.Punto.toString().padStart(3,'000')
        FSerie = FSerie + '-' + item.Numero.toString().padStart(9,'000000000')

        this.SerieFormat=FSerie;
        this.maxID=item.MaxID.toString();
        this.SerieDoc={
          DocType     : this.DocType,
          Serie       : item.Serie,
          Punto       : item.Punto,
          Numero      : item.Numero,
          MaxID       : item.MaxID,
          id          : 0,
          formatSerie : FSerie
        }
      }
    }
  }

  getSeries(setSerPunDefault:boolean){

    if(this.isQuotation) {
      return;
    }
    let Serie:any;
    let Punto:any;
    if (!setSerPunDefault){ //-------Serie y punto que esta seleccionada en combo-----
      if (this.listSeries){
        if (this.listSeries.length>0 && this.idSerie<this.listSeries.length){
          Serie=this.listSeries[this.idSerie].Serie;
          Punto=this.listSeries[this.idSerie].Punto;
        }
      }
    }

    this._invoiceService.getSeries(this.DocType).subscribe((response : Array<typ_serieDoc>) => {
      let existe:boolean=false;
      if (response) {
        //this.listSeries=response;
        this.listSeries=[];
        let c=0;
        for (let item of response){
          if (item.Serie>0 && item.Numero>0){
            let Serie='';
            Serie = Serie + item.Serie.toString().padStart(3,'000')
            Serie = Serie + '-' + item.Punto.toString().padStart(3,'000')

            let serTemp:typ_serieDoc = {
              DocType     : item.DocType,
              Serie       : item.Serie,
              Punto       : item.Punto,
              Numero      : item.Numero,
              MaxID       : item.MaxID,
              id          : c,
              formatSerie : Serie
            }
            this.listSeries.push(serTemp);
            c++;
          }
        }


        if (this.listSeries.length>0){
          for (let item of this.listSeries){
            if (item.Serie==this.Session_Serie && item.Punto==this.Session_Punto){
              existe=true;
              break;
            }
          }
        }
        if (setSerPunDefault){
          if (this.listSeries.length>0){
            this.getMaxId(this.listSeries[0].Serie,this.listSeries[0].Punto);
            for (let item of this.listSeries){
              if (item.Serie==this.Session_Serie && item.Punto==this.Session_Punto){
                this.idSerie=item.id;
                this.getMaxId(this.Session_Serie,this.Session_Punto);
                break;
              }
            }
          }
        } else {
          if (Serie && Punto){
            this.getMaxId(Serie,Punto);
          } else {
            if (this.listSeries.length>0){
              this.getMaxId(this.listSeries[0].Serie,this.listSeries[0].Punto);
              this.idSerie=this.listSeries[0].id
            }
          }
        }
        /*----------Si la serie no existe entonces se agrega la que el usuario tiene por defecto--*/
        if (!existe){
          let Serie='';
          if (Number(this._sessionService.getSessionInfo().Extras.serie)!=0){
              Serie = Serie + this.Session_Serie.toString().padStart(3,'000')
              Serie = Serie + '-' + this.Session_Punto.toString().padStart(3,'000')
              let idTemp=this.listSeries.length;
              let serTemp:typ_serieDoc = {
                DocType     : this.DocType,
                Serie       : this.Session_Serie,
                Punto       : this.Session_Punto,
                Numero      : response[0].Numero,
                MaxID       : response[0].MaxID,
                id          : idTemp,
                formatSerie : Serie
              }
              this.listSeries.push(serTemp);
              /*
              this.idSerie=idTemp;
              //this.getMaxId(this.listSeries[idTemp].Serie,this.listSeries[idTemp].Punto);
              this.SerieDoc={
                DocType     : this.DocType,
                Serie       : this.Session_Serie,
                Punto       : this.Session_Punto,
                Numero      : 0,
                MaxID       : 0,
                id          : 0,
                formatSerie : Serie

              }
              */
          }
        }
      }
    });

  }

  /*****************************************************************/
  /**                  Grid Configuracion General                 **/
  /*****************************************************************/
  initGrid(){
    this.toolbarOptions = ['Add', 'Delete', 'Edit', 'Update', 'Cancel'];
    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true , mode: 'Batch',showConfirmDialog: false, allowEditOnDblClick :false};// 'Normal',Batch' };
    this.selectionOptions = {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell' }; //Cell //Both
    this.commands = [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }];
    this.numericParams = { params: { decimals: 0, showSpinButton:false } };
    this.numericParamsBod = {
      params: { decimals: 0, showSpinButton:false,min:1,max:8,focus:this.focusFn.bind(this) },
      destroy:()=>{ }
    };
    this.floatParamsPos = {
      params: { decimals: 4, showSpinButton:false,min:0,focus:this.focusFn.bind(this)   },//htmlAttributes :{},change: this.changeFn.bind(this)
      destroy:()=>{
        //console.log(this.selColEdit)
      },
    };
    this.floatParams = {
      params: { decimals: 4, showSpinButton:false,focus:this.focusFn.bind(this)   },//htmlAttributes :{},change: this.changeFn.bind(this)
      destroy:()=>{
        //console.log(this.selColEdit)
      },
    };
    this.floatParamsCant=this.floatParams;
    if (this.Session_OnlyIntCant){
      this.floatParamsCant = {
        params: { decimals: 0, showSpinButton:false,focus:this.focusFn.bind(this) ,format: 'N',validateDecimalOnType: true,  },//htmlAttributes :{},change: this.changeFn.bind(this)
        destroy:()=>{
          //console.log(this.selColEdit)
        },
      };
    }
  }
  queryCellInfo(args: any): void {
    //console.log(args);
    if (args.column.field == 'Itemno') {
      //args.cell.style.color = '#b2cddf';
      if (args.data.isPromo) {
        let color="#b2cddf";
        //debugger
        /*
        let setColor=["#ecf3e1","#f3e7e1","#f3e1f3","#f1f3e1","#f3e1e1","#f3f3e1","#e1f2f3","#e2e1f3","#e1f3ee","#ebe1f3","#e1f3e8","#f1f3e1","#ebe1f3","#e1f3f2","#b2cddf"]
        let salir:boolean=false;
        while (salir==false) {
          let i=this.math.getRandomInt(14);
          color=setColor[i];
          if (color!=this.UltimoColor) {
            this.UltimoColor=color;
            salir=true;
          }
        }
        args.cell.style.backgroundColor = color;
        */

        let setColor=["#ecf3e1","#f3e7e1","#f3e1f3","#f1f3e1","#f3e1e1","#f3f3e1","#e1f2f3","#e2e1f3","#e1f3ee","#ebe1f3","#e1f3e8","#f1f3e1","#ebe1f3","#e1f3f2","#b2cddf","#ecf3e1","#f3e7e1","#f3e1f3","#f1f3e1","#f3e1e1","#f3f3e1","#e1f2f3","#e2e1f3","#e1f3ee","#ebe1f3","#e1f3e8","#f1f3e1","#ebe1f3","#e1f3f2","#b2cddf","#f3e1e1"]  
        
        /*
        if (args.data.ItemNoPromo == 1) color="#ecf3e1";
        if (args.data.ItemNoPromo == 2) color="#f3e7e1";
        if (args.data.ItemNoPromo == 3) color="#f3e1f3";
        if (args.data.ItemNoPromo == 4) color="#f1f3e1";
        if (args.data.ItemNoPromo == 5) color="#f3e1e1";
        if (args.data.ItemNoPromo == 6) color="#f3f3e1";
        if (args.data.ItemNoPromo == 7) color="#e1f2f3";
        if (args.data.ItemNoPromo == 8) color="#e2e1f3";
        if (args.data.ItemNoPromo == 9) color="#e1f3ee";
        if (args.data.ItemNoPromo == 10) color="#ebe1f3";
        if (args.data.ItemNoPromo == 11) color="#e1f3e8";
        if (args.data.ItemNoPromo == 12) color="#f1f3e1";
        if (args.data.ItemNoPromo == 13) color="#ebe1f3";
        if (args.data.ItemNoPromo == 14) color="#e1f3f2";
        if (args.data.ItemNoPromo == 15) color="#b2cddf";
        */
        if (args.data.ItemNoPromo >= 1 && args.data.ItemNoPromo <= 30)  {
          color=setColor[args.data.ItemNoPromo];
          //(args.cell as any).style.backgroundColor =color;
          args.cell.style.backgroundColor = color;
          }
        
      } 
    }
    if (!args.data.isPromo) {
      if (args.data.AplicaColor) {
        /*
        this.cellActive= {
          rowIndex  : args.data.itemNo-1,
          cellIndex : this.getIndexColum('Description')
        };
        this.grid.selectCell(this.cellActive,false);
        */
        args.cell.style.color = 'red';
      }
    }
  

  }
  /*****************************************************************/
  /**              Grid Configuracion de Crls Celdas              **/
  /*****************************************************************/
  initGridCtrl(){
    this.taxesParams = {
      create:(args)=>{ // { rowData: object, column: Column }
        this.selRowEdit=this.selRow;
        this.selItemNo=Number(args.data.Itemno);
        this.stateElem = document.createElement('input'); return this.stateElem;
      },
      read:()=>{return this.TaxesObj.text;},
      destroy:()=>{
        if (this.TaxesObj.value) {
          let StylexsaCode=Number(this.TaxesObj.value);
          let Obj_TaxesSel:Tax=this.TaxesGen.find(e => Number(e.StylexsaCode) === StylexsaCode)
          let Rate = Number(Obj_TaxesSel.Rate);
          this.grid.setCellValue(this.selItemNo, "IvaId", StylexsaCode);
          this.grid.setCellValue(this.selItemNo, "IvaPorc", Rate);
        } else {
          this.grid.setCellValue(this.selItemNo, "IvaId", null);
          this.grid.setCellValue(this.selItemNo, "IvaPorc", null);
        }
        this.TaxesObj.destroy();
      },
      write:()=>{
        //let code = this.itemInvoice[this.selRow].Code;
        this.Taxes =  <any[]>this.TaxesGen;
        this.TaxesObj = new DropDownList({
          dataSource:  this.Taxes,
          fields: { value: 'StylexsaCode', text: 'Rate'},
          //placeholder: 'Select Taxes',
          floatLabelType: 'Never',
          //value:0,//-1,  // Iva 0 por defecto
          change: (e) => {
            let StylexsaCode=Number(this.TaxesObj.value);
            let Obj_TaxesSel:Tax=this.TaxesGen.find(e => Number(e.StylexsaCode) === StylexsaCode)
            let Rate = Number(Obj_TaxesSel.Rate);
            this.grid.setCellValue(this.selItemNo, "IvaId", StylexsaCode);
            this.grid.setCellValue(this.selItemNo, "IvaPorc", Rate);
            this.itemInvoice[this.selRowEdit]._TaxesSel=Obj_TaxesSel;
            this.calculateRow(this.selRowEdit,false);
            this.calculateTotal();
            //this.grid.refresh();

            //console.log(this.itemInvoice[this.selRowEdit]._TaxesSel);
          },
       });
       this.TaxesObj.appendTo(this.stateElem);
     }
    }
    this.WarehouseParams = {
      create:(args)=>{ // { rowData: object, column: Column }
        this.selRowEdit=this.selRow;
        this.selItemNo=Number(args.data.Itemno);
        this.WarehouseOld=Number(args.data.WarehouseNo);
        this.stateElem = document.createElement('input'); return this.stateElem;
      },
      read:()=>{return this.WarehouseObj.text;},
      destroy:()=>{
        if (Number(this.WarehouseObj.value)>0) {
          let Id=Number(this.WarehouseObj.value);
          this.grid.setCellValue(this.selItemNo, "WarehouseNo", Id);
        } else {
          this.grid.setCellValue(this.selItemNo, "WarehouseNo", this.Session_BodDefault);
        }
        this.WarehouseObj.destroy();
      },
      write:()=>{
        this.Warehouse =  <any[]>this.WarehouseGen;
        this.WarehouseObj = new DropDownList({ // ComboBox({
          dataSource:  this.Warehouse,
          fields: { value: 'Id', text: 'Name'},
          floatLabelType: 'Never',
          value: this.WarehouseOld, //this.Session_BodDefault,
          headerTemplate:"<span style='height:20px; display: table;'><span style='left: 20px; display: table-cell;vertical-align: middle;text-indent: 16px;'>#</span><span style=' display: table-cell;vertical-align: middle;text-indent: 16px;' >Nombre</span></span>",
          itemTemplate  :"<span><span style='left: 20px;'>${Id}</span><span style='position: absolute;'>${Name}</span></span>",
          change: (e) => {
            let Id=Number(this.WarehouseObj.value);
            this.grid.setCellValue(this.selItemNo, "WarehouseNo", Id);
            let code = this.itemInvoice[this.selRowEdit].Id;
            if(code){
              let stock = this._productService.getProductStock(code).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
                (response:Array<listStock>) => {
                  if (response.length>=1) {
                      let s =  response.filter(x=> x.Id == Id);
                      if(s){
                        if(s.length>0)
                        {
                          this.itemInvoice[this.selRowEdit].Stock = s[0].Stock;
                          this.grid.setCellValue(this.selRowEdit+1, "Stock", s[0].Stock);
                        }
                        else
                        {
                          this.itemInvoice[this.selRowEdit].Stock = 0;
                          this.grid.setCellValue(this.selRowEdit+1, "Stock", 0);
                        }

                      } else {
                        this.itemInvoice[this.selRowEdit].Stock = 0;
                        this.grid.setCellValue(this.selRowEdit+1, "Stock", 0);
                      }
                  }else{
                    this.itemInvoice[this.selRowEdit].Stock = 0;
                    this.grid.setCellValue(this.selRowEdit+1, "Stock", 0);
                  }

                }
              );
            }
          }
       });
       this.WarehouseObj.appendTo(this.stateElem);
     }
    }
  }

  getUnitys($event){
    //debugger
    this.selRowEdit=this.selRow;
    this.selItemNo=Number($event.rowData.Itemno);
    this.NameUnity='';
    //if (this.invoice.Id_Prof>0){
      if (!this.itemInvoice[this.selRowEdit]._PackagingSel){
          let WarehouseNo=this.itemInvoice[this.selRowEdit].WarehouseNo;
          this._productService.getproductByID(this.itemInvoice[this.selRowEdit].Code,WarehouseNo).subscribe(
            (prod:Product)=>{
              /*------------llena una variable observable manualmente------*/
              //https://es.stackoverflow.com/questions/175877/convertir-un-observable-a-un-array-local
              this.Unity3=Observable.of(prod.Packaging);
              //console.log(this.Unity3);

              /*-----------------------------------------------------------*-*/
              this.itemInvoice[this.selRowEdit].Packaging=prod.Packaging;
              //this.itemInvoice[this.selRow].Price=prod.Price;
              this.itemInvoice[this.selRowEdit].ivainc=prod.ivainc;
              this.itemInvoice[this.selRowEdit].Price1=prod.Price1U;
              this.itemInvoice[this.selRowEdit].Price2=prod.Price2U;
              this.itemInvoice[this.selRowEdit].Price3=prod.Price3U;
              this.itemInvoice[this.selRowEdit].Price4=prod.Price4U;
              this.itemInvoice[this.selRowEdit].Price5=prod.Price5U;
              this.itemInvoice[this.selRowEdit].Price6=prod.Price6U;
              this.itemInvoice[this.selRowEdit].Price7=prod.Price7U;
              this.itemInvoice[this.selRowEdit].Price8=prod.Price8U;
              window.setTimeout(() => {
                this.UnityCmb.focusIn();
              }, 100);
            }
          );
      }else{
        this.Unity3=Observable.of(this.itemInvoice[this.selRowEdit].Packaging);
        window.setTimeout(() => {
          this.UnityCmb.focusIn();
        }, 100);
      }
    //} else{
    //  this.Unity3=Observable.of(this.itemInvoice[this.selRowEdit].Packaging);
    //  window.setTimeout(() => {
    //    this.UnityCmb.focusIn();
    //  }, 100);
    //}
  }
  onCreate($event){

  }
  selectUnyt(e){
    //https://stackblitz.com/edit/angular-uzl2br-nepkqa?file=app.component.html
    //debugger
    this.NameUnity='';
    //console.log(e);
    if (e.value) {
        //console.log(e);
        let id=Number(e.value);
        let item: Packaging = e.itemData;
        let Factor = 1;
        let Price = 0;
        let PriceType = 0;
        let Promotion = 0
        Factor = Number(item.Factor);
        Price = Number(item.Price);
        PriceType = Number(item.PriceType);
        //let Promotion = Number(this.Unity.find(e => e.Id === id).Promotion);
        Promotion = 0
        this.grid.setCellValue(this.selItemNo, "UnityId", id);
        this.itemInvoice[this.selRowEdit]._PackagingSel=item;
        this.NameUnity=item.PackagingName;
        this.calculatePrice(Factor,Price,PriceType,Promotion);
    } else {
      this.grid.setCellValue(this.selItemNo, "UnityId", null);
    }

  }

  /*****************************************************************/
  /**          Grid Celdas-Intereaccion con el Usuario            **/
  /*****************************************************************/

  rowSelected(args: RowSelectEventArgs) {
    //alert("row index: "+ " " + (args.row as HTMLTableRowElement).getAttribute('aria-rowindex'));
    //alert("column index: "+ " " + args.target.closest('td').getAttribute('aria-colindex'));
    this.selRow=args.rowIndex;
  }
  changeFn(e) {
    //var rowDetils = this.grid.getRowInfo(e.event.target);
    //this.grid.updateCell(rowDetils.rowIndex, "Tax", e.value / 10);
    //this.grid.updateCell(rowDetils.rowIndex, "Total", e.value / 10 + e.value);
  }
  focusFn(e) {
    window.setTimeout(() => {
      let obj=e.event.target;
      let value:string= String(e.value);
      obj.setSelectionRange(value.length,value.length);
    }, 5);
  }

  cellEdit($event){
    //console.log($event);
    if ($event.columnName== "Unity"){
      //Aqui
      this.getUnitys($event);
    }
    window.setTimeout(() => {
      //$event.columnObject.edit.obj.formEle.onblur();
    }, 1500);

    //https://www.syncfusion.com/forums/160078/modify-other-cell-in-batch-mode
    //https://stackblitz.com/edit/fbh3ow-64qng6?file=index.js
    //var rowDetils = this.grid.getRowInfo(e.event.target);
    //this.grid.updateCell(rowDetils.rowIndex, "Tax", e.value / 10);
    //this.grid.updateCell(rowDetils.rowIndex, "Total", e.value / 10 + e.value);
    //this.grid.updateCell
    //var rowDetils = this.grid.getRowInfo($event.target);
    //console.log(rowDetils.rowIndex);
    this.selColEdit=$event.columnName;
    this.selRowEdit=this.selRow;

    let isEditCell=this.isEditCell(this.selColEdit);
    if (isEditCell==false){
      if (this.grid.isEdit) {
        this.isCellSave=false;
        this.grid.closeEdit();
        return
      }
    }

  }
  isKey(e,type:string){
    if (type=="AZ_09"){
      if ( (e.keyCode>=65  && e.keyCode<=90) ||  //A-Z a-z
           (e.keyCode>=48  && e.keyCode<=57) ||  //0-9
           (e.keyCode==192 || e.keyCode==32 || e.keyCode==13 )) {  //ñ espace enter
            return true;
        }
    }
    if (type=="09"){
      if ( (e.keyCode>=48  && e.keyCode<=57) ||  //0-9
           (e.keyCode>=96  && e.keyCode<=105) || (e.keyCode==110) || //0-9 Bloq-Num
           (e.keyCode==32 || e.keyCode==13 || e.keyCode==190 || e.keyCode==188 )) {  // espace enter . ,
            return true;
        }
    }
    if (type=="ALL"){
      if ( (e.keyCode>=65  && e.keyCode<=90) ||  //A-Z a-z
           (e.keyCode>=48  && e.keyCode<=57) ||  //0-9
           (e.keyCode>=96  && e.keyCode<=105) || (e.keyCode==110) || //0-9 Bloq-Num
           (e.keyCode==192 || e.keyCode==32  || e.keyCode==190 || e.keyCode==188 )) {  //ñ espace enter
            return true;
        }
    }

    //return false;
  }
  getIndexColum(field:string):number{
    let index=0;
    if (this.grid) {
      let cols: Array<any>;
      cols= this.grid.columns;
      for (let item of cols){
        if (item.field==field){
          return index;//item.index;
        }
        index++;
      }
    }
    return 0;
  }
  ActivaCell(e){
    //console.log(e);
    if ((e.target as HTMLElement).classList.contains('e-rowcell')) {
      const rIndex = Number((e.target as HTMLElement).getAttribute("Index"));
      const cIndex = Number((e.target as HTMLElement).getAttribute("aria-colindex"));
      const i = { rowIndex: rIndex, cellIndex: cIndex };
      const field: string = this.grid.getColumns()[cIndex].field;
      this.selCol=cIndex;
      this.selRow=rIndex;
      if (!this.Session_EditPrice){
        if (field=='Price') { e.cancel = true; return; }
      }
      if (!this.Session_EditDiscount){
        if (field=='Discount') {e.cancel = true; return;}
      }
      if (!this.Session_IvaChange){
        if (field=='IVA') {e.cancel = true; return;}
      }

      if (field=='Description'){
        this.loadProducts(false,e.key);
      }
      if (field=='Unity'){
        //Aqui
        this.grid.editCell((rIndex), field);
      }
      else {
        let itemNo = this.itemInvoice[this.selRow].Itemno;
        let char:any;
        if (field=='Price' || field=='Quantity' || field=='Discount') { // || field=='WarehouseNo' ){
          char=Number(e.key);
        } else
        if (field=='Description'){
          char="";
        }else{
          char=e.key;
        }

        if (e.key!='Enter'){
          let colEdit=['Code','Description','Price','Quantity','Discount','Unity'] // Aqui'Unity','WarehouseNo']

          for (let col of colEdit){
            if (col==field){
              this.grid.setCellValue(itemNo, field, char);
              break;
            }
          }
        }
        this.grid.editCell((rIndex), field);
        //this.inputs.nativeElement.focus();
      }
    }

  }
  keyPressed(e) {
    console.log(e);
    var Col=0;
    if ((e.target as HTMLElement).classList.contains('e-rowcell')) {
      const rIndex = Number((e.target as HTMLElement).getAttribute("Index"));
      const cIndex = Number((e.target as HTMLElement).getAttribute("aria-colindex"));
      const i = { rowIndex: rIndex, cellIndex: cIndex };
      Col=cIndex;
      this.selColEdit = this.grid.getColumns()[cIndex].field;
    }
    
    this.isKeyPressed = (e.key == "Enter");

    if (e.key === 'Delete') {
      e.cancel=true;
        this.deleteRow();
        return;
    }
    //debugger;
    if (this.isEditCell(this.selColEdit)==false){
      if (this.itemInvoice[this.selRow].isPromo){
        if (e.key === 'Delete') {
          e.cancel=true;
        }
      }
      return;
    }

    if (this.selColEdit=='Code' || this.selColEdit=='Description' || this.selColEdit=='Price' || this.selColEdit=='Quantity' || this.selColEdit=='Discount' ) { //|| this.selColEdit=='WarehouseNo' ){
      if (this.isKey(e,'ALL')) {
        e.cancel = true;
        if (!this.grid.isEdit) {
          this.ActivaCell(e);
        }
      }
    }
    if (e.key === 'Enter') {
      e.cancel = true;
      if (this.grid.isEdit) {
        this.grid.saveCell();
        //this.grid.endEdit(); // Ojo hace saltar el ActionComplete... El saveCell ya lo hace
        let cell  = {
          rowIndex: 1,
          cellIndex: 1
        }
      }else {
        this.ActivaCell(e);
      }
    }
    if (e.key === 'Escape') {
      this.grid.closeEdit();
    }
  }
  doubleClick(event){
    //console.log(event);
    let cIndex=event.cellIndex;
    const field: string = this.grid.getColumns()[cIndex].field;
    //this.selRow=event.rowIndex;
    //this.selCol=event.cellIndex;
    //event.cancel=true;

    let isEditCell=this.isEditCell(field);

    window.setTimeout(() => {
        if (isEditCell==false){
          if (this.grid.isEdit) {
            this.isCellSave=false;
            this.grid.closeEdit();
            return;
          }
        }
    }, 5);
    if (isEditCell==true){
        if (field=='Description'){
          this.loadProducts(false,null);
        }
    }
    /*
    if (field=='Unity'){
      console.log(event);
      this.selRowEdit=this.selRow;
      this.GetUnity(event);
      //this.grid.closeEdit();
    }
    */
  }
  cellSelected(event){
    //console.log(event);
    this.cellActive=event.cellIndex;
    this.selRow=this.cellActive.rowIndex;
    this.selCol=this.cellActive.cellIndex;
  }
  isEditCell(field){
    let isEditCell=true;
    //debugger;
    if (this.itemInvoice[this.selRow].isPromo){
      isEditCell=false;
    } else {
      if (field=='Price'){
        isEditCell=false;
        if (this.Session_EditPrice){
          isEditCell=true;
        }
      }
      if (field=='Discount'){
        isEditCell=false;
        if (this.Session_EditDiscount){
          isEditCell=true;
        }
      }
      if (field=='IVA'){
        isEditCell=false;
        if (this.Session_IvaChange){
          isEditCell=true;
        }
      }
    }
    return isEditCell;
  }

  actionBegin(event){
    //console.log(event);
  }
  beginEdit(e){
    //console.log(e);
  }
  actionComplete(e: any): void{
    /*
    if (e.requestType === 'beginEdit') {
      e.form.elements.namedItem('Unity').focus();   // Set focus to the Target element
    }
    */
    if (e.requestType === 'beginEdit') {
      //(e.form.elements.namedItem('Unity')as HTMLInputElement).focus();
    }

    if (e.requestType === 'batchsave') {
      this.grid.closeEdit();
      this.calculateRow(this.selRowEdit,false);
      this.calculateTotal();

      this.cellActive= {
        rowIndex:this.selRow,
        cellIndex:this.selCol
      };
      this.grid.selectRow(this.selRow);
      this.grid.selectCell(this.cellActive,false);
    }
  }
  cellsave(args){
    console.log("cellsave")
    this.grid.editSettings.showConfirmDialog=false;
    this.grid.editModule.batchSave();
    this.isCellSave = true;
  }
  cellsaved(args){
    console.log("cellsaved")
    if( this.isCellSave){
        this.grid.editModule.batchSave();
        args.cancel = true;
        if (args.columnName=='Code') {
          let code = args.value;// this.itemInvoice[this.selRow].Code;
          if (code!=null && code!=''){
              let found = false;
              let WarehouseNo=this.Session_BodDefault; // this.itemInvoice[this.selRowEdit].WarehouseNo;
              this._productService.getproductByID(code, WarehouseNo,this.invoice.Id_Prof ).subscribe((response:Product) => {
                if (response) {
                  found=true;
                  this.setProd(this.selRowEdit,response,true,1,false,'');

                  //if (this.Session_AlertStock) this.AlertStock(1,args.rowData.Itemno,this.selRow);

                  let row=this.itemInvoice.length-1;
                  if (this.selRow==row){
                    this.addRow();
                  }
                  this.calculateRow(this.selRowEdit,false);
                  this.calculateTotal();
                  this.grid.refresh();
                  this.cellActive= {
                    rowIndex  : this.selRow,
                    cellIndex : this.getIndexColum('Code')
                  };
                  window.setTimeout(() => {
                    this.grid.selectCell(this.cellActive,false);
                  }, 100);
                }else{
                  this._productService.getpromoByCode(code,this.invoice.TipoPrecio).subscribe((response3:PromoItem[]) => {
                    if (response3.length>=1){
                      this.setPromotions(this.selRowEdit,response3,code);
                    } else {
                      this.setProd(this.selRowEdit,null,true,1,true,code);

                      //if (this.Session_AlertStock) this.AlertStock(1,args.rowData.Itemno,this.selRow);

                      this.calculateRow(this.selRowEdit,false);
                      this.calculateTotal();
                      this.grid.refresh();
                      this.cellActive= {
                        rowIndex  : this.selRow,
                        cellIndex : this.getIndexColum('Code')
                      };
                      window.setTimeout(() => {
                        this.grid.selectCell(this.cellActive,false);
                      }, 100);

                    }
                  });
                }
              });
            }
        }
        if (args.columnName=='Quantity') {
          //debugger;
          let Quantity = args.value;
          if (Quantity==null || Quantity==''){
            Quantity=1;
            this.grid.setCellValue(args.rowData.Itemno, "Quantity", Quantity);
          } else {
              if (this.invoice.TipoPrecio==1){
                let _prod=this.itemInvoice[this.selRow]._Prod;
                
                if (_prod) { //if (_prod.hasOwnProperty('To_0')) {
                  let NPrecio=this.invoice.TipoPrecio;
                  let Precio = _prod.Price;
                  if (NPrecio==1) {Precio = _prod.Price1U;}
                  if (NPrecio==2) {Precio = _prod.Price2U;}
                  if (NPrecio==3) {Precio = _prod.Price3U;}
                  if (NPrecio==4) {Precio = _prod.Price4U;}
                  if (NPrecio==5) {Precio = _prod.Price5U;}
                  if (NPrecio==6) {Precio = _prod.Price6U;}
                  if (NPrecio==7) {Precio = _prod.Price7U;}
                  if (NPrecio==8) {Precio = _prod.Price8U;}
  
                  if (_prod.Tipo==1) {
                      if (_prod.To_0 > 0) {
                        let Price:number = Precio; // _prod.Price1U; //this.itemInvoice[this.selRow].Price;
                        if (_prod.To_2 > 0 && Quantity > _prod.To_2) {
                          Price = _prod.Price4U;
                        } else
                        if (_prod.To_1 > 0 && Quantity > _prod.To_1) {
                          Price = _prod.Price3U;
                        } else
                        if (_prod.To_0 > 0 && Quantity > _prod.To_0) {
                          Price = _prod.Price2U;
                        }
                        this.itemInvoice[this.selRow].Price=Price;
                      }
                  }
                }
              }

              this.calculateRow(this.selRow,false);
              this.calculateTotal();
              this.grid.refresh();
              this.cellActive= {
                rowIndex  : this.selRow,
                cellIndex : this.getIndexColum('Quantity')
              };
              window.setTimeout(() => {
                this.grid.selectCell(this.cellActive,false);
              }, 100);

          }
          if (this.Session_AlertStock) this.AlertStock(this.selRow,true);
        }
        if (args.columnName=='Discount') {
          let Discount = args.value;
          if (Discount==null || Discount==''){
            this.grid.setCellValue(args.rowData.Itemno, "Discount", 0);
          }

          let DiscountCal=this.getDiscount(this.selRowEdit,Discount);
          this.grid.setCellValue(args.rowData.Itemno, "Discount", DiscountCal);
        }
        /*
        if (args.columnName=='WarehouseNo') {
          let bodega = args.value;
          if (bodega==null || bodega==''){
            this.grid.setCellValue(args.rowData.Itemno, "WarehouseNo", 1);
          }
        }
        */
        if (args.columnName=='WarehouseNo') {
          //this.Session_Bodegas=[1,2];
          /*
          let WarehouseNo = Number(args.value);
          let acceso:boolean=false;
          for (let bod of this.Session_Bodegas){
              if (Number(bod)==WarehouseNo){
                acceso=true;
                break;
              }
          }
          if (acceso==false){
            this.grid.setCellValue(args.rowData.Itemno, "WarehouseNo", this.Session_BodDefault);
            this._translateService.get('No se tiene acceso a esta bodega!!').subscribe((errorMessage: string) => {
              this._toastrService.showError(errorMessage, 'Error');
            });

          }
          */
        }
        if (args.columnName=='Price') {
          let Price = args.value;
          let IvaPorc = args.rowData.IvaPorc;
          let ivainc = args.rowData.ivainc;
          if (ivainc && IvaPorc>0){
            Price = Price / (1 + (IvaPorc / 100))
            this.grid.setCellValue(args.rowData.Itemno, "Price", Price);
          }
          if (this.Session_Allow0Price==false){
            if (Price<=0){
              this._translateService.get('AllowZeroSalesPrice').subscribe((errorMessage: string) => {
                this._toastrService.showError(errorMessage, 'Error');
              });
            }
          }
        }
        //Aqui

        if (args.columnName=='Unity'){
          if (this.NameUnity){
            this.grid.setCellValue(this.selItemNo, "Unity", this.NameUnity);
          }else{
            this.grid.setCellValue(this.selItemNo, "Unity", '');
            this.grid.setCellValue(this.selItemNo, "UnityId", null);
            if (this.itemInvoice[this.selRowEdit].Unity==''){
              let Factor = 1;
              let Price = 0;
              let PriceType = 0;
              let Promotion = 0
              this.calculatePrice(Factor,Price,PriceType,Promotion);
            }
          }
        }




    }
    else{
      this.isKeyPressed = false;
    }
  }
  batchCancel(args: any) :void {
    this.grid.clearSelection();
  }

  /*****************************************************************/
  /**      Grid-Modal Search-Intereaccion con el Usuario          **/
  /*****************************************************************/

  insertProd(){
    this.loadProducts(true,null);
  }
  loadProducts(newRow:boolean,keyCode){

    if (keyCode=='Enter' || keyCode=='Space'){
      keyCode=null;
    }
    let width='800px';
    let height='500px';
    let cols=[
      { field: "Id",    headerText: "Id",          width: "80" },
      { field: "Name",  headerText: "Descripción", width: "250",href:false},
      { field: "Price", headerText: "Precio",      width: "60", format : '1.2-2', textAlign: 'Right'}, //format : 'N2'
      { field: "Stock", headerText: "Stock",       width: "60", format : '1.0-0', textAlign: 'Right'}, //format : 'N'
      { field: "",      headerText: "",            width: "30", inf:true}
    ]

    if (this.Session_AddColSearch==true){
      width='1280px';
      height='500px';
      cols=[
        { field: "Id",        headerText: "Id",          width: "80" },
        { field: "CodBar",    headerText: "Cod.Bar",     width: "80" },
        { field: "Name",      headerText: "Descripción", width: "250",href:false},
        { field: "Ubicacion", headerText: "Ubicación",   width: "100" },
        { field: "Marca",     headerText: "Marca",       width: "100" },
        { field: "Stock",     headerText: "Stock",       width: "60", format : '1.0-0', textAlign: 'Right'}, //format : 'N'
        //{ field: "Price",     headerText: "Precio",      width: "60", format : '1.2-2', textAlign: 'Right'}, //format : 'N2'
        { field: "Price1U",   headerText: "Prec.1",      width: "80", format : '1.2-2', textAlign: 'Right'},
        { field: "Price2U",   headerText: "Prec.2",      width: "80", format : '1.2-2', textAlign: 'Right'},
        { field: "",          headerText: "",            width: "30", inf:true}
      ]
    }

    let parameters = {
      params  : {
        api           : 'Products/Pagging',
        sizePage      : 20,
        width         : width, // '800px',
        height        : height, //'500px',
        keyCode: keyCode ? keyCode : null,
        apiInfo       : {
            api: 'Products/ProductInfo',
            parameter:'code',
            field  : 'Code'
        },
        aditionalFilters  : {
          PriceType    : this.invoice.TipoPrecio,
          //Filter: `PriceType=${this.selSupplier.Id};`
        }
      },
      columns : cols,
      /*[
        { field: "Id",    headerText: "Id",          width: "80" },
        { field: "Name",  headerText: "Descripción", width: "250",href:false},
        { field: "Price", headerText: "Precio",      width: "60", format : '1.2-2', textAlign: 'Right'}, //format : 'N2'
        { field: "Stock", headerText: "Stock",       width: "60", format : '1.0-0', textAlign: 'Right'}, //format : 'N'
        { field: "",      headerText: "",            width: "30", inf:true}
      ],*/
    }
    let dialog=this.searchGrid.Show(parameters);

    dialog.afterClosed().subscribe((response:Product) => {
      if (response) {
        //console.log(response);
        if (newRow==false){
            let WarehouseNo=this.itemInvoice[this.selRow].WarehouseNo;
            this._productService.getproductByID(response.Code,WarehouseNo).subscribe((response2:Product) => {
                if (response2){
                  this.setProd(this.selRow,response2,true,1,false,'');

                  //if (this.Session_AlertStock) this.AlertStock(1,this.selItemNo,this.selRow);

                  let row=this.itemInvoice.length-1;
                  if (this.selRow==row){
                    this.addRow();
                  }
                  this.calculateRow(this.selRow,false);
                  this.calculateTotal();
                  this.grid.refresh();
                  this.cellActive= {
                    rowIndex  : this.selRow,
                    cellIndex : this.getIndexColum('Description')
                  };
                  window.setTimeout(() => {
                    this.grid.selectCell(this.cellActive,false);
                  }, 100);
                } else {
                  this._productService.getpromoByCode(response.Code,this.invoice.TipoPrecio).subscribe((response3:PromoItem[]) => {
                    if (response3){
                      this.setPromotions(this.selRow,response3,response.Code);
                    }
                  });
                }
              }
            );
        } else {
          let row=this.itemInvoice.length-1;
          let WarehouseNo=this.Session_BodDefault;//this.itemInvoice[row].WarehouseNo;
          this._productService.getproductByID(response.Code,WarehouseNo).subscribe((response2:Product) => {
            if (response2) {
              this.setProd(row,response2,true,1,false,'');

              //if (this.Session_AlertStock) this.AlertStock(1,this.selItemNo,this.selRow);

              this.addRow();
              this.calculateRow(row,false);
              this.calculateTotal();
              this.grid.refresh();
              this.cellActive= {
                rowIndex  : row,
                cellIndex : this.getIndexColum('Description')
              };
              //this.grid.selectRow(this.selRow);
              window.setTimeout(() => {
                this.grid.selectCell(this.cellActive,false);
              }, 1500);
            } else {
              this._productService.getpromoByCode(response.Code,this.invoice.TipoPrecio).subscribe((response3:PromoItem[]) => {
                if (response3){
                  this.setPromotions(row,response3,response.Code);
                }
              });
            }
          });
        }

      }
      //this.ObjObserver.unsubscribe();
    });

  }
  setProdID(row:number,prod:Product){
    this._productService.getproductByID(prod.Code).subscribe((response:Product) => {
      this.setProd(row,response,true,1,false,'');
    });
  }
  setProd(row:number,prod:Product,isValidateStockbyRow:boolean, Quantity:number = 1 ,isPromo:boolean,id_promo:string){
    
    console.log(this.itemInvoice)
    console.log("row number " + row)
    if (prod){
        this.itemInvoice[row].Code=prod.Code;
        this.itemInvoice[row].Id=prod.Code;
        this.itemInvoice[row].CodBar = prod.CodBar;
        this.itemInvoice[row].Description=prod.Name;
        this.itemInvoice[row].Quantity=Quantity;
        this.itemInvoice[row].Unity="";
        this.itemInvoice[row].UnityId=null;
        this.itemInvoice[row]._PackagingSel=null;
        this.itemInvoice[row].IVA="";
        this.itemInvoice[row].IvaId=null;
        this.itemInvoice[row]._TaxesSel=null;
        this.itemInvoice[row].IvaPorc=null;
        this.itemInvoice[row].TotIva=null;
        this.itemInvoice[row].WarehouseNo=this.Session_BodDefault; //1;
        this.itemInvoice[row].WarehouseName=this.Session_BodNameDef; //1;
        this.itemInvoice[row]._Prod=prod;
        this.itemInvoice[row].ItemNoPromo=this.ItemNoPromo;
        

        /*-------------Control de stock-------------*/
        this.itemInvoice[row].Stock = 0; //1;
        if(!this.Session_EnableReservedStock)
        {
          if (prod.WarehouseStock){
            if (prod.WarehouseStock.length>=1){
              for (let item of prod.WarehouseStock ){
                if (item.Id==this.Session_BodDefault){
                  this.itemInvoice[row].Stock=item.Stock;
                  break;
                }
              }
            }
          }
        }
        else
        {
          this.itemInvoice[row].Stock= prod.Stock;
        }

        this.itemInvoice[row].stockReal=this.Stock_Logico(row);
        if (this.invoice.Id_Prof>0){
          //this.itemInvoice[row].stockReal=this.itemInvoice[row].Quantity+this.itemInvoice[row].Stock;
        }

        /*-------------ConverP Jalar de API---------*/
        this.itemInvoice[row].Packaging=prod.Packaging;
        this.itemInvoice[row].Taxes=prod.Taxes;

        if (prod.Taxes[0]){ /*-----------Iva por defecto el Indice 0 -----*/
          let StylexsaCode=Number(prod.Taxes[0].StylexsaCode);
          let Rate = Number(prod.Taxes[0].Rate);

          this.itemInvoice[row].IvaId=StylexsaCode;
          this.itemInvoice[row].IvaPorc=Rate;
          this.itemInvoice[row].IVA=Rate.toString();
          this.itemInvoice[row]._TaxesSel=prod.Taxes[0];
        }

        this.itemInvoice[row].ivainc=prod.ivainc;
        let Price1 = prod.Price1U;
        let Price2 = prod.Price2U;
        let Price3 = prod.Price3U;
        let Price4 = prod.Price4U;
        let Price5 = prod.Price5U;
        let Price6 = prod.Price6U;
        let Price7 = prod.Price7U;
        let Price8 = prod.Price8U;

        this.itemInvoice[row].Price1=Price1;
        this.itemInvoice[row].Price2=Price2;
        this.itemInvoice[row].Price3=Price3;
        this.itemInvoice[row].Price4=Price4;
        this.itemInvoice[row].Price5=Price5;
        this.itemInvoice[row].Price6=Price6;
        this.itemInvoice[row].Price7=Price7;
        this.itemInvoice[row].Price8=Price8;

        let IvaPorc = this.itemInvoice[row].IvaPorc;
        let ivainc = this.itemInvoice[row].ivainc;
        if (ivainc && IvaPorc>0){
          //prod.Price=prod.Price / (1 + (IvaPorc / 100))
          Price1 = prod.Price1U / (1 + (IvaPorc / 100))
          Price2 = prod.Price2U / (1 + (IvaPorc / 100))
          Price3 = prod.Price3U / (1 + (IvaPorc / 100))
          Price4 = prod.Price4U / (1 + (IvaPorc / 100))
          Price5 = prod.Price5U / (1 + (IvaPorc / 100))
          Price6 = prod.Price6U / (1 + (IvaPorc / 100))
          Price7 = prod.Price7U / (1 + (IvaPorc / 100))
          Price8 = prod.Price8U / (1 + (IvaPorc / 100))
        }
        //debugger;
        let NPrecio=this.invoice.TipoPrecio;
        let Precio = prod.Price;

        if (this.Session_UserPvp) {this.itemInvoice[row].Price=prod.Price;}

        if (NPrecio==1) {Precio = Price1;this.itemInvoice[row].Discount=prod.PDesMin1; }
        if (NPrecio==2) {Precio = Price2;this.itemInvoice[row].Discount=prod.PDesMin2; }
        if (NPrecio==3) {Precio = Price3;this.itemInvoice[row].Discount=prod.PDesMin3; }
        if (NPrecio==4) {Precio = Price4;this.itemInvoice[row].Discount=prod.PDesMin4; }
        if (NPrecio==5) {Precio = Price5;this.itemInvoice[row].Discount=prod.PDesMin5; }
        if (NPrecio==6) {Precio = Price6;this.itemInvoice[row].Discount=prod.PDesMin6; }
        if (NPrecio==7) {Precio = Price7;this.itemInvoice[row].Discount=prod.PDesMin7; }
        if (NPrecio==8) {Precio = Price8;this.itemInvoice[row].Discount=prod.PDesMin8; }
        this.itemInvoice[row].Price=Precio;

        

        if (isPromo) {
          this.itemInvoice[row].isPromo=true;
          this.itemInvoice[row].Id_promo=id_promo;
        }else{
          this.itemInvoice[row].isPromo=false;
          this.itemInvoice[row].Id_promo='';
        }

        if (this.Session_AlertStock){
          if (isValidateStockbyRow){
            /*
            let Stock=this.itemInvoice[row].Stock;
            if (Quantity>Stock){
              this._translateService.get('AlertInsufficientStock').subscribe((errorMessage: string) => {
                this._toastrService.showError(errorMessage, 'Error');
              });
              Quantity=0;
              this.itemInvoice[row].Quantity=Quantity;
            }
            */
            this.AlertStock(row,true)
          }
        }
        if (this.Session_Allow0Price==false){
          if (Precio<=0){
            this._translateService.get('AllowZeroSalesPrice').subscribe((errorMessage: string) => {
              this._toastrService.showError(errorMessage, 'Error');
            });
          }
        }
    } else {
      this.itemInvoice[row].Code='*invalid code*';
      this.itemInvoice[row].Id='';
      this.itemInvoice[row].CodBar='';
      this.itemInvoice[row].Description='';
      this.itemInvoice[row].Price=0;
      this.itemInvoice[row].Quantity=0;
      this.itemInvoice[row].ivainc=false;
      this.itemInvoice[row].Price1=0;
      this.itemInvoice[row].Price2=0;
      this.itemInvoice[row].Price3=0;
      this.itemInvoice[row].Price4=0;
      this.itemInvoice[row].Price5=0;
      this.itemInvoice[row].Price6=0;
      this.itemInvoice[row].Price7=0;
      this.itemInvoice[row].Price8=0;
      this.itemInvoice[row]._Prod=null;

      this.itemInvoice[row].Unity="";
      this.itemInvoice[row].UnityId=null;
      this.itemInvoice[row]._PackagingSel=null;

      this.itemInvoice[row].Packaging=null;
      this.itemInvoice[row].Taxes=null;
      this.itemInvoice[row].WarehouseNo=this.Session_BodDefault; //1;
      this.itemInvoice[row].WarehouseName=this.Session_BodNameDef; //1;
      this.itemInvoice[row].IVA="";
      this.itemInvoice[row].IvaId=null;
      this.itemInvoice[row]._TaxesSel=null;
      this.itemInvoice[row].IvaPorc=null;
      this.itemInvoice[row].TotIva=null;
      this.itemInvoice[row].isPromo=false;
      this.itemInvoice[row].Id_promo='';
      this._translateService.get('Product.does.not.exist').subscribe((errorMessage: string) => {
        this._toastrService.showError(errorMessage, 'Error');
      });

    }
  }
  getDiscount(row:number,Desc_User:number){
    let _prod=this.itemInvoice[row]._Prod;
    let DescMax: number=0;
    let DescMin: number=0;
    let NPrecio=this.invoice.TipoPrecio;


    //-----------------Descuentos por Unidad-----------------------------
    if (this.invoice['Unity'] != "") {
        DescMin = 0
        DescMax = 0
        if (!this.itemInvoice[row]._PackagingSel){
            let _PackagingSel=this.itemInvoice[row]._PackagingSel
            //DescMin = _PackagingSel["DesMin"];
            //DescMax = _PackagingSel["DesMax"];
        }
    }

    //-----------------Si No hay Descuentos Unidad Busca por Precio-----------------------------
    if (DescMax == 0 && DescMin == 0) {
        if (NPrecio == 1) {
            DescMin = _prod.PDesMin1;
            //DescMax = _prod.PDesMax1;
        }
        if (NPrecio == 2) {
          DescMin = _prod.PDesMin2;
          //DescMax = _prod.PDesMax2;
        }
        if (NPrecio == 3) {
          DescMin = _prod.PDesMin3;
          //DescMax = _prod.PDesMax3;
        }
        if (NPrecio == 4) {
          DescMin = _prod.PDesMin4;
          //DescMax = _prod.PDesMax4;
        }
        if (NPrecio == 5) {
          DescMin = _prod.PDesMin5;
          //DescMax = _prod.PDesMax5;
        }
        if (NPrecio == 6) {
          DescMin = _prod.PDesMin6;
          //DescMax = _prod.PDesMax6;
        }
        if (NPrecio == 7) {
          DescMin = _prod.PDesMin7;
          //DescMax = _prod.PDesMax7;
        }
        if (NPrecio == 8) {
          DescMin = _prod.PDesMin8;
          //DescMax = _prod.PDesMax8;
        }
        //'-----------------Si No hay Descuentos por precio busca los descuentos generales---------------------------
        if (DescMin <= 0) {
            DescMin = Desc_User; // 'por defecto
        } else {
            if (Desc_User == 0) {
                Desc_User = DescMin
            }
        }
        if (DescMax <= 0){
            //DescMax = OAr.Valor(OAr.OrRead(TProd, "DescMin")) 'DescMin
        }
    }
    let Desc_Cal=0;
    if (DescMin!=0 && DescMax!=0) { // || (Desc_User <= DescMax ))) {
      if (Desc_User >= 0 && ((Desc_User >= DescMin && Desc_User <= DescMax))) { // || (Desc_User <= DescMax ))) {
        Desc_Cal= Desc_User;  //'--------------Si el descuento esta en el rango correcto deja el que escribe el Usuario
      }
      else {
        Desc_Cal= 0;
      }
    } else {
      Desc_Cal = Desc_User;
    }
    if (!(Desc_Cal>=0 && Desc_Cal<=100)){
      Desc_Cal = 0;
    }
    return Desc_Cal;

  }
  setPromotions(row:number,response:PromoItem[],id_promo:string){
    let parameters={
      title   : 'Importante!!',
      message : '¿Cuantas promociones?',
      default : 1,
      type    : "Number"
    }
    let dialogConfig = new MatDialogConfig();
    dialogConfig.backdropClass='g-transparent-backdrop';
    dialogConfig.data= parameters;
    dialogConfig.panelClass= 'custom-dialog-containerBox';
    dialogConfig.disableClose=false;
    dialogConfig.width='380px';
    dialogConfig.height='190px';
    let Pasar:boolean=true;

    for (let itemPr of response){
      if (itemPr.STOCK<=0) {
          Pasar=false;
      }
    }
    if (Pasar) {
        let result=this.dialog.open(InputboxComponent, dialogConfig);
        result.afterClosed().subscribe(result => {
          if (result){
            if (result.success){
                let CantU : number =result.data;
                this.ItemNoPromo++;
                let rowNew=row;
                for (let itempr of response){
                  let prod:object ={
                    Id:itempr.ID_PROD,
                    Name:itempr.DESCRIP,
                    Code:itempr.ID_PROD,
                    //Packaging:itempr.Packaging,
                    Stock: itempr.STOCK,
                    Taxes:itempr.TAXES,
                    ivainc:itempr.IVAINC,
                    Price:itempr.PRECIO,
                    Price1U:itempr.PRECIO,
                    Price2U:itempr.PRECIO,
                    Price3U:itempr.PRECIO,
                    Price4U:itempr.PRECIO,
                    Price5U:itempr.PRECIO,
                    Price6U:itempr.PRECIO,
                    Price7U:itempr.PRECIO,
                    Price8U:itempr.PRECIO,
                    Descuento:itempr.PROMOCION,
                    PDesMin1:itempr.PROMOCION,
                    PDesMin2:itempr.PROMOCION,
                    PDesMin3:itempr.PROMOCION,
                    PDesMin4:itempr.PROMOCION,
                    PDesMin5:itempr.PROMOCION,
                    PDesMin6:itempr.PROMOCION,
                    PDesMin7:itempr.PROMOCION,
                    PDesMin8:itempr.PROMOCION,
                    WarehouseStock:itempr.WarehouseStock
                  }

                  this.setProd(rowNew,prod as Product,false,itempr.CANTIDAD*CantU,true,id_promo);
                  this.addRow();
                  this.calculateRow(rowNew,true);
                  rowNew++;
                }
                this.calculateTotal();
                this.grid.refresh();

                this.cellActive= {
                  rowIndex  : row,
                  cellIndex : this.getIndexColum('Description')
                };
                window.setTimeout(() => {
                  this.grid.selectCell(this.cellActive,false);
                }, 100);

            }
          }
        });
    } else {
      console.log(response);
      this._translateService.get('En esta promoción hay productos sin Stock').subscribe((errorMessage: string) => {
        this._toastrService.showError(errorMessage, 'Error');
      });

    }
  }

  onSelectTypePrice(event){
    //debugger;
    //let row=0;
    //let TypePrice: number = event;
    /*
    for (let item of this.itemInvoice){
      let _prod=item._Prod;
      row++;
      if (_prod) {
        if (TypePrice==0) { item.Price = _prod.Price1U; }
        if (TypePrice==1) { item.Price = _prod.Price1U; }
        if (TypePrice==2) { item.Price = _prod.Price2U; }
        if (TypePrice==3) { item.Price = _prod.Price3U; }
        if (TypePrice==4) { item.Price = _prod.Price4U; }
        if (TypePrice==5) { item.Price = _prod.Price5U; }
        if (TypePrice==6) { item.Price = _prod.Price6U; }
        if (TypePrice==7) { item.Price = _prod.Price7U; }
        if (TypePrice==8) { item.Price = _prod.Price8U; }

        this.calculateRow(row,false);
      }

    }
    this.calculateTotal();
    this.grid.refresh();
    */
    //debugger;
    //let AccPre: Boolean=this._sessionService.getSessionPermision('USUACCPRE');
    //this._sessionService.getSessionInfo().Extras.enterprise.config == 1 ? true:false;
    //AccPre=true;
    //console.log(this.Session_USUACCPRE);
    if (this.Session_CalcNextPre) {
      return;
    }
  }

  onCalcPrice(event){
    if(this.itemInvoice.length>1 && this.Session_USUACCPRE)
    {
      for (let row = 0; row<=this.itemInvoice.length-1; row++)
      {
        let pasar: boolean=true;
        if (this.itemInvoice[row].isPromo) {
          pasar=false;
        }
        if (pasar){
          let prod=this.itemInvoice[row]._Prod;
          if (prod) {
            let Price1 = prod.Price1U;
            let Price2 = prod.Price2U;
            let Price3 = prod.Price3U;
            let Price4 = prod.Price4U;
            let Price5 = prod.Price5U;
            let Price6 = prod.Price6U;
            let Price7 = prod.Price7U;
            let Price8 = prod.Price8U;

            this.itemInvoice[row].Price1=Price1;
            this.itemInvoice[row].Price2=Price2;
            this.itemInvoice[row].Price3=Price3;
            this.itemInvoice[row].Price4=Price4;
            this.itemInvoice[row].Price5=Price5;
            this.itemInvoice[row].Price6=Price6;
            this.itemInvoice[row].Price7=Price7;
            this.itemInvoice[row].Price8=Price8;

            let IvaPorc = this.itemInvoice[row].IvaPorc;
            let ivainc = this.itemInvoice[row].ivainc;

            if (ivainc && IvaPorc>0){
              //prod.Price=prod.Price / (1 + (IvaPorc / 100))
              Price1 = this.itemInvoice[row].Price1 / (1 + (IvaPorc / 100))
              Price2 = this.itemInvoice[row].Price2 / (1 + (IvaPorc / 100))
              Price3 = this.itemInvoice[row].Price3 / (1 + (IvaPorc / 100))
              Price4 = this.itemInvoice[row].Price4 / (1 + (IvaPorc / 100))
              Price5 = this.itemInvoice[row].Price5 / (1 + (IvaPorc / 100))
              Price6 = this.itemInvoice[row].Price6 / (1 + (IvaPorc / 100))
              Price7 = this.itemInvoice[row].Price7 / (1 + (IvaPorc / 100))
              Price8 = this.itemInvoice[row].Price8 / (1 + (IvaPorc / 100))
            }
            let NPrecio=this.invoice.TipoPrecio;
            let Precio = prod.Price;
            if (this.Session_UserPvp) {this.itemInvoice[row].Price=prod.Price;}
            if (NPrecio==1) {Precio = Price1;this.itemInvoice[row].Discount=prod.PDesMin1; }
            if (NPrecio==2) {Precio = Price2;this.itemInvoice[row].Discount=prod.PDesMin2; }
            if (NPrecio==3) {Precio = Price3;this.itemInvoice[row].Discount=prod.PDesMin3; }
            if (NPrecio==4) {Precio = Price4;this.itemInvoice[row].Discount=prod.PDesMin4; }
            if (NPrecio==5) {Precio = Price5;this.itemInvoice[row].Discount=prod.PDesMin5; }
            if (NPrecio==6) {Precio = Price6;this.itemInvoice[row].Discount=prod.PDesMin6; }
            if (NPrecio==7) {Precio = Price7;this.itemInvoice[row].Discount=prod.PDesMin7; }
            if (NPrecio==8) {Precio = Price8;this.itemInvoice[row].Discount=prod.PDesMin8; }
            this.itemInvoice[row].Price=Precio;
            
            this.calculateRow(row,false);
          }
          this.calculateTotal();
          this.grid.refresh();
        }
      }
    }

  }

  Stock_Logico(selRow:number){
    let QuantityPrf : number = 0;
    let Quantity : number = 0;
    let Stock : number = 0;
    let Id_Prod=this.itemInvoice[selRow].Id;
    let i:number=0;
    for (let item of this.itemInvoice){
      if (item.Id==Id_Prod){
        Stock=item.Stock;
        if (item.isItemOfPrf){
          //QuantityPrf = QuantityPrf + ( item.QuantityPrf + ( item.QuantityPrf - item.Quantity) )
          QuantityPrf = QuantityPrf + ( item.QuantityPrf );
          //QuantityPrf = QuantityPrf + ( item.Quantity );
        }
        if ( i != selRow) {
          Quantity = Quantity + ( item.Quantity )
        }
      }
      i++;
    }
    let stockLogico= Stock + QuantityPrf ;//+ ( QuantityPrf-Quantity);
    return stockLogico;
  }

  AlertStock(selRow:number,setZero:boolean){
    let stockInsuficiente : boolean = false;
    let Stock=this.itemInvoice[selRow].Stock;

    let Id_Prod=this.itemInvoice[selRow].Id;
    let Quantity:number=0;
    for (let item of this.itemInvoice){
      if (item.Id==Id_Prod){
        Quantity=Quantity+item.Quantity;
      }
    }
    //debugger
    //Stock=this.Stock_Logico(selRow);
    Stock=this.itemInvoice[selRow].stockReal;

    if (Quantity>Stock){
      this._translateService.get('AlertInsufficientStock').subscribe((errorMessage: string) => {
        this._toastrService.showError(errorMessage, 'Error');
      });
      stockInsuficiente=true;
      if (setZero){
        Quantity=0;
        this.itemInvoice[selRow].Quantity=Quantity;
      }
      //this.grid.setCellValue(ItemNo, "Quantity", Quantity);
    }
    return stockInsuficiente;
  }

  AlertSockAllItems(){
    let i:number=0;
    let stockInsuficiente : boolean = false;
    stockInsuficiente=false;

    for (let item of this.itemInvoice){
      let Id_Prod=item.Id;
      let Stock:number=0;
      let Quantity:number=0;

      for (let itemX of this.itemInvoice){
        if (itemX.Id==Id_Prod){
          Quantity=Quantity+itemX.Quantity;
        }
      }
      Stock=this.Stock_Logico(i);
      if (Quantity>Stock){
        stockInsuficiente=true;
        break;
      }
      i++;
    }

    if (stockInsuficiente){
      this._translateService.get('Items con stock insuficiente...').subscribe((errorMessage: string) => {
        this._toastrService.showError(errorMessage, 'Error');
      });
    }
  }

  addRow(){
    //if (this.itemInvoice){
      let NItem=1;
      if (this.itemInvoice){
        NItem= this.itemInvoice.length+1;
      }
      let record : ItemInvoice = {
        Itemno        : NItem,
        Code          : '',
        CodBar        : '',
        Description   : '',
        Price         : 0.00,
        Quantity      : 0.0,
        Unity         : '',
        UnityId       : 0,
        Discount      : 0,
        Subtotal      : 0,
        WarehouseNo   : this.Session_BodDefault,//1,
        WarehouseName : this.Session_BodNameDef,
        ivainc        : false,
        IVA           : '0',
        IvaId         : 0,
        IvaPorc       : 0,
        TotIva        : 0,

        Taxes         : null,

        Packaging    : null,
        _PackagingSel   : null,

        Id            : '',
        Price1        : 0,
        Price2        : 0,
        Price3        : 0,
        Price4        : 0,
        Price5        : 0,
        Price6        : 0,
        Price7        : 0,
        Price8        : 0,
        Total         : 0,
        VAT           : 0,
        Detail        : '',
        Cost          : 0,
        imageUrl      : '',
        Stock         : 0,
        StockSales    : 0,

      };
      this.itemInvoice.push(record);
    //}
  }
  deleteRow(){
    let rowF=this.itemInvoice.length-1;
    if (this.selRow==rowF){
      return;
    }
    if (this.itemInvoice[this.selRow].isPromo){
      this.deleteRowsPromo();
      return;
    } else {
      this.grid.selectionSettings.mode="Both"
      this.grid.selectRow(this.selRow);
      this.grid.editSettings.showDeleteConfirmDialog=false;
      this.grid.deleteRecord();
      this.grid.editSettings.showConfirmDialog=false;
      this.grid.selectionSettings.mode="Cell"
      this.doubleAction=false;
      this.grid.endEdit();

      let i=0;
      for (let item of this.itemInvoice){
        i++;
        item.Itemno=i;
      }
      this.calculateTotal();
      this.cellActive= {
        rowIndex  : this.selRow,
        cellIndex : this.getIndexColum('Code')
      };
      this.grid.refresh();
      window.setTimeout(() => {
        this.grid.selectCell(this.cellActive,false);
      }, 1000);

    }
    //console.log(this.data);
  }
  deleteRowsPromo(){
    let rowF=this.itemInvoice.length-1;
    if (this.selRow==rowF){
      return;
    }
    let id_Promo=this.itemInvoice[this.selRow].Id_promo;
    let ItemNoPr=this.itemInvoice[this.selRow].ItemNoPromo;
    this.itemInvoice = this.itemInvoice.filter((i) => i.Id_promo != id_Promo || i.ItemNoPromo !=ItemNoPr ); // filtramos
    let i=0;
    for (let item of this.itemInvoice){
      i++;
      item.Itemno=i;
    }
    this.calculateTotal();
    this.cellActive= {
      rowIndex  : 0,
      cellIndex : this.getIndexColum('Code')
    };
    this.grid.refresh();
    rowF=this.itemInvoice.length;
    if (rowF==0){
      this.addRow();
    }
    window.setTimeout(() => {
      this.grid.selectCell(this.cellActive,false);
    }, 1500);

  }

  /*****************************************************************/
  /**                        Calculos                             **/
  /*****************************************************************/

  calculateRow(row:number,setData:boolean){
    //debugger
    if (row>=0){
      let item = this.itemInvoice[row];
      if (item && item.Code && item.Code != "" ){
        let itemNo= item.Itemno;
        let Price = item.Price;
        let Quantity = item.Quantity;
        let Discount = item.Discount;
        let Total = Price*Quantity;
        //Total = (Total - (Total * (Discount / 100)));
        Total = this.math.Round((Total - (Total * (Discount / 100))) , this.DecimalesSubtotalRendondeo);
        let PIva = item.IvaPorc;
        let TIva = (Total * PIva) / 100;
        if (setData){
            this.itemInvoice[row].TotIva=Total+TIva;
            this.itemInvoice[row].Subtotal=Total;
        } else {
            this.grid.setCellValue(itemNo, "TotIva", Total+TIva);
            this.grid.setCellValue(itemNo, "Subtotal", Total);
        }
        if (this.itemInvoice[row]._TaxesSel){
          this.itemInvoice[row]._TaxesSel.BaseImponible=Total;
          this.itemInvoice[row]._TaxesSel.Value= TIva; //Total*PIva;
        } else { /*-----------Iva por defecto el Indice 0 -----*/
          this.itemInvoice[row].Taxes[0].BaseImponible=Total;
          this.itemInvoice[row].Taxes[0].Value= TIva; 
        }
      }
      //this.grid.refresh();
    }
    //console.log(this.itemInvoice);
  }
  calculateTotal(){
    let SubTot=0;
    let PorcDiscountEnd=0
    let TIva=0;
    let Recargo=0;
    //console.log(this.TaxesBases);
    for (let iTax of this.TaxesBases){
      iTax.Total=0
    }
    let Base0:number=0;
    let BaseX:number=0;
    let IvaX:number=0;

    for (let item of this.itemInvoice){
      let Price = item.Price;
      let Quantity = item.Quantity;
      let Discount = item.Discount;
      let Total = Price*Quantity;
      //Total = (Total - (Total * (Discount / 100)));
      Total = this.math.Round((Total - (Total * (Discount / 100))) , this.DecimalesSubtotalRendondeo);
      for (let iTax of this.TaxesBases){
        if (item.IvaPorc==iTax.Rate){
          iTax.Total=iTax.Total+Total;
        }
      }
      SubTot=SubTot+Total;
      //let PIva = item.IvaPorc;
      ////let TIva = (Total * PIva) / 100;
      //if (PIva > 0){
      //  TIva = TIva + (((Total * (1 - (PorcDiscountEnd) / 100))) * (PIva / 100));
      //}
    }
    this.SubTotal=this.math.RoundIt(SubTot,2);//.toFixed(2);
    this.Discount = this.math.RoundIt((SubTot * PorcDiscountEnd) / 100,2);

    //this.Iva=this.math.RoundIt(TIva,2);
    //----------------debugger
    for (let iTax of this.TaxesBases){

      //console.log(iTax);
      if (iTax.Rate==0){
        Base0=Base0+iTax.Total;
      } else {
        BaseX=BaseX+iTax.Total;
        let PIva=iTax.Rate;
        IvaX=IvaX+(((iTax.Total * (1 - (PorcDiscountEnd) / 100))) * (PIva / 100));
      }
    }
    //let PIva=12;
    //this.Iva=(((BaseX * (1 - (PorcDiscountEnd) / 100))) * (PIva / 100));
    this.Iva=this.math.RoundIt(IvaX,2);
    TIva = this.math.RoundIt(IvaX,2);
    this.Ice=0;
    this.Total = this.math.RoundIt(SubTot,2) + this.math.RoundIt(TIva,2) + Recargo - this.math.RoundIt(this.Discount,2);
    //this.grid.refresh();

  }
  calculatePrice(Factor:number,Price:number,PriceType:number,Promotion){

    let _Prod=<ItemInvoice>this.itemInvoice[this.selRowEdit];
    if (_Prod){
        let Precio = _Prod.Price;
        if (PriceType==0) {
          // Aqui el precio se jala de Prod de los 1...8 precios de acuerdo a combo general Precio
          let NPrecio=this.invoice.TipoPrecio;

          if (NPrecio==1) {Precio = _Prod.Price1; }
          if (NPrecio==2) {Precio = _Prod.Price2; }
          if (NPrecio==3) {Precio = _Prod.Price3; }
          if (NPrecio==4) {Precio = _Prod.Price4; }
          if (NPrecio==5) {Precio = _Prod.Price5; }
          if (NPrecio==6) {Precio = _Prod.Price6; }
          if (NPrecio==7) {Precio = _Prod.Price7; }
          if (NPrecio==8) {Precio = _Prod.Price8; }
        }
        if (PriceType>=1 && PriceType<=8) {
          // Aqui el precio se jala de Prod de los 1...8 precios de acuerdo a combo Unidad Precio
          let NPrecio=PriceType;
          if (NPrecio==1) {Precio = _Prod.Price1; }
          if (NPrecio==2) {Precio = _Prod.Price2; }
          if (NPrecio==3) {Precio = _Prod.Price3; }
          if (NPrecio==4) {Precio = _Prod.Price4; }
          if (NPrecio==5) {Precio = _Prod.Price5; }
          if (NPrecio==6) {Precio = _Prod.Price6; }
          if (NPrecio==7) {Precio = _Prod.Price7; }
          if (NPrecio==8) {Precio = _Prod.Price8; }
        }
        if (PriceType==9) {
          Factor=1;
          Precio = Price;
        }
        Precio = Precio * Factor;
        let Descuento = (Precio * Promotion) / 100;
        Precio = Precio - Descuento;
        let IvaInc=_Prod.ivainc;
        let IvaPorc=this.itemInvoice[this.selRowEdit].IvaPorc;

        if (IvaInc && IvaPorc>0 ){
            Precio = Precio / (1 + (IvaPorc) / 100);
        }
        this.grid.setCellValue(this.selItemNo, "Price", Precio);
    }
  }


  /*****************************************************************/
  /**                     Seteos Iniciales                        **/
  /*****************************************************************/
  initCtrl(){
    this.Form = this._formBuilder.group({
      'Id_Vend'       : this.CtrTo_IdVend,
      'TipoPrecio'    : this.CtrTo_TipoPrecio,
      //'SelPrecio'    : this.CtrTo_SelPrecio,
      'idSerie'       : this.CtrTo_idSerie,
      'Customer'      : this.CtrTo_Customer
    });
    this.FormPrf = this._formBuilder.group({
      'NPrf'          : this.CtrTo_NPrf
    });
    //this.Form.controls['EquipmentCustomerID'].disable();
  }
  /*****************************************************************/
  /**                     Nueva de Factura                      **/
  /*****************************************************************/
  newInvoice(){
    this.getSeries(true);
    let customer: selCustomer = {
      Id : '',
      RUC: '',
      Name:'',
      Address:'',
      Phones:'',
      Email:''
    }
    this.selCustomer=customer;
    this.Form.controls['Customer'].setValue('');
    let horaAct = new Date();
    let id_vend=this.Session_Sales;//null;
    console.log(this.invoice);

    if (this.invoice){
      if (this.invoice.Id_Vend!=this.Session_Sales){
        id_vend=this.Session_Sales;
        this.invoice.Id_Vend=this.Session_Sales;
      }
      if (this.invoice.Id_Vend!=null){
        id_vend=this.invoice.Id_Vend;
      }
      //let idSerie=this.Form.controls['idSerie'].value;
      if (this.idSerie){
        this.onChangeSerie(this.idSerie);
      }
    }

    this.invoice ={
      DireccionCli    : '',
      CheckoutData    : false,
      EmailCli        : '',
      TelfCli         : '',
      ValHasta        : new Date(),
      Hora            : horaAct.getTime().toString(),
      Vence           : new Date(),
      Apagar          : 0,
      Id_Fact         : 0,
      Id_Prof         : 0,
      Serie           : 0,
      Punto           : 0,
      Numero          : 0,
      Caja            : 0,
      SRIAut          : '',
      RefExt          : '',
      Empresa         : '',
      TotalSubsidio   : 0,
      IVATotal        : 0,
      ICETotal        : 0,
      CIRUC           : '',
      Tipo            : '',
      GlobalDiscount  : 0,
      TipoPrecio      : 1,
      Id              : 0,
      IsGpsEnabled    : false,
      Lat             : '',
      Lng             : '',
      Address         : '',
      Fecha           : new Date(),
      GUID            : '',
      IdCliente       : '',
      Id_Vend         : id_vend,
      NombCli         : '',
      Nota            : '',
      IdUser          : '',
      Total           : 0,

      DocumentItems : null,
      Fpagos        : null,
      FPagosNew     : null,
    }
    //debugger
    if (this.Session_UserPvp) {
      this.invoice.TipoPrecio=0; //0 Equivale a Precio PVP desde el API
    } else {
      if (this.listPrice.length>0){
        this.invoice.TipoPrecio=this.listPrice[0].id;
      }
    }
    
    let existPriceX:boolean=false;
    if (this.listPrice.length>0){
      this.listPrice.forEach(item => {
        if (item.id==this.invoice.TipoPrecio) { existPriceX=true; }
      });
      if (existPriceX==false){ this.invoice.TipoPrecio=this.listPrice[0].id; }
    }

    if (this._dataService.case){
      this.invoice.Nota=this._dataService.case.Problem;
    }
    this.itemInvoice=[];
    this.addRow();
    this.calculateTotal();
    this.cellActive= {
      rowIndex  : 0,
      cellIndex : this.getIndexColum('Code')
    };
    window.setTimeout(() => {
      this.grid.refresh();
      //this.grid.selectCell(this.cellActive,false);
    }, 1000);
  }

  onDialogNewInvoice(templateRef: TemplateRef<any>){
    this.dialogNewInvoice = this.dialog.open(templateRef, { width: '350px', height: '170px',panelClass: 'custom-dialog-containerBox' });
    this.dialogNewInvoice.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result:any) => {
        if (result){
          if (result.success){
            this.newInvoice();
          }
        }
      });

  }
  closeDialogNewFact() {
    let result: object = {
      success:false,
      data:null
    }
    this.dialogNewInvoice.close(result);
  }
  onAcceptNewFact(){
    let result: object = {
      success   : true,
      data      : null
    }
    this.dialogNewInvoice.close(result);
  }
  onCancelNewFact(){
    this.closeDialogNewFact();
  }

  onBack(){
    let parameters={
      title:'Importante!!',
      message:'¿Está seguro que desea salir?'
    }
    let dialogConfig = new MatDialogConfig();
    dialogConfig.backdropClass='g-transparent-backdrop';
    dialogConfig.data= parameters;
    dialogConfig.panelClass= 'custom-dialog-containerBox';
    dialogConfig.disableClose=false;
    dialogConfig.width='380px';
    dialogConfig.height='170px';

    let result=this.dialog.open(DialogboxComponent, dialogConfig);
    result.afterClosed().subscribe(result => {
      if (result){
        if (result.success){
          if (this.isInvoice){
            this._navigationService.navigateToListInvoices();
          }
          if (this.isQuotation){
            this._navigationService.navigateToAllQuotations();
          }

        }
      }
    });
  }


  /*****************************************************************/
  /**               Edicion de Factura & Proforma                 **/
  /*****************************************************************/
  editInvoice(docId:number){
    let c:Number=0;
    this._ItemsFact.getInvoiceItems(docId.toString())
    .pipe(
      map((data: any) =>{
          return {
            items: data.map((regs:InvoiceItem) => {
              //c++;
              return {
                Itemno      : 0,
                Code        : regs.Code,
                Description : regs.Description,
                Price       : regs.Price,
                Quantity    : regs.Quantity,
                Unity       : '',//regs.,
                UnityId     : 0,//regs.Quantity,
                Discount    : regs.Discount,
                Subtotal    : regs.Subtotal,
                WarehouseNo : 1,
                IVA         : '12',
                IvaId       : 1,
                IvaPorc     : regs.VAT,
                TotIva      : 0
              };
            })
          }
        }
    ))
    .subscribe(
    (response:any) => {
      if (response != null) {
        //this.dataItemFact = <ItemVisit[]> response.items;
        let c:number=0;
        this.itemInvoice = <ItemInvoice[]>response.items;
        for (let item of this.itemInvoice){
          c++;
          item.Itemno=c;
          item.IVA=this.TaxesGen.find(e => Number(e.StylexsaCode) === Number(item.IvaId)).Rate.toString();
        }
        this.addRow();
        let i=0;
        for (let item of this.itemInvoice){
         this.calculateRow(i,true);
         i++;
        }
        this.calculateTotal();

      }
    }, error => console.error('An error has occured'));

  }
  editQuotation(docId:number){
    if (docId>0){
      this.waitService.Show(`Cargando datos..`);
      let id_prf=docId.toString();
      this._quotationService.QuotationById(id_prf)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((response: Type_quotation) => {
              if (response){
                this.setQuotation(response);
              }else{
                this.waitService.close();
                this.swalService.getInstance('Quotation does not exist', 'error').then(() => {});
              }

          }, error => {
              this.waitService.close();
              this.swalService.getInstance('An error has occurred', 'error').then(() => {});
          });
  }
  }
  showDialog(templateRef: TemplateRef<any>){
  }
  loadQuotation(templateRef: TemplateRef<any>){
    //this.FormPrf.controls['NPrf'].setValue(74734); //74774
    this.dialogPrf = this.dialog.open(templateRef, { width: '380px', height: '170px',panelClass: 'custom-dialog-containerBox' });
    this.dialogPrf.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result:any) => {
        if (result){
          if (result.success){
              let id_prf=this.FormPrf.controls['NPrf'].value;

              let promesa = new Promise((resolve, reject) => {
                if (this.Session_PrfUnique){
                      this._quotationService.GetQuotationInvoices(id_prf)
                      .pipe(takeUntil(this.ngUnsubscribe))
                      .subscribe((response: any) => {
                          //console.log(response);
                          if (response.length>0){
                            this.swalService.getInstance('Esta proforma ya está facturada', 'error').then(() => {});
                            resolve(false);
                          }else{
                            resolve(true);
                          }
                      }, error => {
                          this.swalService.getInstance('An error has occurred', 'error').then(() => {});
                      });
                }else{
                    resolve(true);
                }
              });

              promesa.then( (success) => {
                this.waitService.Show(`Cargando datos..`);
                this._quotationService.QuotationById(id_prf)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((response: Type_quotation) => {
                        if (response){
                          this.setQuotation(response);
                        }else{
                          this.waitService.close();
                          this.swalService.getInstance('Quotation does not exist', 'error').then(() => {});
                        }

                    }, error => {
                        this.waitService.close();
                        this.swalService.getInstance('An error has occurred', 'error').then(() => {});
                    });
              });


          }
        }
      });
  }
  setQuotation(quotation:any){
    //console.log(quotation);
    let id_cliente=quotation.IdCliente;
    this._customersService.getCustomersById(id_cliente)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        if (result){
          this.setCustomer(result);
          //this.invoice.TipoPrecio=3;
        } else {
          if (quotation.TipoPrecio<=0){
            this.invoice.TipoPrecio=1;
          }else {
            this.invoice.TipoPrecio=quotation.TipoPrecio;
            //if (this.Session_UserPvp) this.invoice.TipoPrecio=100;
          }
        }
    });

    this.invoice.Id_Vend=quotation.Id_Vend;
    this.invoice.Nota=quotation.Nota;
    this.invoice.Id_Prof = quotation.Id;
    this.invoice.GUID = quotation.GUID;

    //console.log(quotation);


    let c:number=0;
    this.itemInvoice = <ItemInvoice[]>quotation.DocumentItems;
    for (let item of this.itemInvoice){
      c++;
      item.Itemno=c;

      //item.IvaId=null;
      //item.IvaPorc=null;
      //item.IVA="";
      item.Code=item.Id;
      item._TaxesSel=null;
      item.ivainc=item['VATInc'];
      if (item.Taxes){
        if (item.Taxes[0]){ /*-----------Iva por defecto el Indice 0 -----*/
          let StylexsaCode=Number(item.Taxes[0].StylexsaCode);
          let Rate = Number(item.Taxes[0].Rate);
          item.IvaId=StylexsaCode;
          item.IvaPorc=Rate;
          item.IVA=Rate.toString();
          item._TaxesSel=item.Taxes[0];
        }
      }

      item.Unity=item['Unit'];

      item._PackagingSel=null;

      if (item.WarehouseNo==0) {
        item.WarehouseNo=this.Session_BodDefault;//1;
        item.WarehouseName=this.Session_BodNameDef;
      } else {
        item.WarehouseName=this.WarehouseGen.find(e => Number(e.Id) === item.WarehouseNo).Name
      }

      item.QuantityPrf=item.Quantity;
      item.isItemOfPrf=true;
      item.isPromo=false;
      //debugger;
      if (this.math.allTrim(item.Id_promo)!='') {   //|| item.Id_promo!=null
        item.isPromo=true;
      }
      item.Id_promo=item.Id_promo;
    }
    this.invoice.DocumentItems=this.itemInvoice;
    console.log(this.invoice);

    this.addRow();
    let i=0;
    //debugger
    for (let item of this.itemInvoice){
      this.calculateRow(i,true);
      item.stockReal=this.Stock_Logico(i);
      i++;
    }
    this.calculateTotal();

    this.waitService.close();


    if (this.Session_AlertStock) {
      this.AlertSockAllItems();
    }

  }
  closeDialogPRF() {
    let result: object = {
      success:false,
      data:null
    }
    this.dialogPrf.close(result);
  }
  onAcceptPRF(){
    let result: object = {
      success   : true,
      data      : null
    }
    this.dialogPrf.close(result);
  }
  onCancelPRF(){
    this.closeDialogPRF();
  }


  onDialog(title,message){
      this.dialogTitle=title;
      this.dialogMessage=message;
      return this.dialog.open(this.tplDialog, { width: '380px', height: '170px',panelClass: 'custom-dialog-containerBox' });
  }
  onCloseDialogYN(){
    let result: object = {
      success:false,
      data:null
    }
    this.dialogYN.close(result);
  }
  onAcceptDialogYN(){
    let result: object = {
      success   : true,
      data      : null
    }
    this.dialogYN.close(result);
  }
  onCancelDialogYN(){
    this.onCloseDialogYN();
  }
  onDialogSaveAs(){
    /*
    this._translateService.get(title).subscribe((text: string) => {
      this.dialogTitle=text;
      this._translateService.get(message).subscribe((text: string) => {
        this.dialogMessage=text;
        return this.dialog.open(this.tplDialog, { width: '380px', height: '170px',panelClass: 'custom-dialog-containerBox' });
      })
    });
    */
    this.dialogYN = this.onDialog('Proforma','Guardar como proforma.?');
    this.dialogYN.afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((result:any) => {
      if (result){
        if (result.success){
          if (this.onValidationCtrl()){
            return;
          }
          if (this.onValidationGrid()){
            return;
          }
          this.saveQuotation();
        }
      }
    });
  }
  onSaveQuotation(){
    if (this.onValidationCtrl()){
      return;
    }
    if (this.onValidationGrid()){
      return;
    }
    this.onValidationStock().then( (success) => {
      //console.log(success);
      if (!success){
        this.saveQuotation();
      }
    });
  }

  saveQuotation(){

    let n=this.itemInvoice.length-1
    let i=0;

    let docItem : InvoiceItem[]=[];
    for (let item of this.itemInvoice){
      if (i<n){

        let itemTemp : InvoiceItem = {
          Id          : item.Id,
          Itemno      : item.Itemno,
          Code        : item.Code,
          CodBar      : item.CodBar,
          Quantity    : item.Quantity,
          Price       : item.Price,
          Price1      : item.Price1,
          Price2      : item.Price2,
          Price3      : item.Price3,
          Price4      : item.Price4,
          Price5      : item.Price5,
          Price6      : item.Price6,
          Price7      : item.Price7,
          Price8      : item.Price8,
          Description : item.Description,
          VAT         : item.VAT,
          Discount    : item.Discount,
          Detail      : item.Detail,
          Subtotal    : item.Subtotal,
          Cost        : item.Cost,
          Total       : item.Total,
          imageUrl    : item.imageUrl,
          Stock       : item.Stock,
          StockSales  : item.StockSales,
          Taxes       : item.Taxes,
          Packaging   : Array(item._PackagingSel),// item.Packaging,
          WarehouseNo : item.WarehouseNo,
          Id_Promo    : item.Id_promo
        }
        docItem.push(itemTemp);
      }
      i++;
    }
    let horaAct = new Date();
    let lat:string='';
    let lng:string='';
    //this._sessionService.getPosition().then(pos => {lat  = pos.lat;lng  = pos.lng; });
    let NGuid = Guid.newGuid();
    if (this.DocId>0){
      NGuid=this.invoice.GUID;
    }

    this.invoice ={
        DireccionCli    : this.selCustomer.Address,
        CheckoutData    : false,
        EmailCli        : this.selCustomer.Email,
        TelfCli         : this.selCustomer.Phones,
        ValHasta        : new Date(),
        Hora            : moment(horaAct).local().format("HH:mm:ss"),
        Vence           : new Date(),
        Apagar          : 0,
        Id_Fact         : 0,
        Id_Prof         : this.DocId,
        Id              : this.DocId,
        Serie           : 0,
        Punto           : 0,
        Numero          : 0,
        Caja            : 0,
        SRIAut          : '',
        RefExt          : '',
        Empresa         : this.Session_Empresa,
        TotalSubsidio   : 0,
        IVATotal        : this.Iva,
        ICETotal        : 0,
        CIRUC           : this.selCustomer.RUC,
        Tipo            : 'PRF',
        GlobalDiscount  : 0,
        TipoPrecio      : this.invoice.TipoPrecio,
        IsGpsEnabled    : false,
        Lat             : lat,
        Lng             : lng,
        Address         : this.selCustomer.Address,
        Fecha           : new Date(),
        GUID            : NGuid,
        IdCliente       : this.selCustomer.Id,
        Id_Vend         : this.invoice.Id_Vend,
        NombCli         : this.selCustomer.Name,
        Nota            : this.invoice.Nota,
        IdUser          : this.Session_User,
        Total           : this.Total,

        DocumentItems   : docItem,
        Fpagos          : null,
        FPagosNew       : null
    }
    //console.log(this.invoice);

    this.waitService.Show(`Guardando datos..`);
    this._invoiceService.insertQuotation(this.invoice)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((response: DocumentResponse) => {
      if (response.Successful) {
        this.waitService.close();

        //this.swalService.getInstance(`Quotation No. ${response.DocumentNumber} was recorded correctly`, 'success').then(() => {});

        let parameters={
          title:'Importante!!',
          message:`Quotation No. ${response.DocumentNumber} was recorded correctly, want to print..?`
        }
        let dialogConfig = new MatDialogConfig();
        dialogConfig.backdropClass='g-transparent-backdrop';
        dialogConfig.data= parameters;
        dialogConfig.panelClass= 'custom-dialog-containerBox';
        dialogConfig.disableClose=true;//false;
        dialogConfig.width='380px';
        dialogConfig.height='170px';

        let result=this.dialog.open(DialogboxComponent, dialogConfig);
        result.afterClosed().subscribe(result => {
          if (result){
            //console.log(result);
            if (result.success){
                let quotation: IQuotation = new IQuotation();
                quotation.Type = 'Quotation';
                quotation.Id=response.DocumentNumber;
                quotation.Customer=this.Customer; //this._dataService.customer;
                this._navigationService.navigateToDocumentDetail(quotation);
            } else {
              this.newInvoice();
              if (this.isQuotation){
                if (this.DocId>0){
                  this._navigationService.navigateToAllQuotations();
                }
              }
            }
          }
        });

      } else {
        this.waitService.close();
        this.swalService.getInstance(`An error has occurred ${response.Message}`, 'error').then(() => {});
      }
    }, error => {
      this.waitService.close();
      this.swalService.getInstance('An error has occurred', 'error').then(() => {});
    });


  }

  /*****************************************************************/
  /**                 Validaciones antes de grabar                **/
  /*****************************************************************/
  onValidationCtrl():boolean{
    this.isSubmitted = true;
    if (this.Form.invalid) {
      this._translateService.get('ErrorForm').subscribe((errorMessage: string) => {
        this._toastrService.showError(errorMessage, 'Error');
      });
      return true;
    }
    return false;
  }
  onValidationGrid():boolean{
    this.isSubmitted = true;
    let NItem=0;
    if (this.itemInvoice){
      NItem = this.itemInvoice.length;
      if (NItem==1){
        this._translateService.get('Selectproduct').subscribe((errorMessage: string) => {
          this._toastrService.showError(errorMessage, 'Error');
        });
        return true;
      }
      if (NItem>1){
        let row=0;
        for (let item of this.itemInvoice){
          if (row<(NItem-1)){
            if (item.Quantity<=0){
              this._translateService.get('Amount.must.be.greater.than.0').subscribe((errorMessage: string) => {
                this._toastrService.showError(errorMessage, 'Error');
              });
              this.cellActive= {
                rowIndex  : row,
                cellIndex : this.getIndexColum('Quantity')
              };
              this.grid.selectRow(row);
              this.grid.selectCell(this.cellActive,false);
              return true;
            }
            if (item.Description==''){
              this._translateService.get('Selectproduct').subscribe((errorMessage: string) => {
                this._toastrService.showError(errorMessage, 'Error');
              });
              this.cellActive= {
                rowIndex  : row,
                cellIndex : this.getIndexColum('Description')
              };
              this.grid.selectRow(row);
              this.grid.selectCell(this.cellActive,false);
              return true;
            }
            if (this.Session_Allow0Price==false){
              if (item.Price<=0){
                this._translateService.get('AllowZeroSalesPrice').subscribe((errorMessage: string) => {
                  this._toastrService.showError(errorMessage, 'Error');
                });
                this.cellActive= {
                  rowIndex  : row,
                  cellIndex : this.getIndexColum('Price')
                };
                this.grid.selectRow(row);
                this.grid.selectCell(this.cellActive,false);
                return true;
              }
            }
            if (this.Session_AlertStock){
              /*
              let Stock=item.Stock;
              let Quantity=item.Quantity;
              if (Quantity>Stock){
                this._translateService.get('AlertInsufficientStock').subscribe((errorMessage: string) => {
                  this._toastrService.showError(errorMessage, 'Error');
                });
                this.cellActive= {
                  rowIndex  : row,
                  cellIndex : this.getIndexColum('Quantity')
                };
                this.grid.selectRow(row);
                this.grid.selectCell(this.cellActive,false);
                return true;
              }
              */
             if (this.AlertStock(row,false)){
              this.cellActive= {
                rowIndex  : row,
                cellIndex : this.getIndexColum('Quantity')
              };
              this.grid.selectRow(row);
              this.grid.selectCell(this.cellActive,false);
              return true;
             }
            }
          }
          row++;
        }
      }
    }
    else {
      this._translateService.get('Selectproduct').subscribe((errorMessage: string) => {
        this._toastrService.showError(errorMessage, 'Error');
      });
      return true;
    }
    return false;
  }
  onValidationStock():Promise<boolean> {
    return new Promise((resolve)=> {
        this.isSubmitted = true;
        let NItem=0;
        let ProdSend=[];
            NItem = this.itemInvoice.length;
            let row=0;

            for (let item of this.itemInvoice){
              let ProdX : ProductStock ={
                Id : this.math.allTrim(item.Id),
                Stock  : item.Quantity,
                WarehouseNumber:item.WarehouseNo
              };
              ProdSend.push(ProdX);
            }
            /*
            var groupBy = function (miarray, prop) {
              return miarray.reduce(function(groups, item) {
                  var val = item[prop];
                  groups[val] = groups[val] || {Id: item.Id, WarehouseNumber:0, Stock : 0};
                  groups[val].WarehouseNumber= item.WarehouseNumber;
                  groups[val].Stock  += item.Stock;
                  return groups;
              }, {});
            }
            let ProdSendGroup : ProductStock[] = groupBy(ProdSend,'Id');
            */

            //JSON.stringify(ProdSend)}
            this._invoiceService.ProductStocks(ProdSend ,this.invoice.Id_Prof).subscribe((response:Array<ProductStock>) => {
              //debugger;
              if (response) {
                console.log(response);
                if (response.length>0){
                  this._translateService.get('El producto o los productos se vendieron recientemente, sin stock actualmente').subscribe((errorMessage: string) => {
                    this._toastrService.showError(errorMessage, 'Error');
                  });
                  //debugger;
                  let i=0;
                  for (let item of this.itemInvoice){
                    for (let itemR of response){
                      if (itemR.Id==item.Id){
                        if(item.Id_promo == '' || item.Id_promo == null){
                          item.Quantity=0;
                          item.AplicaColor=true;
                        }
                        item.Stock=itemR.Stock;
                        item.stockReal=itemR.Stock;
                        this.calculateRow(i,true);
                      }
                    }
                    i++;
                  }
                  this.calculateTotal();
                  this.grid.refresh();
                  resolve(true);
                } else {
                  resolve(false);
                }
              } else {
                resolve(false);
              }
            });


    });
  }

  isFormControlValid(controlName: string) {
    let value=(!this.isSubmitted || !this.Form.controls[controlName].errors) &&
    (this.Form.controls[controlName].valid || this.Form.controls[controlName].untouched);
    return value;
  }

  /*****************************************************************/
  /**                     Forma de Pagos                          **/
  /*****************************************************************/

  onPayments(){

    if (this.onValidationCtrl()){
      return;
    }
    if (this.onValidationGrid()){
      return;
    }

    this.onValidationStock().then( (success) => {
      //console.log(success);
      if (!success){
        const dialog = this._notificationService._dialog.open(PaymentsComponent, { width: '1200px', height: '550px',panelClass: 'custom-dialog-container' });
            dialog.componentInstance.dialogInstance = dialog;
            dialog.componentInstance.dataInvoice = {
              date             : new Date(),
              subTotal          : this.SubTotal,
              totDiscount       : this.Discount,
              totIva            : this.Iva,
              totIce            : this.Ice,
              total             : Number(this.Total.toFixed(2)),
              customerID        : this.CustomerID,
              listCash          : this.listCash,
              listBank          : this.listBank,
              listBankEmp       : this.listBankEmp,
              listCreditCard    : this.listCreditCard,
              selCustomer       : this.selCustomer,
              cashDefault       : this.Session_Cash,
              enableInvoiceZero : APP_SETTINGS.setups.cart.enableInvoiceZero
            };
            dialog.afterClosed()
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((result:any) => {
                if (result){
                  if (result.success){
                      this.onSave(result)
                  }
                }
              });
      }
    });
  }
  

  /*****************************************************************/
  /**                     Grabada de Datos                        **/
  /*****************************************************************/
  onSave(dataPayments){
    let dataFPago:Type_fPagosNew = dataPayments.data;
    let dataExtra:Type_dataExtra = dataPayments.dataExtra;

    this.isSubmitted = false;
    let n=this.itemInvoice.length-1
    let i=0;


    let docItem : InvoiceItem[]=[];
    for (let item of this.itemInvoice){
      if (i<n){
        //let Packaging : Packaging[]=[];
        //Packaging.push(item._PackagingSel);

        if (this.invoice.Id_Prof>0){
          if (!item._PackagingSel){
            if (item.Packaging){
              item._PackagingSel=item.Packaging[0]; // Por defecto el Indice 0 son las unidades escogidas en la proforma
            }
          }
        }

        if (item._TaxesSel){

          item.Taxes[0].Rate=item._TaxesSel.Rate;
          item.Taxes[0].SRICode=item._TaxesSel.SRICode;
          item.Taxes[0].StylexsaCode=item._TaxesSel.StylexsaCode;
          item.Taxes[0].BaseImponible=item._TaxesSel.BaseImponible;
          item.Taxes[0].Value=item._TaxesSel.Value;
        }

        let itemTemp : InvoiceItem = {
          Id          : item.Id,
          Itemno      : item.Itemno,
          Code        : item.Code,
          CodBar      : item.CodBar,
          Quantity    : item.Quantity,
          Price       : item.Price,
          Price1      : item.Price1,
          Price2      : item.Price2,
          Price3      : item.Price3,
          Price4      : item.Price4,
          Price5      : item.Price5,
          Price6      : item.Price6,
          Price7      : item.Price7,
          Price8      : item.Price8,
          Description : item.Description,
          VAT         : item.VAT,
          Discount    : item.Discount,
          Detail      : item.Detail,
          Subtotal    : item.Subtotal,
          Cost        : item.Cost,
          Total       : item.Total,
          imageUrl    : item.imageUrl,
          Stock       : item.Stock,
          StockSales  : item.StockSales,
          Taxes       : item.Taxes,//Array(item._TaxesSel)
          Packaging   : Array(item._PackagingSel),// item.Packaging,
          WarehouseNo : item.WarehouseNo,
          Id_Promo    : item.Id_promo
        }
        docItem.push(itemTemp);
      }
      i++;
    }
    let horaAct = new Date();
    let lat:string='';
    let lng:string='';
    //this._sessionService.getPosition().then(pos => {lat  = pos.lat;lng  = pos.lng;});
    //console.log(this.invoice.Fecha);
    //console.log(new Date());
    //console.log(this.SerieDoc);

    this.invoice ={
        DireccionCli    : this.selCustomer.Address,
        CheckoutData    : false,
        EmailCli        : this.selCustomer.Email,
        TelfCli         : this.selCustomer.Phones,
        ValHasta        : new Date(),
        Hora            : moment(horaAct).local().format("HH:mm:ss"),
        Vence           : new Date(),
        Apagar          : dataExtra.APagar,
        Id_Fact         : 0,
        Id_Prof         : this.invoice.Id_Prof,
        Serie           : this.SerieDoc.Serie,
        Punto           : this.SerieDoc.Punto,
        Numero          : this.SerieDoc.Numero,
        //Serie           : 1,
        //Punto           : 1,
        //Numero          : 3,

        Caja            : dataExtra.cajaId,
        SRIAut          : '',
        RefExt          : '',
        Empresa         : this.Session_Empresa,
        TotalSubsidio   : 0,
        IVATotal        : this.Iva,
        ICETotal        : 0,
        CIRUC           : this.selCustomer.RUC,
        Tipo            : this.DocType,
        GlobalDiscount  : 0,
        TipoPrecio      : this.invoice.TipoPrecio,
        Id              : 0,//this.maxID;
        IsGpsEnabled    : false,
        Lat             : lat,
        Lng             : lng,
        Address         : this.selCustomer.Address,
        Fecha           : new Date(moment(this.invoice.Fecha).local().toISOString()), // this.invoice.Fecha,// new Date(),
        GUID            : Guid.newGuid(),
        IdCliente       : this.selCustomer.Id,
        Id_Vend         : this.invoice.Id_Vend,
        NombCli         : this.selCustomer.Name,
        Nota            : this.invoice.Nota,
        IdUser          : this.Session_User,
        Total           : this.Total,

        DocumentItems   : docItem,
        Fpagos          : null,
        FPagosNew       : dataFPago
    }
    console.log(this.invoice);
    this.waitService.Show(`Guardando datos..`);
    this._invoiceService.insertInvoice(this.invoice)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((response: DocumentResponse) => {
      if (response.Successful) {
        this.waitService.close();
        //this.swalService.getInstance(`Invoice No. ${response.DocumentNumber} was recorded correctly`, 'success').then(() => {});

        let parameters={
          title:'Importante!!',
          message:`Invoice No. ${response.DocumentNumber} was recorded correctly, want to print..?`
        }
        let dialogConfig = new MatDialogConfig();
        dialogConfig.backdropClass='g-transparent-backdrop';
        dialogConfig.data= parameters;
        dialogConfig.panelClass= 'custom-dialog-containerBox';
        dialogConfig.disableClose=true;//false;
        dialogConfig.width='380px';
        dialogConfig.height='170px';

        let result=this.dialog.open(DialogboxComponent, dialogConfig);
        result.afterClosed().subscribe(result => {
          if (result){
            //console.log(result);
            if (result.success){

              let InvoiceRep: Invoice = new Invoice();
              InvoiceRep.Type = 'Invoice';
              InvoiceRep.Id=response.DocumentNumber;
              InvoiceRep.Customer=this.Customer;// this._dataService.customer;

              this._ItemsFact.GetInvoiceByID(response.DocumentNumber).subscribe( document  => {
                if (document){
                  if (this.isTabs==false){
                    this._dataService.document = document;
                    this._navigationService.navigateToDocumentDetail(document);
                  } else {
                    this.messageEvent.emit(document);
                    this.newInvoice();
                  }
                }
              });



            } else {
              this.newInvoice();
            }
          }
        });

      } else {
        this.waitService.close();
        this.swalService.getInstance(`An error has occurred ${response.Message}`, 'error').then(() => {});
      }
    }, error => {
      this.waitService.close();
      this.swalService.getInstance('An error has occurred', 'error').then(() => {});
    });

  }

}




//









  /*****************************************************************/
  /**               Funciones sin utilizar                        **/
  /*****************************************************************/
  /*
  begin(args: any) {
  }
  dataSourceChanged(state: DataSourceChangedEventArgs): void {
    //console.log('dataSourceChanged: ' + JSON.stringify((<any>this.grid.dataSource).result));
  }
  dataStateChange(state: DataStateChangeEventArgs): void {
		//console.log('dataStateChange: ' + JSON.stringify((<any>this.grid.dataSource).result));
		//setTimeout(() => {
			//console.log('AFTER: ' + JSON.stringify((<any>this.grid.dataSource).result));
		//}, 0);
  }
  afterBatchSave($event){
  }
  setUnity(code:string){
  }
  actionBegin(args: SaveEventArgs) {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
       // this.orderData = Object.assign({}, args.rowData);
    }
    if (args.requestType === 'save') {
        // cast string to integer value.
        //const OrderDate = 'OrderDate';
        //args.data[OrderDate] = this.orderData[OrderDate];
        //console.log(this.itemInvoice);
    }
  }
  beginEdit($event){
    //console.log(event);
  }
  dataBound($event){
  }
  onFromDateChange(value: Date) {
    //this.from = this.datePipe.transform(value, 'yyyyMMdd');
  }
  shallow<T extends object>(source: T): T {
    const copy = {} as T
    Object.keys(source).forEach((key) => {
      copy[key] = source[key]
    })
    //console.log(copy);
    return copy
  }
*/

/*-----------------------------------Combos del grid-----------------------------------*/
    /*
     this.unidadParams = {
      params: {
          dataSource: new DataManager(this.Unidad),
          fields: { text: 'Unidad', value: 'Unidad' },
          query: new Query(),
          actionComplete: () => false
      }
    };
    */
    /*
    this.txtParams = {
      read: (args) => {
        console.log(args);
        return;
      },
      destroy:()=>{
        console.log(this.selColEdit);
        if (this.selColEdit=='Code'){
            // en caso que de problemas el actionComplete "disparos multiples"
            //Aqui iria la busqueda del producto
        }
      }
    };
    */
    /*
    this.txtNumParams = {
      params: {htmlAttributes:{type:"number"}},
      create:(args)=>{
        this.selRowEdit=this.selRow;
        this.selItemNo=Number(args.data.Itemno);
        this.stateElem = document.createElement('input'); return this.stateElem;
      },
      read:()=>{return this.TxtNumObj.value;},
      destroy:()=>{
        if (this.TxtNumObj.value) {
          //this.grid.setCellValue(this.selItemNo, "IvaPorc", Rate);
        }
        this.TxtNumObj.destroy();
      },
      write:(args: { rowData: object, column: Column })=>{
        this.TxtNumObj = new NumericTextBox({
          value: args.rowData[args.column.field]
        })
        this.TxtNumObj.appendTo(this.stateElem);
     }
    };
    */


    /*
export interface PagoEfectivo{
  Valor:number;
}
*/
/*
export interface Plantar {
    Id: number;
    Nombre: string;
    Modo: number;
    Meses: number;
    Interes: number;
    CodCont: string;
}
*/
/*
export interface Type_fPagos {
    Tipo:string;
    Banco:string;
    Cuenta:string;
    NumeroCheque:string;
    Emision:Date;
    Vence:Date;
    Valor:number;
    Detail:string;
    CustmerReference:string;
    PagoTarjeta : PagoTarjeta;
    PagoCredito : PagoCredito;
}
*/




/*------------------------------COMBO Unidad llenado con UrlAdator-----------------*/
/*
import { DataManager, Query, UrlAdaptor } from '@syncfusion/ej2-data';
import { RetCode } from '../../../models/accountreceivable/WithholdingTaxes';

class ApiAdaptor extends UrlAdaptor {
  code:string;
  constructor(newCode:string) {
    super();
    this.code=newCode;
  }
  processQuery(dm: DataManager, query: Query, hierarchyFilters?: object[]) {
    let original = super.processQuery.apply(this, arguments);
    let data = JSON.parse(original.data);

    let parameters = `?Id=${this.code}`;
    return {
      data: JSON.stringify({ value: data }),
      url: `${original.url}${parameters}`,
      pvtData: original.pvtData,
      type: 'GET',
      contentType: 'application/json; charset=utf-8'
    };
  }
  processResponse(data, ds, query, xhr, request, changes) {
    const original = super.processResponse.apply(this, arguments);
    return data.Packaging ;
  };
}



initGridCtrl(){
    this.unityParams = {
      create:(args)=>{
        this.selRowEdit=this.selRow;
        this.selItemNo=Number(args.data.Itemno);
        this.stateElem = document.createElement('input');
        return this.stateElem;
      },
      read:()=>{
        return this.UnityObj.text;
      },
      destroy:()=>{
        if (this.UnityObj.value) {
          let id=Number(this.UnityObj.value);
          this.grid.setCellValue(this.selItemNo, "UnityId", id);
        } else {
          this.grid.setCellValue(this.selItemNo, "UnityId", null);
          let Factor = 1;
          let Price = 0;
          let PriceType = 0;
          let Promotion = 0
          this.calculatePrice(Factor,Price,PriceType,Promotion);
        }
        this.UnityObj.destroy();
      },
      write: ()=>{
        //https://stackblitz.com/edit/angular-zqcmgz
        //https://stackblitz.com/edit/dropdown-asyncpipe-1?file=src%2Fapp%2Fapp.component.html
          let code = this.itemInvoice[this.selRow].Code;
          let _Packaging = this.itemInvoice[this.selRow].Packaging;
          this.Unity=null;
          this.Unity2=null;
          let dataX:any;
          if (_Packaging){
            this.Unity = <any[]>_Packaging;
            this.Unity2=_Packaging;
            dataX=this.Unity;
          } else {

            if (this.invoice.Id_Prof>0){
              dataX = new DataManager({
                url: 'http://fe-net.net:8700/api/Inventory/Product',//this.getApiQuery(`Products/Code?code=${code}`),
                adaptor: new ApiAdaptor(code),
                headers: [{
                  'Authorization':'BEARER AQAAANCMnd8BFdERjHoAwE_Cl-sBAAAArQQZC1WMr02FXKL5T6plVQAAAAACAAAAAAAQZgAAAAEAACAAAAB_Lq3Sn3R7_NGoPEPVPtAmkfMUe7Pshu5wolo7cEXVogAAAAAOgAAAAAIAACAAAAAZMsOMsIIsNmyOKeZQsYNwZ_td-D828w2gB8B1a6gzh_AAAABxqnNZkltdtem9milEh1PzumC4eTKF9q4_o2G5tVn11Rzo5b3F0mx74ujAS_peD9xfd5kf-wiNKFm0TlsWsWh8QpFbQxzbAusB3IowW157M-Z0ftzRR2QsNYqBpllBSpQwgu_xS1r1-FsBimrPTX4-QE-8QmnKP50sxF8ZKft-kq57YRQfEjxxtxmgkGZLwht7FzFHEcUYJkMizaMco1v7ZAySeULThUyLJuvBA9iIP4mY2SdM1GZagslR0fJHRb0XKMIh6EbkxcaOWP4G8Xftgiom6r1PP__1MieZ7yuPA5-DiBk63HXkUoncS-4oel1AAAAAF06v1YdgBK88LoCoMu98ZFppUCN_1_MHmt7yJZdVZQ8eF_h3t6u2K7aacTJ6lpc240SQkoqVoMyM75m_YClNdQ'
                }]
              })
            }
          }
          this.UnityObj = new DropDownList({
            dataSource: dataX, //this.Unity ,
            fields: { value: 'Id', text: 'PackagingName' },
            placeholder: 'Select Unity',
            floatLabelType: 'Never',
            change: () => {
              let Factor = 1;
              let Price = 0;
              let PriceType = 0;
              let Promotion = 0
              if (this.UnityObj.value) {
                  let id=Number(this.UnityObj.value);
                  let Obj_PackagingsSel:Packaging=this.Unity2.find(e => e.Id === id)
                  Factor = Number(Obj_PackagingsSel.Factor);
                  Price = Number(Obj_PackagingsSel.Price);
                  PriceType = Number(Obj_PackagingsSel.PriceType);
                  //let Promotion = Number(this.Unity.find(e => e.Id === id).Promotion);
                  Promotion = 0
                  this.grid.setCellValue(this.selItemNo, "UnityId", id);
                  this.itemInvoice[this.selRowEdit]._PackagingSel=Obj_PackagingsSel;
              } else {
                this.grid.setCellValue(this.selItemNo, "UnityId", null);
              }
              this.calculatePrice(Factor,Price,PriceType,Promotion);
            }
          });

          this.UnityObj.appendTo(this.stateElem);
      }
    }
  }

  */


/*------------------------------COMBO Unidad llenado con Observable y mapeo-----------------*/

  /*
  public getListUnity(code): Observable<any> {
    if (this.itemInvoice[this.selRow].Packaging){
      return Observable.of(this.itemInvoice[this.selRow].Packaging);
    }else{
      return this._productService.getproductByID(code)
        .pipe(
          map(data => {
            //console.log('data retrieved', data);
            return (<Product>data).Packaging;
          }),
          finalize(() => {
            console.log('end api');
          })
        );
    }
  }
  public uso(){
    //https://es.stackoverflow.com/questions/175877/convertir-un-observable-a-un-array-local
    //------------llena una variable observable manualmente------
    this.Unity3=Observable.of(prod.Packaging);
    //--------------Variable observable llena desde al api service--------
    this.Unity3=this.getListUnity(this.itemInvoice[this.selRow].Code);
    //----Variable observable se activa la suscripcion de esta forma------
    this.Unity3.subscribe((data:any)=>{
      console.log(data);
    });
  }
  */

  /*
    onTest(){
    let deci=2.975;
    let redo=this.math.RoundIt(deci,2);
    console.log(`${deci} ; ${redo} `);
  }
*/

/*
selectStore(e){
  //https://stackblitz.com/edit/angular-uzl2br-nepkqa?file=app.component.html
  //debugger
  //this.NameStore='';
  if (e.value) {
      let id=Number(e.value);
      this.grid.setCellValue(this.selItemNo, "WarehouseNo", id);
      //this.NameUnity=item.PackagingName;
  } else {
    this.grid.setCellValue(this.selItemNo, "WarehouseNo", this.Session_BodDefault);
  }

}

*/




/*----------------------------Probando Combo Asyn--------------------*/
/*
this.unityParams = {

  //params: {
  //  allowFiltering: true,
  //  dataSource: this.Unity,
  //  fields: { text: 'PackagingName', value: 'Id' },
  //  //actionComplete: () => false,
  //  query: new Query(),
  //  actionBegin : async () =>{
  //    //this.GetUnity(null);
  //  }
  //},

  create:(args)=>{ // { rowData: object, column: Column }
    console.log('create');
    this.selRowEdit=this.selRow;
    this.selItemNo=Number(args.data.Itemno);
    this.stateElem = document.createElement('input'); return this.stateElem;

  },
  read:()=>{return this.UnityObj.text;},
  destroy:()=>{
    if (this.UnityObj.value) {
      let Id=Number(this.UnityObj.value);
      let Obj_UnitySel:any=this.Unity.find(e => Number(e.Id) === Id)
      let item: Packaging = Obj_UnitySel;
      let Factor = 1;
      let Price = 0;
      let PriceType = 0;
      let Promotion = 0
      Factor = Number(item.Factor);
      Price = Number(item.Price);
      PriceType = Number(item.PriceType);
      //let Promotion = Number(this.Unity.find(e => e.Id === id).Promotion);
      Promotion = 0
      this.grid.setCellValue(this.selItemNo, "UnityId", Id);
      this.itemInvoice[this.selRowEdit]._PackagingSel=item;
      this.NameUnity=item.PackagingName;
      this.calculatePrice(Factor,Price,PriceType,Promotion);
    } else {
      this.grid.setCellValue(this.selItemNo, "UnityId", null);
    }
    this.UnityObj.destroy();
  },
  write:async () => {
    console.log('write');
    //let code = this.itemInvoice[this.selRow].Code;
    //this.GetUnity(null);
    this.NameUnity='';
      this.UnityObj = new DropDownList({
        //allowFiltering: true,
        dataSource: this.GetUnity(null),//   this.Unity,//new DataManager(this.Unity4 as unknown as Object[]), //new DataManager(this.Unity),//
        fields: { value: 'Id', text: 'PackagingName'},
        floatLabelType: 'Never',
        //autofill:true,
        change: (e) => {
        },
      });
      //this.UnityObj.dataBind();
      this.UnityObj.appendTo(this.stateElem);

 }
}
*/


/*
 GetUnity(event): Array<any> {
    if (this.invoice.Id_Prof>0){
      if (!this.itemInvoice[this.selRowEdit]._PackagingSel){
        this._productService.getproductByID(this.itemInvoice[this.selRowEdit].Code).subscribe(
          (prod:Product)=>{
            //debugger
            //https://es.stackoverflow.com/questions/175877/convertir-un-observable-a-un-array-local
            this.Unity=<any[]>prod.Packaging;
            return this.Unity;
            //event.column.edit.params.dataSource=this.Unity;
            console.log(this.Unity);
            console.log(event);
            this.itemInvoice[this.selRowEdit].Packaging=prod.Packaging;
            //this.itemInvoice[this.selRow].Price=prod.Price;
            this.itemInvoice[this.selRowEdit].ivainc=prod.ivainc;
            this.itemInvoice[this.selRowEdit].Price1=prod.Price1U;
            this.itemInvoice[this.selRowEdit].Price2=prod.Price2U;
            this.itemInvoice[this.selRowEdit].Price3=prod.Price3U;
            this.itemInvoice[this.selRowEdit].Price4=prod.Price4U;
            this.itemInvoice[this.selRowEdit].Price5=prod.Price5U;
            this.itemInvoice[this.selRowEdit].Price6=prod.Price6U;
            this.itemInvoice[this.selRowEdit].Price7=prod.Price7U;
            this.itemInvoice[this.selRowEdit].Price8=prod.Price8U;
          }
        );
      }else{
        this.Unity=<any[]>this.itemInvoice[this.selRowEdit].Packaging;
        return this.itemInvoice[this.selRowEdit].Packaging;
      }
    }else{
        this.Unity=<any[]>this.itemInvoice[this.selRowEdit].Packaging;
        return this.itemInvoice[this.selRowEdit].Packaging;
    }

  }
  openCmb(){
    console.log("open");
    //this.UnityCmb.focusIn();
  }
  public onCreate(args){
    //https://ej2.syncfusion.com/angular/documentation/drop-down-list/accessibility/
    console.log(args);
    //this.UnityCmb.focusIn();
  }
  */



    /*
  getMaxIdOLD(serie:number,punto:number){
    this._invoiceService.getSeries(this.DocType).subscribe((response : Array<typ_serieDoc>) => {
      if (response) {
        let list = response;

        for (let item of list){
          if (item.Serie==serie && item.Punto==punto){
            let FSerie='';
            FSerie = FSerie + item.Serie.toString().padStart(3,'000')
            FSerie = FSerie + '-' + item.Punto.toString().padStart(3,'000')
            FSerie = FSerie + '-' + item.Numero.toString().padStart(9,'000000000')

            this.SerieFormat=FSerie;
            this.maxID=item.MaxID.toString();
            this.SerieDoc={
              DocType     : this.DocType,
              Serie       : item.Serie,
              Punto       : item.Punto,
              Numero      : item.Numero,
              MaxID       : item.MaxID,
              id          : 0,
              formatSerie : FSerie

            }
            break;
          }
        }
      }
    });
  }
  */

















          /*
        this._SalesmansService.getListSalesmans().subscribe(
            (response:Array<Salesmans>) => {
              if (response != null) {
                this.listSales = response;
                this.invoice.Id_Vend=this.Session_Sales;
              }
          }, error => console.error('An error has occured'));

          this._cashService.getCashList(this.Session_User).subscribe((response : Array<typ_Cash>) => {
            if (response ) {
              this.listCash=response;
            }
          });
          this._bankService.getBankList().subscribe((response : Array<typ_Bank>) => {
            if (response ) {
              this.listBank=response;
            }
          });
          this._creditcardsService.getCreditCardList().subscribe((response : Array<typ_Creditcard>) => {
            if (response ) {
              this.listCreditCard=response;
            }
          });
          this._BankempService.getBanckEmp().subscribe((response : Array<typ_BankEmp>) => {
            if (response ) {
              this.listBankEmp=response;
            }
          });

        this._productService.getTaxesList().subscribe((response:any) => {
          if (response) {
            this.TaxesGen = response;
            for (let item of this.TaxesGen){
              let iTax: TaxBases = {
                StylexsaCode:item.StylexsaCode,
                Rate:item.Rate,
                Total:0,
                TIva:0
              }
              this.TaxesBases.push(iTax);
            }
            this.TaxesBases
          }
        });
        this._productService.getStoreList().subscribe((response:Array<typ_Warehouse>) => {
          if (response) {
            this.WarehouseGen = response;
            this.WarehouseGen.sort(function(a, b) {
              return a.Id - b.Id; // ||  a.name.localeCompare(b.name);
            });
            this.Session_BodNameDef=this.WarehouseGen.find(e => Number(e.Id) === this.Session_BodDefault).Name
            let StoreFilter=[];
            for (let bodAcc of this.Session_Bodegas){
              for (let bod of this.WarehouseGen){
                if (bod.Id==bodAcc){
                  StoreFilter.push(bod);
                }
              }
            }
            if (StoreFilter.length>0){
              this.WarehouseGen=StoreFilter;
            }
          }
          */














                    /*
          let SalesList$ : Observable<any> =this._SalesmansService.getListSalesmans();
          let CashList$ : Observable<any> =this._cashService.getCashList(this.Session_User);
          let BankList$: Observable<any> =this._bankService.getBankList();
          let CreditList$: Observable<any>=this._creditcardsService.getCreditCardList();
          let BankEmpList$: Observable<any>=this._BankempService.getBanckEmp();
          let TaxesList$: Observable<any>=this._productService.getTaxesList();
          let WarehouseList$: Observable<any>=this._productService.getStoreList();

         Observable.zip(SalesList$,CashList$,BankList$,CreditList$,BankEmpList$,TaxesList$,WarehouseList$)
          .subscribe(([SalesList, CashList,BankList,CreditList,BankEmpList,TaxesList,WarehouseList]) => {
            if (SalesList) { // response of SalesList
              this.listSales = SalesList as Array<Salesmans>;
              this.invoice.Id_Vend=this.Session_Sales;
            }
            if (CashList) { // response of CashList
              this.listCash=CashList as Array<typ_Cash>;
            }
            if (BankList) { // response of BankList
              this.listBank=BankList as Array<typ_Bank>;
            }
            if (CreditList) { // response of CreditList
              this.listCreditCard=CreditList as Array<typ_Creditcard>;
            }
            if (BankEmpList) { // response of BankEmpList
              this.listBankEmp=BankEmpList as  Array<typ_BankEmp>;
            }
            if (TaxesList) { // response of TaxesList
              this.TaxesGen = TaxesList as any;
              for (let item of this.TaxesGen){
                let iTax: TaxBases = {
                  StylexsaCode:item.StylexsaCode,
                  Rate:item.Rate,
                  Total:0,
                  TIva:0
                }
                this.TaxesBases.push(iTax);
              }
            }
            if (WarehouseList) { // response of WarehouseList
              this.WarehouseGen = WarehouseList as Array<typ_Warehouse>;
              this.WarehouseGen.sort(function(a, b) {
                return a.Id - b.Id; // ||  a.name.localeCompare(b.name);
              });
              this.Session_BodNameDef=this.WarehouseGen.find(e => Number(e.Id) === this.Session_BodDefault).Name
              let StoreFilter=[];
              for (let bodAcc of this.Session_Bodegas){
                for (let bod of this.WarehouseGen){
                  if (bod.Id==bodAcc){
                    StoreFilter.push(bod);
                  }
                }
              }
              if (StoreFilter.length>0){
                this.WarehouseGen=StoreFilter;
              }
            }
            console.log("Apis ZIP cargados...");
            resolve(true);
          });
          */

          /*
          let SalesList=this._SalesmansService.getListSalesmans();
          let CashList=this._cashService.getCashList(this.Session_User);
          let BankList=this._bankService.getBankList();
          let CreditList=this._creditcardsService.getCreditCardList();
          let BankEmpList=this._BankempService.getBanckEmp();
          let TaxesList=this._productService.getTaxesList();
          let WarehouseList=this._productService.getStoreList();
          forkJoin([SalesList, CashList,BankList,CreditList,BankEmpList,TaxesList,WarehouseList]).subscribe(response => {
          */
          /*
          let sources : Observable<any>[]  = [
            this._SalesmansService.getListSalesmans(),
            this._cashService.getCashList(this.Session_User),
            this._bankService.getBankList(),
            this._creditcardsService.getCreditCardList(),
            this._BankempService.getBanckEmp(),
            this._productService.getTaxesList(),
            this._productService.getStoreList()
          ];
          //forkJoin(sources).subscribe(([response1,response2,response3,response4,response5,response6,response7]) => {
          forkJoin(sources)
            .pipe(
              map(([SalesList,CashList,BankList,CreditList,BankEmpList,TaxesList,WarehouseList])=>{

                if (SalesList) { // response of SalesList
                  this.listSales = SalesList as Array<Salesmans>;
                  this.invoice.Id_Vend=this.Session_Sales;
                }
                if (CashList) { // response of CashList
                  this.listCash=CashList as Array<typ_Cash>;
                }
                if (BankList) { // response of BankList
                  this.listBank=BankList as Array<typ_Bank>;
                }
                if (CreditList) { // response of CreditList
                  this.listCreditCard=CreditList as Array<typ_Creditcard>;
                }
                if (BankEmpList) { // response of BankEmpList
                  this.listBankEmp=BankEmpList as  Array<typ_BankEmp>;
                }
                if (TaxesList) { // response of TaxesList
                  this.TaxesGen = TaxesList as any;
                  for (let item of this.TaxesGen){
                    let iTax: TaxBases = {
                      StylexsaCode:item.StylexsaCode,
                      Rate:item.Rate,
                      Total:0,
                      TIva:0
                    }
                    this.TaxesBases.push(iTax);
                  }
                }
                if (WarehouseList) { // response of WarehouseList
                  this.WarehouseGen = WarehouseList as Array<typ_Warehouse>;
                  this.WarehouseGen.sort(function(a, b) {
                    return a.Id - b.Id; // ||  a.name.localeCompare(b.name);
                  });
                  this.Session_BodNameDef=this.WarehouseGen.find(e => Number(e.Id) === this.Session_BodDefault).Name
                  let StoreFilter=[];
                  for (let bodAcc of this.Session_Bodegas){
                    for (let bod of this.WarehouseGen){
                      if (bod.Id==bodAcc){
                        StoreFilter.push(bod);
                      }
                    }
                  }
                  if (StoreFilter.length>0){
                    this.WarehouseGen=StoreFilter;
                  }
                }
                console.log("Apis cargados...");
                resolve(true);

              })
            ).subscribe()
            //if (response[4]) { // response of BankEmpList
              //  this.listBankEmp=response[4] as  Array<typ_BankEmp>;
              //}

          */



    //this.getSeries();





    /*
  loadDataCtrl(): Promise<boolean> {
     return new Promise((resolve)=> {
          this.listPrice = [
            { id : 0, name: 'P.V.P'},
            { id : 1, name: 'Precio 1' },
            { id : 2, name: 'Precio 2' },
            { id : 3, name: 'Precio 3' },
            { id : 4, name: 'Precio 4' },
            { id : 5, name: 'Precio 5' },
            { id : 6, name: 'Precio 6' },
            { id : 7, name: 'Precio 7' },
            { id : 8, name: 'Precio 8' }
          ];
          this._SalesmansService.getListSalesmans().subscribe(
            (response:Array<Salesmans>) => {
              if (response != null) {
                this.listSales = response;
                this.invoice.Id_Vend=this.Session_Sales;
              }
              this._cashService.getCashList(this.Session_User).subscribe((response : Array<typ_Cash>) => {
                if (response ) {
                  this.listCash=response;
                }
                this._bankService.getBankList().subscribe((response : Array<typ_Bank>) => {
                  if (response ) {
                    this.listBank=response;
                  }
                  this._creditcardsService.getCreditCardList().subscribe((response : Array<typ_Creditcard>) => {
                    if (response ) {
                      this.listCreditCard=response;
                    }
                    this._BankempService.getBanckEmp().subscribe((response : Array<typ_BankEmp>) => {
                        if (response ) {
                          this.listBankEmp=response;
                        }
                        this._productService.getTaxesList().subscribe((response:any) => {
                          if (response) {
                            this.TaxesGen = response;
                            for (let item of this.TaxesGen){
                              let iTax: TaxBases = {
                                StylexsaCode:item.StylexsaCode,
                                Rate:item.Rate,
                                Total:0,
                                TIva:0
                              }
                              this.TaxesBases.push(iTax);
                            }
                            this.TaxesBases
                          }
                          this._productService.getStoreList().subscribe((response:Array<typ_Warehouse>) => {
                            if (response) {
                              this.WarehouseGen = response;
                              this.WarehouseGen.sort(function(a, b) {
                                return a.Id - b.Id; // ||  a.name.localeCompare(b.name);
                              });
                              this.Session_BodNameDef=this.WarehouseGen.find(e => Number(e.Id) === this.Session_BodDefault).Name
                              let StoreFilter=[];
                              for (let bodAcc of this.Session_Bodegas){
                                for (let bod of this.WarehouseGen){
                                  if (bod.Id==bodAcc){
                                    StoreFilter.push(bod);
                                  }
                                }
                              }
                              if (StoreFilter.length>0){
                                this.WarehouseGen=StoreFilter;
                              }
                              console.log("Cascada de Subscribe OK");
                              resolve(true);

                            }
                            });
                        });
                    });
                  });
               });
              });
          });
     });
  }

    */
