<page-heading pageTitle="{{ titleComponent | translate}}">
  <div>
    <button *ngIf="dialogInstance" type="button" class="btn btn-primary" tabindex="-1"
      (click)="closeDialog()">
      <i class="ti-close"></i>
    </button>
  </div>
</page-heading>

<div class="ibox ">
  <div class="ibox-content">
    <div class="row">

    </div>
  </div>
</div>