import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { InvoiceItem } from '../models';
import { APP_SETTINGS } from '../appsettings';
import { CustomBaseCurrencyPipe } from './custom-base-currency.pipe';

// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({
  name: 'itemsTax'
})
export class ItemsTaxPipe extends CustomBaseCurrencyPipe {

  calculate(items: Array<InvoiceItem>, display: 'narrow' | 'wide') {
    if (items) {

      const result = [];

      items.forEach(function (a) {
        if ( !this[a.VAT.toString()]) {
            this[a.VAT.toString()] = { VAT: a.VAT, Subtotal : 0};
            result.push(this[a.VAT.toString()]);
        }
        this[a.VAT.toString()].Subtotal +=  Math.round(a.Subtotal*100)/100;
    }, Object.create(null));

    let tax =  0.00;

    result.forEach(item => {
      tax += this.Round(item.VAT * item.Subtotal /100,2);
    });

      return formatCurrency(tax,
        this.locale,
        getCurrencySymbol(APP_SETTINGS.formats.currencyCode, display),
        APP_SETTINGS.formats.currencyCode,
        APP_SETTINGS.formats.digitsInfo);
    } else {
      return null;
    }
  }

}
