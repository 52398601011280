import { IDocument } from '../IDocument';

export class CustomerLedgerReport extends IDocument {
        ID_Vend?: number;
        Califica?: number;
        Estado: string;
        Compania: string;
        TelefCasa: string;
        Direccion: string;
        Ci_Ruc: string;
        Ciudad: string;
        Total: number;
        ID_Doc?: number;
        Deuda?: number;
        Dias?: number;
        DiasVence?: number;
        Detail: string;
        Vence?: Date;
        Fecha: Date;
        Tipo: string;
        Telefono: string;
        Nombre: string;
        ID_Cliente: string;
        CodPos: string;
        DocType: DocTypeForReports;
}

export enum DocTypeForReports {
    Invoice = 0,
    CreditNote = 1,
    DebitNote = 2,
    Retention = 3,
    Gm = 4,
    Others = 5
}
