<page-heading pageTitle="Test" >
  <div class="title-action col-lg-1 col-sm-1 col-xs-1 pull-right" style="padding-right: 10px">
    <button  type="button"  class="btn btn-blue" style="padding: 6px; " tabindex="-1" (click)="closeDialog()">
      <i class="ti-close"></i>
    </button>
  </div>
</page-heading>

      <div class="row" style="margin-bottom: 5px;margin-top: 5px;">
          <div class="form-group">
            <div class="col-sm-12">
              <input #idInput (keydown)="onKeydown($event,idInput.value)" type="text" placeholder="Escriba la información a buscar.." class="input form-control"/>
            </div>
          </div>
      </div>

      <div class="row">
          <div class="col-sm-12">
            <input type="text"  style="width:100%" placeholder="Pick one" #text aria-label="Number" [formControl]="searchText" matInput [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith" showPanel="true" (optionsScroll)="onScroll()">
              <mat-option *ngFor="let option of filteredLookups$ | async" [value]="option">
                {{option.Name}}
              </mat-option>
            </mat-autocomplete>
            <br/> <br/>
          </div>
          
      </div>

      <div style="height: 240px;overflow:auto">
        <mat-selection-list>
          <mat-list-option  *ngFor="let item of items">
              {{ item }}
              <mat-divider></mat-divider>
          </mat-list-option>  
        </mat-selection-list> 
        </div>
        
      <div class="row">
          <div class="form-group p-h-sm text-right">
            <button mat-button  class="btn btn-yellow-lg" style="margin-right: 10px;" (click)="onAceptar()"> Aceptar </button>
            <button mat-button  class="btn btn-yellow-lg" style="margin-right: 20px;" (click)="onCancel()">  Cancelar </button>
          </div>
      </div>
