import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavigationService } from '../helpers/navigation.service';
import { SessionService } from '../helpers/session.service';
import { Observable } from 'rxjs';
import { Route } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminSalesmanGuardService implements CanActivate {

  constructor(private navigationService: NavigationService, private sessionService: SessionService, ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.sessionService.isAdminOrSalesman()) {
      return true;
    } else {
      this.navigationService.navigateToLogin();
      return false;
    }
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (this.sessionService.isAdminOrSalesman()) {
      return true;
    } else {
      this.navigationService.navigateToError();
      return false;
    }
  }
}
