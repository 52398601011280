<div class="form-group">
    <!--matAutocompleteTriggerAccessor [optionHeight]="48"-->
    
    <!-- -----------------Esto esta OK
    <ng-container *ngIf="isProccess" >
        <div class="iconoInput" *ngIf="isProccess">
            <i class="fa fa-cog fa-spin" 
            aria-hidden="true" 
            style="font-size: 16px; color: rgb(80, 95, 175);">
            </i>
            Loading..
        </div>
    </ng-container>
    -->

    <input 
        type="text"  
        style="width:100%;" 
        placeholder="Escriba la información a buscar.." 
        #text 
        class="form-control inputPadding"
        [formControl]="searchText" 
        aria-label="Number"
        matInput 
        [matAutocomplete]="auto">
    
    <!--showPanel="true" -->
    <mat-autocomplete 
        #auto="matAutocomplete" 
        [displayWith]="displayWith" 
        (keydown.arrowdown)="selectionChange($event)"
        showPanel="true"
        (keyup.enter)="chooseFirstOption()"
        (optionsScroll)="onScroll($event)"
        >
            <ng-container *ngIf="filteredLookups$">
                <!--
                <mat-option *ngFor="let option of filteredLookups$ | async" [value]="option" (onSelectionChange)="optionSelected($event)">
                        {{option.Name}}
                </mat-option>
                -->
                <mat-option *ngFor="let item of filteredLookups$ | async" [value]="item" (onSelectionChange)="optionSelected($event)">
                    <ng-template
                        [ngTemplateOutlet]="itemTemplate"
                        [ngTemplateOutletContext]="{ $implicit: item }">
                    </ng-template>
                </mat-option>                
            </ng-container>
            
    </mat-autocomplete>
</div>  
