import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanLoad, Route} from '@angular/router';
import { SessionService } from '../helpers/session.service';
import { NavigationService } from '../helpers/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class CanLoadProtectedComponentService implements CanLoad {

  constructor(private sessionService: SessionService, private navigationService: NavigationService) { }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (this.sessionService.isAdminOrSalesman()) {
      return true;
    } else {
      this.navigationService.navigateToError();
      return false;
    }
  }
}
