import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../helpers/data.service';
import { NavigationService } from '../helpers/navigation.service';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CurriculumGuard implements CanActivate {

  constructor(private dataService: DataService, private navigationService: NavigationService) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.dataService.employee) {
      if (this.dataService.employee.NationalID) {
        return true;
      } else {
        this.navigationService.navigateToPersonnelList();
        return false;
      }
    } else {
      this.navigationService.navigateToPersonnelList();
      return false;
    }
  }
}
