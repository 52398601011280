import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Groups } from '../../models/Sales/Groups';


@Injectable({
  providedIn: 'root'
})
export class GroupsService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }


  getListGroups() {
    const url = this.getApiQuery(`Customer/Grupos`);
    return this._httpClient.get<Groups[]> (url, { headers: this.getAuthorizationHeader() });

  }
}
