import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';
import { SessionService, } from '../helpers/session.service';
import { NavigationService } from '../helpers/navigation.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class CompanyService implements CanActivate {
  public listadoCompanys : boolean;
  public Session_MULTIADMIN : boolean=false;
  constructor(private _navigationService: NavigationService, private _sessionService: SessionService) { }

  canActivate() {
    this.Session_MULTIADMIN  = this._sessionService.getSessionPermision('MULTIADMIN');
    if (!this.Session_MULTIADMIN){
      this._navigationService.navigateToCompany(null);
    }

   return true;
  }
}
