import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class Dashboardsales extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }


  getVentaPie(From:string,To:string,Type:string,Top:string,Filter?:string) : Observable<any> {
    let params: HttpParams = new HttpParams();

    if (From) { params = params.append('From',From); }
    if (To) { params = params.append('To',To); }
    if (Type) { params = params.append('Type',Type); }
    if (Top) { params = params.append('Top',Top); }
    if (Filter) { params = params.append('Filter',Filter); }

    let url = this.getApiQuery(`DASHBOARD/GetVentaPie`);
    return this._httpClient.get<any>(
        url,
        {
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }


  getVentaTopTable(From:string,To:string,Type:string,Top:string,Filter?:string) : Observable<any> {
    let params: HttpParams = new HttpParams();

    if (From) { params = params.append('From',From); }
    if (To) { params = params.append('To',To); }
    if (Type) { params = params.append('Type',Type); }
    if (Top) { params = params.append('Top',Top); }
    if (Filter) { params = params.append('Filter',Filter); }

    let url = this.getApiQuery(`DASHBOARD/GetVentaTopTable`);
    return this._httpClient.get<any>(
        url,
        {
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }

  GetVentaTopVendTable(From:string,To:string,Type:string,Top:string,Filter?:string) : Observable<any> {
    let params: HttpParams = new HttpParams();

    if (From) { params = params.append('From',From); }
    if (To) { params = params.append('To',To); }
    if (Type) { params = params.append('Type',Type); }
    if (Top) { params = params.append('Top',Top); }
    if (Filter) { params = params.append('Filter',Filter); }

    let url = this.getApiQuery(`DASHBOARD/GetVentaTopVendTable`);
    return this._httpClient.get<any>(
        url,
        {
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }

  GetVentaTopCliTable(From:string,To:string,Type:string,Top:string,Filter?:string) : Observable<any> {
    let params: HttpParams = new HttpParams();

    if (From) { params = params.append('From',From); }
    if (To) { params = params.append('To',To); }
    if (Type) { params = params.append('Type',Type); }
    if (Top) { params = params.append('Top',Top); }
    if (Filter) { params = params.append('Filter',Filter); }

    let url = this.getApiQuery(`DASHBOARD/GetVentaTopCliTable`);
    return this._httpClient.get<any>(
        url,
        {
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }

  GetVentaCards(From:string,To:string,Top:string,Filter?:string) : Observable<any> {
    let params: HttpParams = new HttpParams();

    if (From) { params = params.append('From',From); }
    if (To) { params = params.append('To',To); }
    if (Top) { params = params.append('Top',Top); }
    if (Filter) { params = params.append('Filter',Filter); }

    let url = this.getApiQuery(`DASHBOARD/GetVentaCards`);
    return this._httpClient.get<any>(
        url,
        {
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }

  GetVentaPedidoBar(From:string,To:string,Type:string,Top:string,Filter?:string) : Observable<any> {
    let params: HttpParams = new HttpParams();

    if (From) { params = params.append('From',From); }
    if (To) { params = params.append('To',To); }
    if (Type) { params = params.append('Type',Type); }
    if (Top) { params = params.append('Top',Top); }
    if (Filter) { params = params.append('Filter',Filter); }

    let url = this.getApiQuery(`DASHBOARD/GetVentaPedidoBar`);
    return this._httpClient.get<any>(
        url,
        {
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }

  GetVentaDiaBar(From:string,To:string,Type:string,Top:string,Filter?:string) : Observable<any> {
    let params: HttpParams = new HttpParams();

    if (From) { params = params.append('From',From); }
    if (To) { params = params.append('To',To); }
    if (Type) { params = params.append('Type',Type); }
    if (Top) { params = params.append('Top',Top); }
    if (Filter) { params = params.append('Filter',Filter); }

    let url = this.getApiQuery(`DASHBOARD/GetVentaDiaBar`);
    return this._httpClient.get<any>(
        url,
        {
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }


}
