export class Packaging {
    Id: number;
    Code: string;
    PackagingName: string;
    Codebar: string;
    Factor: number;
    PriceType: number;
    Price: number;
    Discount: number;
    MaxDiscount: number;
    MinDiscount: number;
}
