import { Component, OnInit, ViewChild, OnDestroy, AfterContentInit, Input, Injectable, Injector } from '@angular/core';
import { DataService, LazyLoaderService, NavigationService } from '../../../services';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Navigation } from '../../../helpers';
import { BtnReturnComponent } from '../../widgets/btn-return/btn-return.component';
import { MatDialogRef } from '@angular/material/dialog';
import { EmployeeRecord } from '../../../models/RRHH/EmployeeRecord';
import { IDocument } from 'src/app/models';
import { Location } from '@angular/common';
import { BaseService } from 'src/app/services/base/base.service';
import { HttpParams } from '@angular/common/http';
import { paramsViewer } from '../../widgets/viewer-modals/viewer-modals.component';

//import { Stimulsoft } from '../../../../assets/js/stimulsoft.viewer.pack';

@Injectable({
  providedIn: 'root'
})
export class DataMRTService extends BaseService {
  public dataReport:any;
  public paramsViewer:paramsViewer;
  constructor(private _injector: Injector) {
    super(_injector);
    
  }

  public getDataAPi()  {
    let url:string=this.paramsViewer.url;
    let prmsFilter:object=this.paramsViewer.aditionalFilters;
    let params: HttpParams = new HttpParams();
    if (prmsFilter){
      for (let ifield in prmsFilter){
        params = params.append(ifield ,prmsFilter[ifield]);
      }
    }

    url = this.getApiQuery(url);
    return this._httpClient.get(url, 
        {
            headers: this.getAuthorizationHeader(), 
            params: params
        });
  }
}
  



declare var Stimulsoft: any;

@Component({
  selector: 'viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css']
})

  export class ViewerComponent implements OnInit, OnDestroy, AfterContentInit {

    //@Input() params : paramsViewer;
    //@Input() dialogInstance: MatDialogRef<ViewerComponent, any>

    //viewer: any = null;
    //report: any = null;
    title: string;
    modal:boolean;
    private ngUnsubscribe = new Subject();

    public dataReport: any=null;
    public params : paramsViewer;
    public viewer;
    public report;
    public conData:boolean=false;
    public isOnBack: boolean=true;

    navigation: Navigation;
    @ViewChild('returnBtn') btn: BtnReturnComponent;

    private readonly reportsJsUrl = '../../../../assets/js/stimulsoft.reports.pack.js';
    private readonly viewerJsUrl = '../../../../assets/js/stimulsoft.viewer.pack.js';

    constructor(private lazyLoaderService: LazyLoaderService,
                private route: ActivatedRoute,
                private navigationService: NavigationService,
                private _dataService: DataService,
                private _data: DataMRTService,
                private location: Location) {

                //this.dataReport = this._dataService.dataReport as IDocument;
                this.dataReport = this._data.dataReport as IDocument;
                
                this.params=this._data.paramsViewer;
                //console.log(this.params);
                this.title=this.params.title;
                if (!this.params.title){
                  this.title="Export Report";
                }
                this.modal=this.params.modal;
                this.isOnBack=this.params.onBack;
                /*
                this.dataReport=null;
                this._data.getDataAPi(this.params.url,this.params.aditionalFilters).subscribe((response:any) => {
                  if (response != null) {
                    this.conData=true;
                    
                    this.dataReport = response as IDocument;
                    this.renderReport();
                  }
                });
                */
              }

    ngOnInit() {
      //this.navigation = this.navigationService.getNavigationItem('viewer');
      // Cargando los scripts de stimulsoft que serán obtenidos por lazy loading

      /*

      this.lazyLoaderService.loadScript(this.reportsJsUrl, 'reports')
      .then(() => {})
      .catch(() => {});

      this.lazyLoaderService.loadScript(this.viewerJsUrl, 'viewer')
      .then(() => this.renderReport())
      .catch(() => {});
      */
      
      this.renderReport();
      
    }

    ngAfterContentInit() {
      //this.btn.navigation = this.navigation;
      /*
      this._data.getDataAPi(this.params.url,this.params.aditionalFilters).subscribe((response:any) => {
        if (response != null) {
          this.dataReport = response as IDocument;

          const data = JSON.stringify(this.dataReport);
          const dataSet = new Stimulsoft.System.Data.DataSet('data');
          dataSet.readJson(data);//EmployeeRecord);
    
          this.report.regData(dataSet.dataSetName, dataSet.dataSetName, dataSet);
          this.report.dictionary.synchronize();
          this.report.renderAsync();
          this.report.render();
        }
      });
      */
    }

    ngOnDestroy() {
      //this.lazyLoaderService.removeScript('reports');
      //this.lazyLoaderService.removeScript('viewer');

      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }

    onBack(){
      //this._navigationService.navigateToAllInvoices();
      this.location.back();
    }

    renderReport() {
      // const key = this.route.snapshot.data.settings.stimulsoft_Key;
      // Stimulsoft.Base.StiLicense.Key = key ? key : '';

      const key = '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHmS44uBPQ+lBz6gKY7P+72PalJ3IuKZGV1qoMjJyP7LWS8ObT' +
        'S6QkyMz3OoZ2iLR/1IYrXF7ixYijvuJMKdOKTENLG+CYAYQ5X+zMKC0Q+yMHPzRa+Dkp335T5LquJmorIfI3BGiIcD' +
        'MVzSjsBWqCJZ1x0n7gqlus+qr7jU3oldJ1thsM7H80dxlJgua+mbgwAlOMsxb3+4Q8N3qF35C+eiGe1Or4vSnN4sUa' +
        'bqMeRU/gLkTX5uF/npWu1R0X0CGjzI21dEJsGqerwYNbqcj+W/7chRyrGN0cQ8lKjj5A/kaM6zTu1z4POWHM+m9uik' +
        'kyaJVuadI6155NMpG9WJpqrV2WAOFUoOIvBHJwhblDlFKRH3jWugyJV3AmWvvqlV56JTPfYMExw5q8kWV/QIpIYcMZ' +
        'QMlTs5+h3VjwQ/T1IxDrmCu5fTmg5sOqmwHMkc/t6+XkpdfHg6zz1YAT6ADnnK0cf2/TYGZjPaw3ADGXUNbeIe9hcf' +
        '7qR+BgzUfAHJNUu6qHqm2Q9YgkIrbIkTAllvxxkLX6zNUwNPXDKkmlOCH//T3191+IjCWq9ODuloZBWNMs/47Ow/53' +
        'FMBCl9wakE3VTP+hvbZB0MUxyo09SwC57xCtaVDXIujrkJzDhBSi/ZiNWRrBG4AP+PEKu/Dq/IFxwPPMY68EG4xeNV' +
        'kGF4k0syYIdpILBOQPbqSSSpm+SqXUD/gfkUGDqfsGAOMn9Otb2zqjTEoHp06Lmy/72/Kr/KQPC2LzhhSN1mI253Z2' +
        'w=';
        Stimulsoft.Base.StiLicense.Key = key ? key : '';

      //const EmployeeRecord: EmployeeRecord = this.route.snapshot.data.employeeRecord;

      const options = new Stimulsoft.Viewer.StiViewerOptions();
      // options.toolbar.visible = false;
      options.toolbar.showAboutButton = false;
      options.toolbar.showBookmarksButton = false;
      options.toolbar.showButtonCaptions = false;
      options.toolbar.showDesignButton = false;
      options.toolbar.showEditorButton = false;
      options.toolbar.showFindButton = false;
      options.toolbar.showFullScreenButton = false;
      options.toolbar.showMenuMode = false;
      options.toolbar.showOpenButton = false;
      options.toolbar.showParametersButton = false;
      options.toolbar.showZoomButton = false;
      
      this.viewer = new Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
      this.viewer.renderHtml('idviewer');
      
      this.report = new Stimulsoft.Report.StiReport();
      //report.loadFile('./assets/reports/HojaVida.mrt');
      let n='';
      if (this.params.newGUID) {
        n = Stimulsoft.System.Guid.newGuid().toString().substr(0, 8);
      }
      this.report.loadFile(this.params.templateMRT+ "?n=" + n);
      this.viewer.report = this.report;

      this.report.dictionary.clear();
      
      const dataSet = new Stimulsoft.System.Data.DataSet('data');
      //asi se va a llamar en el reporte el dataset que viene
      
      const data = JSON.stringify(this.dataReport);
      dataSet.readJson(data);//EmployeeRecord);


      this.report.regData(dataSet.dataSetName, dataSet.dataSetName, dataSet);
      this.report.dictionary.synchronize();

      //this.report.renderAsync();
      
    }

  }
