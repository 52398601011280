export class InvoiceReport {
    CeditDays: number;
    Date: Date;
    Detail: string;
    Expire: Date;
    ExpireDays: number;
    InvoiceId: number;
    SalesManId: number;
    Series: string;
    TaxId: string;
    ToPay: number;
}
