
    export class EquipmentModel {
      EquipmentModelID: number;
      Name: string;
      Description: string;
      Selected: boolean;
      ReferenceCost: number;
      Info: string;
      MaintenanceCycle: number;
      Blocked: boolean;
      Manufacturer: string;
      UserID: number;
  }
