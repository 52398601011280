import { Component, OnInit, Input, Output, EventEmitter, AfterContentInit, OnDestroy, ViewEncapsulation, Injector, Injectable, ViewChild, ElementRef, ViewChildren, QueryList, ContentChild, TemplateRef } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { BaseService } from '../../../services/base/base.service';
import { HttpParams } from '@angular/common/http';

import { fromEvent, Observable, Subject, Subscription, observable, BehaviorSubject, Subscriber } from 'rxjs';
import {  of,  merge, combineLatest } from 'rxjs';
import { map, startWith, switchMap, tap, debounceTime, filter, scan, withLatestFrom, mergeMap, takeUntil, takeWhile, distinctUntilChanged, skipUntil, exhaustMap, endWith, catchError, finalize, take, first } from 'rxjs/operators';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
import { MatAutocompleteTrigger, MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { FocusOrigin } from '@angular/cdk/a11y';
import { WaitService } from '../../widgets/wait/wait.service';

export interface ILookup {
  Id: number,
  Name: string
}

@Injectable({
  providedIn: 'root'
})
export class DatagridService extends BaseService {
  constructor(private _injector: Injector) {
    super(_injector);
  }

  public getDataAPi(skip: any,url:string,filter? : string)  {

    let params: HttpParams = new HttpParams();
    if (filter)     { params = params.append('SearchTerm',filter); }
    params = params.append('PageNumber',skip.toString());
    params = params.append('PageSize','20'); 
    url = this.getApiQuery(url);
    return this._httpClient.get(url, {headers: this.getAuthorizationHeader(), params: params}).pipe(map((data: any) => {		
        return data;
      /*
      return  {
         result: data.Result.map((reg:any)=>{
          return {
            id: reg.Id,
            name:reg.Name,
          }
        })
      }
      */

    }))
  }
}



@Component({
  selector: 'search-complete',
  templateUrl: './search-complete.component.html',
  styleUrls: ['./search-complete.component.css'],
  //encapsulation: ViewEncapsulation.Emulated
})
export class SearchCompleteComponent implements OnInit, AfterContentInit, OnDestroy {
  
  @ViewChild('auto', { read: MatAutocomplete })  matautoComplete: MatAutocomplete;
  @ViewChild('text',{static: false}) input: ElementRef;

  @ContentChild(TemplateRef,{static: false}) itemTemplate: TemplateRef<any>;
  
  @Input() Api : string;
  @Input() Width : string;
  @Output() Select = new EventEmitter<object>();

  private ngUnsubscribe     = new Subject();
  public  filter            : string='';
  public  searchText        = new FormControl({ Id: 0, Name: '' });
  public  filteredLookups$  : Observable<ILookup[]>;
  private nextPage$         = new Subject();
  public  current           : number=0;
  public  isProccess        : boolean=false;
  public  pos               : Array<any>=[];


  constructor(private builder: FormBuilder,
             private _data: DatagridService,
             private waitService: WaitService) {
            }

  ngAfterContentInit() {
  }

  ngOnInit() {
    this.filter='';
    this.init();
    console.log(this.filteredLookups$);
  }
  init(){
    const filter$ = this.searchText.valueChanges.pipe(
      startWith(''),
      debounceTime(1000),
      filter(q => typeof q === "string"));

    this.filteredLookups$ = filter$.pipe(
      switchMap(filter => {
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          
          exhaustMap(query => this.getData(filter, currentPage).pipe(
            map(response => response!=null ? response.Result:[]),
            
            tap(() => {
              currentPage++;
              console.log('Begin api');
              if (!this.isProccess) {
                if (currentPage>2){
                  this.waitService.Show(`Loading..`);
                }
              }
              this.isProccess=true;
            }),
              
            catchError(error => of(console.log(error))),
            finalize(() => {
              console.log('end api');
              let d:FocusOrigin;
              let f:FocusOptions={
                preventScroll:true
              }
              window.setTimeout(() => {
                if (currentPage==2){
                  this.current=256;
                  this.matautoComplete._keyManager.setActiveItem(0);
                  this.matautoComplete._setScrollTop(0);
                  if (currentPage>2) this.waitService.close();
                } else {
                  let index=((currentPage-2)*16);
                  this.matautoComplete._keyManager.setActiveItem(index);
                  this.input.nativeElement.focus();
                  
                  this.matautoComplete._setScrollTop(this.pos[0]-(48));
                  this.pos=[];
                  if (currentPage>2) this.waitService.close();
                }
                this.isProccess=false;
                
              }, 500);
              //this.waitService.close();
            }))
            
          ),
          
          takeWhileInclusive(p => p!=null?  p.length > 0:false),
          scan((allData, newItems) => allData.concat(newItems),[] ),
          
        );
      })); 

  }
  displayWith(lookup) {
    return lookup ? lookup.Name : null;
  }

  onScroll(event) {
    console.log('scroll');
    this.current = (event.scrollEvent.target as HTMLInputElement).scrollTop  + (event.scrollEvent.target as HTMLInputElement).clientHeight;
    this.pos.push(this.current);
    this.nextPage$.next();
  }
  private getData(startsWith: string, page: number):Observable<any> { //: Observable<ILookup[]>
    const take = 20;
    const skip = page;
    return this._data.getDataAPi(skip,this.Api,startsWith);
  }
  optionSelected($event: MatAutocompleteSelectedEvent): void {
    //console.log('event -->', $event);
    if ($event['isUserInput']){
      //console.log($event.source['value']);
      
      if ($event.source['value']){
        this.Select.emit($event.source['value']);
      }else {
        this.Select.emit(null);
      }
    }else {
      this.Select.emit(null);
    }
  }
  selectionChange(event){
    console.log('selection changed using keyboard arrow');
  }
  chooseFirstOption(): void {
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
