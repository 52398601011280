import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { forkJoin, Observable, Subject } from 'rxjs';
import { DocFile, refDocAnex } from 'src/app/models/system/doc_anex';
import { DocAnexService } from 'src/app/services/system/doc_anex.service';
import { FileService, SweetAlertService } from '../../../../app/services';
import { APP_SETTINGS } from '../../../appsettings';
import { Guid } from 'src/app/models';

const URL = `${APP_SETTINGS.endpoints.image_upload_service}`;

@Component({
  selector: 'upload-allfiles',
  templateUrl: './upload-allfiles.component.html',
  styleUrls: ['./upload-allfiles.component.css']
})
export class UploadallfilesComponent implements OnInit {
  @ViewChild('imagePreview') imagePreview: ElementRef;

  @Input() Api : string;
  @Input() Width : string;
  @Input() Height : string;
  @Input() ControlEnabled : string;
  @Input() MaxSize : number;

  private ObjSubjectRx$: Subject<any> = new Subject<any>();

  isLoading: boolean = false;
  isChecking: boolean = false;
  isForSell: boolean = false;
  isSubmitted = false;
  imgPlaceholder: string | ArrayBuffer = "assets/images/placeholder-image.png";

  //imagesUrls = '';
  public imagesUrls : string[] = [];
  public doc_Anex : DocFile[] = [];
  public doc_AnexDown : DocFile[] = [];

  public uploader: FileUploader;
  public allowedMimeType = [
    'image/jpeg', 
    'image/png',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'audio/x-mpeg',
    'audio/mp3',
    'audio/ogg',
    'video/mp4',
    'video/avi',
    'video/x-ms-asf',
    'audio/mpeg',
    'application/x-shockwave-flash',
  ];
  public style:string;
  public HeightImg:string;
  public response:string;
  public imageUrl: string | ArrayBuffer;
  public errorInFiles:boolean=false;

  constructor(
    private _filesService: FileService,
    private swalService: SweetAlertService,
    private _DocAnexService:DocAnexService,) {

    this.style='150px;';
    this.imageUrl= this.imgPlaceholder;
  }
  ngOnInit() {
    this.HeightImg=`calc(${this.Height} - 65px)`;
    this.setupFileUpload();
  }

  ngAfterViewInit() {
  }

  setupFileUpload() {
    this.uploader = new FileUploader({
      url: URL,
      allowedMimeType: this.allowedMimeType,
      maxFileSize: this.MaxSize  //1*1024*1024 //1MB
    });

    this.uploader.onWhenAddingFileFailed = (item, filter) => {
        let message = '';
        switch (filter.name) {
          case 'fileSize':
            message = 'Warning ! \nThe uploaded file \"' + item.name + '\" is ' + this.formatBytes(item.size) + ', this exceeds the maximum allowed size of ' + this.formatBytes(1024);
            break;
          default:
            message = 'Error trying to upload file '+item.name;
            break;
        }
        this.swalService.getInstance(message, 'error').then(() => {});
    };

  }

  formatBytes(bytes, decimals?) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  loadFileOLD(Urls:DocFile[]){
    //console.log(Urls);
    //const urls = Urls.split(';');
    //urls.pop();

    //const observables = urls.map(url => this._filesService.getBlobThumbnail(url));
    const observables = Urls.map(url => this._filesService.getBlobThumbnail(url.DocUrl));
    const source = forkJoin(observables);
    const files: Array<File> = [];
    //const files: Array<FileUploader> = [];

    source.subscribe(
      response => {
        response.forEach((blob, index) => {

          const fullName = Urls[index].DocUrl;
          //const name = Urls[index].Filename; //fullName.split('/');
          const name = fullName.split('/').pop();
          const file = new File([blob], name, { type: 'image/jpeg' });
          /*
          const file = new FileUploader({
            url: fullName,
            autoUpload: true,
            //authToken: 'Bearer ' + token
          });
          */
          files.push(file);
        });
      },
      err => { },
      () => {
        //console.log(files);
        //this.uploader.addToQueue(files);
        this.uploader.addToQueue(files, { autoUpload: false });
      }
    );

  }
  

  uploadFile(folder:string,refDoc?:refDocAnex) : Observable<any>{
    const fileList = this.uploader.queue.filter(file=>file.file.size>4);
    /*
    console.log(fileList);
    console.log(fileList.length);
    
    for (let f of this.uploader.queue){
      console.log(f.file.name+'-'+f.isUploaded)
    }
    */
    fileList.forEach(function (fileItem) {
      fileItem.withCredentials = false;
    });
    if (fileList.length > 0) {
      this.errorInFiles = true;
      this.uploader.setOptions({
        additionalParameter: { folderName: folder }
      });
      //this.uploader.uploadAll();

      for (let f of this.uploader.queue){
        if (f.file.size>4){
          this.uploader.uploadItem(f);
        }
      }
  

      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        
        const obj = JSON.parse(response);
        //console.log(obj);
        //console.log(item);
        //console.log(headers);
        if (!obj.error) {
          //this.imagesUrls = this.imagesUrls.concat(obj.url.toLowerCase(), ';');

          this.imagesUrls.push(obj.url.toLowerCase());
          let itemAnex : DocFile = {
            Id        : refDoc.Id,
            Type      : refDoc.Type,
            SubType   : refDoc.SubType,
            Reference : refDoc.Reference,
            Guid      : refDoc.Guid,
            Filename  : item.file.name,
            FileType  : item.file.type,
            DocUrl    : obj.url.toLowerCase(),//item.url,
            Metadata  : null
          }
          if (refDoc.UniqueGuid == true) {
            itemAnex.Guid = Guid.shortNewGuid().toUpperCase()
          }
          this.doc_Anex.push(itemAnex);

          this.errorInFiles = false;
        }
      };
      this.uploader.onCompleteAll = () => {
        let response={
          success : !this.errorInFiles,
          files   : this.imagesUrls,
          docAnex : this.doc_Anex
        }
        this.ObjSubjectRx$.next(response);
      }
    } else {
      let response={
        success:true,
        files:this.imagesUrls,
        docAnex : this.doc_Anex
      }
      window.setTimeout(() => {
        this.ObjSubjectRx$.next(response);
      }, 0);
    }
    return this.ObjSubjectRx$.asObservable();
  }
  public onComplete(): Observable<any>{
    return this.ObjSubjectRx$.asObservable();
  }
  removeFile() {
    if (this.uploader.queue.length > 0) {
      this.uploader.removeFromQueue(this.uploader.queue[0]);
    }
    this.imagePreview.nativeElement.src = this.imgPlaceholder;
  }
  loadFile(Urls:DocFile[]){
    //console.log(Urls);
    this.doc_AnexDown=Urls;
    const files: Array<File> = [];
    for (let item of Urls){
      const fullName = item.DocUrl;
      const name = fullName.split('/').pop();
      const file = new File(["load"], name, { type: 'image/jpeg' });
      
      files.push(file);
    }
    this.uploader.addToQueue(files, { autoUpload: false });

  }
  clear(){
    this.uploader.clearQueue()
  }
  downloadFile(item){
    //console.log(item);
    const name=this.doc_AnexDown[item].DocUrl;
    //const name = item.file.name.split('/').pop();
    //console.log(name);
    const observables = this._filesService.getBlobThumbnail(name);// this.doc_AnexDown[index].DocUrl);
    const source = forkJoin(observables);
    const files: Array<File> = [];

    source.subscribe(
      response => {
        response.forEach((blob, index) => {

          //const fullName = this.doc_AnexDown[index].DocUrl;
          //const name = fullName.split('/').pop();
          //console.log(name);
          const file = new File([blob], name, { type: 'image/jpeg' });
          //files.push(file);
          //var downloadURL = window.URL.createObjectURL(blob); 
          //window.open(downloadURL);

          let link = document.createElement('a');
          link.target = '_blank';
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", name.split('/').pop());
          link.click()


        });
      },
      err => { },
      () => {
        //this.uploader.addToQueue(files, { autoUpload: false });
      }
    );
  }

  saveFiles(docAnex:any){
    return this._DocAnexService.updateFiles(docAnex);
  }

  readFiles(Origen: string,Reference:any){

    this._DocAnexService.loadFiles(Origen,Reference)
      .subscribe((response:Array<DocFile>) => {
        if (response) {
          //console.log(response);

          this.loadFile(response);

          /*
          let itemAnex : DocFile = {
            Id        : 772,
            Type      : 'SR',
            SubType   : '',
            Reference : '5205',
            Guid      : '',
            Filename  : 'Fact 001010000004451.pdf',
            FileType  : 'app',
            DocUrl    : '../uploads/5205/fact 001010000004451.pdf',
            Metadata  : '../uploads/5205/fact 001010000004451.pdf'
          }
          let docAnex0: Array<DocFile>=[];
          docAnex0.push(itemAnex);

          this.loadFile(docAnex0);
          */
        }
    });

  }

}
