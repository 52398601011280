  <div class="ibox-title">
    <h5>{{data.title}}</h5>
    <!--
    <div class="pull-right">
      <div class="btn-group">
        <button type="button" class="btn btn-xs btn-white active">Today</button>
        <button type="button" class="btn btn-xs btn-white">Monthly</button>
        <button type="button" class="btn btn-xs btn-white">Annual</button>
      </div>
    </div>
    -->
  </div>
  <div class="ibox-content">
    <chart-scrollable [step]="6" [axis]="'x'">
      <canvas 
          baseChart 
          height="280"
          [datasets]="barChartData" 
          [labels]="barChartLabels" 
          [options]="barChartOptions" 
          [legend]="barChartLegend"
          [plugins]="barChartPlugins"
          [chartType]="barChartType" 
          (chartHover)="chartHovered($event)" 
          (chartClick)="chartClicked($event)">
      </canvas>
    </chart-scrollable>    
  </div>



