import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
//import { Company } from '../../models/system/company';

import { Observable } from '@angular-devkit/core/node_modules/rxjs';
import { HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class InventoryService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }
  PagosService(){

  }

  createRecord(record: any) {
    console.log('Ok Grabando..')
    console.log(record);
    const url = this.getApiQuery('crm/create');
    return this._httpClient.post(
      url,
      record,
      { headers: this.getAuthorizationHeader() }
    );
  }

  updateRecord(record: any) {
    const url = this.getApiQuery('Inventory/InventoryInputOutput');  
    return this._httpClient.post(
      url,
      record,
      { headers: this.getAuthorizationHeader() }
    );
  }

  getRecord(Id:number) : Observable<any> {
    let params: HttpParams = new HttpParams();
      
    if (Id) { params = params.append('Id',Id.toString()); }

    const url = this.getApiQuery(`Inventory/GetInventoryAdjustmentById`);
    
    return this._httpClient.get<any>(
        url,
        { 
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }

  getList(PageNumber: number, PageSize:number,From:string,To:string, DocId:string,Filter:string) : Observable<any[]> {
      let params: HttpParams = new HttpParams();
      
      if (DocId) { params = params.append('DocId',DocId.toString()); }
      if (From) { params = params.append('From',From.toString()); }
      if (To)   { params = params.append('To',To.toString()); }
  
      if (PageNumber) { params = params.append('PageNumber',PageNumber.toString()); }
      if (PageSize)   { params = params.append('PageSize',PageSize.toString()); }

      if (Filter){ params = params.append('filter',Filter.toString()); }

      let url = this.getApiQuery(`Inventory/InventoryAdjustment/Pagging`);
      return this._httpClient.get<any[]>(
          url,
          { 
            params: params,
            headers: this.getAuthorizationHeader()
          }
      );
    }

    getProdList() {
      let params: HttpParams = new HttpParams();
  
      //if (userId) { params = params.append('idUsuario',userId); }
  
      const url = this.getApiQuery(`Inventory/GetProductsList`);
      return this._httpClient.get(url, { 
        params: params,
        headers: this.getAuthorizationHeader() 
      });
    }
  
  
  
}
