<nav class="navbar-default navbar-static-side" role="navigation">
  <div class="sidebar-collapse">
    <ul class="nav metismenu" id="side-menu">
      <li class="nav-header">
        <div class="dropdown profile-element text-center">
          <span class="clear">
            <h4 class="text-warning m-b-md">
              {{CompanyName | titlecase}}
            </h4>
            <span class="block m-t-xs"> <span class="username-label">{{username}}</span>
            </span>
            <!-- <span class="text-xs block">{{userRole | translate}} -->
            <span class="text-xs block">{{'Welcome' | translate}}</span>
          </span>
        </div>
      </li>
      <h6 class="text-right bg-success no-margins p-b-xs p-w-xs">Ver. {{version}}</h6>

      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a [routerLink]="['/app/home']" (click)="clickHome($event, 'Home')">
          <i class="fa fa-th-large"></i>
          <span class="nav-label">{{'Home' | translate}}</span>
        </a>
      </li>

      
      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
        *ngFor="let item of parentItems; trackBy: trackById">

        <ng-container *ngIf="item.Children && item.Children.length > 0; else noChildren">
          <a>
            <i [ngClass]="item.Icon ? item.Icon : 'fa fa-file'"></i>
            <span class="nav-label">{{ item.Name | translate}}</span>
            <span class="fa arrow"></span>
          </a>

          <ul class="nav nav-second-level collapse" routerLinkActive="in" aria-expanded="true">
            <ng-container *ngFor="let itemChild of item.Children">
              <li routerLinkActive="active">
                <a class="li-children"  (click)="clearSelectedEmployee()"
                  routerLink="/app/{{itemChild.Area?.toLowerCase()}}/{{itemChild.Controller?.toLowerCase()}}">
                  {{ itemChild.Name | translate}}</a>


                  <ul class="nav nav-third-level collapse" routerLinkActive="in">
                    <ng-container *ngFor="let itemChild2 of itemChild.Children">
                      <li routerLinkActive="active">
                        <a class="li-children" role="menu-item" (click)="clearSelectedEmployee()"
                          routerLink="/app/{{itemChild2.Area?.toLowerCase()}}/{{itemChild2.Controller?.toLowerCase()}}">
                          {{ itemChild2.Name | translate}}</a>
                      </li>
                    </ng-container>
                  </ul>
                  
                  
              </li>
            </ng-container>
          </ul>

        </ng-container>
        <ng-template #noChildren>
          <a routerLink="/app/{{item.Area?.toLowerCase()}}/{{item.Controller?.toLowerCase()}}">
            <i [ngClass]="item.Icon ? item.Icon : 'fa fa-file'"></i>
            <span class="nav-label">{{ item.Name | translate}}</span>
          </a>
        </ng-template>
      </li>
      

      <!--
      <li  *ngFor="let item of parentItems">
        <a aria-expanded="false">
          <i [ngClass]="item.Icon ? item.Icon : 'fa fa-file'"></i>
          <span class="nav-label">{{ item.Name | translate}}</span><span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" aria-expanded="false" >
            <li  *ngFor="let itemChild of item.Children">
              <ng-container *ngIf="itemChild.Children && itemChild.Children.length > 0; else noChildren">
                  <a  aria-expanded="false">{{ itemChild.Name | translate}}<span class="fa arrow"></span></a>
                  <ul class="nav nav-third-level collapse" aria-expanded="false" >
                      <li *ngFor="let itemChild2 of itemChild.Children">
                          <a routerLink="/app/{{itemChild2.Area?.toLowerCase()}}/{{itemChild2.Controller?.toLowerCase()}}">{{ itemChild2.Name | translate}}</a>
                      </li>
                  </ul>
              </ng-container>
              <ng-template #noChildren>
                  <a routerLink="/app/{{itemChild.Area?.toLowerCase()}}/{{itemChild.Controller?.toLowerCase()}}">{{ itemChild.Name | translate}}</a>
              </ng-template>
            </li>
        </ul>
      </li>
    -->


    </ul>
  </div>
</nav>