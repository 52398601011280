import { Packaging } from './Packaging';
import { Tax } from './Tax';

export class Product {
    Id: string;
    ProductId: number;
    Code: string;
    Tipo: number;
    Name: string;
    Reference: string;
    Price: number;
    Price1U: number;
    Price2U: number;
    Price3U: number;
    Price4U: number;
    Price5U: number;
    Price6U: number;
    Price7U: number;
    Price8U: number;
    CostoRef: number;
    ivainc: boolean;
    GroupID: number;
    TaxRate: number;
    Stock: number;
    ImageUrl: string;
    Location: string;
    Descuento: number;
    PDesMin1: number;
    PDesMin2: number;
    PDesMin3: number;
    PDesMin4: number;
    PDesMin5: number;
    PDesMin6: number;
    PDesMin7: number;
    PDesMin8: number;
    Description: string;
    Subgrupo: string;
    Categoria: string;
    Marca: string;
    Modelo: string;
    SRIVATCode: number;
    StylexsaVATCode: number;
    priceRef: string;
    SKUs: Sku[];
    To_0: number;
    To_1: number;
    To_2: number;
    StockSales: number;
    ProductID: number;
    CostoNeto: number;
    CostoUReal: number;
    CostoU: number;
    WRef: number;
    AfectaInventario: boolean;
    CodBar: string;
    Venta: boolean;
    Compra: boolean;
    WeightUnit: string;
    VolumeUnit: string;
    Blocked: boolean;
    CategoryId: number;
    MarcaID: number;
    ColorID: number;
    StockMin: number;
    StockMax: number;
    Weight: number;
    Volume: number;
    SizeID: number;
    Metodo: number;
    MetodoID: number;
    Supplier1Code: string;
    Supplier2Code: string;
    LastPurchaseCost: number;
    LastPurchaseDate: Date;
    LasPurchaseNo: number;
    AverageCost: number;
    Images: string[];
    Taxes: Tax[];
    Packaging:Packaging[];
    WarehouseStock:WarehouseStock[];
    Quantity?:number;
}

export class Sku {
  SKU: string;
  Size: string;
  Color: string;
  Price: number;
  Stock: number;
}

export class WarehouseStock {
  Id: number;
  Name: string;
  Stock: number;
}
