import { PersonnelService } from '../reports/personnel.service';
import { Injectable, Injector, Type } from '@angular/core';
import { CustomersService } from '../sales/customers.service';
import { AttendanceService } from '../reports/attendance.service';
import { AbsenceService } from '../reports/absence.service';
@Injectable({
  providedIn: 'root'
})

export class ServiceMapper {
  // Aqui poner todos los servicios que se quieran mapear
  private services = {
    Personnel: PersonnelService,
    Attendance: AttendanceService,
    Customer: CustomersService,
    Absence: AbsenceService,
  }

  constructor(private injector: Injector) {
  }

  getService(serviceName: string): any {
    const service = this.injector.get<any>(this.services[serviceName]);
    if (service) {
      return service;
    } else {
      throw new Error('Service not found');
    }
  }
}
