import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { CustomerEquipment } from '../../models/Sales/CustomerEquipment';

@Injectable({
  providedIn: 'root'
})
export class EquipmentcustomersService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }


  getEquipmentCustomers(CustomerID: number): Observable<CustomerEquipment[]> {
    const url = this.getApiQuery('ControlEditor/GetEquipmentsCustomer');
    let params = new HttpParams();
    if (CustomerID) {
      params = params.append('CustomerID', CustomerID.toString());
    }
    return this._httpClient.get<CustomerEquipment[]>(url, { headers: this.getAuthorizationHeader(), params: params });
  }

  EquipmentCreate(record: CustomerEquipment) {

    const url = this.getApiQuery('ControlEditor/SaveEquipmentsCustomer');
    return this._httpClient.post(
      url,
      record,
      { headers: this.getAuthorizationHeader() }
    );
  }
  EquipmentUpdate(record: CustomerEquipment) {
    const url = this.getApiQuery('ControlEditor/SaveEquipmentsCustomer');
    return this._httpClient.post(
      url,
      record,
      { headers: this.getAuthorizationHeader() }
    );
  }

}
