import { WarehouseStock } from '.';
import { Packaging } from './Sales/Packaging';
import { Tax } from './Sales/Tax';

export class InvoiceItem {
    Id: string;
    Itemno: number;
    Code: string;
    CodBar: string;
    Quantity: number;
    Price: number;
    Price1: number;
    Price2: number;
    Price3: number;
    Price4: number;
    Price5: number;
    Price6: number;
    Price7: number;
    Price8: number;
    Description: string;
    VAT: number;
    Discount: number;
    Detail: string;
    Subtotal: number;
    Cost: number;
    Total: number;
    imageUrl: string;
    Stock:number;
    StockSales:number;

    Taxes: Tax[];
    Packaging:Packaging[];
    //Storage?:number;
    WarehouseNo:number;
    Id_Promo?  : string;
}

export class PromoItem {
  ID_PROMO: string;
  ID_PROD: string;
  DESCRIP: string;
  CANTIDAD: number;
  PRECIO: number;
  IVA: number;
  PROMOCION: number;
  CANTBONIFICADA?: any;
  DESCUENTO?: any;
  INCREMENTO?: any;
  IMPUESTO: number;
  STOCK: number;
  UNIDAD: string;
  ACTUALIZ: boolean;
  ELIMINA: boolean;
  IVAINC: boolean;
  ID: number;
  TAXES: Tax[];
  WarehouseStock:WarehouseStock[];
}
