import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'starter',
	templateUrl: 'starter.template.html'
})
export class StarterViewComponent implements OnDestroy, OnInit  {

	public nav:any;

	public constructor(private _router: Router) {
		this.nav = document.querySelector('nav.navbar');		
	}

	public ngOnInit():any {	
					
	}


	public ngOnDestroy():any {
		this.nav.classList.remove("white-bg");
	}

}
