import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';
import { SessionService } from '../helpers/session.service';

@Injectable()
export class RoleService implements CanActivate {

  constructor(private _sessionService: SessionService) { }

  canActivate() {

    if (this._sessionService.isAdminOrSalesman()) {
      return true;
    } else {
      return false;
    }
  }
}
