import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Product, BaseResult, PagedResult } from '../../models';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ProductResourceParameters } from '../../helpers';
import { APP_SETTINGS } from '../../appsettings';
import { DocFile } from '../../models/system/doc_anex';
@Injectable({
  providedIn: 'root'
})
export class DocAnexService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  //let params: HttpParams = new HttpParams();
  //params = params.append('type', type);


  updateFiles(DocFiles : Array<DocFile>) {
    const url = this.getApiQuery(`Files/SaveFile`);
    console.log(DocFiles);
    return this._httpClient.post(
      url, 
      DocFiles,
      {headers: this.getAuthorizationHeader()}
    );
  }

  loadFiles(Origen: string,Reference:string) {
    const url = this.getApiQuery('Files/Getfile');
    let params = new HttpParams();
    params = params.append('Origin', Origen);
    params = params.append('Reference', Reference);
    return this._httpClient.get(url,
      {
        params: params,
        headers: this.getAuthorizationHeader()
      }
    );
  }

}
