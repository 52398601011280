import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { UserCredential } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(private _injector: Injector) {
    super(_injector);
  }

  changePassword(credential: UserCredential): Observable<boolean> {
    const url = this.getApiQuery('Account/UpdateUserPassword');
    return this._httpClient.post<boolean>(url, credential, { headers: this.getAuthorizationHeader() });
  }
}
