import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Injectable, Injector, Input, Inject, TemplateRef, ViewChildren, QueryList, ContentChild, ContentChildren, AfterContentInit, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';

export interface DialogData {
  title?:string;
  message?:string;
  buttons?:string;
  ok?:boolean;
  cancel?:boolean;
}
export interface typFilter {
  filterLite?    : string;
}
export interface typFilter {
  doc?            : string;
  start?          : Date;
  end?            : Date;
  search_Id?     : string;
  search_Name?   : string;
  logicCondition? : string;
  valueCondition? : string;
}
export interface typeCmb {
  Id      : number;
  Detail  : string;
  Ref     : string;
}
export let datalogicConditions: Array<typeCmb> =
[
  {Id: 0, Detail: ''                       ,Ref:''},
  {Id: 1, Detail: 'Mayor que'              ,Ref:'>'},
  {Id: 2, Detail: 'Menor que'              ,Ref:'<'},
  {Id: 3, Detail: 'Igual que'              ,Ref:'='},
  {Id: 4, Detail: 'Mayor o igual que'      ,Ref:'>='},
  {Id: 5, Detail: 'Menor o igual que'      ,Ref:'<='},
  {Id: 6, Detail: 'Diferente que'          ,Ref:'<>'},
];
@Component({
  selector: 'dialog-filter',
  templateUrl: './dialog-filter.component.html',
  styleUrls: ['./dialog-filter.component.css'],
  encapsulation: ViewEncapsulation.None
  //providers: [InfiniteScrollService]
})
export class DialogfilterComponent implements OnInit, OnDestroy  {
  ngUnsubscribe = new Subject();
  public dialogRefHtmlRef:any;

  public Form: FormGroup;
  public CtrTo_Filter     : FormControl = new FormControl('');
  @ViewChild('tplFilter',{static: false}) tplFilter: TemplateRef<any>;
  @ViewChild('idInput',{static: false}) input: ElementRef;
  @ViewChildren("ctrls") ctrls: QueryList<ElementRef>
  public txtfilterLite: string;
  //@ContentChildren(ListItemComponent) public readonly items: QueryList<ListItemComponent>;
  //@ContentChildren("ctrls",{ descendants: true }) ctrls : QueryList<ElementRef>
  //@ContentChildren("ctrls", { read: ElementRef }) ctrls: QueryList<any>;
  //@ContentChildren("ctrls") ctrls: ElementRef;
  //@ContentChildren("formdialog") formdialog;// ctrls: QueryList<ElementRef>


  @Output() filterLite = new EventEmitter<string>();
  @Output() acceptFilter = new EventEmitter<string>();
  @Output() cancelFilter = new EventEmitter<string>();
  @Input() customPlaceholder: string;
  public filter  :  typFilter   = {
    filterLite    : '',
  }
  public placeholder:string;
  constructor(
    //@Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    public elem: ElementRef,

    private _viewContainerRef: ViewContainerRef){
      //console.log("contruct");
  }

  ngOnInit(): void {
    //console.log("onInit");
    this.Form = this._formBuilder.group({
      'filter'        : this.CtrTo_Filter
    });
    //console.llg
    if(this.customPlaceholder) {
      this.placeholder = this.customPlaceholder;
    } else {
      this.placeholder = 'Buscar';
    }

  }
  ngAfterContentInit() {
    //console.log("HolaContenct");
    //console.log(this.ctrls);
    //console.log(this.formdialog);
    //this.ctrls.forEach(tabInstance => console.log(tabInstance));
    //console.log(this._viewContainerRef);

  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngAfterViewInit() {
    //console.log("HolaView");
    //console.log(this.ctrls);
  }



  onShowFilters(){

    let width:any='600px';
    let height:any='';
    /*
    dialogRef = this.Modal.htmRef(this.dialogFiltering,true,width,height);
    dialogRef.afterClosed().subscribe(result => {
    });
    */
    let elemRef : DOMRect  = this.input.nativeElement.getBoundingClientRect();

    let left=elemRef.left-40;
    let top=elemRef.top+elemRef.height+3;



    let dialogConfig = new MatDialogConfig();
    dialogConfig.backdropClass='g-transparent-backdrop';
    dialogConfig.data= {};
    dialogConfig.panelClass= 'custom-dialog-containerBox';  //esta clase esta en style.css
    dialogConfig.disableClose=false;
    dialogConfig.maxWidth='100vw';
    dialogConfig.maxHeight= '100vh';

    const rightMostPos = window.innerWidth - Number(left)- Number(width)-5;
    let poxtop= Number(top) + Number(height);
    if (poxtop>=window.innerHeight)
      poxtop=window.innerHeight-Number(height)-10;
    else
      poxtop= Number(top);

    let poxleft= Number(left) + Number(width);
    if (poxleft>=window.innerWidth)
      poxleft=window.innerWidth-Number(width)-10;
    else
      poxleft= Number(left);

    const bottomMostPos = window.innerHeight - Number(top)- Number(height);
    dialogConfig.position = {
      top: `${poxtop}px`,
      left: `${poxleft}px`,  //left: `${parameters.left}px`,
      right: `${rightMostPos}px`,
      //bottom: `${bottomMostPos}px`,
    }


    if (width) dialogConfig.width=width;
    if (height) dialogConfig.height=height;

    this.dialogRefHtmlRef=this.dialog.open(this.tplFilter,dialogConfig);

    this.dialogRefHtmlRef.keydownEvents().subscribe(e => {
      if (e.key=='Escape'){// || e.key=='Backspace'){
        //console.log(e);
        this.dialogRefHtmlRef.close();//(params);
      }
    });
    this.dialogRefHtmlRef.beforeClosed().subscribe((res) => {
      //console.log(this.ctrls);
      var objs = document.getElementById("formdialog");
      //console.log(objs);

      //var inputs = document.getElementById("formdialog").elements;

      // Iterate over the form controls

      /*
      var i;
      for (i = 0; i < this.ctrls.length; i++) {
        if (this.ctrls[i].nodeName === "INPUT" && this.ctrls[i].type === "text") {
          this.ctrls[i].value.toLocaleUpperCase();
        }
      }
      */
    });

    return this.dialogRefHtmlRef;
  }


  onKeydown(event,value){
    if (event.key=='Enter'){
      this.onLoadFilter();
    }
  }
  keyPressed(e) {
    if (e.key === 'Enter') {
      e.cancel = true;
      this.onLoadFilter();
    }
  }

  onLoadFilter(){
    this.getValuesCtrls();
    this.filterLite.emit(this.input.nativeElement.value);
  }

  closeDialog(){
    let result: object = {
      success:false,
      data:null
    }
    console.log(this.ctrls);
    //console.log(this.formdialog);
    //this.ctrls.forEach(ctrl => console.log(ctrl));

    //this.ctrls.forEach(item => {console.log(item.elem.nativeElement.style.someHTMLProperty)});

    this.dialogRefHtmlRef.close(result);
  }
  onAccept(){
    this.getValuesCtrls();
    this.input.nativeElement.value='';
    this.acceptFilter.emit();
    this.dialogRefHtmlRef.close();
  }

  onCancel(){
    this.cancelFilter.emit();
    this.dialogRefHtmlRef.close();
  }
  getValuesCtrls(){
    /*------------Esto funciona OK------------*/
    /*
    var i;
    var objs = document.getElementById("ctrls");
    //var obj = objs.querySelectorAll("input,select");
    var objInputs = objs.getElementsByTagName("input");
    for (i = 0; i < objInputs.length; i++) {
        //console.log(obj[i]);
        console.log(objInputs[i].name + "=" +objInputs[i].value);
    }
    var objSelects = objs.getElementsByTagName("select");
    for (i = 0; i < objSelects.length; i++) {
        console.log(objSelects[i].name + "=" +objSelects[i].value);
    }
    */
    let i=0;
    let cad:string='';
    this.ctrls.forEach((ctrl:ElementRef) => {
      let objs:any= ctrl.nativeElement.children[0];
      for (i = 0; i < objs.length; i++) {
        //console.log(objs[i].name + "=" +objs[i].value);
        if (objs[i].value){
          cad=cad+(objs[i].name + "=" +objs[i].value)+";";
        }
      }
    });
    this.filter.filterLite=cad;
    //this.placeholder='';//cad;
    //this.input.nativeElement.value='';
    //console.log(cad);

    //this.ctrls.forEach(item => {console.log(item.elem.nativeElement.style.someHTMLProperty)});

  }
}









  //var inputs = document.getElementById("formdialog").elements;
  // Iterate over the form controls
  /*
  var i;
  for (i = 0; i < this.ctrls.length; i++) {
    console.log(this.ctrls[i]);
    //
    //if (this.ctrls[i].nodeName === "INPUT" && this.ctrls[i].type === "text") {
    //  this.ctrls[i].value.toLocaleUpperCase();
    //}
    //
  }
  */

  /*
  var container = document.querySelector('#ctrls');
  console.log(container);
  var matches = container.querySelectorAll('input');
  console.log(matches);
  */

  /*
  const forms = document.querySelector('#formdialog'); //document.querySelectorAll('formdialog');
  const form = forms[0];
  [...forms.elements].forEach((input) => {
    console.log(input);
  });
  [...this.formdialog].forEach((input) => {
    console.log(input);
  });
  */


    /*
    this.form= ctrl.nativeElement.children[0] as HTMLFormElement;
    console.log(this.form);
    this.form.forEach((input) => {
      console.log(input);
    });
    */
  /*
  this.cnt.forEach((ctrl:ElementRef) => {
    console.log(ctrl);
  });
  */


