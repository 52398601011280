import {
  Directive,
  ElementRef,
  HostListener,
} from "@angular/core";

@Directive({
  selector: "[alphanumericOnly]",
})

export class AlphanumericOnlyDirective {
  constructor(private el: ElementRef) {}
  @HostListener("keypress", ["$event"]) onKeyPress(event: KeyboardEvent) {
    return this.validateAlphanumeric(event);
  }

  validateAlphanumeric(event: KeyboardEvent) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
