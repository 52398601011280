import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Injectable, Injector, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

import { CommandClickEventArgs, CommandModel, EditSettingsModel, GridComponent, SaveEventArgs, SelectionSettingsModel, RowSelectEventArgs, ToolbarItems, IEditCell, IIndex } from '@syncfusion/ej2-angular-grids';


//import { listFPago,dataChe,dataTar,dataCre } from './../data';

import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { ComboBox } from '@syncfusion/ej2-dropdowns';
import { DatePicker } from '@syncfusion/ej2-calendars';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { BankService, CashService, CreditcardsService, ToastrService } from '../../../../services';
import { PagoBanco, PagoCredito, PagoEfectivo, PagoTarjeta,  PagoTrDep,  Type_fPagosNew, typ_Bank, typ_CardPlan, typ_Cash, typ_Creditcard, typ_CustomerBank, typ_EmiTar } from './../../../../models';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { dataInvoice, selCustomer } from '../invoice.component';
import { typ_BankEmp } from 'src/app/models/purchase/purchase';

export interface FPagos {
  Itemno: number,
  FPago: string,
  Ref:string,
  Valor:number,
}

/*****************************************************************/
/**              Tipos de Datos que usan los Grid               **/
/*****************************************************************/

/*---------------Cheques--------------*/
export interface FPagoChe {
  Itemno: number,
  IdCheCli:number,
  Cuenta: string,
  Numero:string,
  Girado:Date,
  Valor:number,
}
/*---------------Tarjeta--------------*/
export interface FPagoTar{
  Itemno: number,
  Plan: string,
  //Emisor:string,
  NumeroTar: string,
  NumeroVau:string,
  Lote:string,
  Valor:number,
  IdPlan:number,
  //IdEmisor:number
}
/*----------------Credito-------------*/
export interface FPagoCre{
  Itemno: number,
  Vence: Date,
  Tiempo:string,
  NLetras:number,
  Garante:string,
  CIGarante:string,
  Financ:number,
  Valor:number,
  idTiempo:number
}
/*------Transferencia Bancaria-------*/
export interface FPagoTrb {
  Itemno: number,
  IdBanco:string,
  Banco: string,
  Numero:string,
  Fecha:Date,
  Valor:number,
}

/*----Data Extra pasada a Componente padre---*/
export interface Type_dataExtra {
  cajaId    : number;
  APagar    : number;
}


@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
  encapsulation: ViewEncapsulation.Emulated
  //providers: [InfiniteScrollService]
})
export class PaymentsComponent implements OnInit, OnDestroy {

  ngUnsubscribe = new Subject();
  private subscription: Subscription;
  private subscriptionGridChe: Subscription;

  @Input() dialogInstance: MatDialogRef<PaymentsComponent, any>
  //@ViewChild('gridFPago', { static: false }) public gridFPago: GridComponent;
  @ViewChild('gridChe',   { static: false }) public gridChe: GridComponent;
  @ViewChild('gridTar',   { static: false }) public gridTar: GridComponent;
  @ViewChild('gridCre',   { static: false }) public gridCre: GridComponent;
  @ViewChild('gridTrb',   { static: false }) public gridTrb: GridComponent;

  //public listFPago         : FPagos[];
  public dataChe           : FPagoChe[];
  public dataTar           : FPagoTar[];
  public dataCre           : FPagoCre[];
  public dataTrb           : FPagoTrb[];

  public selRowFPago       : number;
  public isVisibleEfe      : boolean;
  public isVisibleChe      : boolean;
  public isVisibleCre      : boolean;
  public isVisibleTar      : boolean;
  public isVisibleTrb      : boolean;

  public editSettings      : EditSettingsModel;
  public selectionOptions  : SelectionSettingsModel;
  public DateFormat        : {type:"date", format:"yyyy-MM-dd"};

  public isKeyPressed;
  public numericParams     : IEditCell;
  public numericParamsLet  : IEditCell;
  public floatParams       : IEditCell;
  public dpParams          : IEditCell;
  public datePickerObj     : DatePicker;

  public dataInvoice       : dataInvoice;
  public cajaId            : number=0;

  /*------------Variables Calculos Generales---------*/
  public SubTotal          : number=0;
  public Discount          : number=0;
  public Ice               : number=0;
  public Iva               : number=0;
  public Total             : number=0;
  public totalPagos        : number=0;
  public Diferencia        : number=0;
  public listCash          : Array<typ_Cash>=[];
  public listBank          : Array<typ_Bank>=[];
  public listCreditCard    : Array<typ_Creditcard>=[];
  public dataFPago         : Type_fPagosNew;
  public enableInvoiceZero : boolean=false;
  /*********************Variables Efectivo*********************************/
  public efeTotal          : number;
  /*********************Variables Cheque**********************************/
  public cheCliLst         : { [key: string]: Object }[] =  [];//any;//Array<TPchecli>=[];
  public cheCliParams      : IEditCell;
  public cheCliObj         : ComboBox;// DropDownList;
  public cheisCellSave;
  public cheCellActive     : IIndex;
  public cheSelRow         : number;
  public cheSelCol         : number;
  public cheSelItemNo      : number;
  public cheSelRowEdit     : number;
  public cheSelColEdit     : string;
  public cheTotal          : number;
  public listCustomerBank  : Array<typ_CustomerBank>=[];

  /*********************Variables Credito********************************/
  public creTimeLst        : Array <any>=[];// [key: string]: Object }[] =  [];//any;//Array<TPchecli>=[];
  public creTimeParams     : IEditCell;
  public creTimeObj        : DropDownList;
  public creisCellSave;
  public creCellActive     : IIndex;
  public creSelRow         : number;
  public creSelCol         : number;
  public creSelItemNo      : number;
  public creSelRowEdit     : number;
  public creSelColEdit     : string;
  public creTotal          : number;
  /*********************Variables Tarjeta**********************************/
  public tarPlanLst        : { [key: string]: Object }[] =  [];//any;//Array<TPchecli>=[];
  public tarPlanParams     : IEditCell;
  public tarEmiLst         : { [key: string]: Object }[] =  [];//any;//Array<TPchecli>=[];
  public tarEmiParams      : IEditCell;
  public tarCliObj         : DropDownList;
  public tarEmiObj         : ComboBox;
  public tarisCellSave;
  public tarCellActive     : IIndex;
  public tarSelRow         : number;
  public tarSelCol         : number;
  public tarSelItemNo      : number;
  public tarSelRowEdit     : number;
  public tarSelColEdit     : string;
  public tarTotal          : number;
  public listCardPlan      : Array<typ_CardPlan>=[];
  public listEmiTar        : Array<typ_EmiTar>=[];

  /*********************Variables Trans.Bancaria****************************/
  public trbBanLst         : { [key: string]: Object }[] =  [];//any;//Array<TPchecli>=[];
  public trbBanParams      : IEditCell;
  public trbObj            : DropDownList;
  public trbisCellSave;
  public trbCellActive     : IIndex;
  public trbSelRow         : number;
  public trbSelCol         : number;
  public trbSelItemNo      : number;
  public trbSelRowEdit     : number;
  public trbSelColEdit     : string;
  public trbTotal          : number;
  public listBankEmp       : Array<typ_BankEmp>=[];

  public accessCHE         : boolean = true;
  public accessTAR         : boolean = true;
  public accessCXC         : boolean = true;
  public accessTRB         : boolean = true;
  public accessRET         : boolean = false;
  public accessNCC         : boolean = false;
  public accessANT         : boolean = false;

  public TotalEFE          : number = 0;
  public TotalCHE          : number = 0;
  public TotalTAR          : number = 0;
  public TotalCXC          : number = 0;
  public TotalTRB          : number = 0;
  public TotalRET          : number = 0;
  public TotalNCC          : number = 0;
  public TotalANT          : number = 0;

  public isVisibleCols     : boolean=false;

  public stateElem         : HTMLElement;
  public selCustomer       : selCustomer;

  constructor(
    private _cashService: CashService,
    private _bankService: BankService,
    private _creditcardsService: CreditcardsService,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
    )
  {

  }


  ngOnInit(): void {
    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true , mode: 'Batch',showConfirmDialog: false};// 'Normal',Batch' };
    this.selectionOptions = {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell' }; //Cell //Both
    this.floatParams = { params: { decimals: 4, showSpinButton:false,focus:this.focusFn.bind(this) } };
    this.numericParams = { params: { decimals: 0, showSpinButton:false,focus:this.focusFn.bind(this) } };
    this.numericParamsLet = { params: { decimals: 0, showSpinButton:false, min:0,max:600,focus:this.focusFn.bind(this) } };

    //this.dpParams = { params: {value: new Date(),format: 'yyyy-MM-dd' } };
    //this.DateFormat =  { type: "date", format: "yyyy-MM-dd" };
    //this.formatoptions = { type: "dateTime", format: "M/d/y" };


    //this.selectionOptions = { mode: 'Both' }; //Cell //Both

    //this.listFPago  = <FPagos[]>listFPago;
    //console.log(this.dataInvoice);

    this.SubTotal          = this.dataInvoice.subTotal;
    this.Discount          = this.dataInvoice.totDiscount;
    this.Ice               = this.dataInvoice.totIce;
    this.Iva               = this.dataInvoice.totIva;
    this.Total             = this.dataInvoice.total;
    this.listCash          = this.dataInvoice.listCash;
    this.listBank          = this.dataInvoice.listBank;
    this.listBankEmp       = this.dataInvoice.listBankEmp;
    this.listCreditCard    = this.dataInvoice.listCreditCard;
    this.selCustomer       = this.dataInvoice.selCustomer;
    this.cajaId            = this.dataInvoice.cashDefault;
    this.enableInvoiceZero = this.dataInvoice.enableInvoiceZero;
    /*
    this.listCash=[
      {Id:1,Name:'Caja chiquita'}
    ];
    debugger
    */
    if (this.listCash){
      if (this.listCash.length==1){
        this.cajaId=this.listCash[0].Id;
      }
    }

    //this.efeTotal = this.dataInvoice.total;
    /*
    this._cashService.getCashList('1').subscribe((response : Array<typ_Cash>) => {
      if (response ) {
        this.listCash=response;
      }
    });
    */
    this.isVisibleEfe=true;
    this.isVisibleChe=false;
    this.isVisibleTar=false;
    this.isVisibleCre=false;
    this.isVisibleTrb=false;

    this.dpParams = {
      create: () => {
          this.stateElem = document.createElement('input');
          return this.stateElem;
      },
      read: () => {
          return this.datePickerObj.value;
      },
      destroy: () => {
          this.datePickerObj.destroy();
      },
      write: (args) => {
          this.datePickerObj = new DatePicker({
              value: new Date(args.rowData[args.column.field]),
              floatLabelType: 'Never',
              //created: created.bind(this),
          });
          function created(){
            this.datePickerObj.inputElement.disabled = true
          }
          this.datePickerObj.appendTo(this.stateElem);
      }
    };
    /*
    this.subscriptionGridChe = this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.TranslaterHeaders();
    });
    */

    /*---------------------------------------------------*/
    this.setData_Che();
    this.setData_Cre();
    this.setData_Tar();
    this.setData_Trb();

  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    //this.subscriptionGridChe.unsubscribe();
  }

  ngAfterContentChecked(){
    //this.TranslaterHeaders();
  }
  ngAfterViewChecked(){
    //this.efeTotal = this.dataInvoice.total;

  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      let element = document.getElementById('li-tab8') as HTMLElement;
      element.click();
    }, 10);

    this.addItem_Che();
    this.addItem_Cre();
    this.addItem_Tar();
    this.addItem_Trb();

    setTimeout(() => {
      this.efeTotal = 0; //this.dataInvoice.total;
      /*
      for(let item of this.listFPago){
        if (item.Ref=="EFE") item.Valor=this.efeTotal;
      }
      */
    });

  }

  TranslaterHeadersChe(){
    let Headers =[
      {header:'ItemNo',field:'Itemno' },
      {header:'Currentaccount',field:'Cuenta' },
      {header:'Checknumber',field:'Numero' },
      {header:'DueDate',field:'Vencimiento' },
      {header:'Value',field:'Valor' }
    ]
    window.setTimeout(() => {
      for (let item of Headers){
        this._translateService.get(item.header)
            .subscribe((result: string) => {
              let column = this.gridChe.getColumnByField(item.field); // Get column object.
              column.headerText = result;
        });
      }
      this.gridChe.refreshHeader();
    }, 50);
  }
  TranslaterHeadersTar(){
    let Headers =[
      {header:'ItemNo',field:'Itemno' },
      {header:'Plan',field:'Plan' },
      //{header:'Transmitter',field:'Emisor' },
      {header:'Cardnumber',field:'NumeroTar' },
      {header:'Vauchernumber',field:'NumeroVau' },
      {header:'Value',field:'Valor' }

    ]
    window.setTimeout(() => {
      for (let item of Headers){
        this._translateService.get(item.header)
            .subscribe((result: string) => {
              let column = this.gridTar.getColumnByField(item.field); // Get column object.
              column.headerText = result;
        });
      }
      this.gridTar.refreshHeader();
    }, 50);
  }
  TranslaterHeadersCre(){
    let Headers =[
      {header:'ItemNo',field:'Itemno' },
      {header:'Expires',field:'Vence' },
      {header:'TimePeriod',field:'Tiempo' },
      {header:'Letters',field:'NLetras' },
      {header:'Guarantor',field:'Garante' },
      {header:'CIGuarantor',field:'CIGarante' },
      {header:'Financing',field:'Financ' },
      {header:'Value',field:'Valor' },

    ]
    window.setTimeout(() => {
      for (let item of Headers){
        this._translateService.get(item.header)
            .subscribe((result: string) => {
              let column = this.gridCre.getColumnByField(item.field); // Get column object.
              column.headerText = result;
        });
      }
      this.gridCre.refreshHeader();
    }, 50);
  }
  TranslaterHeadersTrb(){
    let Headers =[
      {header:'ItemNo',field:'Itemno' },
      {header:'Bank',field:'Banco' },
      {header:'Number',field:'Numero' },
      {header:'Date',field:'Fecha' },
      {header:'Value',field:'Valor' }
    ]
    window.setTimeout(() => {
      for (let item of Headers){
        this._translateService.get(item.header)
            .subscribe((result: string) => {
              let column = this.gridTrb.getColumnByField(item.field); // Get column object.
              column.headerText = result;
        });
      }
      this.gridTrb.refreshHeader();
    }, 50);
  }
  selectFPago(type){

    this.isVisibleEfe=false;
    this.isVisibleChe=false;
    this.isVisibleCre=false;
    this.isVisibleTar=false;
    this.isVisibleTrb=false;


    if (type=='EFE'){
      this.isVisibleEfe=true;
    }
    if (type=='CHE'){
      this.isVisibleChe=true;
      this.TranslaterHeadersChe();
    }
    if (type=='CXC'){
      this.isVisibleCre=true;
      this.TranslaterHeadersCre();
    }
    if (type=='TAR'){
      this.isVisibleTar=true;
      this.TranslaterHeadersTar();
    }
    if (type=='TRB'){
      this.isVisibleTrb=true;
      this.TranslaterHeadersTrb();
    }

  }
  asignValue(type){
    this.selectFPago(type);
    if (type=='EFE'){
      this.calculos_Cre();
      this.calculos_Che();
      this.calculos_Tar();
      this.calculos_Trb();
      //debugger
      if (this.TotalCHE==0 && this.TotalTAR==0 && this.TotalCXC==0 && this.TotalNCC==0 && this.TotalRET==0 && this.TotalTRB==0) {
        this.TotalEFE=this.Total;
        this.efeTotal=this.Total;
      } else {
        //this.calculos_Pagos();
        let TP =this.TotalCHE+this.TotalTAR+this.TotalCXC+this.TotalTRB+this.TotalRET+this.TotalNCC+this.TotalANT;
        let Dif=this.Total-TP
        if (Dif>0){
          this.TotalEFE = Dif;
          this.efeTotal = Dif;
          this.calculos_Pagos();
        }
      }
    }
    if (type=='CXC'){

      this.calculos_Cre();
      this.calculos_Che();
      this.calculos_Tar();
      this.calculos_Trb();
      //debugger
      let NItemCre = this.dataCre.length;
      if (NItemCre==1){
        if (this.TotalEFE==0 && this.TotalCHE==0 && this.TotalTAR==0 && this.TotalNCC==0 && this.TotalRET==0 && this.TotalTRB==0) {
          this.gridCre.setCellValue(1, "Valor", this.Total);
          this.TotalCXC=this.Total;
          this.creTotal=this.Total;
          this.addItem_Cre();
          this.gridCre.refresh();

          this.calculos_Pagos();
        } else {
          //this.calculos_Pagos();
          let TP =this.TotalEFE+this.TotalCHE+this.TotalTAR+this.TotalCXC+this.TotalTRB+this.TotalRET+this.TotalNCC+this.TotalANT;
          let Dif=this.Total-TP
          if (Dif>0){
            this.gridCre.setCellValue(1, "Valor", Dif);
            this.TotalCXC=Dif;
            this.creTotal=Dif;
            this.addItem_Cre();
            this.gridCre.refresh();
            this.calculos_Pagos();
          }
        }
      }
    }

  }
  rowSelectedFPago(args){
    this.selRowFPago=args.rowIndex;
    this.isVisibleEfe=false;
    this.isVisibleChe=false;
    this.isVisibleCre=false;
    this.isVisibleTar=false;
    this.isVisibleTrb=false;

    if (this.selRowFPago==0){
      //this.onDivVisible('divEfe',true);
      this.isVisibleEfe=true;
    }
    if (this.selRowFPago==1){
      this.isVisibleChe=true;
    }
    if (this.selRowFPago==2){
      this.isVisibleCre=true;
    }
    if (this.selRowFPago==3){
      this.isVisibleTar=true;
    }
    if (this.selRowFPago==4){
      this.isVisibleTrb=true;
    }

  }
  focusFn(e) {
    window.setTimeout(() => {
      let obj=e.event.target;
      let value:string= String(e.value);
      obj.setSelectionRange(value.length,value.length);
    }, 5);
  }
  isKey(e,type:string){
    if (type=="AZ_09"){
      if ( (e.keyCode>=65  && e.keyCode<=90) ||  //A-Z a-z
           (e.keyCode>=48  && e.keyCode<=57) ||  //0-9
           (e.keyCode==192 || e.keyCode==32 || e.keyCode==13 )) {  //ñ espace enter
            return true;
        }
    }
    if (type=="09"){
      if ( (e.keyCode>=48  && e.keyCode<=57) ||  //0-9
           (e.keyCode>=96  && e.keyCode<=105) || (e.keyCode==110) || //0-9 Bloq-Num
           (e.keyCode==32 || e.keyCode==13 || e.keyCode==190 || e.keyCode==188 )) {  // espace enter . ,
            return true;
        }
    }
    if (type=="ALL"){
      if ( (e.keyCode>=65  && e.keyCode<=90) ||  //A-Z a-z
           (e.keyCode>=48  && e.keyCode<=57) ||  //0-9
           (e.keyCode>=96  && e.keyCode<=105) || (e.keyCode==110) || //0-9 Bloq-Num
           (e.keyCode==192 || e.keyCode==32  || e.keyCode==190 || e.keyCode==188 )) {  //ñ espace enter
            return true;
        }
    }

    //return false;
  }
  /*****************************************************************/
  /**           Eventos y funciones Pago Efectivo                 **/
  /*****************************************************************/

  onChangeEfe(event){
    //console.log(event);
    //this.gridFPago.setCellValue("EFE", "Valor", this.efeTotal);
    this.TotalEFE=this.efeTotal;
    //console.log(this.TotalEFE);
    this.calculos_Pagos();
  }

  /*****************************************************************/
  /**           Eventos y funciones Pago Cheque                   **/
  /*****************************************************************/
  setData_Che(){
     /*
     this.cheCliLst = [
      { Id : 1, Name: 'Banco Pacifico  Ahorros   1225126' },
      { Id : 2, Name: 'Banco Pichincha Corriente 1223127' },
      { Id : 3, Name: 'Banco Internacional - 1223127' }
     ];
     */

     this._bankService.GetCustomerBankAccounts(this.dataInvoice.customerID).subscribe((response : Array<typ_CustomerBank>) => {
      if (response ) {
        this.listCustomerBank=response;
        /*
        this.listCustomerBank=[{
          Id:1,
          Name:'Cta.Banco Pacifico'
        }]
        */
      }
    });

     this.dataChe    = [];//<FPagoChe[]>dataChe;

     this.setColCheCli();
     this.calculos_Che();
     /*
     for(let item of this.listFPago){
       if (item.Ref=="CHE") item.Valor=this.cheTotal;
     }
     */
  }

  setColCheCli(){
    this.cheCliParams = {
      create:(args)=>{ // { rowData: object, column: Column }
        this.cheSelRowEdit=this.cheSelRow;
        this.cheSelItemNo=Number(args.data.Itemno);
        this.stateElem = document.createElement('input'); return this.stateElem;
      },
      read:()=>{return this.cheCliObj.text;},
      destroy:()=>{
          let id=Number(this.cheCliObj.value);
          this.gridChe.setCellValue(this.cheSelItemNo, "IdCheCli", id);
          this.cheCliObj.destroy();
          /*
          let rowF=this.dataChe.length-1;
          if (this.cheSelRowEdit==rowF){
            this.addItem_Che();
            this.gridChe.refresh();
          }
          */
      },
      write:()=>{
        this.cheCliLst= <any[]>this.listCustomerBank;
        let value = this.dataChe[this.cheSelRow].Cuenta;
        this.cheCliObj = new ComboBox({// DropDownList({
          dataSource: this.cheCliLst,

          fields: { value: 'Id', text: 'Name'},
          placeholder: 'Select Bank',
          floatLabelType: 'Always',
          allowFiltering:true,
          actionFailureTemplate:"<span class='action-failure'> Data fetch get fails</span>",
          noRecordsTemplate:"<span class='norecord'> No data availible</span>",
          value:value,
          change: (e) => {
            let id=Number(this.cheCliObj.value);
            if (Number.isNaN(id)){
              id=null;
            }
            //console.log(id);
            this.gridChe.setCellValue(this.cheSelItemNo, "IdCheCli", id);
          },
       });
       this.cheCliObj.appendTo(this.stateElem);
     }
    };
  }
  getIndexColumChe(field:string):number{
    let index=0;
    if (this.gridChe) {
      let cols: Array<any>;
      cols= this.gridChe.columns;
      for (let item of cols){
        if (item.field==field){
          return index;
        }
        index++;
      }
    }
    return 0;
  }

  rowSelected_Che(args: RowSelectEventArgs) {
    this.cheSelRow=args.rowIndex;
  }
  dataBound_Che($event){
  }

  ActivaCell_Che(e){
    //console.log(e);
    if ((e.target as HTMLElement).classList.contains('e-rowcell')) {
      const rIndex = Number((e.target as HTMLElement).getAttribute("Index"));
      const cIndex = Number((e.target as HTMLElement).getAttribute("aria-colindex"));
      const i = { rowIndex: rIndex, cellIndex: cIndex };
      const field: string = this.gridChe.getColumns()[cIndex].field;
      this.cheSelCol=cIndex;
      this.cheSelRow=rIndex;
      if (field=='Cuenta_123'){
        this.gridChe.editCell((rIndex), field);
      } else {
        let itemNo = this.dataChe[this.cheSelRow].Itemno;
        let char:any;
        if (field=='Numero' || field=='Valor'){
          char=Number(e.key);
        }else{
          char=e.key;
        }
        if (e.key!='Enter'){
          let colEdit=['Cuenta','Numero','Girado','Valor']
          for (let col of colEdit){
            if (col==field){
              this.gridChe.setCellValue(itemNo, field, char);
              break;
            }
          }
        }
        this.gridChe.editCell((rIndex), field);
      }
    }

  }
  keyPressed_Che(e) {
    //console.log(e);
    if ((e.target as HTMLElement).classList.contains('e-rowcell')) {
      const rIndex = Number((e.target as HTMLElement).getAttribute("Index"));
      const cIndex = Number((e.target as HTMLElement).getAttribute("aria-colindex"));
      const i = { rowIndex: rIndex, cellIndex: cIndex };
      this.cheSelColEdit = this.gridChe.getColumns()[cIndex].field;
    }

    this.isKeyPressed = (e.key == "Enter");
    if (e.key === 'Delete') {
      e.cancel=true;
      this.delItem_Che();
    }
    if (this.cheSelColEdit=='Cuenta' || this.cheSelColEdit=='Numero' || this.cheSelColEdit=='Girado' || this.cheSelColEdit=='Valor' ){
      if (this.isKey(e,'ALL')) {
        e.cancel = true;
        if (!this.gridChe.isEdit) {
          this.ActivaCell_Che(e);
        }
      }
    }
    if (e.key === 'Enter') {
      e.cancel = true;
      if (this.gridChe.isEdit) {
        this.gridChe.saveCell();
      }else {
        this.ActivaCell_Che(e);
      }
    }
    if (e.key === 'Escape') {
      this.gridChe.closeEdit();
    }
  }
  doubleClick_Che(event){
    let cIndex=event.cellIndex;
    const field: string = this.gridChe.getColumns()[cIndex].field;
    if (field=='Cuenta'){
      //this.loadProducts(false);
    }

  }
  beginEdit_Che($event){
  }
  cellEdit_Che($event){
    this.cheSelColEdit=$event.columnName;
    this.cheSelRowEdit=this.cheSelRow;
    //console.log($event);
  }

  cellSelected_Che(event){
    this.cheCellActive=event.cellIndex;
    this.cheSelRow=this.cheCellActive.rowIndex;
    this.cheSelCol=this.cheCellActive.cellIndex;
  }
  actionBegin_Che(args: SaveEventArgs) {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
    }
    if (args.requestType === 'save') {
    }
  }
  actionComplete_Che(e: any): void{
    //console.log(e);
    if (e.requestType === 'batchsave') {
      this.gridChe.closeEdit();
      this.calculos_Che();
      //this.gridFPago.setCellValue("CHE", "Valor", this.cheTotal); //AQUI BIEN
      this.calculos_Pagos();
      this.cheCellActive= {
        rowIndex:this.cheSelRow,
        cellIndex:this.cheSelCol
      };
      this.gridChe.selectCell(this.cheCellActive);
    }
  }
  celsave_Che(args){
    this.gridChe.editSettings.showConfirmDialog=false;
    this.gridChe.editModule.batchSave();
    this.cheisCellSave = true;
  }
  celsaved_Che(args){
    //debugger
    if( this.cheisCellSave){
      this.gridChe.editModule.batchSave();
        args.cancel = true;
        if (args.columnName=='Cuenta' ||  args.columnName=='Numero' || args.columnName=='Girado' || args.columnName=='Valor') {
          if (args.columnName!='Valor'){
            if (Number(args.rowData.Valor)==0 && this.Diferencia>0){
              this.gridChe.setCellValue(args.rowData.Itemno, "Valor", this.Diferencia);
            }
          }
          let rowF=this.dataChe.length-1;
          if (this.cheSelRowEdit==rowF){
            this.addItem_Che();
            this.gridChe.refresh();
            window.setTimeout(() => {
              this.gridChe.selectCell(this.cheCellActive,false);
            }, 100);
          }
        }

      }
      else{
        this.isKeyPressed = false;
      }
  }
  addItem_Che(){
    let NItem= this.dataChe.length+1;
    let record : FPagoChe = {
      Itemno: NItem,
      IdCheCli:0,
      Cuenta: '',
      Numero:'',
      Girado: new Date(),
      Valor:0.0,
    };
    //let rowF=this.dataCre.length-1;
    //if (rowF==0){
      this.dataChe.push(record);
    //}

  }
  delItem_Che(){
    let rowF=this.dataChe.length-1;
    if (this.cheSelRow==rowF){
      return;
    }

    this.gridChe.selectionSettings.mode="Both"
    this.gridChe.selectRow(this.cheSelRow);
    this.gridChe.editSettings.showDeleteConfirmDialog=false;
    this.gridChe.deleteRecord();
    this.gridChe.editSettings.showConfirmDialog=false;
    this.gridChe.selectionSettings.mode="Cell"
    this.gridChe.endEdit();

    let i=0;
    for (let item of this.dataChe){
      i++;
      item.Itemno=i;
    }
  }

  calculos_Che(){
    let total:number=0;
    for (let item of this.dataChe){
      total= total + item.Valor;
    }
    this.cheTotal=total;
    this.TotalCHE=total;
  }

  /*****************************************************************/
  /**           Eventos y funciones Pago Credito                  **/
  /*****************************************************************/

  setData_Cre(){
    this.creTimeLst = [
      { Id : 0, Name: 'Semanal' },
      { Id : 1, Name: 'Quincenal' },
      { Id : 2, Name: 'Mensual' },
      { Id : 3, Name: 'Anual' }
      ];
    this.dataCre    = [];//<FPagoCre[]>dataCre;
    this.creTimeParams = {
      create:(args)=>{
        this.creSelRowEdit=this.creSelRow;
        this.creSelItemNo=Number(args.data.Itemno);
        this.stateElem = document.createElement('input'); return this.stateElem;
      },
      read:()=>{return this.creTimeObj.text;},
      destroy:()=>{
          let id=Number(this.creTimeObj.value);
          this.gridCre.setCellValue(this.creSelItemNo, "idTiempo", id);
          this.creTimeObj.destroy();
          /*
          let rowF=this.dataCre.length-1;
          if (this.creSelRowEdit==rowF){
            this.addItem_Cre();
            this.gridCre.refresh();
          }
          */
      },
      write:()=>{
        this.creTimeObj = new DropDownList({
          dataSource: this.creTimeLst,

          fields: { value: 'Id', text: 'Name'},
          floatLabelType: 'Always',
          value:0,
          change: (e) => {
            let id=Number(this.creTimeObj.value);
            this.gridCre.setCellValue(this.creSelItemNo, "idTiempo", id);
          },
       });
       this.creTimeObj.appendTo(this.stateElem);
      }
    }
    this.calculos_Cre();
    /*
    for(let item of this.listFPago){
      if (item.Ref=="CRE") item.Valor=this.creTotal;
    }
    */

  }
  getIndexColumCre(field:string):number{
    let index=0;
    if (this.gridCre) {
      let cols: Array<any>;
      cols= this.gridCre.columns;
      for (let item of cols){
        if (item.field==field){
          return index;
        }
        index++;
      }
    }
    return 0;
  }

  rowSelected_Cre(args: RowSelectEventArgs) {
    this.creSelRow=args.rowIndex;
  }
  dataBound_Cre($event){
  }
  ActivaCell_Cre(e){
    if ((e.target as HTMLElement).classList.contains('e-rowcell')) {
      const rIndex = Number((e.target as HTMLElement).getAttribute("Index"));
      const cIndex = Number((e.target as HTMLElement).getAttribute("aria-colindex"));
      const i = { rowIndex: rIndex, cellIndex: cIndex };
      const field: string = this.gridCre.getColumns()[cIndex].field;
      this.creSelCol=cIndex;
      this.creSelRow=rIndex;
      if (field=='Cuenta'){
        this.gridCre.editCell((rIndex), field);
      } else {
        let itemNo = this.dataCre[this.creSelRow].Itemno;
        let char:any;
        if (field=='NLetras' || field=='Valor' || field=='Financ'){
          char=Number(e.key);
        }else{
          char=e.key;
        }
        if (e.key!='Enter'){
          let colEdit=['Vence','Tiempo','NLetras','Garante','CIGarante','Financ','Valor']
          for (let col of colEdit){
            if (col==field){
              this.gridCre.setCellValue(itemNo, field, char);
              break;
            }
          }
        }
        this.gridCre.editCell((rIndex), field);
      }
    }

  }
  keyPressed_Cre(e) {
    if ((e.target as HTMLElement).classList.contains('e-rowcell')) {
      const rIndex = Number((e.target as HTMLElement).getAttribute("Index"));
      const cIndex = Number((e.target as HTMLElement).getAttribute("aria-colindex"));
      const i = { rowIndex: rIndex, cellIndex: cIndex };
      this.creSelColEdit = this.gridCre.getColumns()[cIndex].field;
    }

    this.isKeyPressed = (e.key == "Enter");
    if (e.key === 'Delete') {
      e.cancel=true;
      this.delItem_Cre();
    }
    if (this.creSelColEdit=='NLetras' || this.creSelColEdit=='Garante' || this.creSelColEdit=='CIGarante' || this.creSelColEdit=='Financ' || this.creSelColEdit=='Valor'){
      if (this.isKey(e,'ALL')) {
        e.cancel = true;
        if (!this.gridCre.isEdit) {
          this.ActivaCell_Cre(e);
        }
      }
    }
    if (e.key === 'Enter') {
      e.cancel = true;
      if (this.gridCre.isEdit) {
        this.gridCre.saveCell();
      }else {
        this.ActivaCell_Cre(e);
      }
    }
    if (e.key === 'Escape') {
      this.gridCre.closeEdit();
    }
  }
  doubleClick_Cre(event){
    let cIndex=event.cellIndex;
    const field: string = this.gridCre.getColumns()[cIndex].field;
    if (field=='Vence'){
      //this.loadProducts(false);
    }

  }
  beginEdit_Cre($event){
  }
  cellEdit_Cre($event){
    this.creSelColEdit=$event.columnName;
    this.creSelRowEdit=this.creSelRow;
  }

  cellSelected_Cre(event){
    this.creCellActive=event.cellIndex;
    this.creSelRow=this.creCellActive.rowIndex;
    this.creSelCol=this.creCellActive.cellIndex;

  }
  actionBegin_Cre(args: SaveEventArgs) {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
    }
    if (args.requestType === 'save') {
    }
  }
  actionComplete_Cre(e: any): void{
    if (e.requestType === 'batchsave') {
      this.gridCre.closeEdit();
      this.calculos_Cre();
      //this.gridFPago.setCellValue("CRE", "Valor", this.creTotal); //AQUI BIEN
      this.calculos_Pagos();
      this.creCellActive= {
        rowIndex:this.creSelRow,
        cellIndex:this.creSelCol
      };
      this.gridCre.selectCell(this.creCellActive);
    }
  }
  celsave_Cre(args){
    this.gridCre.editSettings.showConfirmDialog=false;
    this.gridCre.editModule.batchSave();
    this.creisCellSave = true;
  }
  celsaved_Cre(args){
    if( this.creisCellSave){
      this.gridCre.editModule.batchSave();
        args.cancel = true;
        if (args.columnName=='Vence' ||  args.columnName=='Tiempo' || args.columnName=='NLetras' || args.columnName=='Garante' || args.columnName=='CIGarante' || args.columnName=='Financ' || args.columnName=='Valor') {
          if (args.columnName!='Valor'){
            if (Number(args.rowData.Valor)==0 && this.Diferencia>0){
              this.gridCre.setCellValue(args.rowData.Itemno, "Valor", this.Diferencia);
            }
          }
          let rowF=this.dataCre.length-1;
          if (this.creSelRowEdit==rowF){
            this.addItem_Cre();
            this.gridCre.refresh();
            window.setTimeout(() => {
              this.gridCre.selectCell(this.creCellActive,false);
            }, 100);
          }
        }
      }
      else{
        this.isKeyPressed = false;
      }
  }
  addItem_Cre(){
    let NItem= this.dataCre.length+1;
    let record : FPagoCre = {
      Itemno: NItem,
      Vence: new Date(),
      Tiempo:'Semanal',
      NLetras:0,
      Garante:'',
      CIGarante:'',
      Financ:0,
      Valor: 0,
      idTiempo:0
    };
    //let rowF=this.dataCre.length-1;
    //if (rowF==0){
    this.dataCre.push(record);
    //}

  }
  delItem_Cre(){
    let rowF=this.dataCre.length-1;
    if (this.creSelRow==rowF){
      return;
    }
    this.gridCre.selectionSettings.mode="Both"
    this.gridCre.selectRow(this.creSelRow);
    this.gridCre.editSettings.showDeleteConfirmDialog=false;

    this.gridCre.deleteRecord();
    this.gridCre.editSettings.showConfirmDialog=false;
    this.gridCre.selectionSettings.mode="Cell"
    this.gridCre.endEdit();

    let i=0;
    for (let item of this.dataCre){
      i++;
      item.Itemno=i;
    }
  }
  calculos_Cre(){
    let total:number=0;
    for (let item of this.dataCre){
      total= total + item.Valor;
    }
    this.creTotal=total;
    this.TotalCXC=total;
  }

  /*****************************************************************/
  /**           Eventos y funciones Pago Tarjeta                  **/
  /*****************************************************************/
  setData_Tar(){
     /*
     this.tarPlanLst = [
      { Id : 1, Name: 'American Express' },
      { Id : 2, Name: 'Diners Club' },
      { Id : 3, Name: 'MasterCard' }
     ];
     */
     //this.tarPlanLst= this.dataInvoice.listCardPlan;
     this._creditcardsService.getCardPlan(this.dataInvoice.total.toString()).subscribe((response : Array<typ_CardPlan>) => {
      if (response) {
        this.listCardPlan=response;
        //console.log(this.listCardPlan);
        let c=1;
        for(let item of this.listCardPlan){
          item.ID=c;
          c++;
        }
      }
    });
    /*
    this.tarEmiLst = [
      { Id : 1, Name: 'Banco Bolivariano' },
      { Id : 2, Name: 'Banco del Pichincha' },
      { Id : 3, Name: 'Banco del Pacifico' }
    ];
    */
    /*
    this._creditcardsService.getEmisorCard().subscribe((response : Array<typ_EmiTar>) => {
      if (response) {
        this.listEmiTar=response;
      }
    });
    */
     this.dataTar    = [];//<FPagoTar[]>dataTar;
     this.setColTar();
     this.calculos_Tar();
     /*
     for(let item of this.listFPago){
       if (item.Ref=="TAR") item.Valor=this.tarTotal;
     }
     */

  }
  setColTar(){
    this.tarPlanParams = {
      create:(args)=>{ // { rowData: object, column: Column }
        this.tarSelRowEdit=this.tarSelRow;
        this.tarSelItemNo=Number(args.data.Itemno);
        this.stateElem = document.createElement('input'); return this.stateElem;
      },
      read:()=>{return this.tarCliObj.text;},
      destroy:()=>{
          let id=Number(this.tarCliObj.value);
          this.gridTar.setCellValue(this.tarSelItemNo, "IdPlan", id);
          this.tarCliObj.destroy();
          /*
          let rowF=this.dataTar.length-1;
          if (this.tarSelRowEdit==rowF){
            this.addItem_Tar();
            this.gridTar.refresh();
          }
          */
      },
      write:()=>{
        let _PlanTar = this.listCardPlan;
        this.tarPlanLst= <any[]>_PlanTar;

        this.tarCliObj = new DropDownList({
          dataSource: this.tarPlanLst,
          fields: { value: 'ID', text: 'BANK'},
          //placeholder: 'Select Taxes',
          floatLabelType: 'Never',
          value:0,
          change: (e) => {
            let id=Number(this.tarCliObj.value);
            //console.log(this.tarSelItemNo);
            this.gridTar.setCellValue(this.tarSelItemNo, "IdPlan", id);
            },
       });
       this.tarCliObj.appendTo(this.stateElem);
     }
    };
    /*
    this.tarEmiParams = {
      create:(args)=>{ // { rowData: object, column: Column }
        this.tarSelRowEdit=this.tarSelRow;
        this.tarSelItemNo=Number(args.data.Itemno);
        this.stateElem = document.createElement('input'); return this.stateElem;
      },
      read:()=>{return this.tarEmiObj.text;},
      destroy:()=>{
          let id=Number(this.tarEmiObj.value);
          this.gridTar.setCellValue(this.tarSelItemNo, "IdEmisor", id);
          this.tarEmiObj.destroy();
      },
      write:()=>{
        let value = this.dataTar[this.tarSelRow].Emisor;
        let _EmiTar = this.listEmiTar;
        this.tarEmiLst= <any[]>_EmiTar;

        this.tarEmiObj = new ComboBox ({ //DropDownList({
          dataSource: this.tarEmiLst,
          fields: { value: 'Id', text: 'Name'},
          placeholder: 'Select Emisor',
          floatLabelType: 'Always',
          allowFiltering:true,
          noRecordsTemplate:"<span class='norecord'> No data availible</span>",
          value:value,
          change: (e) => {
            let id=Number(this.tarEmiObj.value);
            if (Number.isNaN(id)){
              id=null;
            }
            //console.log(this.tarSelItemNo);
            this.gridTar.setCellValue(this.tarSelItemNo, "IdEmisor", id);
            },
       });
       this.tarEmiObj.appendTo(this.stateElem);
     }
    };
    */
  }
  getIndexColumTar(field:string):number{
    let index=0;
    if (this.gridTar) {
      let cols: Array<any>;
      cols= this.gridTar.columns;
      for (let item of cols){
        if (item.field==field){
          return index;
        }
        index++;
      }
    }
    return 0;
  }

  rowSelected_Tar(args: RowSelectEventArgs) {
    this.tarSelRow=args.rowIndex;
  }
  dataBound_Tar($event){
  }
  ActivaCell_Tar(e){
    if ((e.target as HTMLElement).classList.contains('e-rowcell')) {
      const rIndex = Number((e.target as HTMLElement).getAttribute("Index"));
      const cIndex = Number((e.target as HTMLElement).getAttribute("aria-colindex"));
      const i = { rowIndex: rIndex, cellIndex: cIndex };
      const field: string = this.gridTar.getColumns()[cIndex].field;
      this.tarSelCol=cIndex;
      this.tarSelRow=rIndex;
      if (field=='Plan'){
        this.gridTar.editCell((rIndex), field);
      } else {
        let itemNo = this.dataTar[this.tarSelRow].Itemno;
        let char:any;
        if (field=='NumeroTar' || field=='NumeroVau' || field=='Valor'){
          char=Number(e.key);
        }else{
          char=e.key;
        }
        if (e.key!='Enter'){
          let colEdit=['Plan','NumeroTar','NumeroVau','Valor']
          for (let col of colEdit){
            if (col==field){
              this.gridTar.setCellValue(itemNo, field, char);
              break;
            }
          }
        }
        this.gridTar.editCell((rIndex), field);
      }
    }

  }
  keyPressed_Tar(e) {
    if ((e.target as HTMLElement).classList.contains('e-rowcell')) {
      const rIndex = Number((e.target as HTMLElement).getAttribute("Index"));
      const cIndex = Number((e.target as HTMLElement).getAttribute("aria-colindex"));
      const i = { rowIndex: rIndex, cellIndex: cIndex };
      this.tarSelColEdit = this.gridTar.getColumns()[cIndex].field;
    }

    this.isKeyPressed = (e.key == "Enter");
    if (e.key === 'Delete') {
      e.cancel=true;
      this.delItem_Tar();
    }
    if (this.tarSelColEdit=='Plan' || this.tarSelColEdit=='Emisor' || this.tarSelColEdit=='NumeroTar' || this.tarSelColEdit=='NumeroVau' || this.tarSelColEdit=='Valor' ){
      if (this.isKey(e,'ALL')) {
        e.cancel = true;
        if (!this.gridTar.isEdit) {
          this.ActivaCell_Tar(e);
        }
      }
    }
    if (e.key === 'Enter') {
      e.cancel = true;
      if (this.gridTar.isEdit) {
        this.gridTar.saveCell();
      }else {
        this.ActivaCell_Tar(e);
      }
    }
    if (e.key === 'Escape') {
      this.gridTar.closeEdit();
    }
  }
  doubleClick_Tar(event){
    let cIndex=event.cellIndex;
    const field: string = this.gridTar.getColumns()[cIndex].field;
    if (field=='Plan'){
      //this.loadProducts(false);
    }

  }
  beginEdit_Tar($event){
  }
  cellEdit_Tar($event){
    this.tarSelColEdit=$event.columnName;
    this.tarSelRowEdit=this.tarSelRow;
  }

  cellSelected_Tar(event){
    this.tarCellActive=event.cellIndex;
    this.tarSelRow=this.tarCellActive.rowIndex;
    this.tarSelCol=this.tarCellActive.cellIndex;
  }
  actionBegin_Tar(args: SaveEventArgs) {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
    }
    if (args.requestType === 'save') {
    }
  }
  actionComplete_Tar(e: any): void{
    if (e.requestType === 'batchsave') {
     this.gridTar.closeEdit();
     this.calculos_Tar();
     //this.gridFPago.setCellValue("TAR", "Valor", this.tarTotal); //AQUI BIEN
     this.calculos_Pagos();
     this.tarCellActive= {
        rowIndex:this.tarSelRow,
        cellIndex:this.tarSelCol
    };
     this.gridTar.selectCell(this.tarCellActive);
    }
  }
  celsave_Tar(args){
    this.gridTar.editSettings.showConfirmDialog=false;
    this.gridTar.editModule.batchSave();
    this.tarisCellSave = true;
  }
  celsaved_Tar(args){
    //debugger
    if( this.tarisCellSave){
      this.gridTar.editModule.batchSave();
        args.cancel = true;
        if (args.columnName=='Plan' ||  args.columnName=='NumeroTar' || args.columnName=='NumeroVau' || args.columnName=='Valor') {
          if (args.columnName!='Valor'){
            if (Number(args.rowData.Valor)==0 && this.Diferencia>0){
              this.gridTar.setCellValue(args.rowData.Itemno, "Valor", this.Diferencia);
            }
          }
          let rowF=this.dataTar.length-1;
          if (this.tarSelRowEdit==rowF){
            this.addItem_Tar();
            this.gridTar.refresh();
            window.setTimeout(() => {
              this.gridTar.selectCell(this.tarCellActive,false);
            }, 100);
          }
        }

      }
      else{
        this.isKeyPressed = false;
      }
  }
  addItem_Tar(){
    let NItem= this.dataTar.length+1;
    let record : FPagoTar = {
      Itemno: NItem,
      Plan: '',
      //Emisor:'',
      NumeroTar:'',
      NumeroVau:'',
      Lote:'',
      Valor:0.0,
      IdPlan:0,
      //IdEmisor:0
    };
    //let rowF=this.dataTar.length-1;
    //if (rowF==0){
      this.dataTar.push(record);
    //}

  }
  delItem_Tar(){
    let rowF=this.dataTar.length-1;
    if (this.tarSelRow==rowF){
      return;
    }

    this.gridTar.selectionSettings.mode="Both"
    this.gridTar.selectRow(this.tarSelRow);
    this.gridTar.editSettings.showDeleteConfirmDialog=false;
    this.gridTar.deleteRecord();
    this.gridTar.editSettings.showConfirmDialog=false;
    this.gridTar.selectionSettings.mode="Cell"
    this.gridTar.endEdit();


    let i=0;
    for (let item of this.dataTar){
      i++;
      item.Itemno=i;
    }
  }
  calculos_Tar(){
    let total:number=0;
    for (let item of this.dataTar){
      total= total + item.Valor;
    }
    this.tarTotal=total;
    this.TotalTAR=total;
  }



  /*****************************************************************/
  /**           Eventos y funciones Transferencia Bancaria        **/
  /*****************************************************************/
  setData_Trb(){

    this.dataTrb    = [];

    this.setColTrb();
    this.calculos_Trb();
    /*
    for(let item of this.listFPago){
      if (item.Ref=="CHE") item.Valor=this.cheTotal;
    }
    */
 }

 setColTrb(){
  this.trbBanParams = {
    create:(args)=>{ // { rowData: object, column: Column }
      this.trbSelRowEdit=this.trbSelRow;
      this.trbSelItemNo=Number(args.data.Itemno);
      this.stateElem = document.createElement('input'); return this.stateElem;
    },
    read:()=>{return this.trbObj.text;},
    destroy:()=>{
        let id=this.trbObj.value;
        this.gridTrb.setCellValue(this.trbSelItemNo, "IdBanco", id);
        this.trbObj.destroy();
    },
    write:()=>{
      this.trbBanLst= <any[]>this.listBankEmp;

      this.trbObj = new DropDownList({
        dataSource: this.trbBanLst,
        fields: { value: 'ID_BANCO', text: 'NOMBRE'},
        floatLabelType: 'Never',
        value:0,
        change: (e) => {
          let id=this.trbObj.value;
          this.gridTrb.setCellValue(this.trbSelItemNo, "IdBanco", id);
          },
     });
     this.trbObj.appendTo(this.stateElem);
   }
  };
 }
 getIndexColumTrb(field:string):number{
   let index=0;
   if (this.gridTrb) {
     let cols: Array<any>;
     cols= this.gridTrb.columns;
     for (let item of cols){
       if (item.field==field){
         return index;
       }
       index++;
     }
   }
   return 0;
 }

 rowSelected_Trb(args: RowSelectEventArgs) {
   this.trbSelRow=args.rowIndex;
 }
 dataBound_Trb($event){
 }

 ActivaCell_Trb(e){
   //console.log(e);
   if ((e.target as HTMLElement).classList.contains('e-rowcell')) {
     const rIndex = Number((e.target as HTMLElement).getAttribute("Index"));
     const cIndex = Number((e.target as HTMLElement).getAttribute("aria-colindex"));
     const i = { rowIndex: rIndex, cellIndex: cIndex };
     const field: string = this.gridTrb.getColumns()[cIndex].field;
     this.trbSelCol=cIndex;
     this.trbSelRow=rIndex;
     if (field=='Banco'){
       this.gridTrb.editCell((rIndex), field);
     } else {
       let itemNo = this.dataTrb[this.trbSelRow].Itemno;
       let char:any;
       if (field=='Numero' || field=='Valor'){
         char=Number(e.key);
       }else{
         char=e.key;
       }
       if (e.key!='Enter'){
         let colEdit=['Banco','Numero','Fecha','Valor']
         for (let col of colEdit){
           if (col==field){
             this.gridTrb.setCellValue(itemNo, field, char);
             break;
           }
         }
       }
       this.gridTrb.editCell((rIndex), field);
     }
   }

 }
 keyPressed_Trb(e) {
   //console.log(e);
   if ((e.target as HTMLElement).classList.contains('e-rowcell')) {
     const rIndex = Number((e.target as HTMLElement).getAttribute("Index"));
     const cIndex = Number((e.target as HTMLElement).getAttribute("aria-colindex"));
     const i = { rowIndex: rIndex, cellIndex: cIndex };
     this.trbSelColEdit = this.gridTrb.getColumns()[cIndex].field;
   }

   this.isKeyPressed = (e.key == "Enter");
   if (e.key === 'Delete') {
     e.cancel=true;
     this.delItem_Trb();
   }
   if (this.trbSelColEdit=='Banco' || this.trbSelColEdit=='Numero' || this.trbSelColEdit=='Fecha' || this.trbSelColEdit=='Valor' ){
     if (this.isKey(e,'ALL')) {
       e.cancel = true;
       if (!this.gridTrb.isEdit) {
         this.ActivaCell_Trb(e);
       }
     }
   }
   if (e.key === 'Enter') {
     e.cancel = true;
     if (this.gridTrb.isEdit) {
       this.gridTrb.saveCell();
     }else {
       this.ActivaCell_Trb(e);
     }
   }
   if (e.key === 'Escape') {
     this.gridTrb.closeEdit();
   }
 }
 doubleClick_Trb(event){
   let cIndex=event.cellIndex;
   const field: string = this.gridTrb.getColumns()[cIndex].field;
   if (field=='Banco'){
     //this.loadProducts(false);
   }

 }
 beginEdit_Trb($event){
 }
 cellEdit_Trb($event){
   this.trbSelColEdit=$event.columnName;
   this.trbSelRowEdit=this.trbSelRow;
   //console.log($event);
 }

 cellSelected_Trb(event){
   this.trbCellActive=event.cellIndex;
   this.trbSelRow=this.trbCellActive.rowIndex;
   this.trbSelCol=this.trbCellActive.cellIndex;
 }
 actionBegin_Trb(args: SaveEventArgs) {
   if (args.requestType === 'beginEdit' || args.requestType === 'add') {
   }
   if (args.requestType === 'save') {
   }
 }
 actionComplete_Trb(e: any): void{
   //console.log(e);
   if (e.requestType === 'batchsave') {
     this.gridTrb.closeEdit();
     this.calculos_Trb();
     this.calculos_Pagos();
     this.trbCellActive= {
       rowIndex:this.trbSelRow,
       cellIndex:this.trbSelCol
     };
     this.gridTrb.selectCell(this.trbCellActive);
   }
 }
 celsave_Trb(args){
   this.gridTrb.editSettings.showConfirmDialog=false;
   this.gridTrb.editModule.batchSave();
   this.trbisCellSave = true;
 }
 celsaved_Trb(args){
   //debugger
   if( this.trbisCellSave){
     this.gridTrb.editModule.batchSave();
       args.cancel = true;
       if (args.columnName=='Banco' ||  args.columnName=='Numero' || args.columnName=='Fecha' || args.columnName=='Valor') {
         if (args.columnName!='Valor'){
           if (Number(args.rowData.Valor)==0 && this.Diferencia>0){
             this.gridTrb.setCellValue(args.rowData.Itemno, "Valor", this.Diferencia);
           }
         }
         let rowF=this.dataTrb.length-1;
         if (this.trbSelRowEdit==rowF){
           this.addItem_Trb();
           this.gridTrb.refresh();
           window.setTimeout(() => {
             this.gridTrb.selectCell(this.trbCellActive,false);
           }, 100);
         }
       }

     }
     else{
       this.isKeyPressed = false;
     }
 }
 addItem_Trb(){
   let NItem= this.dataTrb.length+1;
   let record : FPagoTrb = {
     Itemno: NItem,
     IdBanco:'',
     Banco: '',
     Numero:'',
     Fecha: new Date(),
     Valor:0.0,
   };
     this.dataTrb.push(record);

 }
 delItem_Trb(){
   let rowF=this.dataTrb.length-1;
   if (this.trbSelRow==rowF){
     return;
   }

   this.gridTrb.selectionSettings.mode="Both"
   this.gridTrb.selectRow(this.trbSelRow);
   this.gridTrb.editSettings.showDeleteConfirmDialog=false;
   this.gridTrb.deleteRecord();
   this.gridTrb.editSettings.showConfirmDialog=false;
   this.gridTrb.selectionSettings.mode="Cell"
   this.gridTrb.endEdit();

   let i=0;
   for (let item of this.dataTrb){
     i++;
     item.Itemno=i;
   }
 }

 calculos_Trb(){
   let total:number=0;
   for (let item of this.dataTrb){
     total= total + item.Valor;
   }
   this.trbTotal=total;
   this.TotalTRB=total;
 }




  /*****************************************************************/
  /**                   Calculos Generales                        **/
  /*****************************************************************/
  calculos_Pagos(){
     this.totalPagos=0;
     /*
     for (let item of this.listFPago){
        this.totalPagos=this.totalPagos+item.Valor
     }
     */
     this.totalPagos=this.TotalEFE+this.TotalCHE+this.TotalTAR+this.TotalCXC+this.TotalTRB+this.TotalRET+this.TotalNCC+this.TotalANT;
     this.Diferencia=this.Total-this.totalPagos;
   }
  /*****************************************************************/
  /**           Eventos y funciones de grids Generales            **/
  /*****************************************************************/

  insertItem(){
    if (this.isVisibleEfe){

    }
    if (this.isVisibleChe){
      this.addItem_Che();
      this.gridChe.refresh();
    }
    if (this.isVisibleCre){
      this.addItem_Cre();
      this.gridCre.refresh();
    }
    if (this.isVisibleTar){
      this.addItem_Tar();
      this.gridTar.refresh();
    }
    if (this.isVisibleTrb){
      this.addItem_Trb();
      this.gridTrb.refresh();

    }

  }
  deleteItem(){
    if (this.isVisibleEfe){

    }
    if (this.isVisibleChe){
      this.delItem_Che();
    }
    if (this.isVisibleCre){
      this.delItem_Cre();
    }
    if (this.isVisibleTar){
      this.delItem_Tar();
    }
    if (this.isVisibleTrb){
      this.delItem_Trb();
    }


  }
  closeDialog() {
    let result: object = {
      success:false,
      data:null
    }
    this.dialogInstance.close(result);
  }

  /*****************************************************************/
  /**           Validaciones antes de enviar Objeto               **/
  /*****************************************************************/

  messageError(type:string,row:number,col:string,message:string){
    if (type=='EFE'){
      this.isVisibleEfe=true;
      this.isVisibleChe=false;
      this.isVisibleCre=false;
      this.isVisibleTar=false;
      this.isVisibleTrb=false;
    }
    if (type=='CHE'){
      this.isVisibleEfe=false;
      this.isVisibleChe=true;
      this.isVisibleCre=false;
      this.isVisibleTar=false;
      this.isVisibleTrb=false;
    }
    if (type=='TAR'){
      this.isVisibleEfe=false;
      this.isVisibleChe=false;
      this.isVisibleCre=false;
      this.isVisibleTar=true;
      this.isVisibleTrb=false;
    }
    if (type=='CXC'){
      this.isVisibleEfe=false;
      this.isVisibleChe=false;
      this.isVisibleCre=true;
      this.isVisibleTar=false;
      this.isVisibleTrb=false;
    }
    if (type=='TRB'){
      this.isVisibleEfe=false;
      this.isVisibleChe=false;
      this.isVisibleCre=false;
      this.isVisibleTar=false;
      this.isVisibleTrb=true;
    }
    this._translateService.get(message).subscribe((errorMessage: string) => {
      this._toastrService.showError(errorMessage, 'Error');
    });
    window.setTimeout(() => {
      if (type=='EFE') {

      }
      if (type=='CHE') {
        let CellActive= {
          rowIndex  : row,
          cellIndex : this.getIndexColumChe(col)
        };
        this.gridChe.selectCell(CellActive,false);
      }
      if (type=='CXC') {
        let CellActive= {
          rowIndex  : row,
          cellIndex : this.getIndexColumCre(col)
        };
        this.gridCre.selectCell(CellActive,false);
      }
      if (type=='TAR') {
        let CellActive= {
          rowIndex  : row,
          cellIndex : this.getIndexColumTar(col)
        };
        this.gridTar.selectCell(CellActive,false);
      }
      if (type=='TRB') {
        let CellActive= {
          rowIndex  : row,
          cellIndex : this.getIndexColumTrb(col)
        };
        this.gridTrb.selectCell(CellActive,false);
      }
    }, 100);

  }
  onValidationCtrl():boolean{
    /*
    if (this.Form.invalid) {
      this._translateService.get('ErrorForm').subscribe((errorMessage: string) => {
        this._toastrService.showError(errorMessage, 'Error');
      });
      return true;
    }
    */
    return false;

  }
  allTrim(value:string){
    var cad='';
    var result='';
    if (value){
      cad=value.toString();
      result=cad.replace(/\s/g, "");
    }
    return result;
  }
  onValidationGrids():boolean{
    /*-------Validacion de Efectivo-----*/
    if (this.TotalEFE>0){
      if (this.cajaId<=0){
        this.messageError('EFE',0,'','Select.cash.register');
        return true;
      }
    }

    /*-------Validacion de Cheques-----*/
    let NItemChe=0;
    if (this.dataChe){
      NItemChe = this.dataChe.length;
      //console.log(NItemChe);
      if (NItemChe>1){
        let row=0;
        for (let item of this.dataChe){
          if (row<(NItemChe-1)){
            if (item.Cuenta==''){
              this.messageError('CHE',row,'Cuenta','Select.customer.account');
              return true;
            }
            if (item.Numero==''){
              this.messageError('CHE',row,'Numero','Write.check.number');
              return true;
            }
            if (item.Valor<=0){
              this.messageError('CHE',row,'Valor','Write.check.value');
              return true;
            }

          }
          row++;
        }
      }
    }
    /*-------Validacion de Credito-----*/
    let NItemCre=0;
    if (this.dataCre){
      NItemCre = this.dataCre.length;
      if (NItemCre>1){
        let row=0;
        for (let item of this.dataCre){
          if (row<(NItemCre-1)){
            if (item.Tiempo==''){
              this.messageError('CXC',row,'Tiempo','Select.credit.time');
              return true;
            }
            if (item.NLetras<0){
              this.messageError('CXC',row,'NLetras','Write.number.of.letters');
              return true;
            }
            /*
            if (item.Garante==''){
              this.messageError('CXC',row,'Garante','Write guarantor name');
              return true;
            }
            if (item.CIGarante==''){
              this.messageError('CXC',row,'CIGarante','Write guarantor identification');
              return true;
            }
            if (item.Financ<=0){
              this.messageError('CXC',row,'Financ','Write financing');
              return true;
            }
            */
            if (item.Valor<=0){
              this.messageError('CXC',row,'Valor','Write.credit.value');
              return true;
            }
          }
          row++;
        }
      }
    }
    /*-------Validacion de Tarjeta-----*/
    let NItemTar=0;
    if (this.dataTar){
      NItemTar = this.dataTar.length;
      if (NItemTar>1){
        let row=0;
        for (let item of this.dataTar){
          if (row<(NItemTar-1)){
            
            if (item.IdPlan<=0 && this.allTrim(item.NumeroTar)=='' && this.allTrim(item.NumeroVau)=='' && item.Valor<=0){
              this.messageError('TAR',row,'Plan','Empty.record.please.fill.or.delete');
              return true;
            }
            if (item.IdPlan<=0){
              this.messageError('TAR',row,'Plan','Select.card.plan');
              return true;
            }
            /*
            if (this.allTrim(item.Emisor)==''){
              this.messageError('TAR',row,'Emisor','Select.issuing.entity');
              return true;
            }*/
            if (this.allTrim(item.NumeroTar)==''){
              this.messageError('TAR',row,'NumeroTar','Write.card.number');
              return true;
            }
            if (this.allTrim(item.NumeroVau)==''){
              this.messageError('TAR',row,'NumeroVau','Write.vouchers.number');
              return true;
            }
            if (item.Valor<=0){
              this.messageError('TAR',row,'Valor','Write.card.value');
              return true;
            }
          }
          row++;
        }
      }
    }

    /*-------Validacion de Trans.Bancaria-----*/
    let NItemTrb=0;
    if (this.dataTrb){
      NItemTrb = this.dataTrb.length;
      //console.log(NItemChe);
      if (NItemTrb>1){
        let row=0;
        for (let item of this.dataTrb){
          if (row<(NItemTrb-1)){
            if (item.Banco==''){
              this.messageError('TRB',row,'Banco','Select.Bank');
              return true;
            }
            if (item.Numero==''){
              this.messageError('TRB',row,'Numero','Write.check.number');
              return true;
            }
            if (item.Valor<=0){
              this.messageError('TRB',row,'Valor','Write.check.value');
              return true;
            }
          }
          row++;
        }
      }
    }


    if (this.enableInvoiceZero==false){
      if (this.TotalEFE<=0 && NItemChe==1 && NItemCre==1 && NItemTar==1 && NItemTrb==1 ){
        this._translateService.get('Select.at.least.one.payment.method').subscribe((errorMessage: string) => {
          this._toastrService.showError(errorMessage, 'Error');
        });
        return true;
      }
    }
    if (this.Diferencia>0 ){
      this._translateService.get('Complete.the.form.of.payment.there.is.a.difference').subscribe((errorMessage: string) => {
        this._toastrService.showError(errorMessage, 'Error');
      });
      return true;
    }

    if (this.Diferencia<0 ){
      if (NItemChe>=2 || NItemCre>=2 || NItemTar>=2 || NItemTrb>=2 ){
        this._translateService.get('You.cannot.give.cash.change').subscribe((errorMessage: string) => {
          this._toastrService.showError(errorMessage, 'Error');
        });
        return true;
      }
    }

    return false;
  }
  /*****************************************************************/
  /**               Envio de Objeto JSON                          **/
  /*****************************************************************/
  onAccept(){
    /*
    if (this.onValidationCtrl()){
      return;
    }
    */
    if (this.onValidationGrids()){
      return;
    }
    console.log(this.efeTotal);

    let tempEfec:PagoEfectivo;
    if (this.TotalEFE>0 )
    {
      tempEfec = {
        IdCash         : this.cajaId,
        Valor          : this.efeTotal, //this.Total
        CambioEfectivo : this.Diferencia
      }
    }
    let tempBanco:Array<PagoBanco>=[];
    let NItemChe = this.dataChe.length;
    if (NItemChe>1){
      let row=0;
      for (let data of this.dataChe){
        if (row<(NItemChe-1)){
          let item : PagoBanco = {
            Banco             : data.IdCheCli.toString(),
            Cuenta            : data.Cuenta,
            NumeroCheque      : data.Numero,
            Emision           : data.Girado,
            Vence             : data.Girado,
            Valor             : data.Valor,
            Detail            : '',
            CustmerReference  : ''
          }
          tempBanco.push(item);
        }
        row++;
      }
    }

    let tempCredito:Array<PagoCredito>=[];
    let NItemCre = this.dataCre.length;
    if (NItemCre>1){
      let row=0;
      for (let data of this.dataCre){
        if (row<(NItemCre-1)){

          let item : PagoCredito = {
            NLetras     : data.NLetras,
            Vence       : data.Vence,
            Tiempo      : data.idTiempo.toString(),//.Tiempo,
            Garante     : data.Garante,
            CIGarante   : data.CIGarante,
            Financ      : data.Financ,
            Valor       : data.Valor
          }
          tempCredito.push(item);
        }
        row++;
      }
    }

    let tempTarjeta:Array<PagoTarjeta>=[];
    let NItemTar = this.dataTar.length;
    if (NItemTar>1){
      let row=0;
      for (let data of this.dataTar){
        if (row<(NItemTar-1)){
          let item : PagoTarjeta = {
            IdPlanTar     : data.IdPlan,
            //IdEmisor      : data.IdEmisor,
            NumeroTarjeta : data.NumeroTar,
            NumeroVaucher : data.NumeroVau,
            Lote          : data.Lote,
            Valor         : data.Valor,
          }
          tempTarjeta.push(item);
        }
        row++;
      }
    }
    let tempTrb:Array<PagoTrDep>=[];
    let NItemTrb = this.dataTrb.length;
    if (NItemTrb>1){
      let row=0;
      for (let data of this.dataTrb){
        if (row<(NItemTrb-1)){
          let item : PagoTrDep = {
            Banco         : data.IdBanco.toString(),
            Tipo          : '',
            Fecha         : data.Fecha,
            Numero        : data.Numero,
            Valor         : data.Valor
          }
          tempTrb.push(item);
        }
        row++;
      }
    }

    let tempDataFPago: Type_fPagosNew = {
      PagoEfectivo  : tempEfec,
      PagoBanco     : tempBanco,
      PagoTarjeta   : tempTarjeta,
      PagoCredito   : tempCredito,
      PagoTrDep     : tempTrb
    }
    let tempDataExtra : Type_dataExtra = {
      cajaId  : this.cajaId,
      APagar  : this.totalPagos
    }
    let result: object = {
      success   : true,
      dataExtra : tempDataExtra,
      data      : tempDataFPago //this.getForm()
    }
    //console.log(result);
    this.dialogInstance.close(result);
  }

  onDivVisible(selector, visible) {
    var elemento = document.querySelector(selector);
    //console.log(elemento);
    if (elemento != null) {
      elemento.style.display = visible?'block':'none';
    }
  }

  onConsolse(){

  }



}
