import { SessionStorageService } from 'angular-web-storage';
import { WebResult, InvoiceItem, WebEnterprise } from '../../models';
import { Injectable } from '@angular/core';
import { KeyMaps } from '../../mappings/keyMaps';
import { ExpiredUnit } from 'angular-web-storage/src/util';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { toInt } from 'ngx-bootstrap/chronos/utils/type-checks';
import { DocumentResourceParameters } from 'src/app/helpers';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  sessionInfoBehavior = new BehaviorSubject<WebResult>(this.getSessionInfo());
  sessionInfoEnterprise = new BehaviorSubject<WebEnterprise>(this.getSessionEnterprise());

  //private loadingEmp = new BehaviorSubject(false);
  loadingObsEmp = this.sessionInfoEnterprise.asObservable();


  constructor(private _sessionStorageService: SessionStorageService,
    private router: Router) { }

  getSessionInfo(): WebResult {
    const sessionInfo = this._sessionStorageService.get(KeyMaps.SESSION_INFO);
    if (sessionInfo !== null && sessionInfo !== undefined) {
      return sessionInfo as WebResult;
    } else {
      return null;
    }
  }

  getSessionPermision(permision:string): boolean {
    let value:boolean=false;
    if (this.getSessionInfo().Extras['UserPermissions']){
      let UserPermissions=this.getSessionInfo().Extras.UserPermissions;
      for (let item of UserPermissions){
        if (item.Name==permision){
          value=item.Value;
          break;
        }
      }
      //if (permision=='MULTIADMIN'){ value=true;} //Simulando Variable
    }
    return value;
  }

  getSessionPermisionEnterprice(permision:string): boolean {
    let value:boolean=false;
    if (this.getSessionInfo().Extras['enterprise']){
      let enterprise=this.getSessionInfo().Extras.enterprise;
      if (enterprise['Config']){
          let Config=enterprise.Config;
          for (let item of Config){
            if (item.Name==permision){
              if (typeof item.Value == "boolean") {
                value=item.Value;
                break;
              }
              if (Number(item.Value)==1) {
                value=true;
              } else {
                value=false;
              }
              break;
            }
          }
      }
    }
    return value;
  }
  setSessionInfo(data: any, expireAt: number, expireUnit: ExpiredUnit) {
    this._sessionStorageService.set(KeyMaps.SESSION_INFO, data, expireAt, expireUnit);
    this.sessionInfoBehavior.next(this.getSessionInfo());
  }


  setSessionEnterprise(data: any) {
    this._sessionStorageService.set(KeyMaps.SESSION_ENTERPRISE, data);
    this.sessionInfoEnterprise.next(this.getSessionEnterprise());
  }
  getSessionEnterprise(): WebEnterprise {
    const sessionEnterprise = this._sessionStorageService.get(KeyMaps.SESSION_ENTERPRISE);
    if (sessionEnterprise !== null && sessionEnterprise !== undefined) {
      return sessionEnterprise as WebEnterprise;
    } else {
      return null;
    }
  }


  isDefaultUser(): boolean {
    const sessionInfo = this.getSessionInfo();
    if (sessionInfo !== null && sessionInfo !== undefined) {
      if (sessionInfo.Extras.name === '') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  isLoggedIn() {
    const sessionInfo = this.getSessionInfo();
    if (sessionInfo !== null && sessionInfo !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  isAdminOrSalesman(): boolean {
    const sessionInfo = this.getSessionInfo();
    let response = false;
    if (sessionInfo && sessionInfo !== undefined) {
      if (sessionInfo.Role === 'admin' || sessionInfo.Role === 'salesman') {
        response = true;
      }
    }
    return response;
  }

  isAdmin() {
    const sessionInfo = this.getSessionInfo();
    if (sessionInfo && sessionInfo !== undefined) {
      if (sessionInfo.Role === 'admin') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isSalesman() {
    const sessionInfo = this.getSessionInfo();
    if (sessionInfo && sessionInfo !== undefined) {
      if (sessionInfo.Role === 'salesman') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isCustomer(): boolean {
    const sessionInfo = this.getSessionInfo();
    let response = false;
    if (sessionInfo && sessionInfo !== undefined) {
      if (sessionInfo.Role === 'customer') {
        response = true;
      }
    }
    return response;
  }

  hasHumanResourcesPermission() {
    const sessionInfo = this.getSessionInfo();
    if (sessionInfo && sessionInfo !== undefined) {
      if (sessionInfo.Extras.ApruebaPermisoPersonal == 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  hasPermissionByName(permissionName: string): boolean {
    const sessionInfo = this.getSessionInfo();
    if (sessionInfo && sessionInfo !== undefined) {
      if (sessionInfo.Extras && sessionInfo.Extras[permissionName] === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  hasApprovalForClockingPermission() {
    const sessionInfo = this.getSessionInfo();
    if (sessionInfo && sessionInfo !== undefined) {
      if (sessionInfo.Extras.ApruebaMarcacionPersonal == 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  hasApprovalForLoans() {
    const sessionInfo = this.getSessionInfo();
    if (sessionInfo && sessionInfo !== undefined) {
      if (sessionInfo.Extras.ApruebaPrestamoPersonal == 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  hasApprovalForAdvancePayments() {
    const sessionInfo = this.getSessionInfo();
    if (sessionInfo && sessionInfo !== undefined) {
      if (sessionInfo.Extras.ApruebaAnticipoPersonal == 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  hasApprovalForVacations() {
    const sessionInfo = this.getSessionInfo();
    if (sessionInfo && sessionInfo !== undefined) {
      if (sessionInfo.Extras.ApruebaVacacionpersona == 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  clearSession() {
    this._sessionStorageService.clear();
    this.router.navigate(['/home']);
  }

  setIsLodadedState(data: boolean) {
    this._sessionStorageService.set(KeyMaps.STATE, data);
  }

  getIsLoaded(): boolean {
    const state = this._sessionStorageService.get(KeyMaps.STATE);
    return state != null ? state : false;
  }

  setImageService(data: string) {
    this._sessionStorageService.set(KeyMaps.IMAGE_SERVICE, data);
  }

  getImageService(): string {
    return this._sessionStorageService.get(KeyMaps.IMAGE_SERVICE);
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });
  }

  getPrices(): Array<any>
  {
    var listPrice  : Array<any> = [];
    console.log(this.getSessionPermision('PRECIO1'));
    console.log(this.getSessionPermisionEnterprice('USUACCPRE'));
    if(this.getSessionPermision('PRECIO1') && this.getSessionPermisionEnterprice('USUACCPRE'))
    {
      console.log(this.getSessionPermision('PRECIO3'));
      listPrice.push({ id : 1, name: 'Precio 1' });
      if(this.getSessionPermision('PRECIO2')) listPrice.push({ id : 2, name: 'Precio 2' });
      if(this.getSessionPermision('PRECIO3')) listPrice.push({ id : 3, name: 'Precio 3' });
      if(this.getSessionPermision('PRECIO4')) listPrice.push({ id : 4, name: 'Precio 4' });
      if(this.getSessionPermision('PRECIO5')) listPrice.push({ id : 5, name: 'Precio 5' });
      if(this.getSessionPermision('PRECIO6')) listPrice.push({ id : 6, name: 'Precio 6' });
      if(this.getSessionPermision('PRECIO7')) listPrice.push({ id : 7, name: 'Precio 7' });
      if(this.getSessionPermision('PRECIO8')) listPrice.push({ id : 8, name: 'Precio 8' });
    }
    else
    {
      listPrice = [
        { id : 0, name: 'P.V.P'},
        { id : 1, name: 'Precio 1' },
        { id : 2, name: 'Precio 2' },
        { id : 3, name: 'Precio 3' },
        { id : 4, name: 'Precio 4' },
        { id : 5, name: 'Precio 5' },
        { id : 6, name: 'Precio 6' },
        { id : 7, name: 'Precio 7' },
        { id : 8, name: 'Precio 8' }
      ];
    }
    return listPrice;
  }

  setSearchParamsForJournal(data: DocumentResourceParameters) {
    this._sessionStorageService.set("searchJournalParams", data);
  }

  getSearchParamsForJournal(key: string) {
    return this._sessionStorageService.get(key);
  }
}
