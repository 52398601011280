import { Injectable, Injector } from "@angular/core";
import { BaseService } from "../base/base.service";
import { Observable } from "rxjs";
import { BaseResult, Employee, EmployeeRecord, PagedResult } from "../../models";
import { DocumentResourceParameters, PersonnelResourceParameters } from "../../helpers";
import { GenericData } from "src/app/models/RRHH/GenericData";
import { IDName } from "src/app/models/Responses/IDName";
import { HttpParams } from "@angular/common/http";
import { HRJobPosition } from "src/app/models/RRHH/EmployeeRecord";

@Injectable({
  providedIn: "root",
})
export class PersonnelService extends BaseService {
  constructor(injetor: Injector) {
    super(injetor);
  }

  getPersonnel(args: PersonnelResourceParameters): Observable<PagedResult<EmployeeRecord>> {
    const url = this.getApiQuery('RRHH/Employee/Pagging');
    let params = new HttpParams();
    if (args.SearchTerm) {
      params = params.append('SearchTerm', args.SearchTerm);
    }
    params = params.append('PageNumber', args.PageNumber.toString());
    params = params.append('PageSize', args.PageSize.toString());

    return this._httpClient.get<PagedResult<EmployeeRecord>>(url, { headers: this.getAuthorizationHeader(), params: params });
  }

  getEmployee(searchTerm: string): Observable<EmployeeRecord> {
    const url = this.getApiQuery(`RRHH/Employee/CV/WEB?ID_PER=${searchTerm}`);
    return this._httpClient.get<EmployeeRecord>(url, {
      headers: this.getAuthorizationHeader(),
    });
  }

  getGenericData(): Observable<GenericData> {
    const url = this.getApiQuery(`RRHH/GetCountry`);
    return this._httpClient.get<GenericData>(url, {
      headers: this.getAuthorizationHeader(),
    });
  }

  savePersonnel(personnel: EmployeeRecord) {
    const url = this.getApiQuery('RRHH/Employee/CV');
    return this._httpClient.post(url, personnel, { headers: this.getAuthorizationHeader() });
  }

  deletePersonnel(id: string) {
    const url = this.getApiQuery(`RRHH/Employee/Delete?ID_PER=${id}`);
    return this._httpClient.post<BaseResult>(url, { headers: this.getAuthorizationHeader() });
  }

  getDeparments() {
    const url = this.getApiQuery('ControlEditor/DEPARTAMENTOPERSONAL');
    return this._httpClient.get<IDName[]>(url, {
      headers: this.getAuthorizationHeader(),
    });
  }

  getAreas() {
    const url = this.getApiQuery('Areas');
    return this._httpClient.get<IDName[]>(url, {
      headers: this.getAuthorizationHeader(),
    });
  }

  getJobPositions() {
    const url = this.getApiQuery('ControlEditor/CARGOPERSONAL');
    return this._httpClient.get<HRJobPosition[]>(url, {
      headers: this.getAuthorizationHeader(),
    });
  }

  getLiteEmployee(ID_PER) {
    let params: HttpParams = new HttpParams();
    params = params.append('ID_PER',ID_PER);

    const url = this.getApiQuery(`RRHH/Employee/RecordLite`);
    return this._httpClient.get(url, {
      params: params,
      headers: this.getAuthorizationHeader()
    });
  }

  // getLiteEmployee(idPersonnel: string): Observable<EmployeeRecord> {
  //   const url = this.getApiQuery(`RRHH/Employee/RecordLite?ID_PER=${idPersonnel}`);
  //   return this._httpClient.get<EmployeeRecord>(url, {
  //     headers: this.getAuthorizationHeader(),
  //   });
  // }
}
