import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { InvoiceItem } from '../models';
import { APP_SETTINGS } from '../appsettings';
import { CustomBaseCurrencyPipe } from './custom-base-currency.pipe';

// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({
  name: 'itemsTotal'
})
export class ItemsTotalPipe extends CustomBaseCurrencyPipe {

  calculate(items: Array<InvoiceItem>, display: 'narrow' | 'wide') {
    if (items) {
      let total = 0.00;
      items.forEach(item => {
        //total += (item.Price * item.Quantity) - (item.Discount > 0
        //   ? (item.Price * item.Quantity) * (item.Discount / 100) : 0)
        //   + ((item.Price * item.Quantity) * (item.VAT / 100));
        total += this.Round(item.Price * (item.Quantity > 0 ? item.Quantity : 0) * (1 - item.Discount / 100),2) * (1+(item.VAT / 100));
      });

      return formatCurrency(total,
        this.locale,
        getCurrencySymbol(APP_SETTINGS.formats.currencyCode, display),
        APP_SETTINGS.formats.currencyCode,
        APP_SETTINGS.formats.digitsInfo);
    } else {
      return null;
    }
  }
}
