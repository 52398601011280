import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Injectable, Injector, Renderer2, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { SweetAlertService } from 'src/app/services';
import { BaseService } from 'src/app/services/base/base.service';
import { paramsConfig } from '../dinamic-crud.component';
import { SearchGridService } from '../../../widgets/searchGrid/searchGrid.service';

//https://qastack.mx/programming/32494174/can-you-create-nested-classes-in-typescript  


/**************************************************************************/
/**   Definicion de Typos para la variable Config que se usará en Padre  **/
/**************************************************************************/

export enum typeButton {
  normal,
  save,
  cancel,
  search
}

export interface objValidate{
  require     : boolean;
  msgError    : string;
}
export interface objFrame{
  type        : string;
  name        : string;
  placeholder?: string;
  class       : string;
  style?      : string;
  col         : string;
  maxleght?   : number;  
  validate?   : objValidate;
}
export interface objGprFrame extends objFrame{
  groupRow?   : boolean;
  label?      : string;
  labelClass? : string;
  labelCol?   : string;
}
export interface objCmbFrame extends objGprFrame{
  displayField: string;
  valueField  : string;
  store       : object;
}
export interface objBtnFrame{
  type        : string;
  name        : string;
  label       : string;
  class       : string;
  style?      : string;
  col         : string;
  icon        : string;
  action      : typeButton;
  onClick     : object;
}
export interface objBtnShrFrame{
  type        : string;
  name        : string;
  groupRow?   : boolean;
  label       : string;
  class       : string;
  style?      : string;
  col         : string;
  icon        : string;
  action      : typeButton;
  onClick     : object;

  labelClass? : string;
  labelCol?   : string;
  placeholder?: string;
  maxleght?   : number;  
  validate?   : objValidate;
  colInputGrp?: string;
  modalShr    : object;
  valueShr    : string;
  textShr     : string;
}


export interface objFormFrame{
  type        : string;
  name        : string;
  class       : string;
  items       : object;
}

/**************************************************************************/
/**          Clase Principal para generar el Formulario dinamico         **/
/**************************************************************************/

export class formDinamic {
 
  private obj:objFormFrame;
  
  constructor(obj:objFormFrame){
    this.obj=obj;
  }
  frame(){
    return this.obj;
  }
  
}
/**************************************************************************/
/**           Clase para definir Cuadro de Texto (Input)                 **/
/**************************************************************************/
export class textBox {
 
  private obj:objGprFrame;
  private element: HTMLInputElement;

  constructor(obj:objGprFrame){
    this.obj=obj;
  }
  frame(){
    return this.obj;
  }
  getValue(){
    this.element = document.getElementById(this.obj.name) as HTMLInputElement;
    let objValue = this.element.value; 
    return objValue;
  }
  setValue(value){
    this.element = document.getElementById(this.obj.name) as HTMLInputElement;
    this.element.value=value; 
  }
  
  isValid(){
    this.element = document.getElementById(this.obj.name) as HTMLInputElement;
    //debugger
    let value = this.element.value;
    let valid:boolean=true;

    if (this.obj.validate){
      if (this.obj.validate['require']){
        if (value.length==0){
          let span = document.getElementById('sp00_'+this.obj.name) as HTMLSpanElement;
          span.setAttribute("class",  'alert form-error');
          span.setAttribute("style", 'margin-bottom: -16px;margin-top: 0px;font-size: 11px;');
          return false;
        }
      }
    }
    return valid;
  }
}
/**************************************************************************/
/**                Clase para definir Control CheckBox                   **/
/**************************************************************************/

export class checkBox {
 
  private obj:objGprFrame;
  private element: HTMLInputElement;

  constructor(obj:objGprFrame){
    this.obj=obj;
  }
  frame(){
    return this.obj;
  }
  getValue(){
    this.element = document.getElementById(this.obj.name) as HTMLInputElement;
    let objValue = this.element.checked; 
    return objValue;
  }
  setValue(value){
    this.element = document.getElementById(this.obj.name) as HTMLInputElement;
    this.element.value=value; 
  }
}
/**************************************************************************/
/**                   Clase para definir ComboBox                        **/
/**************************************************************************/
export class comboBox {
 
  private obj:objCmbFrame;
  private element: HTMLSelectElement;

  constructor(obj:objCmbFrame){
    this.obj=obj;
  }
  frame(){
    return this.obj;
  }
  getValue(){
    this.element = document.getElementById(this.obj.name) as HTMLSelectElement;
    let objValue = this.element.value; 
    return objValue;
  }
  setValue(value){
    this.element = document.getElementById(this.obj.name) as HTMLSelectElement;
    this.element.value=value; 
  }
  isValid(){
    this.element = document.getElementById(this.obj.name) as HTMLSelectElement;
    //debugger
    let value = this.element.value;
    let valid:boolean=true;

    if (this.obj.validate){
      if (this.obj.validate['require']){
        if (value=='null'){
          let span = document.getElementById('sp00_'+this.obj.name) as HTMLSpanElement;
          span.setAttribute("class", 'alert form-error');
          span.setAttribute("style", 'margin-bottom: -16px;margin-top: 0px;font-size: 11px;');
          return false;
        }
      }
    }
    return valid;
  }
}
/**************************************************************************/
/**               Clase para definir Etiquetas o Label                   **/
/**************************************************************************/
export class label {
  private obj:any;
  constructor(obj:any){
    this.obj=obj;
  }
  frame(){
    return this.obj;
  }
}
/**************************************************************************/
/**                Clase para definir Button Command                     **/
/**************************************************************************/
export class button {
  private obj:objBtnFrame;
  constructor(obj:objBtnFrame){
    this.obj=obj;
  }
  frame(){
    return this.obj;
  }
}

/**************************************************************************/
/**                Clase para definir Button Search                      **/
/**************************************************************************/
export class buttonSearch {
  private obj:objBtnShrFrame;
  constructor(obj:objBtnShrFrame){
    this.obj=obj;
  }
  frame(){
    return this.obj;
  }
}

export interface rnodos{
  nodos  : Array<any>;
  divs   : Array<any>;
}
const $outer = this;

@Injectable({
  providedIn: 'root'
})

export class DataService extends BaseService {
  constructor(private _injector: Injector) {
    super(_injector);
  }
  sendApi(api,record: any) {
    const url = this.getApiQuery(api);
    return this._httpClient.post(
      url,
      record,
      { headers: this.getAuthorizationHeader() }
    );
  }
}

@Component({
  selector: 'Dinamic-Form',
  templateUrl: './dinamic-form.component.html',
  styleUrls: ['./dinamic-form.component.css']
})

export class DinamicFormComponent  implements OnInit, OnDestroy  {

  @Input() Record: any;
  @Input() IsreadOnly: boolean;
  @Input() dialogInstance: MatDialogRef<DinamicFormComponent, any>
  @Input() Config: paramsConfig;

  ngUnsubscribe = new Subject();
  private subscription: Subscription;
  public result:any;
  public resultDIV:any;
  public inputs:Array<any>=[];
  public combos:Array<any>=[];
  public objValidate :Array<any>=[];

  public model: object;
  public modelResult: object;
  public form: object;
  
  public titleComponent:string;
  
  //public in01:form_Field;

  //@Injectable()
  
  //public $outer = this;
  public r2:any

  constructor(public renderer: Renderer2, 
              private el: ElementRef,
              private swalService: SweetAlertService,
              private _dataService:DataService,
              public searchGrid: SearchGridService,
              public dialog: MatDialog,)
    {
      this.r2=this.renderer;
    }
   

  ngOnInit(): void {
    this.resultDIV=null;
    this.form=this.Config.form.renderer;
    this.model=this.Config.form.model;
    this.modelResult=this.model;
    if (this.Record==null){
      this.titleComponent=this.Config.form.titleNew;
    } else {
      this.titleComponent=this.Config.form.titleEdit;
    }
    this.renderForm4(this.form,this.model);
  }

  /**************************************************************************/
  /**   Funcion principal que renderiza el Esqueleto del Formulario        **/
  /**************************************************************************/
  public renderForm4(form,model:object)		{
    let div1;
    let div2;
    let div3;
    let div4;
    let div5;
    //*-------------Aqui habia puesto recursividad----------------*/
    //*-----------pero tuve problemas con los div padres----------*/
    //*---------por lo pronto esta hecho hasta 5 niveles----------*/

    //for (let item of form){
     let item=form; 
      if (item.type=='panel'){
        div1 = this.renderer.createElement('div');
      }
      if (item.items){
        for (let item2 of item.items){
          this.renderObj(item2,div1,model)
          if (item2.type=='panel'){
            div2=this.createDiv(item2);
            this.renderer.appendChild(div1, div2);  
          }
          if (item2.items){
            for (let item3 of item2.items){
              this.renderObj(item3,div2,model)
              if (item3.type=='panel'){
                div3=this.createDiv(item3);
                this.renderer.appendChild(div2, div3);  
              }
              if (item3.items){
                for (let item4 of item3.items){
                  this.renderObj(item4,div3,model)
                  if (item4.type=='panel'){
                    div4=this.createDiv(item4);
                    this.renderer.appendChild(div3, div4);
                  }
                  if (item4.items){
                    for (let item5 of item4.items){
                      this.renderObj(item5,div4,model)
                      if (item5.type=='panel'){
                          div5=this.createDiv(item5);
                          this.renderer.appendChild(div4, div5);
                      }
                    }        
                  }
                }
                            
              }
            }
                    
          }
        }
      //}
    }
    this.renderer.appendChild(this.el.nativeElement, div1); 
  }

  /**************************************************************************/
  /**                      Crea un Div HTML                                **/
  /**************************************************************************/
  createDiv(obj){
    let div = this.renderer.createElement('div');
    this.renderer.setAttribute(div, "class",  obj.class);
    this.renderer.setAttribute(div, "style",  obj.style);
    return div
  }
  /**************************************************************************/
  /**                      Crea un Label HTML                              **/
  /**************************************************************************/
  createLabel(labelText,labelClass,labelCol){
    const divLabel = this.renderer.createElement('div');
    if (labelCol) this.renderer.setAttribute(divLabel, "class",  labelCol);

    let label = this.renderer.createElement('label');
    label.innerHTML=labelText;
    if (labelClass) this.renderer.setAttribute(label, "class",  labelClass);
    this.renderer.appendChild(divLabel, label);    
    return divLabel
  }
  /**************************************************************************/
  /**                    Crea un Text Input HTML                           **/
  /**************************************************************************/
  createInput(obj){
    const divInput = this.renderer.createElement('div');
    if (obj.col!='') this.renderer.setAttribute(divInput, "class", obj.col);
    let input = this.renderer.createElement('input');
    input.placeholder = obj.placeholder;
    input.id = obj.name
    input.type="input";
    if (obj['maxleght']){
      this.renderer.setAttribute(input, 'maxLength', obj.maxleght);
    }
    if (this.Record){
      for (let key in this.Record){
        if (obj.name==key){ 
          input.value=this.Record[key];
        }
      }
    }
    this.renderer.setAttribute(input, "class", obj.class);
    this.renderer.setAttribute(input, "style", obj.style);
    this.renderer.listen(input, 'change', (event) => {
      console.log(input.value);
      //debugger
      if (obj['validate']){
        if (obj.validate['msgError']){
            if (obj.validate['require']){
              if (input.value.length==0){
                this.renderer.setAttribute(divSpanError, "class",  'alert form-error');
                this.renderer.setAttribute(divSpanError, "style", 'margin-bottom: -16px;margin-top: 0px;font-size: 11px;');
              }
              else {
                this.renderer.setAttribute(divSpanError, "class",  'alert form-error hide');
              }
            }
        }
      }
    })
    var divSpanError;
    this.renderer.listen(input, 'input', (event) => {
      //debugger
      if (obj['validate']){
        if (obj.validate['msgError']){
            if (obj.validate['require']){
              if (input.value.length==0){
                this.renderer.setAttribute(divSpanError, "class",  'alert form-error');
                this.renderer.setAttribute(divSpanError, "style", 'margin-bottom: -16px;margin-top: 0px;font-size: 11px;');
              }
              else {
                this.renderer.setAttribute(divSpanError, "class",  'alert form-error hide');
              }
            }
        }
      }
    });
    this.renderer.appendChild(divInput, input);    
    if (obj['validate']){
      if (obj.validate['msgError']){
          //<span role="alert" class="alert form-error" > Campo requerido. </span>
          divSpanError = this.renderer.createElement('span');
          this.renderer.setAttribute(divSpanError, "role",  'alert');
          this.renderer.setAttribute(divSpanError, "class",  'alert form-error hide');
          divSpanError.id='sp00_'+obj.name;
          divSpanError.innerHTML=obj.validate.msgError;
          this.renderer.appendChild(divInput, divSpanError); 
          this.objValidate.push(obj);
      }
    }
    this.inputs.push(input);
    return divInput
  }
  /**************************************************************************/
  /**                      Crea un CheckBox HTML                           **/
  /**************************************************************************/
  createCheck(obj){
    const divChk = this.renderer.createElement('div');
    this.renderer.setAttribute(divChk, "class", obj.col);

    const divLabel = this.renderer.createElement('div');
    let label = this.renderer.createElement('label');
    label.innerHTML=obj.label;
    this.renderer.setAttribute(label, "class",  obj.labelClass);

    let input = this.renderer.createElement('input');
    input.type="checkbox";
    input.id = obj.name
    if (this.Record){
      for (let key in this.Record){
        if (obj.name==key){ 
          input.checked=this.Record[key];
        }
      }
    }
    this.renderer.setAttribute(input, "class", obj.class);
    this.renderer.setAttribute(input, "style", obj.style);

    this.renderer.appendChild(label, input);    
    this.renderer.appendChild(divLabel, label);    
    
    this.renderer.appendChild(divChk, divLabel);    

    this.inputs.push(input);
    return divChk;
  }
  /**************************************************************************/
  /**                      Crea un ComboBox HTML                           **/
  /**************************************************************************/
  createCombo(obj){
    const divCmb = this.renderer.createElement('div');
    this.renderer.setAttribute(divCmb, "class", obj.col);

    let ComboBox = this.renderer.createElement('select');
    ComboBox.placeholder = obj.placeholder;
    ComboBox.id = obj.name
    let valueSel=null;
    if (this.Record){
      for (let key in this.Record){
        if (obj.name==key){ 
          valueSel=this.Record[key];
        }
      }
    }      
    
    this.renderer.setAttribute(ComboBox, "class", obj.class);
    this.renderer.setAttribute(ComboBox, "style", obj.style);
    let option = document.createElement("option");
    option.value = null;
    option.text = 'Select';
    option.disabled=true;
    option.selected=true;
    ComboBox.appendChild(option);
    for (let opt of obj.store) {
      let option = document.createElement("option");
      option.value = opt[obj.valueField];
      option.text = opt[obj.displayField];
      if (valueSel===opt[obj.valueField]){
        //option.selected=true;
      }
      ComboBox.appendChild(option);
    }

    var divSpanError;
    this.renderer.listen(ComboBox, 'change', (event) => {
      if (obj['validate']){
        if (obj.validate['msgError']){
            if (obj.validate['require']){
                this.renderer.setAttribute(divSpanError, "class",  'alert form-error hide');
            }
        }
      }
    });
    this.renderer.appendChild(divCmb, ComboBox);    
    if (obj['validate']){
      if (obj.validate['msgError']){
          if (obj.validate['require']){
              divSpanError = this.renderer.createElement('span');
              this.renderer.setAttribute(divSpanError, "role",  'alert');
              this.renderer.setAttribute(divSpanError, "class",  'alert form-error hide');
              divSpanError.id='sp00_'+obj.name;
              divSpanError.innerHTML=obj.validate.msgError;
              this.renderer.appendChild(divCmb, divSpanError); 
              this.objValidate.push(obj);
          }
      }
    }
    this.combos.push(ComboBox);
    return divCmb;
  }
  /**************************************************************************/
  /**                      Crea un Buttom HTML                             **/
  /**              Con evento Click y validaciones del Form                **/
  /**************************************************************************/
  createButton(obj,model,modelResult){
    const divBtn = this.renderer.createElement('div');
    this.renderer.setAttribute(divBtn, "class",  obj.col);

    let Btn = this.renderer.createElement('Button');
    this.renderer.setAttribute(Btn, "class",  obj.class);
    let icon='';
    if (obj.icon){
      //const divI = this.renderer.createElement('i');
      //this.renderer.setAttribute(divI, "class",  obj.icon);
      //this.renderer.appendChild(Btn, divI);    
      icon=`<i class="${obj.icon} m-r-sm"></i>`
    }
    Btn.innerHTML=icon+obj.label;
    this.renderer.listen(Btn, 'click', (event) => {
      //debugger
      if (obj.action==typeButton.save){
          let isValidate:boolean=true;
          for (let itemInp of this.inputs){
            for (let key in model){
              if (itemInp.id==key){ //model[key]
                  if (itemInp.type=='text'){
                    modelResult[key]=itemInp.value;
                  }
                  if (itemInp.type=='checkbox'){
                    modelResult[key]=itemInp.checked;
                  }
              }
            }              
          }
          for (let itemCmb of this.combos){
            for (let key in model){
              if (itemCmb.id==key){
                  modelResult[key]=itemCmb.value;
              }
            }              
          }
          for (let itemObj of this.objValidate){
            if (itemObj.type=='input'){
              for (let itemInp of this.inputs){
                if (itemInp.id==itemObj.name){
                  if (itemInp.value.length==0){
                      let span = document.getElementById('sp00_'+itemInp.id) as HTMLSpanElement;
                      span.setAttribute("class",  'alert form-error');
                      span.setAttribute("style", 'margin-bottom: -16px;margin-top: 0px;font-size: 11px;');
                      isValidate=false;
                  }
                }
              }
            }
            if (itemObj.type=='comboBox'){
              for (let itemCmb of this.combos){
                if (itemCmb.id==itemObj.name){
                  if (itemCmb.value=='null'){
                      let span = document.getElementById('sp00_'+itemCmb.id) as HTMLSpanElement;
                      span.setAttribute("class",  'alert form-error');
                      span.setAttribute("style", 'margin-bottom: -16px;margin-top: 0px;font-size: 11px;');
                      isValidate=false;
                  }
                }
              }
            }
            if (itemObj.type=='buttonsearch'){
              for (let itemInp of this.inputs){
                if (itemInp.id==itemObj.name){
                  if (itemInp.value.length==0){
                      let span = document.getElementById('sp00_'+itemInp.id) as HTMLSpanElement;
                      span.setAttribute("class",  'alert form-error');
                      span.setAttribute("style", 'margin-bottom: -16px;margin-top: 0px;font-size: 11px;');
                      isValidate=false;
                  }
                }
              }
            }

          }

          this.modelResult=modelResult;
          //console.log(isValidate);
          if (isValidate){
            let api:string;
            if (this.Record)  api=this.Config.form.apiEdit; //Cuando es Edicion
            if (!this.Record) api=this.Config.form.apiInsert; //Cuando es Nuevo
            this._dataService.sendApi(api,modelResult).subscribe((response: any) => {
              if (response) {
                this.swalService.getInstance(`Was updated succesfully`, 'success').then(() => {});
                this.dialogInstance.close({sucess:true});
                return this.modelResult;
              } else {
                this.swalService.getInstance('An error has occurred', 'error').then(() => {});
                return {success:false};
              }
            }, error => {
              this.swalService.getInstance('An error has occurred', 'error').then(() => {});
              return {success:false};
            });
          }
          if (obj.onClick instanceof Function) {
            obj.onClick(modelResult);
          }
      }
      if (obj.action==typeButton.normal){
          if (obj.valueSetter instanceof Function) {
            modelResult=null;
            modelResult=obj.valueSetter();
          }
          let success=obj.onClick(modelResult);
          if (success){
            this.dialogInstance.close();
          }
      }

    })

    this.renderer.appendChild(divBtn, Btn);    
    return divBtn;
  }


  /**************************************************************************/
  /**                      Crea un ButtomSearch HTML                       **/
  /**              Con evento Click y validaciones del Form                **/
  /**************************************************************************/
  createButtonSearch(obj,model,modelResult){
    const divBtn = this.renderer.createElement('span');
    this.renderer.setAttribute(divBtn, "class",  'input-group-btn');

    let Btn = this.renderer.createElement('Button');
    this.renderer.setAttribute(Btn, "class", 'btn btn-yellow');// obj.class);
    let icon='';
    if (obj.icon){
      icon=`<i class="${obj.icon}"></i>`
    }
    Btn.innerHTML=icon;//+obj.label;
    this.renderer.listen(Btn, 'click', (event) => {
      if (obj.action==typeButton.search){
        /*
        if (obj.valueSetter instanceof Function) {
          modelResult=null;
          modelResult=obj.valueSetter();
        }
        console.log(modelResult);
        let success=obj.onClick(modelResult);
        */
        let dialog=this.searchGrid.Show(obj.modalShr);
        dialog.afterClosed().subscribe(result => {
          //console.log(result);
          if (result){
            //debugger;
            for (let itemInp of this.inputs){
                if (itemInp.id==obj.name){
                    itemInp.value=result[obj.valueShr];
                    //itemInp.value=result[obj.textShr];
                    let span = document.getElementById('sp00_'+obj.name) as HTMLSpanElement;
                    span.setAttribute("class",  'alert form-error hide');
          
                }              
            }
            
          }
        });
      }
    });
    
    this.renderer.appendChild(divBtn, Btn);    
    return divBtn;
  }

  /**************************************************************************/
  /**    Funcion Secundaria que renderiza cada Objeto del Formulario       **/
  /**************************************************************************/

  renderObj(obj,divParent,model){
    //let obj=form[i];
    var modelResult = Object.assign({}, model);
    let type=obj.type;
    if (type=='label'){
      this.renderer.appendChild(divParent, this.createLabel(obj.label,obj.class,obj.col));  
    }
    if (type=='input'){
      const divGroup = this.renderer.createElement('div');
      if (obj.groupRow) this.renderer.setAttribute(divGroup, "class", 'form-group row');
      if (obj.label) this.renderer.appendChild(divGroup, this.createLabel(obj.label,obj.labelClass,obj.labelCol));  
      this.renderer.appendChild(divGroup, this.createInput(obj)); 
      this.renderer.appendChild(divParent, divGroup);   
    }

    if (type=='checkBox'){
      const divGroup = this.renderer.createElement('div');
      if (obj.groupRow) this.renderer.setAttribute(divGroup, "class", 'form-group row');
      this.renderer.appendChild(divGroup, this.createCheck(obj));  
      this.renderer.appendChild(divParent, divGroup);   
    }

    if (type=='comboBox'){
      const divGroup = this.renderer.createElement('div');
      if (obj.groupRow) this.renderer.setAttribute(divGroup, "class", 'form-group row');
      if (obj.label) this.renderer.appendChild(divGroup, this.createLabel(obj.label,obj.labelClass,obj.labelCol));  
      this.renderer.appendChild(divGroup, this.createCombo(obj));    
      this.renderer.appendChild(divParent, divGroup);   
    }

    if (type=='button'){
      this.renderer.appendChild(divParent, this.createButton(obj,model,modelResult));  
    }        

    if (type=='buttonsearch'){
      
      const divGroup = this.renderer.createElement('div');
      if (obj.groupRow) this.renderer.setAttribute(divGroup, "class", 'form-group row');
      if (obj.label) this.renderer.appendChild(divGroup, this.createLabel(obj.label,obj.labelClass,obj.labelCol));  
      /*
      this.renderer.appendChild(divGroup, this.createInput(obj)); 
      this.renderer.appendChild(divGroup, this.createButtonSearch(obj,model,modelResult));  
      this.renderer.appendChild(divParent, divGroup);   
      */
      const divGroupMD = this.renderer.createElement('div');
      this.renderer.setAttribute(divGroupMD, "class", obj.colInputGrp);

      const divGroupInputBtn = this.renderer.createElement('div');
        this.renderer.setAttribute(divGroupInputBtn, "class", 'animated input-group');
        this.renderer.appendChild(divGroupInputBtn, this.createInput(obj)); 
        this.renderer.appendChild(divGroupInputBtn, this.createButtonSearch(obj,model,modelResult));  
        this.renderer.appendChild(divGroupMD, divGroupInputBtn);   
        this.renderer.appendChild(divGroup, divGroupMD);   
        this.renderer.appendChild(divParent, divGroup);   

      //this.renderer.appendChild(divParent, this.createButtonSearch(obj,model,modelResult));  
      
    }        

  }
   
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngAfterViewInit() {
  }

  closeDialog(){
    this.dialogInstance.close({sucess:false});
  }

}



/*
 //https://stackoverflow.com/questions/32494174/can-you-create-nested-classes-in-typescript
    public InnerClass = (
      () => {
          const $outer = this;
           class InnerClass {
            private readonly _$outer: typeof $outer;

                constructor(public innerProp1: string) {
                    this._$outer = $outer;
                }
                public outerPrivateProp1(): string {
                  return this._$outer.titleComponent;
              }
          }
      })();
*/
/*
    static Field = class {
      
      
      public objHtml:any;
      public input:any;
      _$outer: typeof DinamicFormComponent;
      //$outer: typeof DinamicFormComponent;
      constructor(obj:any){
        this._$outer = $outer;
        this.objHtml = this.$outer.renderer.createElement('div');
        this.superThis.renderer.setAttribute(this.objHtml, "class", obj.col);
        
        this.input = this.superThis.renderer.createElement('input');
        this.input.placeholder = obj.placeholder;
        this.input.id = obj.name
        this.input.type="input";
        this.superThis.renderer.setAttribute(this.input, "class", obj.class);
        this.superThis.renderer.setAttribute(this.input, "style", obj.style);
        this.superThis.renderer.appendChild(this.objHtml, this.input);    
      }
      renderer2(){
        this.objHtml = this._$outer..createElement('div');
        return this.objHtml;
      }
      getValue(){
        return this.input.value;
      }
    }
*/