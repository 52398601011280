<div class="gray-bg page-heading">
  <div class="heading-content">
    <button *ngIf="showBackBtn" class="btn btn-success btn-circle btn-outline m-r-sm" type="button" (click)="onBack()">
      <i class="fa fa-chevron-left"></i>
    </button>
    <h2>{{ pageTitle | translate}}</h2>
  </div>
  <div class="heading-actions">
    <ng-content></ng-content>
  </div>
</div>