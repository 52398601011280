<page-heading pageTitle="{{'Home' | translate}}"></page-heading>
<div class="container wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12" *ngFor="let item of sortedItems">
            <div class="ibox">
                <div class="ibox-title">
                    <h3 class="monospace">{{ item?.Name | translate}}</h3>
                </div>
                <div class="ibox-content">
                    <div class="panel-body">
                        <div class="col-lg-4" data-toggle="tab" *ngFor="let child of item?.Children let i = index; trackBy: trackById">
                            <a data-toggle="tab">
                                <div class="widget darkcyan-bg p-lg text-center" style="height: 120px;" (click)="openItem(child)">
                                    <div class="m-b-md">
                                        <div class="beautify">
                                            <h3>{{child?.Name | translate}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
