import { Injectable } from '@angular/core';
import { autoCol } from '@syncfusion/ej2-angular-grids';

import swal, { SweetAlertResult, SweetAlertOptions, SweetAlertType, } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetWaitService {

  options: SweetAlertOptions;

  constructor() {
    this.options = {
      confirmButtonColor: 'darkcyan',
      showConfirmButton: false,
      timer: 3000000,
      width: 'auto',
      customClass: 'wait',
      //customContainerClass:'waitclass'
      //heightAuto:false,
      /*
      customClass: {
        header: 'header-class',
        content:'wait'
      }*/
      
    };
    
  }
  wait(message?: string): Promise<SweetAlertResult> {
    if (message) {
      this.options.html = `&nbsp&nbsp <i class="fa fa-cog fa-spin" aria-hidden="true"></i> &nbsp ` + message + `&nbsp&nbsp&nbsp`;
    }
    
    return swal(this.options);
  }
  close(){
    //this.options=null;
    return swal.close();
  }
}
