import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpParams } from '@angular/common/http';
import { Type_invoice } from '../../models';


@Injectable({
  providedIn: 'root'
})
export class QuotationService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }


  QuotationById(docId:string) {
    let params: HttpParams = new HttpParams();

    if (docId) { params = params.append('docId',docId); }
    const url = this.getApiQuery(`Quotations/QuotationById`);
    return this._httpClient.get(url, { 
      params: params,
      headers: this.getAuthorizationHeader() 
    });
  }

  GetQuotationInvoices(docId:string) {
    let params: HttpParams = new HttpParams();

    if (docId) { params = params.append('docId',docId); }
    const url = this.getApiQuery(`Quotations/GetQuotationInvoices`);
    return this._httpClient.get(url, { 
      params: params,
      headers: this.getAuthorizationHeader() 
    });
  }

}
