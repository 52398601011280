import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BankService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }


  getBankList() {
    let params: HttpParams = new HttpParams();

    //if (userId) { params = params.append('idUsuario',userId); }

    const url = this.getApiQuery(`POS/BanksEC`);
    return this._httpClient.get(url, { 
      //params: params,
      headers: this.getAuthorizationHeader() 
    });
  }

  GetCustomerBankAccounts(ID:string) {
    let params: HttpParams = new HttpParams();

    params = params.append('ID',ID); 

    const url = this.getApiQuery(`POS/GetCustomerBankAccounts`);
    return this._httpClient.get(url, { 
      params: params,
      headers: this.getAuthorizationHeader() 
    });
  }

  GetBankEC() {
    let params: HttpParams = new HttpParams();
    //params = params.append('ID',ID); 
    const url = this.getApiQuery(`POS/BanksEC`);
    return this._httpClient.get(url, { 
      //params: params,
      headers: this.getAuthorizationHeader() 
    });
  }

  getBanksPayments() {
    let params: HttpParams = new HttpParams();
    const url = this.getApiQuery(`Customer/Banks`);
    return this._httpClient.get(url, { 
      //params: params,
      headers: this.getAuthorizationHeader() 
    });
  }

}
