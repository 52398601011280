<page-heading pageTitle="{{this.title}}">
  <button type="button" class="btn btn-primary" tabindex="-1" (click)="closeDialog()">
    <i class="ti-close"></i>
  </button>
</page-heading>

<div class="ibox">
  <form [formGroup]="Form">
    <div class="ibox-content">
      <div class="row form-group">
        <label class="col-md-5 col-form-label">{{this.message}}</label>
        <div class="col-md-7">
          <input *ngIf="isNumber" id="txt" formControlName="txt" class="form-control" type="number" />
          <input *ngIf="isText" id="txt" formControlName="txt" class="form-control" type="text" />
        </div>
      </div>
    </div>
    <div class="ibox-footer text-center">
      <button type="submit" mat-button class="btn btn-yellow m-r-sm" [disabled]="!Form.valid" (click)="onAccept()">
        {{'Accept' | translate}}
      </button>
      <button type="submit" mat-button class="btn btn-yellow" (click)="onCancel()">
        {{'Cancel' | translate}}
      </button>
    </div>
  </form>
</div>