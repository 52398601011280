import { Injectable, Injector } from '@angular/core';
import { Quotation, IQuotation, InvoiceItem, PagedResult, BaseResult } from '../../models';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { DocumentResourceParameters } from '../../helpers';
import { HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QuotationService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }

  getQuotations(args: DocumentResourceParameters): Observable<PagedResult<IQuotation>> {
    const url = this.getApiQuery('Quotations/Pagging');
    let params = new HttpParams();
    if (args.UserId) {
      params = params.append('UserId', args.UserId);
    }

    if (args.From) {
      params = params.append('From', args.From);
    }

    if (args.To) {
      params = params.append('To', args.To);
    }

    if (args.DocId) {
      params = params.append('DocId', args.DocId);
    }
    if (args.Filter) {
      params = params.append('Filter', args.Filter);
    }
    params = params.append('PageNumber', args.PageNumber.toString());
    params = params.append('PageSize', args.PageSize.toString());

    return this._httpClient.get<PagedResult<IQuotation>>(url, { headers: this.getAuthorizationHeader(), params: params });
  }

  getQuotationById(docId: string) {
    const url = this.getApiQuery('Quotations/QuotationById');
    let params = new HttpParams();
    params = params.append('docId', docId);
    return this._httpClient.get<Quotation>(url, { headers: this.getAuthorizationHeader(), params: params });
  }


  getQuotationItems(docId: string): Observable<InvoiceItem[]> {
    const url = this.getApiQuery('Quotations/items');
    let params = new HttpParams();
    params = params.append('docId', docId);
    return this._httpClient.get<InvoiceItem[]>(url, { headers: this.getAuthorizationHeader(), params: params });
  }

  sendQuotation(quotation: Quotation) {
    const url = this.getApiQuery('Quotations/create');
    return this._httpClient.post(url, quotation, { headers: this.getAuthorizationHeader() });
  }

  saveQuotationLocalUser(quotation: Quotation) {
    const url = this.getApiQueryLocal('Quotations/savelocal');
    console.log(url);
    return this._httpClient.post(url, quotation, { headers: this.getAuthorizationHeader() });
  }

  getQuotationTransport(quotation: Quotation) {
    const url = this.getApiQuery('Quotations/transportValue');
    return this._httpClient.post(url, quotation, { headers: this.getAuthorizationHeader() });
  }

  getLocalQuotationUser(Rolid: string) {
    const url = this.getApiQueryLocal('Quotations/getlocal');
    return this._httpClient.post(url, Rolid, { headers: this.getAuthorizationHeader() });
    // return this._httpClient.post(url, quotation);
  }

  approveQuotation(docId: string) {
    const url = this.getApiQuery('Quotations/approveQuotation');
    let params = new HttpParams();
    params = params.append('Id', docId);
    return this._httpClient.get(url, { headers: this.getAuthorizationHeader(), params: params });
  }
  getQuotationMRT(PageNumber: number, PageSize:number,Desde:string,Hasta:string,): Observable<PagedResult<Quotation>> {
    let params = new HttpParams();
    //if (Desde) { params = params.append('From', Desde); }
    //if (Hasta) { params = params.append('To', Hasta); }
    //params = params.append('PageNumber', PageNumber.toString());
    //params = params.append('PageSize', PageSize.toString());
    const url = this.getApiQuery('Invoices/GetReportQuotations?From=2021-11-22&To=2021-11-22&PageNumber=1&PageSize=20');
    return this._httpClient.get<PagedResult<Quotation>>(url,
        {
          headers: this.getAuthorizationHeader(),
          //params: params
        });
  }
}
