import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { PersonnelResourceParameters } from '../../helpers';
import { PersonnelAction, PersonnelActionType } from '../../models';
@Injectable({
  providedIn: 'root'
})
export class PersonnelActionService extends BaseService {

  constructor(injetor: Injector) {
    super(injetor);
   }

   getPersonnelActions(params?: PersonnelResourceParameters, idType?: string): Observable<Array<PersonnelAction>> {
     const url = params.SearchTerm ? this.getApiQuery(`RRHH/Employee/ACCPER?FROM=${params.From}&TO=${params.To}&ID_PER=${params.SearchTerm}&ID_ACCPER=${idType}`) : this.getApiQuery(`RRHH/Employee/ACCPER?FROM=${params.From}&TO=${params.To}`);
     return this._httpClient.get<Array<PersonnelAction>>(url, {headers: this.getAuthorizationHeader()});
   }

   getPersonnelActionTypes(typeName?: string): Observable<Array<PersonnelActionType>> {
    const url = typeName ? this.getApiQuery(`RRHH/Employee/TIPACCPER?NOMBRE=${typeName}`) : this.getApiQuery(`RRHH/Employee/TIPACCPER`);
    return this._httpClient.get<Array<PersonnelActionType>>(url, {headers: this.getAuthorizationHeader()});
   }
}
