import { Routes } from '@angular/router';
import { UserComponent } from './user/user.component';
import { BoardComponent } from './board/board.component';
import { BoardsalesComponent } from './board-sales/board-sales.component';
//import { BoardcardComponent } from './board/board-card/board-card.component';


export const dashboardRoutes: Routes = [
  //{ path: '', redirectTo: 'profile', pathMatch: 'full'},
  //{ path: '', redirectTo: 'board', pathMatch: 'full' },
  { path: 'boardsales', component: BoardsalesComponent },
  { path: 'profile', component: UserComponent }
];
