<div class="ibox float-e-margins border">
  <div class="ibox-title">
    <span *ngIf="data.button" class="label label-success pull-right">{{data.button}}</span>
    <h5 class="no-margins limit" style="display: inherit;float:none">{{data.title}}</h5>
  </div>
  <div class="ibox-content" style="min-height: 95px;">
    <h1 class="no-margins limit">{{data.value}}</h1>
    <!-- <h1 class="no-margins">{{data.value | currency}}</h1> -->
    <div *ngIf="data.percent" class="stat-percent font-bold text-success">{{data.percent}}% <i class="fa fa-bolt"></i>
    </div>
    <small>{{data.subtitle}}</small>
  </div>
</div>