import { BaseResult } from './BaseResult';
import { Company } from './system/Company';

export class WebResult extends BaseResult {
    Role: string;
    AccessToken: string;
    Extras: any;
    docformat: any;
}
export class WebEnterprise extends Company {
    Extras: any;
}
