<page-heading pageTitle="{{ title | translate }}" >
  <div class="title-action col-lg-1 col-sm-1 col-xs-1 pull-right" style="padding-right: 10px">
    <!--
    <button *ngIf="dialogInstance" type="button" class="btn btn-blue" style="padding: 6px; width: 40px;" tabindex="-1" (click)="closeDialog()">
      <i class="ti-close"></i>
    </button>
    -->
    <button *ngIf="dialogInstance" type="button" class="btn btn-primary" tabindex="-1" (click)="closeDialog()">
      <i class="ti-close"></i>
    </button>
  </div>
</page-heading>
<div id="idviewer" class="report"></div>

