  <div class="ibox-title">
    <h5>{{data.title}}</h5>
  </div>
  <div class="ibox-content">
    <ejs-grid 
        #grid 
        [dataSource]='dataGrid' 
        width='100%'
        height='300'
        >
        <e-columns>
          
            <ng-template #template ngFor let-column [ngForOf]="columns">
                <e-column 
                [field]="column.field" 
                [headerText]="column.headerText" 
                [width]=150
                [minWidth]=100 
                [format]="column.format"
                [textAlign]="column.textAlign">
                </e-column>
            </ng-template>
          <!--
            "column.width"
            [minWidth]=100 
            [maxWidth]=100

            <e-column headerText='Date'         field='Code'     width='70' >  </e-column>
            <e-column headerText='Time'         field='Name'     width='70' textAlign='Left'></e-column>
          -->
        </e-columns>
    </ejs-grid>
  </div>