import { InvoiceItem } from './InvoiceItem';
import { Contact } from './Sales/Contact';
import { Enterprise } from './Enterprise';
import { IQuotation } from './Sales/IQuotation';
import { IFact } from './Sales/IFact';

export class IDocument {
    Id: string;
    Fecha: Date;
    GUID: string;
    Enterprise: Enterprise;
    Customer: Contact;
    Id_Vend: number;
    NombVendedor: string;
    Nota: string;
    IdUser: string;
    Serie: string;
    Type: 'Invoice' | 'Quotation' | string;
    DocumentItems: InvoiceItem[];
    Total: number;
    Vat: number;
    IdCliente: string;
    Aprobada: boolean;
    SalesDoc : any;
    logo64?:any;

    public toIQuotation?(this: IDocument): IQuotation {
        return this as IQuotation;
    }

    public toIFact?(this: IDocument): IFact {
        return this as IFact;
    }
}
