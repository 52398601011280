<page-heading pageTitle="{{ config.crud.title | translate }}">
</page-heading>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-md-7 pull-right">
            <div class="row">
                <div class="col-xs-12 col-sm-4 pull-right m-b-md">
                    <button (click)="onNewRecord()" class="btn btn-action btn-yellow btn-block">
                        <i class="fa fa-plus-circle font-size-20 m-r-xs"></i>
                        <span>{{ 'New' | translate }}</span>
                    </button>
                </div>
                <div class="col-sm-8">
                    <div class="input-group search-bg">
                        <input #idInput type="text" [(ngModel)]="this.Search" placeholder="{{'Search' | translate}}"
                            class="input form-control" />
                        <span class="input-group-btn">
                            <button role="button" type="button" class="btn btn-yellow" (click)="onFilterData()">
                                <i class="fa fa-search"></i>
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="m-t-md" [ngClass]="{'col-sm-8': infoRecord!=null,'col-sm-12': infoRecord==null}">
            <ejs-grid #grid [dataSource]='data' [pageSettings]='pageSettings' allowPaging='true' class="grid"
                [rowHeight]='50' width='100%' (dataStateChange)='dataStateChange($event)'>
                <e-columns>
                    <ng-template #template ngFor let-column [ngForOf]="columns">
                        <e-column [field]="column.field" [headerText]="column.headerText" [width]="column.width">
                        </e-column>
                    </ng-template>
                    <e-column headerText='Actions' field='Manage' textAlign="Right" width='170' minWidth=170
                        maxWidth=170 [customAttributes]='customAttributes'>
                        <ng-template #template let-data>
                            <button *ngIf="config.crud.view" #btninf role="button" aria-label="view" class="btn-white
                              btn btn-md" (click)="onEditRecord($event,data,true)" tooltip="{{ 'View' | translate }}"
                                placement="top">
                                <i class="ti-eye"></i>
                            </button>
                            <button *ngIf="config.crud.edit" #btnedi role="button" aria-label="edit"
                                class="btn btn-white btn-md" (click)="onEditRecord($event,data,false)"
                                tooltip="{{'Edit' | translate}}" placement="top">
                                <i class="ti-pencil-alt"></i>
                            </button>
                            <button *ngIf="config.crud.delete" #btndel role="button" aria-label="delete"
                                class="btn btn-white btn-md" (click)="onDeleteRecord($event,data)"
                                tooltip="{{'Delete' | translate}}" placement="top">
                                <i class="ti-na"></i>
                            </button>
                        </ng-template>
                    </e-column>

                </e-columns>
            </ejs-grid>

        </div>
        <div class="col-sm-4" *ngIf="infoRecord!=null">
            <div class="panel-body">
                <div class="tab-content">
                    <div id="contact-1" class="tab-pane active">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <h2 class="p-m">{{infoRecord?.Name}}</h2>
                            </div>
                            <div class="col-lg-12">
                                <p class="customer-detail-p">{{'Mas data' | translate}}</p>
                                <div class="hr-line-dashed"></div>
                            </div>
                            <hr>
                            <div class="client-detail text-center">
                                <p class="customer-detail-p">{{'QuickAccess' | translate}}</p>
                                <div class="btn-group">
                                    <a class="btn btn-white btn-md" tooltip="{{'Edit' | translate}}" placement="bottom"
                                        (click)="onEditRecordIcon(infoRecord)">
                                        <i class="ti-pencil-alt"></i>
                                    </a>
                                </div>
                            </div>
                            <hr>
                            <a class="back-link" (click)="unselectRecord()">
                                <i class="fa fa-angle-double-left"></i> {{'Backtofulllist' | translate}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>