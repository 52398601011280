import { Injectable, ElementRef, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationService } from './navigation.service';
import { APP_SETTINGS } from '../../appsettings';

@Injectable({
  providedIn: 'root'
})
export class LandingGeneratorService {

  private render: Renderer2;
  private components: Array<any>;

  constructor(private rendererFactory: RendererFactory2, private navigationService: NavigationService) {
    this.render = this.rendererFactory.createRenderer(null, null);
    this.components = new Array<any>();
  }

  public renderLandingPage(parent: ElementRef, data?: any) {
    const landingComponents = APP_SETTINGS.setups.landingPage.components;
    landingComponents.forEach( component => {
      if (!this.isComponentRegistered(component.name)) {
        const element = this.createComponent(component.name);
        if (element) {
          this.registerComponentListeners(element, component);
          this.components.push(element);
        }
      }
    });

    this.components.forEach(component => {
      this.render.appendChild(parent.nativeElement, component);
    });
  }

  private isComponentRegistered(componentName: string): boolean {
    const component = this.components.find(c => c.name === componentName);
    if (component) {
      return true;
    } else {
      return false;
    }
  }

  private createComponent(componentName: string): any {
    return document.createElement(componentName) as any;
  }

  private registerComponentListeners(component: any, componentData: any) {
    switch (componentData.name) {
      case 'cms-navbar':
      this.renderNavbar(component, componentData);
      break;
      case 'cms-banner':
      this.renderBanner(component, componentData);
      break;
      case 'cms-newsletter':
      this.renderGenericComponent(component, componentData);
      break;
      case 'cms-catalog':
      this.renderCatalogComponent(component, componentData)
      break;
      case 'cms-footer':
      this.renderGenericComponent(component, componentData);
      break;
    }
  }

  public clearComponets(): void {
    this.components = [];
  }

  /** TODO: Crear otros servicios para renderizar los otros componentes cms
  * para así mantener limpio este servicio
  * */

  private renderNavbar(component: any, componentData: any): void {
    this.addNavbarListeners(component);
    this.setNavbarData(component, componentData);
  }

  private addNavbarListeners(component: any): void {
    component.addEventListener('$onSelectedItem', event => {
      console.log(`${event.detail}`);
      this.navigationService.navigate(`${event.detail}`);
    });

    component.addEventListener('$onLogin', event => {
      this.navigationService.navigateToLogin();
    });

    component.addEventListener('$onCart', event => {
      this.navigationService.navigateToShoppingCart();
    });
  }

  private setNavbarData(component: any, componentData): void {
    const propertyItems = componentData.properties as Array<any>;
    propertyItems.forEach(obj => {
      const properties = Object.keys(obj);
      properties.forEach(property => {
        if (property === 'items') {
          component.items =  obj[property];
        } else {
          component.setAttribute(`${property}`, `${obj[property]}`);
        }
      });
    });
  }

  private renderBanner(component: any, componentData: any): void {
    this.setBannerData(component, componentData);
  }

  private setBannerData(component: any, componentData: any) {
    const propertyItems = componentData.properties as Array<any>;
    propertyItems.forEach(obj => {
      const properties = Object.keys(obj);
      properties.forEach(property => {
        if (property === 'items') {
          component.items =  obj[property];
        } else {
          component.setAttribute(`${property}`, `${obj[property]}`);
        }
      });
    });
  }

  private renderGenericComponent(component: any, componentData: any) {
    const propertyItems = componentData.properties as Array<any>;
    propertyItems.forEach(obj => {
      const properties = Object.keys(obj);
      properties.forEach(property => {
        component[property] = obj[property];
      });
    });
  }

  private renderCatalogComponent(component: any, componentData: any) {
    this.addCatalogEventListeners(component);
    this.setCatalogData(component, componentData);
  }

  private addCatalogEventListeners(component: any): void {
    component.addEventListener('$btnClicked', event => {
      this.navigationService.navigateToProducts();
    });
  }
  private setCatalogData(component: any, componentData): void {
    const propertyItems = componentData.properties as Array<any>;
    propertyItems.forEach(obj => {
      const properties = Object.keys(obj);
      properties.forEach(property => {
        if (property === 'catalog') {
          component.catalog =  obj[property];
        } else {
          component.setAttribute(`${property}`, `${obj[property]}`);
        }
      });
    });
  }
}

