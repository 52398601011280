export class Debts {
    X_Secuencia: number;
    ID_Cliente: string;
    Nombre: string;
    Telefono: string;
    Tipo: string;
    Fecha: Date;
    Vence: Date;
    Detail: string;
    DiasVence:number;
    Dias: number;
    Deuda: number;
    ID_Doc: number;
    Total: number;
    Ciudad: string;
    Ci_Ruc: string;
    Direccion: string;
    TelefCasa: string;
    Compania: string;
    Estado: string;
    Califica: number;
    ID_Vend: number;
    CodPos: string;
    DocType: number;
}
