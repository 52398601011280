import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from './data.service';
import { Contact, Product, IDocument, MenuItem, SortedMenuItem, Employee, PersonnelAction, PersonnelPermit, ShiftChange, Supplier2 } from '../../models';
import { of, Observable } from 'rxjs';
import { SessionService } from './session.service';
import { Navigation } from '../../helpers';
import { RoutePlan } from '../../models/logistic/routevisit';
import { ExtendedServiceRequest } from 'src/app/models/crm/ExtendedServiceRequests';
import { Pago } from '../../models/accountreceivable/PayCustomer';
import { Retencion } from 'src/app/models/accountreceivable/withholdingtaxes';
import { Companys } from 'src/app/models/system/Company';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private previousRoute: string;
  private currentRoute: string;

  navigation: Navigation;

  constructor(private _router: Router, private _dataService: DataService, private sessionService: SessionService) {
    this.currentRoute = this._router.url;

    this.navigation = {
      name: 'app',
      uri: 'app',
      redirects: new Map(
        [
          ['error', '404'],
          ['login', 'login']
        ]
        ),
        children: [
          {
            parent: 'app',
            name: 'home',
            uri: 'app/home',
          },
          {
            parent: 'app',
            name: 'reports',
            uri: 'app/reports',
            children: [
              {
                parent: 'reports',
                name: 'invoices',
                uri: 'app/reports/invoices',
                fnNext: (o) => { this.navigateToDocumentDetail(o); },
                fnPrev: () => { this._dataService.invoiceProcedence === 1 ? this.navigateToCustomers() : this.navigateToRoot(); },
              },
              {
                parent: 'reports',
                name: 'curriculum',
                uri: 'app/reports/curriculum',
                fnNext: (o) => {  },
                fnPrev: () => { this.navigateToPersonnelList()},
              },
              {
                parent: 'reports',
                name: 'personnelpermit',
                uri: 'app/reports/personnelpermit',
                fnNext: (o) => {  },
                fnPrev: () => { this.navigateToPersonnelPermits(this._dataService.employee)},
              },
              {
                parent: 'reports',
                name: 'shiftChanges',
                uri: 'app/reports/shiftchanges',
                fnNext: (o) => {  },
                fnPrev: () => { this.navigateToPersonnelList()},
              },
              {
                parent: 'reports',
                name: 'shiftChangeReport',
                uri: 'app/reports/shiftChangeReport',
                fnNext: (o) => {  },
                fnPrev: () => { this.navigateToPersonnelShiftChanges(this._dataService.employee)},
              },
              {
                parent: 'reports',
                name: 'absence',
                uri: 'app/reports/absence',
                fnNext: (o) => {  },
                fnPrev: () => { this.navigateToPersonnelList()},
              },
              {
                parent: 'reports',
                name: 'attendance',
                uri: 'app/reports/attendance',
                fnNext: (o) => {  },
                fnPrev: () => { this.navigateToPersonnelList()},
              },
              {
                parent: 'reports',
                name: 'personnelAction',
                uri: 'app/reports/personnelaction',
                fnNext: (o) => {  },
                fnPrev: () => { this.navigateToPersonnelList()},
              },
              {
                parent: 'reports',
                name: 'personnelPermit',
                uri: 'app/reports/personnelpermit',
                fnNext: (o) => {  },
                fnPrev: () => { this.navigateToPersonnelList()},
              },
              {
                parent: 'reports',
                name: 'personnelActionReport',
                uri: 'app/reports/personnelreport',
                fnNext: (o) => {  },
                fnPrev: () => { this.navigateToPersonnelList()},
              },
              {
                parent: 'reports',
                name: 'retentions',
                uri: 'app/reports/retentions',
                fnNext: (o) => { this.navigateToDocumentDetail(o); },
                fnPrev: () => { this._dataService.invoiceProcedence === 1 ? this.navigateToCustomers() : this.navigateToRoot(); },
              },
              {
                parent: 'reports',
                name: 'quotations',
                uri: 'app/reports/quotations',
                fnNext: (o) => { this.navigateToDocumentDetail(o); },
                fnPrev: () => { this._dataService.invoiceProcedence === 1 ? this.navigateToCustomers() : this.navigateToRoot(); },
              },
              {
                parent: 'reports',
                name: 'dayreport',
                uri: 'app/reports/dayreport',
                fnPrev: () => {this.navigateToRoot(); },
              },
              {
                parent: 'reports',
                name: 'document',
                uri: 'app/reports/document/:type',
                fnNext: (o) => { this.navigateToReportViewer(o); },
                fnPrev: () => {
                  if (this._dataService.document.Type === 'Quotation') {
                    this.navigateToQuotations(this._dataService.customer);
                  } else if (this._dataService.document.Type === 'Invoice') {
                    this.navigateToInvoices(this._dataService.customer);
                  } else {
                    this.navigateToRoot();
                  }
                },
              },
              {
                parent: 'reports',
                name: 'salesRp',
                uri: 'app/reports/sales',
                fnNext: (o) => { this.navigateToDocumentDetail(o); },
                fnPrev: () => { this.navigateToRoot(); },
              },
              {
                parent: 'reports',
                name: 'ledger',
                uri: 'app/reports/ledger',
                fnNext: (o) => { this.navigateToDocumentDetail(o); },
                fnPrev: () => { this.sessionService.isAdminOrSalesman() ? this.navigateToCustomers() : this.navigateToRoot(); },
                redirects: new Map(
                  [
                    ['customers', 'customers']
                  ]
                  ),
                },
                {
                  parent: 'reports',
                  name: 'viewer',
                  uri: 'app/reports/document',
                  fnPrev: () => { this.navigateToDocumentDetail(this._dataService.document); }
                },
                {
                  parent: 'reports',
                  name: 'overallPortfolio',
                  uri: 'app/reports/overallportfolio',
                  fnNext: (o) => {  },
                  fnPrev: () => { this.navigateToRoot(); }
                },
              ]
            },
            {
              parent: 'app',
              name: 'purchase',
              uri: 'app/sales',
              children: [
                {
                  parent: 'purchase',
                  name: 'purchase-cart',
                  uri: 'app/purchase/purchase-cart',
                  fnPrev: () => { this.navigateToRoot(); }
                }
              ]
            },
            {
              parent: 'app',
              name: 'sales',
              uri: 'app/sales',
              children: [
                {
                  parent: 'sales',
                  name: 'customers',
                  uri: 'app/sales/customers',
                  fnPrev: () => { this.navigateToRoot(); }
                },
                {
                  parent: 'sales',
                  name: 'products',
                  uri: 'app/sales/products',
                  fnPrev: () => { this.navigateToRoot(); }
                },
                {
                  parent: 'sales',
                  name: 'product',
                  uri: 'app/sales/products/:id',
                  fnPrev: () => { this.navigateToProducts(); }
                },
                {
                  parent: 'sales',
                  name: 'editProduct',
                  uri: 'app/sales/products/:id/edit',
                  fnPrev: () => { this.navigateToProducts(); }
                }
              ]
            },
            {
              parent: 'app',
              name: 'system',
              uri: 'app/system',
              children: [
                {
                  parent: 'settings',
                  name: 'settings',
                  uri: 'app/system/settings',
                  fnPrev: () => { this.navigateToRoot(); }
                }
              ]
            },
            {
              parent: 'app',
              name: '404',
              uri: 'app/404',
              fnPrev: () => this.navigateToRoot()
            },
            {
              parent: 'app',
              name: 'commerce',
              uri: 'app/commerce',
              children: [
                {
                  parent: 'commerce',
                  name: 'shopping-cart',
                  uri: 'app/commerce/shopping-cart',
                  fnPrev: () => { this.navigateToRoot(); }
                }
              ]
            },
          ]
        };

        this._router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            this.previousRoute = this.currentRoute;
            this.currentRoute = event.url;
          }
        });
      }

      getNavigationItem(key: string = 'app', navigation: Navigation = this.navigation): Navigation {
        if (navigation) {
          if (navigation.name === key) {
            return navigation;
          } else {
            if (navigation.children) {
              for (const child of navigation.children) {
                const item = this.getNavigationItem(key, child);
                if (item) {
                  return item;
                }
              }
            }
            return;
          }
        } else {
          return null;
        }
      }

      navigate(route: string, id?: string) {
        if (id || id !== undefined) {
          this._router.navigate([`${route}`, id]);
        } else {
          this._router.navigate([`${route}`])
          .catch(e => this.navigateToError());
        }
      }

      navigateToLanding() {
        this.navigate('/');
      }

      navigateToStore() {
        this.navigate('/store');
      }

      navigateToLogin() {
        this.navigate('/login');
      }

      navigateToRoot() {
        this.navigate('/app');
      }

      navigateToCustomers() {
        this.navigate('app/sales/customers');
      }
      navigateToCustomerID( customer: Contact) {
        //this.navigate('app/sales/customers/', customer.Id);
        this.navigate(`app/sales/customers/${customer.Id}/edit`);
      }

      navigateToNewCustomer() {
        this.navigate('app/sales/customers/new');
      }

      navigateToShoppingCart() {
        this.navigate('app/commerce/shopping-cart');
      }

      navigateToPuchaseCart() {
        this.navigate('app/purchase/purchase-cart');
      }

      navigateToCustomerLedger(customer: Contact) {
        this._dataService.customer = customer;
        this.navigate('app/reports/ledger');
      }

      navigateToQuotations(customer: Contact) {
        this._dataService.customer = customer;
        this.navigate('app/reports/quotations/', customer.Id);
      }

      navigateToAllQuotations() {
        this.navigate('app/reports/quotations/');
      }

      navigateToAllDevolutions() {
        this.navigate('app/reports/quotations/');
      }

      navigateToInvoices(customer: Contact) {
        this._dataService.customer = customer;
        this.navigate('app/reports/invoices/', customer.Id);
      }

      navigateToListInvoices() {
        this.navigate('app/reports/invoices');
      }

      navigateToAllInvoices() {
        this.navigate('app/reports/invoices/');
      }

      navigateToCases(id?: string) {
        this.navigate('app/reports/cases', id);
      }

      navigateToCustomerProfile(customer: Contact) {
        this._dataService.customer = customer;
        this.navigate('app/sales/customer/profile');
      }

      navigateToUserProfile() {
        this.navigate('app/dashboards/user/user');
      }

      navigateToReportViewer(type: string) {
        this._dataService.document.Type = type;
        this.navigate(`app/reports/document/${type}`);
      }

      navigateToError() {
        this.navigate('app/404');
      }

      navigateToProducts() {
        this.navigate('app/sales/products');
      }


      navigateToDashBoardSales() {
        this.navigate('app/dashboards/board');
      }

      navigateToHome(route:string) {
        this.navigate(route);
      }

      navigateToNewProduct() {
        this.navigate('app/sales/products/new');
      }

      navigateToEditProduct(productId: string) {
        this._dataService.ProductID = productId;
        this.navigate(`app/sales/products/${productId}/edit`);
      }

      navigateToProduct(product: Product) {
        this._dataService.product = product;
        this.navigate(`app/sales/products/${product.Id}`);
      }

      navigateToCheckout(customer?: Contact, checkoutData?: any) {
        this._dataService.customer = customer;
        this._dataService.checkoutData = checkoutData;
        this.navigate('app/commerce/checkout');
      }

      /*
      navigateToRetentions() {
        this.navigate('app/reports/retentions');
      }
      */

      navigateToDocumentDetail(document: IDocument) {
        this._dataService.document = document;
        if (document.Type === 'Invoice') {
          this.navigate(`app/reports/invoices/${document.Serie}/detail`);
        } else {
          this.navigate(`app/reports/invoices/${document.Id}/detail`);
        }
      }

      navigateToInvoiceDetail(document: IDocument) {
        this._dataService.document = document;
        this.navigate(`app/reports/invoices/${document.Serie}/detail`);
      }

      navigateToInvoiceById(Id: string) {
        console.log('navigateToInvoiceById');
        this.navigate(`app/reports/invoices/${Id}/detail`);
      }

      navigateToPersonnelList() {
        this._dataService.employee = null;
        this.navigate('app/reports/personnel');
      }
      
      navigateToNewPersonnel() {
        this._dataService.employee = null;
        this.navigate('app/reports/personnel/new');
      }

      navigateToEditPersonnel(Id: string) {
        this.navigate(`app/reports/personnel/${Id}/edit`);
      }

      navigateToCv(employee: Employee) {
        this._dataService.employee = employee;
        this.navigate('app/reports/curriculum')
      }

      navigateToPersonnelActions(employee: Employee) {
        this._dataService.employee = employee;
        this.navigate('app/reports/personnelaction')
      }

      navigateToPersonnelAttendance(employee: Employee) {
        this._dataService.employee = employee;
        this.navigate('app/reports/attendance')
      }

      navigateToPersonnelShiftChanges(employee: Employee) {
        this._dataService.employee = employee;
        this.navigate('app/reports/shiftchanges')
      }

      navigateToPersonnelShiftChangeReport(shiftChange: ShiftChange) {
        this._dataService.personnelShiftChange = shiftChange;
        this.navigate('app/reports/shiftChangeReport')
      }

      navigateToPersonnelAbsenceAndDelays(employee: Employee) {
        this._dataService.employee = employee;
        this.navigate('app/reports/absence')
      }

      navigateToPersonnelActionReport(action: PersonnelAction) {
        this._dataService.personnelAction = action;
        this.navigate('app/reports/personnelreport');
      }

      navigateToPersonnelPermitReport(action: PersonnelPermit) {
        this._dataService.personnelPermit = action;
        this.navigate('app/reports/personnelpermitreport');
      }

      navigateToPersonnelPermits(action: Employee) {
        this._dataService.employee = action;
        this.navigate('app/reports/personnelpermit');
      }

      navigateToDispatch(itemRecord:RoutePlan) {
        this._dataService.routePlan = itemRecord;
        this.navigate('app/logistic/newdispatch');
      }

      navigateToListDispatch() {
        this.navigate('app/logistic/dispatch');
      }

      navigateToListCases() {
        this.navigate('app/crm/cases');
      }

      navigateToListCustomer() {
        this.navigate('app/sales/customers');
      }

      navigateToTruck() {
        this.navigate('app/logistic/truck');
      }

      navigateToDriver() {
        this.navigate('app/logistic/driver');
      }

      navigateToPermission() {
        //this._dataService.employee = action;
        this.navigate('app/reports/permission');
      }

      navigateToInvoiceTabs() {
        //this._dataService.employee = action;
        this.navigate('app/sales/invoicetabs');
      }

      navigateToVacation() {
        //this._dataService.employee = action;
        this.navigate('app/reports/vacation');
      }

      navigateToLoan() {
        this.navigate('app/reports/loan/new');
      }

      navigateToLoans() {
        this.navigate('app/reports/loans');
      }

      navigateToEditLoan(Id: string) {
        this.navigate(`app/reports/loan/${Id}`);
      }

      navigateToPayment() {
        this.navigate('app/reports/advancepayment');
      }
      navigateToEditPayment() {
        //this.navigate(`app/reports/advancepayment/${Id}`);
        this.navigate(`app/reports/advancepayment`);
      }

      navigateToNewAttendaceRecord() {
        this.navigate(`app/reports/registerattendance`);
      }

      navigateToAttendanceList() {
        this.navigate(`app/reports/clockinginout`);
      } 

      navigateToEditClockInOut(Id: string) {
        this.navigate(`app/reports/clockinginout/${Id}`);
      }

      navigateToPayrollList() {
        this.navigate(`app/reports/payroll`);
      } 

      navigateToCase(itemRecord:ExtendedServiceRequest) {
        this._dataService.case = itemRecord;
        this.navigate('app/crm/newcase');
      }

      navigateToJournalList() {
        this.navigate(`app/accounting/journal`);
      }

      navigateToNewJournalRecord() {
        this.navigate(`app/accounting/journal/new`);
      }

      navigateToEditJournalRecord(id: string, type: string) {
        this.navigate(`app/accounting/journal/${id}/${type}`);
      }

      navigateToSupplier(itemRecord:Supplier2) {
        this._dataService.supplier = itemRecord;
        this.navigate('app/purchase/vendor/new');
      }
      navigateToInvoice(type:string) {
        this._dataService.TypeDoc=type;
        this._dataService.DocId = null;
        this._dataService.case = null;
        this.navigate('app/sales/newinvoice');
      }
      navigateToInvoiceFromCase(type:ExtendedServiceRequest) {
        console.log(type);
        this._dataService.TypeDoc="FAC";
        this._dataService.case = type;
        this.navigate('app/sales/newinvoice');
      }

      navigateToDevolution(invoice: IDocument) {

        this._dataService.document = invoice;
        this.navigate('app/sales/newdevolution');
      }

      navigateToReferalguideFromGuide(docGuide: any) {
        //console.log(invoiceForGuide);
        this._dataService.invoiceForGuide=null;
        this._dataService.docGuide = docGuide;
        this.navigate('app/sales/newguide');
      }

      navigateToReferalguideFromFAC(invoiceForGuide: IDocument) {
        //console.log(invoiceForGuide);
        this._dataService.docGuide=null;
        this._dataService.invoiceForGuide = invoiceForGuide;
        this.navigate('app/sales/newguide');
      }

      navigateToViewer() { //params:paramsViewer
        //this._dataService.paramsViewer=params;
        this.navigate('app/reports/viewer');
      }

      navigateToEditQuotation(document: IDocument) {
        this._dataService.TypeDoc='PRF';
        this._dataService.DocId = Number(document.Id);
        this._dataService.case = null;
        this.navigate('app/sales/newinvoice');
      }


      navigateToPurchase() {
        this.navigate('app/purchasing/purchasenew');
      }
      navigateToPurchases() {
        this.navigate('app/purchasing/purchaseorden');
      }

      navigateToPayCustomer(itemRecord:Pago) {
        this._dataService.PayCustomer = itemRecord;
        this.navigate('app/accountreceivable/newcustomerspayment');
      }

      navigateToPaySupplier(itemRecord:Pago) {
        this._dataService.PayCustomer = itemRecord;
        this.navigate('app/accountpayable/newsupplierpayment');
      }

      navigateToWithholdingtaxes() {
        this.navigate('app/accountreceivable/withholdingtaxes');
      }

      navigateToWithholdingtax(itemRecord:Retencion) {
        this._dataService.retencion = itemRecord;
        this.navigate('app/accountreceivable/newwithholdingtax');
      }

      navigateToCustomerWithholdingtax(itemRecord:Retencion) {
        this._dataService.retencion = itemRecord;
        this.navigate('app/accountreceivable/newcustomerwithholdingtax');
      }

      navigateToCustomerWithholdingtaxes() {
        this.navigate('app/accountreceivable/customerwithholdingtax');
      }


      navigateToGuide(itemRecord:Pago) {
        this._dataService.docGuide = itemRecord;
        this.navigate('app/sales/newguide');
      }


      hasRoute(routeName: string) {
        return  this._router.url.indexOf(routeName) > -1;
      }

      getCurrentRoute(): string {
        return this.currentRoute;
      }

      getPreviousRoute(): string {
        return this.previousRoute;
      }

      setNewPreviousRoute(route: string) {
        this.previousRoute = route;
      }
      navigateToAdjustment(itemRecord:any) {
        //console.log(itemRecord);
        this._dataService.adjustment = itemRecord;
        this.navigate('app/inventory/adjustmentform');
      }

      navigateToAdjustments() {
        this.navigate('app/inventory/adjustment');
      }

      navigateToUser(itemRecord:any) {
        this._dataService.user = itemRecord;
        this.navigate('app/system/user');
      }

      navigateToCompany(itemRecord:Companys) {
        this._dataService.Company = itemRecord;
        this.navigate('app/system/idcompany');
      }

      navigateToCompanys() {
        this._dataService.Company = null;
        this.navigate('app/system/company');
      }

      private getMenuItems(): MenuItem[] {
        let menuItems = this.sessionService.getSessionInfo().Extras.menu_items as MenuItem[];





        let AddMenu={Id: 96,Area: "System", Controller: "Users", Name: "Users", ParentId: 3, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);


        AddMenu={Id: 98,Area: "dashboards", Controller: "boardsales", Name: "boardsales", ParentId: 79, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 83,Area: "dynamic", Controller: "catalogue/vendedor", Name: "Vendedor", ParentId: 2, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);


        AddMenu={Id: 84,Area: "dynamic", Controller: "catalogue/caja", Name: "Caja", ParentId: 2, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        /*--------------Si funcionan--------------------*/

        AddMenu={Id: 91,Area: "inventory", Controller: "adjustment", Name: "Ajuste Inv.", ParentId: 79, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 92,Area: "Crm", Controller: "TestSearch", Name: "TestSearch", ParentId: 79, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu = { Id: 93, Area: "Crm", Controller: "Testformdinamic", Name: "Testformdinamic", ParentId: 79, Code: null, Action: null, Default: null, Favorit: false, Icon: "fa fa-file", Image: null, IsControlEditor: false, Parameters: null, TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu = { Id: 94, Area: "Crm", Controller: "Testcruddinamic", Name: "Testcruddinamic", ParentId: 79, Code: null, Action: null, Default: null, Favorit: false, Icon: "fa fa-file", Image: null, IsControlEditor: false, Parameters: null, TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 95,Area: "Crm", Controller: "customertracking", Name: "Ubicación Cliente", ParentId: 79, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);


        AddMenu={Id: 96,Area: "AccountReceivable", Controller: "Customerwithholdingtax", Name: "CustomerWithholdingtax", ParentId: 58, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 103,Area: "Reports", Controller: "kardex", Name: "Kardex", ParentId: 1, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 104,Area: "Sales", Controller: "devolutions", Name: "Devolution", ParentId: 2, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 97,Area: "Crm", Controller: "processbackground", Name: "Process Background", ParentId: 79, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 98,Area: "System", Controller: "reportexp", Name: "Export Reports", ParentId: 79, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 99,Area: "System", Controller: "dataimport", Name: "Import Data", ParentId: 79, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 110,Area: "System", Controller: "datarebuild", Name: "Rebuild Data", ParentId: 79, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);


        AddMenu={Id: 111,Area: "Sales", Controller: "referralguide", Name: "referalguide", ParentId: 2, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 112,Area: "Sales", Controller: "labelbarcode", Name: "labelbarcode", ParentId: 2, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 113,Area: "Sales", Controller: "invoicetabs", Name: "invoicetabs", ParentId: 2, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        
        AddMenu={Id: 114,Area: "Reports", Controller: "permissions", Name: "permissions", ParentId: 39, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 115,Area: "Reports", Controller: "vacations", Name: "vacations", ParentId: 39, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);
        /*
        AddMenu={Id: 116,Area: "Crm", Controller: "", Name: "Menu con Nivel", ParentId: 79, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 117,Area: "Crm", Controller: "", Name: "Opcion 1", ParentId: 116, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);
        */
        /*
        AddMenu={Id: 116,Area: "Reports", Controller: "loans", Name: "loans", ParentId: 39, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 117,Area: "Reports", Controller: "advancepayment", Name: "advancepayment", ParentId: 84, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);
        */

        //let AddMenu1={Id: 116,Area: null, Controller: null, Name: "Accounting", ParentId: null, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        //menuItems.push(AddMenu1);
        //AddMenu={Id: 117,Area: "Accounting", Controller: "coas", Name: "coas", ParentId: 116, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        //menuItems.push(AddMenu);

        
        //console.log("Aquin Push Menu");
        /*
        let AddMenu1={Id: 90,Area: null, Controller: null, Name: "Crm", ParentId: null, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu1);

        let AddMenu={Id: 91,Area: "Crm", Controller: "Cases", Name: "Cases", ParentId: 90, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 92,Area: "Crm", Controller: "Test", Name: "Test", ParentId: 90, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 93,Area: "Crm", Controller: "TestSearch", Name: "TestSearch", ParentId: 90, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        /*
        AddMenu={Id: 94,Area: "Sales", Controller: "Invoice", Name: "Invoice", ParentId: 2, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 95,Area: "System", Controller: "Configfe", Name: "Config FE", ParentId: 3, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 96,Area: "Crm", Controller: "Testformdinamic", Name: "Testformdinamic", ParentId: 90, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 97,Area: "Crm", Controller: "Testcruddinamic", Name: "Testcruddinamic", ParentId: 90, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);
        */

        /*
        let AddMenu2={Id: 95,Area: null, Controller: null, Name: "Purchase", ParentId: null, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu2);

        AddMenu={Id: 96,Area: "purchase", Controller: "Vendor", Name: "Vendor", ParentId: 95, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);
        */


        AddMenu={Id: 127,Area: "accounting", Controller: "ledgerbook", Name: "ledgerbook", ParentId: 61, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 128,Area: "accounting", Controller: "trialbalance", Name: "trialbalance", ParentId: 61, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 129,Area: "accounting", Controller: "sheetbalance", Name: "sheetbalance", ParentId: 61, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        AddMenu={Id: 130,Area: "accounting", Controller: "profitloss", Name: "profitloss", ParentId: 61, Code: null, Action: null, Default: null, Favorit: false,Icon: "fa fa-file",Image: null,IsControlEditor: false,Parameters: null,TypeProgram: 0 }
        menuItems.push(AddMenu);

        return menuItems ? menuItems : null;
      }

      getOnlyMenuItemsWithoutParent(): Observable<MenuItem[]> {
        return of(this.getMenuItems().filter(x => x.ParentId != null));
      }

      private getParentMenuItems(): MenuItem[] {
        return this.getMenuItems().filter(x => x.ParentId == null);
      }

      private getChildMenuItems(parentId: number): MenuItem[] {
        return this.getMenuItems().filter(x => x.ParentId === parentId);
      }


      getMenuSorted(): Observable<SortedMenuItem[]> {
        const menuItems = this.getParentMenuItems().map(x => {
          const childrenItems = this.getChildMenuItems(x.Id);
          const sortedMenu = new SortedMenuItem(x);
          sortedMenu.Children = childrenItems;
          return sortedMenu;
        });

        return of(menuItems);
      }

    }
