import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { enableRipple } from '@syncfusion/ej2-base';
import * as ChartDataLabels from 'chartjs-plugin-datalabels';

export interface TypeTipo{
  id:number;
  name:string;
}
export interface prmsBars {
  title           : string;
  subtitle?       : string;
  barChartLabels  : Array<string>;
  barChartData    : Array<any>;
}


enableRipple(false);

@Component({
  selector: 'board-chartbar',
  templateUrl: './board-chartbar.component.html',
  styleUrls: ['./board-chartbar.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BoardchartbarComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  @Input() data : prmsBars;
  @Input() showPercent : string;
  

  public nav:any;
  public todos: any;

  /*-----------------Chart BAR------------------*/
  public barChartOptions: any;
  public barChartLabels: string[];
  public barChartType:string;
  public barChartLegend:boolean;
  public barChartData:any[];
  public barChartPlugins = [];


  constructor(private _formBuilder: FormBuilder){

    this.barChartOptions = {
      scaleShowVerticalLines: false,
      responsive: true,
      maintainAspectRatio: false,
    };
    this.barChartType = 'bar';
    this.barChartLegend = true;
    this.barChartPlugins = [ ChartDataLabels ];
  }

  getData(){
    this.barChartLabels=this.data.barChartLabels;
    this.barChartData =this.data.barChartData;
  }

  refresh(){
    //console.log('refresh');
    //this.chart.update();
    this.barChartLabels=this.data.barChartLabels;
    this.barChartData =this.data.barChartData;

    window.setTimeout(() => {
      this.getData();
    }, 100);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    //console.log(this.data);
    if (this.showPercent=='true') {
      this.barChartOptions.plugins = {
        datalabels: {
          formatter: (value, ctx) => {
            let datasets = ctx.chart.data.datasets;
            if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
              let sum = 0;
              let dataArr: any[] = ctx.chart.data.datasets[0].data;
              dataArr.map((data: number) => {
                sum += data;
              });
              let percentage = value + "\n" + ((value / sum ) * 100).toFixed(2) + "%";
              return percentage;

            } else {
              return 0;
            }
          },
          color: 'black',
        }
      }   
    }

    this.getData();

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  chartClicked(e: any): void {
    //console.log(e.active);
    //console.log(e.event);
  }

  chartHovered(e: any): void {
    //console.log(e);
  }

}
