<div class="ibox-content m-b-sm search-row">
  <div class="row m-b-sm">
    <div class="col-sm-3">
      <div class="form-group">
        <label class="control-label" for="date_added">{{'From' | translate}}</label>
        <div class="input-group date">
          <span class="input-group-addon pointer" (click)="dpfrom.toggle()">

            <i class="fa fa-calendar"></i>
          </span>

          <input class="form-control"
            #dpfrom="bsDatepicker" bsDatepicker
            placeholder="YYYY/MM/DD"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [value]= "from  | date: 'yyyy-MM-dd'"
            [bsConfig] ="bsConfig"
            (bsValueChange)="onFromValueChange($event)">

          <span class="input-group-addon pointer"
            (click)="clearFromPicker()">
            <i class="ti-close"></i>
          </span>
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div class="form-group">
        <label class="control-label" for="date_added">{{'To' | translate}}</label>
        <div class="input-group date">
          <span class="input-group-addon pointer" (click)="dpto.toggle()">
            <i class="fa fa-calendar"></i>
          </span>

          <input class="form-control"
            #dpto="bsDatepicker" bsDatepicker
            placeholder="YYYY/MM/DD"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [value]= "to | date: 'yyyy-MM-dd'"
            [bsConfig] ="bsConfig"
            (bsValueChange)="onToValueChange($event)">

          <span class="input-group-addon pointer"
            (click)="clearToPicker()">
            <i class="ti-close"></i>
          </span>
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <auto-complete
      [currentSelection]="selectedItem"
      [serviceName]="autocompleteServiceName"
      [displayName]="autocompleteDisplayName"
      [displayId]="autocompleteDisplayId"
      placeholder="{{'SelectEmployee'| translate}}"
      (itemSelected)="selectEmployee($event)"
      (onClearItemSelected)="clearSelectedEmployee()">
      </auto-complete>
    </div>

    <ng-content></ng-content>

    <div class="col-xs-12 col-12">
      <button class="btn btn-blue btn-lg" type="button"
        (click)="getData()" style="margin-top: 25px;">
        <i class="fa fa-search"></i>{{'Search' | translate}}
      </button>
    </div>
  </div>
</div>
