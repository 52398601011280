export class RouteVisit {
        id?                : number;
        routePlanid?       : number;
        id_user?           : number;
        order?             : number;
        tracking_id?       : string;
        status?            : string;
        title?             : string;
        address?           : string;
        city?              : any;
        latitude?          : number;
        longitude?         : number;
        load?              : number;
        window_start?      : string;
        window_end?        : string;
        window_start_2?    : string;
        window_end_2?      : string;
        duration?          : string;
        contact_name?      : string;
        contact_phone?     : string;
        contact_email?     : string;
        origin?            : string;
        reference?         : string;
        notes?             : any;
        skills_required?   : string[];
        skills_optional?   : string[];
        planned_date?      : Date;
        estimated_time_arrival?: Date;
        estimated_time_departure?: Date;
        checkin_time?     : Date;
        checkout_time?    : Date;
        checkout_latitude?: number;
        checkout_longitude?: number;
        checkout_comment?  : string;
        signature?         : any;
        pictures?          : string[];
        items?             : ItemVisit[];
        created?           : any;
        modified?          : any;
        eta_predicted?     : any;
        eta_current?       : any;
        driver?            : any;
        vehicle?           : any;
        has_alert?         : boolean;
        sms_enabled?       : boolean;
        priority_level?    : number;

        /*-----Aumentadas-----*/
        Check?            : boolean;
        Fecha?            : string;
        IdCliente?        : string;
        NombCli?          : string;
        CIRUC?            : string;
        Serie?            : string;
        Phone?            : string;
    }

    export interface RoutePlan {
        id            : number;
        id_user       : number;
        title         : string;
        zone?         : any;
        driver?       : any;
        vehicle?      : any;
        plate?        : any;
        routeVisits   : RouteVisit[];
        date          : Date;
        status        : string;
    }

    export interface ItemVisit {
      itemName       : string;
      quantity       : number;
      deliveredQty?  : number;
      packagingType? : string;
      selected?      : any;

      /*-----Aumentadas-----*/
      Id?            : string;
      code?          : string;

  }
