<form>
    <div class="input-group" style="height: 42px;">
        <span class="input-group-addon pointer" (click)="onLoadFilter()">
            <i class="ti-search"></i>
        </span>
        <!--[(ngModel)]="filter.filterLite"  -->
        <input #idInput name="idInput" type="text" placeholder="{{ placeholder | translate }}" class="input form-control"
            tooltip="{{filter.filterLite}}" placement="bottom" (keydown)="onKeydown($event,idInput.value)"
            style="background: white;height: 42px;" />
        <span class="input-group-addon pointer" (click)="onShowFilters()">
            <i class="ti-arrow-circle-down"></i>
        </span>
    </div>
</form>

<ng-template #tplFilter>
    <div #ctrls id="ctrls" class="form-dialog">
        <ng-content>

        </ng-content>
    </div>
    <div class="modal-footer" style="padding-top: 8px;padding-bottom: 8px;">
        <button class="btn  btn-yellow  btn-circle" (click)="onAccept()" type="button">
            <i class="fa fa-check"></i>
        </button>
        <button class="btn  btn-yellow  btn-circle" (click)="onCancel()" type="button">
            <i class="ti-close"></i>
        </button>
    </div>
</ng-template>