<page-heading pageTitle="{{ title | translate }}" >
  <div class="title-action  pull-right" style="padding-right: 5px;padding-left: 5px;">
    <button *ngIf="dialogInstance" type="button" class="btn btn-blue" style="padding: 6px; width: 40px;" tabindex="-1" (click)="closeDialog()">
      <i class="ti-close"></i>
    </button>
  </div>
</page-heading>
<div id="idviewer" class="report" style="overflow: scroll;">
  <pre id="contenido-archivo" style="font-size:smaller; padding-left: 20px;">
    <b>
      {{dataReport}}
    </b>
  </pre>
</div>

