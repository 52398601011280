import { Component, OnInit, Input, Output, EventEmitter, AfterContentInit, OnDestroy } from '@angular/core';
import { ServiceMapper } from '../../../services';
import { PersonnelResourceParameters } from '../../../helpers';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, of, Subject } from 'rxjs';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize, startWith, filter } from 'rxjs/operators';

@Component({
  selector: 'auto-complete',
  templateUrl: './auto-complete.component.html',
  styles: []
})
export class AutoCompleteComponent implements OnInit, AfterContentInit, OnDestroy {

  public dataSource: Array<any> = new Array<any>();
  selectedItem: any;
  public sanitizedSelection: any;
  private service: any;
  public isLoading: boolean;
  public searchControl: FormControl;

  public form: FormGroup;

  @Input() serviceName = 'Personnel' || 'Customer';
  @Input() displayName: string;
  @Input() displayId: string;
  @Input() placeholder: string;
  @Input() currentSelection: any;
  @Input() maxLenght: number

  @Output() itemSelected = new EventEmitter<any>();
  @Output() onClearItemSelected = new EventEmitter<void>();

  private ngUnsubscribe = new Subject();

  constructor(private serviceMapper: ServiceMapper, private builder: FormBuilder) {
    this.searchControl = new FormControl('', Validators.maxLength(this.maxLenght > 0 ? this.maxLenght : 50 ));
    this.form = builder.group({
      'searchTerm': this.searchControl
    });
  }

  ngAfterContentInit() {
    this.service = this.serviceMapper.getService(this.serviceName);
  }

  ngOnInit() {
    this.searchControl
    .valueChanges
    .pipe(
      startWith(''),
      debounceTime(300),
      filter(q => typeof q === "string"),
      tap(() => this.isLoading = true),
      switchMap(value => this.search(value)
      .pipe(
        finalize(() => this.isLoading = false),
      ))
    )
    .subscribe(serviceResult => this.dataSource = serviceResult);

    if (!this.displayName) {
      throw new Error('displayName property is null or empty');
    }

    if (this.currentSelection) {
      if (this.currentSelection[this.displayName]) {
        this.emitSelectedData(this.currentSelection);
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private emitSelectedData(data: any) {
    this.selectedItem = data;
    this.sanitizedSelection = this.selectedItem ? this.selectedItem[this.displayName] : null;
    this.searchControl.setValue(this.selectedItem);
    this.itemSelected.emit(this.selectedItem);
  }

  raiseItemSelected(event: MatAutocompleteSelectedEvent) {
    this.emitSelectedData(event.option.value);
  }

  clearSelection() {
    this.selectedItem = null;
    this.onClearItemSelected.emit();
  }

  search(textToSearch: string): Observable<any> {
    let serviceResult: any = of(null);
    if (textToSearch) {
      if (this.serviceName === 'Personnel') {
        const resourceParameters = new PersonnelResourceParameters();
        resourceParameters.SearchTerm = textToSearch;
        serviceResult = this.service.getPersonnel(resourceParameters);
      } else {
        serviceResult = this.service.getCustomersByName(textToSearch);
      }
    }
    return serviceResult;
  }

  displayMember(item?: any): string | undefined {
    return item ? item[this.displayName] : undefined;
  }

  trackById(index, item) {
    return item;
  }
}
