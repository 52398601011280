import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Injectable, Injector, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataStateChangeEventArgs, GridComponent, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export interface prmsWait {
  message:string;
}
@Component({
  selector: 'app-wait',
  templateUrl: './wait.component.html',
  styleUrls: ['./wait.component.css'],
  encapsulation: ViewEncapsulation.Emulated
  //providers: [InfiniteScrollService]
})
export class WaitComponent implements OnInit, OnDestroy {

  ngUnsubscribe = new Subject();
  private subscription: Subscription;
  public prms : prmsWait;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogConfig : any,
              public dialogRef: MatDialogRef<WaitComponent>)
    {

    }

  ngOnInit(): void {
    //console.log(this.dialogConfig);
    this.prms=this.dialogConfig;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
  }

  onClose(){
    this.dialogRef.close(null);
  }
}
