import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { CustomerLedgerReport, OverallCustomerPortfolioReport } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class LedgerService extends BaseService{

  constructor(injetor: Injector) {
    super(injetor);
   }

   getCustomerLedger(id_customer: string): Observable<CustomerLedgerReport[]> {
    const url = this.getApiQuery(`Customer/Ledger?from=${id_customer}&to=${id_customer}`);
    return this._httpClient.get<CustomerLedgerReport[]>(url, {headers: this.getAuthorizationHeader()});
  }

  getOveralllCustomerPortfolio(): Observable<OverallCustomerPortfolioReport[]> {
    const url = this.getApiQuery('Customer/OverallCustomerPortfolio');
    return this._httpClient.get<OverallCustomerPortfolioReport[]>(url, {headers: this.getAuthorizationHeader()});
  }
}
