import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { Attendance, AttendanceRecord } from '../../models/RRHH/Attendance';
import { DocumentResourceParameters, PersonnelResourceParameters } from '../../helpers';
import { BaseResult, PagedResult } from 'src/app/models';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService extends BaseService {

  constructor(injetor: Injector) {
    super(injetor);
   }

   getAttendance(params: PersonnelResourceParameters): Observable<Array<Attendance>> {
     const url = this.getApiQuery(`RRHH/Employee/ATTENDANCE?FROM=${params.From}&TO=${params.To}&ID_PER=${params.SearchTerm}`);
     return this._httpClient.get<Array<Attendance>>(url, {headers: this.getAuthorizationHeader()});
   }

  saveAttendanceRecord(record: AttendanceRecord) {
    const url = this.getApiQuery('RRHH/Employee/ATTENDANCE');
    return this._httpClient.post<BaseResult>(url, record, { headers: this.getAuthorizationHeader() });
  }

  getClockingList(args: DocumentResourceParameters): Observable<PagedResult<AttendanceRecord>> {
    const url = this.getApiQuery('RRHH/Employee/MARCACI/Pagging');
    let params = new HttpParams();
    if (args.From) {
      params = params.append('From', args.From);
    }

    if (args.To) {
      params = params.append('To', args.To);
    }

    if (args.SearchTerm) {
      params = params.append('SearchTerm', args.SearchTerm);
    }

    params = params.append('PageNumber', args.PageNumber.toString());
    params = params.append('PageSize', args.PageSize.toString());
    return this._httpClient.get<PagedResult<AttendanceRecord>>(url, { headers: this.getAuthorizationHeader(), params: params });
  }

  getAttendanceById(id: string): Observable<AttendanceRecord> {
    const url = this.getApiQuery(`RRHH/Employee/MARCACI?ID=${id}`);
    return this._httpClient.get<AttendanceRecord>(url, { headers: this.getAuthorizationHeader() });
  }

  deleteAttendance(id: number) {
    const url = this.getApiQuery(`RRHH/Employee/MARCACI?ID=${id}`);
    return this._httpClient.post<BaseResult>(url, { headers: this.getAuthorizationHeader() });
  }
}
