import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_SETTINGS } from '../../appsettings';
import { NavigationService } from '../helpers/navigation.service';
import { LoginService } from '../login.service';
import { SessionService } from '../helpers/session.service';
import { smoothlyMenu, hideSideBar } from '../../app.helpers';
import { DataService } from '../helpers/data.service';

@Injectable({
  providedIn: 'root'
})
export class NoLoginGuardService implements CanActivate {

  constructor(private _navigationService: NavigationService,
    private loginService: LoginService,
    private sessionService: SessionService,
    private dataService: DataService) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const noLogin = APP_SETTINGS.features.disableLogin;
    if (noLogin) {
      const credentials = APP_SETTINGS.defaultCredentials;
      this.loginService.authenticate(credentials.defaultCustomer, credentials.defaultPassword)
        .subscribe(result => {
          if (result.Successful) {
            this.sessionService.clearSession();
            this.sessionService.setSessionInfo(result, 0, 'h');
            const isDefaultCustomer = result.Extras.defaultCustomer === 1;
            if (!isDefaultCustomer) {
              console.log('no es defaultcustomer')
              smoothlyMenu(isDefaultCustomer);
            } else {
              hideSideBar();
            }
            this.dataService.setIsDefaultCustomer(isDefaultCustomer);
            this._navigationService.navigateToProducts();
            return true;
          } else {
            return false;
          }
        }, error => {
          this.dataService.setIsDefaultCustomer(true);
          return false
        });
      // return true;
    } else {
      this._navigationService.navigateToLogin();
      return false;
    }
  }
}
