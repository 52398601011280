import { Component, OnInit, ViewChild, OnDestroy, AfterContentInit, Input, ViewEncapsulation, Inject, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Navigation } from '../../../helpers';
import { BtnReturnComponent } from '../../widgets/btn-return/btn-return.component';
//import { IDocument } from 'src/app/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//import { Stimulsoft } from '../../../../assets/js/stimulsoft.viewer.pack';

export interface paramsViewerHtml{
  title? : string;
  modal?:boolean;
  width?: number;
  height?: number;
  dataReport:any;
  dialogButtom?:any;
}

declare var Stimulsoft: any;

@Component({
  selector: 'viewer-html',
  templateUrl: './viewer-html.component.html',
  styleUrls: ['./viewer-html.component.css'],
  encapsulation: ViewEncapsulation.Emulated

})

  export class ViewerhtmlComponent implements OnInit, OnDestroy, AfterContentInit {


    viewer: any = null;
    report: any = null;
    title: string;
    modal:boolean;
    public reportText:any;
    private ngUnsubscribe = new Subject();
    public dataReport: any;
    public height:number;
    @Input()  initialTemplate: TemplateRef<any>;

    navigation: Navigation;
    @ViewChild('returnBtn') btn: BtnReturnComponent;
    currentTemplate: TemplateRef<any>;
    pieTemplate: TemplateRef<any>;

    constructor(public dialogInstance: MatDialogRef<ViewerhtmlComponent>,
      @Inject(MAT_DIALOG_DATA) public data: paramsViewerHtml){
      this.title=data.title;
      this.modal=this.data.modal;
      this.dataReport=this.data.dataReport;
      this.initialTemplate=this.dataReport;
      this.pieTemplate=this.data.dialogButtom;
      this.height = this.data.height; //'570';
      //console.log(this.height);
      //console.log(dialogInstance);
    }
    ngOnInit() {
      this.currentTemplate = this.initialTemplate;
    }

    ngAfterContentInit() {
        //this.btn.navigation = this.navigation;
        this.renderReport();
    }
    renderReport() {
        this.dataReport=this.data.dataReport;
    }

    ngOnDestroy() {
        //this.lazyLoaderService.removeScript('reports');
        //this.lazyLoaderService.removeScript('viewer');

        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    closeDialog(){
      this.dialogInstance.close();
    }

  }
