import { Injectable, Injector } from '@angular/core';
import { Contact, PagedResult, Fact, Customer, Supplier, Supplier2 } from '../../models';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { CustomerResourceParameters } from '../../helpers';
import { HttpParams } from '@angular/common/http';
import { LocationAddress } from '../../models/LocationAddress';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }


  getSupplierById(id: string) {
    const url = this.getApiQuery('Supplier/GetSupplierById');
    let params = new HttpParams();
    params = params.append('supplierId', id);
    return this._httpClient.get(url, 
      { 
        params : params,
        headers: this.getAuthorizationHeader() 
      }
    );
  }

  getList(PageNumber: number, PageSize:number,SearchTerm:string) : Observable<any[]> {
    let params: HttpParams = new HttpParams();

    
    if (SearchTerm) { params = params.append('SearchTerm',SearchTerm.toString()); }
    if (PageNumber) { params = params.append('PageNumber',PageNumber.toString()); }
    if (PageSize)   { params = params.append('PageSize',PageSize.toString()); }

    let url = this.getApiQuery(`Supplier/Pagging`);
    return this._httpClient.get<Supplier2[]>(
        url,
        { 
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }

  createRecord(record: Supplier2) {
    console.log(record);
    const url = this.getApiQuery('CustomerService/SaveSupplier');
    return this._httpClient.post(
      url,
      record,
      { headers: this.getAuthorizationHeader() }
    );
  }

  updateRecord(record: Supplier2) {
    const url = this.getApiQuery('CustomerService/SaveSupplier');
    return this._httpClient.post(
      url,
      record,
      { headers: this.getAuthorizationHeader() }
    );
  }


}
