<page-heading pageTitle="{{ titleComponent | translate}}">
  <button *ngIf="dialogInstance" type="button" class="btn btn-primary" tabindex="-1"
    (click)="closeDialog()">
    <i class="ti-close"></i>
  </button>
</page-heading>

<div [ngClass]="!dialogInstance ? 'p-h-xl' : 'p-sm'">
  <div [ngClass]="!dialogInstance ? 'col-md-12 col-centered' : 'col-md-12
    col-centered'">
    <form [formGroup]="Form" class="form form-horizontal">

      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'Id' | translate}}</label>
        <div class="col-md-4">
          <input id="Id" formControlName="Id" class="form-control
            text-uppercase" type="text" />
        </div>
        <label class="col-md-2 col-form-label">{{'City' | translate}}</label>
        <div class="col-md-4">
          <input id="City" formControlName="City" class="form-control
            text-uppercase" type="text" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'Name' | translate}}</label>
        <div class="col-md-10">
          <input id="Name" formControlName="Name" class="form-control
            text-uppercase" type="text" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'Address' | translate}}</label>
        <div class="col-md-10">
          <input id="Address1" formControlName="Address1" class="form-control
            text-uppercase" type="text" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'Phone' | translate}}</label>
        <div class="col-md-4">
          <input id="Phone" formControlName="Phone" class="form-control
            text-uppercase" type="text" />
        </div>
        <label class="col-md-2 col-form-label">{{'Contact' | translate}}</label>
        <div class="col-md-4">
          <input id="Contact" formControlName="Contact" class="form-control
            text-uppercase" type="text" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'Horario de Atención' |
          translate}}</label>

        <div class="col-md-2">
          <h5 class="Noesp">{{ 'Desde' | translate }}</h5>

          <ejs-timepicker id="Windows_e" name="Windows_e" formControlName="Windows_e" format='HH:mm'
            step='60'></ejs-timepicker>
          <!--
            <input id="Windows_e" formControlName="Windows_e" class="form-control text-uppercase" type="text" />
            -->
        </div>

        <div class="col-md-2">
          <h5 class="Noesp">{{ 'Hasta' | translate }}</h5>
          <ejs-timepicker name="Windows_s" id="Windows_s" formControlName="Windows_s" format='HH:mm'
            step='60'></ejs-timepicker>
          <!--
            <input id="Windows_s" formControlName="Windows_s" class="form-control text-uppercase" type="text" />
            -->
        </div>

        <label class="col-md-2 col-form-label">{{'Ubication' | translate}}</label>
        <div class="col-md-4">
          <h5 class="Noesp">{{ 'Latitude' | translate }}</h5>
          <input id="Lat" formControlName="Lat" class="form-control
            text-uppercase" type="text" />
        </div>

      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label"></label>

        <div class="col-md-2">
          <h5 class="Noesp">{{ 'Desde' | translate }}</h5>
          <ejs-timepicker name="Windows2_e" id="Windows2_e" formControlName="Windows2_e" format='HH:mm'
            step='60'></ejs-timepicker>
          <!--
            <input id="Windows2_e" formControlName="Windows2_e" class="form-control text-uppercase" type="text" />
            -->
        </div>

        <div class="col-md-2">
          <h5 class="Noesp">{{ 'Hasta' | translate }}</h5>
          <ejs-timepicker name="Windows2_s" id="Windows2_s" formControlName="Windows2_s" format='HH:mm'
            step='60'></ejs-timepicker>
          <!--
            <input id="Windows2_s" formControlName="Windows2_s" class="form-control text-uppercase" type="text" />
            -->
        </div>

        <div class="col-md-2">
        </div>

        <div class="col-md-4">
          <h5 class="Noesp">{{ 'Longitude' | translate }}</h5>
          <input id="Long" formControlName="Long" class="form-control
            text-uppercase" type="text" />
        </div>

      </div>

      <div class="form-group p-h-sm text-center">
        <button type="submit" mat-button class="btn btn-yellow" [disabled]="!Form.valid" (click)="onRegister()"><i
            class="ti-save"></i> {{action | translate}}</button>
      </div>

    </form>
  </div>
</div>