import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { EquipmentModel } from '../../models';


@Injectable({
  providedIn: 'root'
})
export class EquipmentModelService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }


  getListEquipmentModel() {
    const url = this.getApiQuery(`ControlEditor/GetEquipmentModels `);
    return this._httpClient.get<EquipmentModel[]> (url, { headers: this.getAuthorizationHeader() });

  }
}
