  export class ExtendedServiceRequest {
    _Status: number;
    ServiceRequestID: number;
    ServiceRequestNo: string;
    SOAppointmentID: number;
    ServiceOrderID: number;
    CallDate: Date;
    RegDate: Date;
    AnswerDate: Date;
    CloseDate: Date;
    CustomerID: string;
    CustomerLocaltionID: number;
    ContactID: number;
    ClassID: number;
    ClassName: string;
    CustomerName: string;
    LocationName: string;
    EmployeeAssigned: string;
    AssignedID?: string;
    EmployeeEditor: string;
    RisponsableID: number;
    Medie: string;
    ShortText: string;
    Problem: string;
    ProblemRTF: string;
    Status: number;
    StatusName: string;
    StatusColor: string;
    Priority: number;
    Unit: number;
    Closed: boolean;
    BrancheUser?: string;
    BrancheID: number;
    BrancheCustomer?: string;
    ConfirmRecived: boolean;
    ContractType: number;
    Owner: number;
    Caller: string;
    Place: string;
    Address: string;
    Phone: string;
    Email: string;
    Note?: string;
    TypeID: number;
    SRTyp: string;
    ErrorCode: number;
    Answered: boolean;
    Started: boolean;
    isTaskSelfCia: boolean;
    isDelivery: boolean;
    isDisturbance: boolean;
    isContract: boolean;
    isCollection: boolean;
    isOutstanding: boolean;
    ProblemID: number;
    ProcessID: number;
    Process: string;
    ProjectID: number;
    Project?: string;
    CostCentre: string;
    SkillID: number;
    SkillLevelID: number;
  }
  export class TypEvent {
    SRHistoryID : number;
    ServiceRequestID : number;
    RegDate : Date;
    EventType :number;
    EventRef :number;
    Description : string;
    CompanyID :number;
    UserID :number;
  }

  export class MergeEvent {
    ServiceRequestID : number;
    ServiceRequestIDRef : number;
  }
  export class VisitReason {
    Code: number;
    CustomerId: string;
    GUID: string;
    Id_User: number;
    Lat: string;
    Long: string;
    Note: string;
    Reason: string;
    State: number;
    Type: number;
    VisitDate: string;
  }