import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { SearchGridComponent } from './searchGrid.component';

@Injectable({ providedIn: 'root' })
export class SearchGridService {
  private ObjSubjectRx$: Subject<any> = new Subject<any>();
  dialogConfig = new MatDialogConfig();
  public data: object;
  public modal: MatDialogRef<SearchGridComponent>;

  constructor(private router: Router,
    private dialog: MatDialog,
  ) {
  }

  public Show(parameter: any){ //: Observable<any> {
    this.dialogConfig.panelClass = 'custom-dialog-container';
    this.dialogConfig.disableClose = false;
    this.dialogConfig.width = parameter.params.width;// '800px',
    this.dialogConfig.height = parameter.params.height;//'600px',
    this.dialogConfig.data = parameter;
    return this.dialog.open(SearchGridComponent, this.dialogConfig);
  }

}
