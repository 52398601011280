import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Injectable, Injector, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { updateColumnTypeForExportColumns } from '@syncfusion/ej2-angular-grids';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';

export interface DialogData {
  title?:string;
  message?:string;
  buttons?:string;
  ok?:boolean;
  cancel?:boolean;
}

@Component({
  selector: 'dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DialogboxComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  private subscription: Subscription;
  public title:string;
  public message:string;
  public isyesno : boolean = true;
  public isyes : boolean = false;

  constructor(
    public dialogInstance: MatDialogRef<DialogboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData){
    this.title=data.title;
    this.message=data.message;
  }
  ngOnInit(): void {
    this.isyesno=true;
    //debugger
    if (this.data.buttons=='yes/no') {
      this.isyesno=true;
      this.isyes=false;
    }
    if (this.data.buttons=='yes') {
      this.isyesno=false;
      this.isyes=true;
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngAfterViewInit() {
  }
  closeDialog() {
    let result: object = {
      success:false,
      data:null
    }
    this.dialogInstance.close(result);
  }
  onAccept(){  
    let result: object = {
      success   : true,
      data      : null
    }
    this.dialogInstance.close(result);
  }
  onCancel(){
    this.closeDialog();
  }
}
