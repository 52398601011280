import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpParams } from '@angular/common/http';
import { PagedResult, Type_invoice } from '../../models';
import { Observable } from 'rxjs';
import { Compra } from '../../models/purchase/purchase';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }

  getSeries(DocType:string) {
    let params: HttpParams = new HttpParams();

    if (DocType) { params = params.append('DocType',DocType); }

    const url = this.getApiQuery(`Account/GetSeries`);
    return this._httpClient.get(url, {
      params: params,
      headers: this.getAuthorizationHeader()
    });
  }

  getList(PageNumber: number, PageSize:number,From:string,To:string, DocId:string) : Observable<PagedResult<Compra>> {
    let params: HttpParams = new HttpParams();


    if (DocId) { params = params.append('DocId',DocId.toString()); }
    if (From) { params = params.append('From',From.toString()); }
    if (To)   { params = params.append('To',To.toString()); }

    if (PageNumber) { params = params.append('PageNumber',PageNumber.toString()); }
    if (PageSize)   { params = params.append('PageSize',PageSize.toString()); }

    let url = this.getApiQuery(`Supplier/GetPurchases`);
    return this._httpClient.get<PagedResult<Compra>>(
        url,
        {
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }


  getTypeDoc() {
    //let params: HttpParams = new HttpParams();
    //if (DocType) { params = params.append('DocType',DocType); }
    const url = this.getApiQuery(`Invoices/GetDocTypes`);
    return this._httpClient.get(url, {
      //params: params,
      headers: this.getAuthorizationHeader()
    });
  }

  GetSustentoTributario(DocType:string) {
    let params: HttpParams = new HttpParams();
    if (DocType) { params = params.append('DocType',DocType); }
    const url = this.getApiQuery(`Invoices/GetSustentoTributario`);
    return this._httpClient.get(url, {
      params: params,
      headers: this.getAuthorizationHeader()
    });
  }

  insertPurchase(compra: Compra) {
    const url = this.getApiQuery('Supplier/InsertCompra');
    return this._httpClient.post(
      url,
      compra,
      { headers: this.getAuthorizationHeader()}
    );
  }
  insertQuotationPur(invoice: Type_invoice) {
    const url = this.getApiQuery('Customer/InsertQuotation');
    return this._httpClient.post(
      url,
      invoice,
      { headers: this.getAuthorizationHeader()}
    );
  }
  GetPurchaseCodbarById(docId:string){
    let params: HttpParams = new HttpParams();
    if (docId) { params = params.append('docId',docId); }
    const url = this.getApiQuery(`Supplier/GetPurchaseCodbarById`);
    return this._httpClient.get(url, {
      params: params,
      headers: this.getAuthorizationHeader()
    });
  }

}
