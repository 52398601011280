import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { InvoiceItem } from '../models';

@Pipe({
  name: 'customBaseCurrencyPipe'
})
export class CustomBaseCurrencyPipe implements PipeTransform {
  protected locale: string;

  Round(n, dp) {
    const h = +('1'.padEnd(dp + 1, '0')) // 10 or 100 or 1000 or etc
    return Math.round(n * h) / h
 }
  constructor(@Inject(LOCALE_ID) locale: string) {
    this.locale = locale;
  }

  transform(items: Array<InvoiceItem>, display?: 'narrow' | 'wide'): string | null {
    return this.calculate(items, display);
  }

  calculate(items: Array<InvoiceItem>, display: 'narrow' | 'wide'): string | null {
    return null;
  }

}
