import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Product, BaseResult, PagedResult } from '../../models';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ProductResourceParameters } from '../../helpers';
import { ProductImages } from 'src/app/models/Sales/ProductImages';
import { APP_SETTINGS } from '../../appsettings';
@Injectable({
  providedIn: 'root'
})
export class ProductsService extends BaseService {
  private enablePromoDetail = APP_SETTINGS.setups.cart.enablePromoDetail;
  constructor(injector: Injector) {
    super(injector);
  }

  getProducts(productResourceParameters: ProductResourceParameters): Observable<PagedResult<Product>> {
    const url = this.getApiQuery(`Products/Pagging`);
    let params: HttpParams = new HttpParams();

    if (productResourceParameters.PageNumber) {
      params = params.append('PageNumber', productResourceParameters.PageNumber.toString());
    }

    if (productResourceParameters.PageSize) {
      params = params.append('PageSize', productResourceParameters.PageSize.toString());
    }

    if (!productResourceParameters.PriceType) {
      productResourceParameters.PriceType = 1;
    }


    params = params.append('FilterCategory', productResourceParameters.FilterCategory);
    params = params.append('FilterSubgroup', productResourceParameters.FilterSubgroup);
    params = params.append('FilterBrand', productResourceParameters.FilterBrand);

    params = params.append('SearchTerm', productResourceParameters.SearchTerm);
    params = params.append('WarehouseNumber', productResourceParameters.WarehouseNumber.toString());
    params = params.append('PriceType', productResourceParameters.PriceType.toString());

    return this._httpClient.get<PagedResult<Product>>(url, {headers: this.getAuthorizationHeader(), params: params});
  }

  saveProduct(product: Product): Observable<string> {
    const url = this.getApiQuery('CustomerService/SaveProduct');
    return this._httpClient.post<string>(url, product, {
      headers: this.getAuthorizationHeader()
    });
  }

  getproductByCode(searchText: string) {
    const url = this.getApiQuery(`Products/Code?code=${searchText}`);
    return this._httpClient.get(url, { headers: this.getAuthorizationHeader() });
  }

  getpromoByCode(searchText: string,precio:number) {
    let url = this.getApiQuery(`Inventory/GetItemsPromo?code=${searchText}&tipoPrecio=${precio}`);
    if(this.enablePromoDetail == false)
    {
        url = this.getApiQuery(`Inventory/GetItemsPromo?code=${searchText}&tipoPrecio=${precio}&nodetail=1`);
    }

    return this._httpClient.get(url, { headers: this.getAuthorizationHeader() });
  }

  getproductByID(searchText: string,WarehouseNo?,docid? ) {
    let params: HttpParams = new HttpParams();
    params = params.append('id', searchText);
    if (docid) params = params.append('docid', docid);
    if (WarehouseNo) params = params.append('warehouseno', WarehouseNo);

    const url = this.getApiQuery(`Inventory/Product`); //?id=${searchText}
    return this._httpClient.get(
      url,
      {
        params: params,
        headers: this.getAuthorizationHeader()
      }
    );
  }

  getProductStock(searchText: string) {
    const url = this.getApiQuery(`Products/ProductStock?code=${searchText}`);
    return this._httpClient.get(url, { headers: this.getAuthorizationHeader() });
  }

  getTaxesList() : Observable<any>  {
    const url = this.getApiQuery(`POS/GetTaxs`);
    return this._httpClient.get(url, { headers: this.getAuthorizationHeader() });
  }

  getStoreList(): Observable<any> {
    const url = this.getApiQuery(`Inventory/GetBodegas`);
    return this._httpClient.get(url, { headers: this.getAuthorizationHeader() });
  }

  getProductById(productId: string) {
    const url = this.getApiQuery(`Inventory/Product?Id=${productId}`);
    return this._httpClient.get(url, { headers: this.getAuthorizationHeader() });
  }

  updateProductImages(productImgs: ProductImages) {
    const url = this.getApiQuery(`CustomerService/SaveProductImages`);
    return this._httpClient.post<string>(url, productImgs, {
      headers: this.getAuthorizationHeader()
    });
  }


  updateImages(Imgs: object,type: string,) {
    let params: HttpParams = new HttpParams();
    params = params.append('type', type);

    const url = this.getApiQuery(`system/SaveImages`);
    return this._httpClient.post<string>(
      url,
      {Imgs,params},
      {headers: this.getAuthorizationHeader()}
    );
  }

  updateImages2(Imgs: object,type: string,) {
    let url:string="";
    if (type=="ART") url = this.getApiQuery(`system/SaveImages`);
    if (type=="CHE") url = this.getApiQuery(`banck/SaveImages`);
    if (type=="EMP") url = this.getApiQuery(`empresa/SaveImages`);

    return this._httpClient.post<string>(
      url,
      Imgs,
      {headers: this.getAuthorizationHeader()}
    );
  }


}
