<div class="login-bg animated fadeIn" [ngStyle]="{'background-image': 'url(' +
  backgroundURL +
  ')'}">
  <div class="middle-box text-center loginscreen wrapper">
    <div id="formContainer" *ngIf="!isLoading">
      <div class="logo-img">
        <img src="assets/images/logos/mainlogo.png" class="img-responsive"
          alt="Your company Logo">
      </div>
      <div class="m-t">
        <div class="form-login">
          <p class="d-flex">
            <span class="fa fa-user"></span>
            <input type="email" id="email" placeholder="{{'Username' |
              translate}}" [(ngModel)]="username"
              (focus)="focus()">
          </p>
          <p class="d-flex">
            <span class="fa fa-lock"></span>
            <input type="password" id="password" placeholder="{{'Password' |
              translate}}" [(ngModel)]="password"
              (keyup.enter)="authenticate()" (focus)="focus()">
          </p>
          <div class="clearfix"></div>
        </div>
        <!-- <div class="form-group">
        <label for="email"></label>
        <input type="email" id="email" class="form-control" placeholder="{{'Username' | translate}}"
          [(ngModel)]="username" (focus)="focus()">
      </div>
      <div class="form-group">
        <label for="password"></label>
        <input type="password" id="password" class="form-control" placeholder="{{'Password' | translate}}"
          [(ngModel)]="password" (keyup.enter)="authenticate()" (focus)="focus()">
      </div> -->
        <div class="alert alert-danger animated fadeIn" role="alert"
          *ngIf="credentialsEmpty">
          {{ message }}
        </div>

        <button type="submit" class="btn btn-primary btn-block" (click)="authenticate()">
          {{'Login' | translate}}
        </button>

        <!-- <a [routerLink]="['/']" class="text-muted font-bold">{{'Return'|translate}}</a> -->
      </div>
    </div>

    <div class="sk-spinner sk-spinner-three-bounce" *ngIf="isLoading"
      [ngClass]="{'loginscreen':isLoading}">
      <div class="sk-bounce1"></div>
      <div class="sk-bounce2"></div>
      <div class="sk-bounce3"></div>
    </div>
  </div>
</div>
