import { Component, OnDestroy } from '@angular/core';
import { LoginService, SessionService, NavigationService } from '../../services';
import { APP_SETTINGS } from '../../appsettings';
import { CompanyService } from 'src/app/services/system/company.service';

@Component({
  selector: 'login',
  templateUrl: 'login.template.html',
})

export class LoginComponent implements OnDestroy {
  backgroundURL: string;
  username: string;
  password: string;
  rememberMe: boolean;
  credentialsEmpty: boolean;
  message: string;
  isLoading: boolean;

  constructor(private _loginService: LoginService,
    private _sessionService: SessionService,
    private _navigationService: NavigationService,
    private _dataServiceEmp: CompanyService, /*----MVC---*/) {

    if (this._sessionService.isLoggedIn()) {
      const isDefaultCustomer = this._sessionService.getSessionInfo().Extras.name === '';
      if (!isDefaultCustomer) {
        this._navigationService.navigateToRoot();
      }
    }
  }

  ngOnInit(): void {
    this.loadLoginBG();
  }

  ngOnDestroy() {
  }

  authenticate() {
    if (this.username && this.username !== undefined && this.password && this.password !== undefined) {
      this.isLoading = true;
      this._loginService.authenticate(this.username, this.password)
        .subscribe(response => {
          if (response.Successful) {
            this.credentialsEmpty = false;
            this._sessionService.clearSession();
            this._sessionService.setSessionInfo(response, 8, 'h');

            //APP_SETTINGS.endpoints.routehome='sales/products';
            //console.log(response);

            this.isLoading = false;
            //this._sessionService.getPosition().then(pos => { });

            //let webhome='app/dashboards/board';
            let webhome=response.Extras.webhome;

            /*------------Jala datos de Empresa---------*/
            this._dataServiceEmp.getRecord(null).subscribe(
              (response:any) => {
                //console.log(response);
                if (response != null) {
                  this._sessionService.setSessionEnterprise(response);
                }
              }
            );
            
            if (webhome){
              this._navigationService.navigateToHome(webhome);
            } else {
              if (this._sessionService.isCustomer()) {
                // aqui aqui aqui
                this._navigationService.navigateToProducts();
              } else {
                this._navigationService.navigateToRoot();
              }
            }

          } else {
            this.showErrorMessage('username or password wrong.');
          }
        }, error => {
          this.showErrorMessage('An error has ocurred');
        });
    } else {
      this.showErrorMessage('The fields can not be empty.');
    }
  }

  focus() {
    this.credentialsEmpty = false;
  }

  private showErrorMessage(message: string) {
    this.isLoading = false;
    this.credentialsEmpty = true;
    this.message = message;
  }

  loadLoginBG() {
    const url = `${APP_SETTINGS.setups.backgroundImage}`;
    this.backgroundURL = url;
  }
}
