import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { APP_SETTINGS } from '../../appsettings';

@Injectable({
  providedIn: 'root'
})
export class FileService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  sendFile(formData: FormData) {
    return this._httpClient.post(this.getApiQuery('Files/upload'), formData, {
      headers: this.getAuthorizationHeader(),
      reportProgress: true
    });
  }

  sendMail(formData: FormData) {
    const url = this.getApiQuery('Files/sendMail');
    return this._httpClient.post(url, formData, {
      headers: this.getAuthorizationHeader('Accept', 'multipart/form-data'),
      reportProgress: true
    });
  }
  saveAs(data: any, fileName: string, type: string) {
    const stream = new Uint8Array(data).buffer;
    const dataBlob = new Blob([stream], { type: type });
    const blobUrl = URL.createObjectURL(dataBlob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = fileName;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  getBlobThumbnail(url: string): Observable<Blob> {
    const urlphp = `${APP_SETTINGS.endpoints.image_get_service}`;
    return this._httpClient.post<Blob>(urlphp, {
      'url': url
    }, { responseType: 'blob' as 'json' });
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this._httpClient.get(imageUrl, { responseType: 'blob' });
  }
}

