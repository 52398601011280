import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Country } from 'src/app/models/Sales/country';
import { GenericData } from 'src/app/models/RRHH/GenericData';


@Injectable({
  providedIn: 'root'
})
export class CountryService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }

  getListCountry() {
    const url = this.getApiQuery(`controleditor/getemp1_p01_pais`);
    return this._httpClient.get<Country[]> (url, { headers: this.getAuthorizationHeader() });
  }

  getFullCountryList() {
    const url = this.getApiQuery(`RRHH/GetCountriesISO`);
    return this._httpClient.get<GenericData[]> (url, { headers: this.getAuthorizationHeader() });
  }
}
