import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CustomersService,SessionService,SweetAlertService } from '../../../../services';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { enableRipple } from '@syncfusion/ej2-base';
import { takeUntil} from 'rxjs/operators';

import { ChartType, ChartOptions } from 'chart.js';
//import { Label} from 'ng2-charts';
//import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';

//import { ChartDataSets } from 'chart.js';
//import { Color } from 'ng2-charts';

export interface TypeTipo{
  id:number;
  name:string;
}
export interface prmsLine {
  title           : string;
  subtitle?       : string;
  lineChartLabels : Array<string>;
  lineChartData   : Array<any>;
}

enableRipple(false);

@Component({
  selector: 'board-chartline',
  templateUrl: './board-chartline.component.html',
  styleUrls: ['./board-chartline.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BoardchartlineComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  public nav:any;
  public todos: any;
  @Input() data : prmsLine;

  /*-----------------Chart LINE------------------*/
  public lineChartData: any;//ChartDataSets[];
  public lineChartLabels: any;//Label[];
  public lineChartOptions:any;
  public lineChartColors: any;//Color[];
  public lineChartLegend :boolean;
  public lineChartPlugins = [];
  public lineChartType = 'line';

  constructor(private _formBuilder: FormBuilder){
    this.lineChartOptions = {
      responsive: true,
    };
    this.lineChartColors = [
      {
        borderColor: 'black',
        backgroundColor: 'rgba(255,255,0,0.28)',
      },
    ];
    this.lineChartLegend = true;
    this.lineChartPlugins = [];
    this.lineChartType = 'line';
    
  }

  getData(){
    this.lineChartLabels = this.data.lineChartLabels;
    this.lineChartData   = this.data.lineChartData;
  }

  refresh(){
    //console.log('refresh');
    //this.chart.update();
    window.setTimeout(() => {
      this.getData();
    }, 100);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  chartClicked(e: any): void {
    console.log(e.active);
    console.log(e.event);
  }

  chartHovered(e: any): void {
    console.log(e);
  }

}
