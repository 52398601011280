import { Component, OnInit, Input, OnDestroy, Injectable, ViewEncapsulation, ViewChild, ElementRef, Injector } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs'; 
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'; 
import { enableRipple } from '@syncfusion/ej2-base';
import { DataStateChangeEventArgs, GridComponent } from '@syncfusion/ej2-angular-grids';
import { NavigationService, PopModalService } from './../../../services';

import { HttpParams } from '@angular/common/http';
import { BaseService } from '../../../services/base/base.service';
import { DinamicFormComponent } from './dinamic-form/dinamic-form.component';
import { takeUntil } from 'rxjs/operators';

export interface paramsCrud{
    title       : string;
    api         : string;
    columns     : object;
    apiDelete   : string;
    fieldFilter : string;
    view        : boolean;
    new         : boolean;
    edit        : boolean;
    delete      : boolean;
}
export interface paramsForm{
    titleNew    : string;
    titleEdit   : string;
    renderer    : object;
    model       : object;
    apiInsert   : string;
    apiEdit     : string;
    width       : string;
    height      : string;
}

export interface paramsConfig{
  crud  : paramsCrud
  form  : paramsForm
}

enableRipple(true);


@Injectable({
  providedIn: 'root'
})

export class DatagridService extends BaseService {
  constructor(private _injector: Injector) {
    super(_injector);
  }

  public getDataAPi(skip: number,url:string,fieldFilter:string,filter : string) : Observable<any[]>  {
    //let Ini= Math.floor((skip/20));
    //Ini++;

    let params: HttpParams = new HttpParams();
    if (filter) { params = params.append(fieldFilter,filter.toString()); }
    params = params.append('PageNumber',skip.toString());
    params = params.append('PageSize','20'); 
    url = this.getApiQuery(url);

    return this._httpClient.get<any[]>(
      url,
      { 
        params: params,
        headers: this.getAuthorizationHeader()
      }
    );
  }
}


@Component({
  selector: 'dinamic-Crud',
  templateUrl: './dinamic-crud.component.html',
  styleUrls: ['./dinamic-crud.component.css'],
  //encapsulation: ViewEncapsulation.None
})
export class DinamicCrudComponent implements OnInit, OnDestroy {

  ngUnsubscribe = new Subject();
  private subscription: Subscription;

  public pageSettings: Object;
  public state:DataStateChangeEventArgs;
  public customAttributes: Object;
  public stackedHeaderRows: Object;
  public PageSize:number;
  public Search:string;
  public infoRecord: any = null;
  

  @ViewChild('grid')
  public grid: GridComponent;

  @ViewChild('idInput',{static: false}) input: ElementRef;
  @ViewChild('btn',{static: false}) btn: ElementRef;


  public data: Object;

  /*****************************************************************/
  /**   El CRUD Dinamic se renderiza acorde a la variable Config  **/
  /*****************************************************************/

  @Input() config : paramsConfig;
  public columns: Object;
  public url:string;

  constructor(
              private _data: DatagridService,
              private _navigationService: NavigationService,
              private _translateService: TranslateService,
              private _notificationService: PopModalService,
            )
  {
  }

  public dataStateChange(state: DataStateChangeEventArgs): void {
    let Skip= Math.floor((state.skip/state.take));
    Skip++;
    this.refresGrid(Skip,state.take);
  }

  refresGrid(PageNumber: number, PageSize:number){
    this._data.getDataAPi(PageNumber,this.url,this.config.crud.fieldFilter, this.Search).subscribe(
      (response:any) => {
        if (response != null) {
          let data = {
            result: response['Result'],
            count: parseInt(response['TotalRows'], 10)
          }
          this.data = data;
        }
      }, error => {
        console.error('An error has occured');
      });
  }

  ngOnInit() {
    this.PageSize=20;
    this.pageSettings = {
      pageSize: this.PageSize,
      pageCount: 3
    };
    this.customAttributes = {class: 'customcss'};
    this.columns=this.config.crud.columns;
    this.url=this.config.crud.api;

    this.subscription = this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      //this.TranslaterHeaders();
    });
    this.refresGrid(1,this.PageSize);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.subscription.unsubscribe();
  }

  TranslaterHeaders(){
      let Headers =[
        {header:'Name',   field:'Name' },
        {header:'Phone',  field:'Phone' },
        {header:'Actions',field:'Manage' }
      ]

      for (let item of Headers){
        this._translateService.get(item.header)
            .subscribe((result: string) => {
              let column = this.grid.getColumnByField(item.field); // Get column object.
              column.headerText = result;
        });
      }
      window.setTimeout(() => {
        this.grid.refreshHeader();
      }, 0);
  }
  ngAfterViewInit() {
    //this.TranslaterHeaders();
  }

  /***********************************************************************************/
  /**   En la Edicion se invoca al FormDinamic y se renderiza  de acuerdo a Config  **/
  /***********************************************************************************/

  onEditRecord(args:any,data:any,readOnly:boolean){
    if (data !== null) {
      let itemRecord = <any>this.grid.getCurrentViewRecords()[data.index];
      const dialog = this._notificationService._dialog.open(DinamicFormComponent, { width: this.config.form.width,height:this.config.form.height });
      dialog.componentInstance.dialogInstance = dialog;
      dialog.componentInstance.IsreadOnly = readOnly;
      dialog.componentInstance.Record = itemRecord;
      dialog.componentInstance.Config = this.config
      dialog.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response:any) => {
          if (response.success){
            this.refresGrid(1,this.PageSize);
          }
        });
    }
  }
  onEditRecordIcon(itemRecord:any){
    if (itemRecord !== null) {
    }
  }

  /***********************************************************************************/
  /**  En Nuevo Record se invoca al FormDinamic y se renderiza  de acuerdo a Config **/
  /***********************************************************************************/


  onNewRecord(){
    const dialog = this._notificationService._dialog.open(DinamicFormComponent, { width: this.config.form.width,height:this.config.form.height });
    dialog.componentInstance.dialogInstance = dialog;
    dialog.componentInstance.IsreadOnly = false;
    dialog.componentInstance.Record = null;
    dialog.componentInstance.Config = this.config
    dialog.afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response:any) => {
        console.log(response);
        if (response.success){
          this.refresGrid(1,this.PageSize);
        }
      });

  }
  onDeleteRecord(args:any,data:any){

  }
  onFilterData(){
    this.refresGrid(1,this.PageSize);
  }
  onShowInfo(args:any,data:any){
    this.infoRecord = <any>this.grid.getCurrentViewRecords()[data.index];
  }
  unselectRecord(){
    this.infoRecord = null;
  }
  
}