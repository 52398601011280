import { DataSource } from '@angular/cdk/table';
import { Observable, of } from 'rxjs';



export class ObservableDataSource<T> extends DataSource<T> {
    private _items: T[] = [];
    constructor(items: T[] ) {
        super();
        this._items = items;
    }

    connect(): Observable<T[]>{
        return of(this._items);
    }

    disconnect(){}
}
