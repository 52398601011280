import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Injectable, Injector, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { forkJoin, Observable, Subject } from 'rxjs';
import { DocAnexService } from '../../../services/system/doc_anex.service';
import { FileService, SweetAlertService } from '../../../../app/services';
import { APP_SETTINGS } from '../../../appsettings';
import { DocFile, refDocAnex } from '../../../models/system/doc_anex';

const URL = `${APP_SETTINGS.endpoints.image_upload_service}`;

@Component({
  selector: 'upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css']
})
export class UploadfilesComponent implements OnInit {
  @ViewChild('imagePreview') imagePreview: ElementRef;

  @Input() Api : string;
  @Input() Width : string;
  @Input() Height : string;
  @Input() MaxSize : number;

  private ObjSubjectRx$: Subject<any> = new Subject<any>();

  isLoading: boolean = false;
  isChecking: boolean = false;
  isForSell: boolean = false;
  isSubmitted = false;
  isControlEnabled = true;
  imgPlaceholder: string | ArrayBuffer = "assets/images/placeholder-image.png";

  public imagesUrls : string[] = [];
  public doc_Anex : DocFile[] = [];
  public uploader: FileUploader;
  public allowedMimeType = ['image/jpeg', 'image/png'];
  public style:string;
  public HeightImg:string;
  public response:string;
  public imageUrl: string | ArrayBuffer;
  public errorInFiles:boolean=false;
  public productImagesDeleted : number[] = [];

  constructor(
    private _filesService: FileService,
    private swalService: SweetAlertService,
    private _DocAnexService:DocAnexService) {
    this.isControlEnabled=false;

    this.style='150px;';
    this.imageUrl= this.imgPlaceholder;
  }
  ngOnInit() {
    this.HeightImg=`calc(${this.Height} - 65px)`;
    this.setupFileUpload();
  }

  ngAfterViewInit() {
  }

  setupFileUpload() {
    this.uploader = new FileUploader({
      url: URL,
      allowedMimeType: this.allowedMimeType,
      maxFileSize: this.MaxSize  //1*1024*1024 //1MB
    });

    this.uploader.onWhenAddingFileFailed = (item, filter) => {
        let message = '';
        switch (filter.name) {
          case 'fileSize':
            message = 'Warning ! \nThe uploaded file \"' + item.name + '\" is ' + this.formatBytes(item.size) + ', this exceeds the maximum allowed size of ' + this.formatBytes(1024);
            break;
          default:
            message = 'Error trying to upload file '+item.name;
            break;
        }
        this.swalService.getInstance(message, 'error').then(() => {});
    };

  }

  formatBytes(bytes, decimals?) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  loadFile(Urls:string[]){
    console.log(Urls);
    //const urls = Urls.toString().split(';');
    //urls.pop();

    const observables = Urls.map(url => this._filesService.getBlobThumbnail(url));
    const source = forkJoin(observables);
    const files: Array<File> = [];

    source.subscribe(
      response => {
        response.forEach((blob, index) => {

          const fullName = Urls[index];
          const name = fullName.split('/').pop();
          const file = new File([blob], name, { type: 'image/jpeg' });
          files.push(file);
        });
      },
      err => { },
      () => {
        this.uploader.addToQueue(files);
        console.log(files);
      }
    );

  }
  uploadFile(folder:string,refDoc?:refDocAnex,EnterpriseNumber?:number) : Observable<any>{
    const fileList = this.uploader.queue;
    //console.log(fileList);

    fileList.forEach(function (fileItem) {
      fileItem.withCredentials = false;
    });
    if (fileList.length > 0) {
      this.errorInFiles = true;
      this.uploader.setOptions({
        additionalParameter: { 
          folderName: folder,
          EnterpriseNumber : EnterpriseNumber
        }
      });
      this.uploader.uploadAll();

      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        const obj = JSON.parse(response);
        if (!obj.error) {
          //this.imagesUrls = this.imagesUrls.concat(obj.url.toLowerCase(), ';');
          this.imagesUrls.push(obj.url.toLowerCase());
          
          
          //console.log(refDoc);

          let itemAnex : DocFile = {
            Id        : refDoc.Id,
            Type      : refDoc.Type,
            SubType   : refDoc.SubType,
            Reference : refDoc.Reference,
            Guid      : refDoc.Guid,
            Filename  : item.file.name,
            FileType  : item.file.type,
            DocUrl    : obj.url.toLowerCase(),//item.url,
            Metadata  : null
          }
          this.doc_Anex.push(itemAnex);
          

          this.errorInFiles = false;

        }
      };
      this.uploader.onCompleteAll = () => {
        let response={
          success:!this.errorInFiles,
          files:this.imagesUrls,
          docAnex : this.doc_Anex
        }
        this.ObjSubjectRx$.next(response);
      }
    } else {
      let response={
        success:true,
        files:this.imagesUrls,
        docAnex : this.doc_Anex
      }
      window.setTimeout(() => {
        this.ObjSubjectRx$.next(response);
      }, 0);
    }
    return this.ObjSubjectRx$.asObservable();
  }
  public onComplete(): Observable<any>{
    return this.ObjSubjectRx$.asObservable();
  }
  
  removeFile() {
    if (this.uploader.queue.length > 0) {
      this.uploader.removeFromQueue(this.uploader.queue[0]);
    }
    this.imagePreview.nativeElement.src = this.imgPlaceholder;
    //this.getImageUrlsToDelete;
  }
  
  
  saveFiles(docAnex:any){
    this._DocAnexService.updateFiles(docAnex)
      .subscribe(response => {
        if (response != "OK") {

        }
    });
  }

  readFiles(Origen: string,Reference:any){

    this._DocAnexService.loadFiles(Origen,Reference)
      .subscribe((response:Array<DocFile>) => {
        if (response) {
          this.loadFile2(response);
        }
    });
  }
  loadFile2(Urls:DocFile[]){
    console.log(Urls);
    //const urls = Urls.split(';');
    //urls.pop();

    //const observables = urls.map(url => this._filesService.getBlobThumbnail(url));
    const observables = Urls.map(url => this._filesService.getBlobThumbnail(url.DocUrl));
    const source = forkJoin(observables);
    const files: Array<File> = [];

    source.subscribe(
      response => {
        response.forEach((blob, index) => {

          const fullName = Urls[index].DocUrl;
          //const name = Urls[index].Filename; //fullName.split('/');
          const name = fullName.split('/').pop();
          const file = new File([blob], name, { type: 'image/jpeg' });
          files.push(file);
        });
      },
      err => { },
      () => {
        this.uploader.addToQueue(files);
      }
    );

  }
}
