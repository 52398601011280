import { Component, OnInit, Input } from '@angular/core';
import { IReturn } from '../../../models';
import { NavigationService } from '../../../services/';
import { Navigation } from '../../../helpers';

@Component({
  selector: 'app-return-btn',
  templateUrl: './btn-return.component.html',
  styleUrls: []
})
export class BtnReturnComponent implements OnInit, IReturn {

  @Input() route = '';
  @Input() navigation: Navigation;

  constructor() { }

  ngOnInit() {
  }

  return() {
    this.navigation.fnPrev();
  }
}
