import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutofocusDirective } from './autofocus.directive';
import { DisableControlDirective } from './disable-control.directive';
import { MediaPreviewDirective } from './media-preview.directive';
import { AfterIfDirective } from './after-if.directive';
import { AlphanumericOnlyDirective } from './alphanumeric.directive';
import { NumericOnlyDirective } from './numeric.directive';
import { TwoDecimalsDirective } from './two-decimals.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AutofocusDirective,
    DisableControlDirective,
    MediaPreviewDirective,
    AfterIfDirective,
    AlphanumericOnlyDirective,
    NumericOnlyDirective,
    TwoDecimalsDirective
  ],
  exports: [
    AutofocusDirective,
    DisableControlDirective,
    MediaPreviewDirective,
    AfterIfDirective,
    AlphanumericOnlyDirective,
    NumericOnlyDirective,
    TwoDecimalsDirective
  ]
})
export class DirectivesModule { }
