import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import 'jquery-slimscroll';
import { SessionService, LoginService, NavigationService, DataService, InvoiceProcedence } from '../../../services';
import { smoothlyMenu } from '../../../app.helpers';
import { BaseResult, MenuItem, SortedMenuItem, Contact } from '../../../models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { APP_SETTINGS } from '../../../appsettings';

declare let jQuery: any;

@Component({
	selector: 'navigation',
	templateUrl: 'navigation.template.html'
})
export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {
	username: string;
	userRole: string;
	ngUnsubscribe = new Subject();
	parentItems: SortedMenuItem[];
    public version:string=APP_SETTINGS.endpoints.version;;
    public CompanyName:string="";

	constructor(
		private _navigationService: NavigationService,
		private _sessionService: SessionService,
		private _loginService: LoginService,
		private _dataService: DataService
	) { }

	ngOnInit() {
		const sessionInfoStored = this._sessionService.getSessionInfo();
		if (sessionInfoStored !== null && sessionInfoStored !== undefined) {
			this.userRole = sessionInfoStored.Role;
			if (this.userRole !== 'customer') {
				this.username = sessionInfoStored.Extras.name;
			} else {
				this.username = sessionInfoStored.Extras.customer_info.Name;
			}
		}
		//console.log(this._sessionService.getSessionEnterprise());
		this._sessionService.loadingObsEmp.subscribe(value => {
			window.setTimeout(() => {
				//console.log(this._sessionService.getSessionEnterprise());
        var sessionEnterprise = this._sessionService.getSessionEnterprise();
          if(sessionEnterprise)
          {
            this.CompanyName=this._sessionService.getSessionEnterprise().CompanyName;
          } else this.CompanyName="";
			  }, 300);
		});

		this._navigationService.getMenuSorted().pipe(takeUntil(this.ngUnsubscribe)).subscribe((items) => {

			this.parentItems = items;
			//debugger
			console.log(items);
			//this.parentItems=this.menuExample();
		});
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	ngAfterViewInit() {
		jQuery('#side-menu').metisMenu();

		if (jQuery('body').hasClass('fixed-sidebar')) {
			jQuery('.sidebar-collapse').slimscroll({
				height: '100%'
			});
		}
	}

	logout() {
		if (this._sessionService.isLoggedIn()) {
			this._loginService.logout().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
				(response: BaseResult) => {
          this._dataService.setIsDefaultCustomer(true);
					this.returnToLogin();
				},
				(error) => {
          this._dataService.setIsDefaultCustomer(true);
					this.returnToLogin();
				}
			);
		}
	}

	private returnToLogin() {
    this._sessionService.clearSession();
		this._navigationService.navigateToLanding();
	}

	trackById(index, item) {
		return item.id;
	}

	getItemRoute(menuItem: MenuItem): string {
		return `/app/${menuItem.Area.toLowerCase()}/${menuItem.Controller.toLowerCase()}`;
	}

	clickHome(event, newValue) {
		if (newValue === 'Home') {
      if ( APP_SETTINGS.features.enableLandingPage) {
        this.returnToLogin();
      }
			jQuery('#side-menu > li').nextAll().each(function (i, li) {
        const span = jQuery(li).find('a span.nav-label').text();
				if (span !== 'Home') {
					jQuery(li).removeClass('active');
				}
			});
		}
	}

	clearSelectedClient() {
		this._dataService.customer = new Contact();
  }

  clearSelectedEmployee() {
    this._dataService.employee = null;
    if (jQuery(document).width() < 769) {
      jQuery('body').toggleClass('mini-navbar');
      smoothlyMenu(false);
    }
  }
  menuExample():Array<SortedMenuItem> {
	let menu=[{
		"Id": 1,
		"Name": "Ventas",
		"Icon": "fa fa-shopping-cart",
		"Controller": null,
		"ParentId": null,
		"Area": null,
		"Children": [
			{
				"Id": 2,
				"Name": "Clientes",
				"Icon": null,
				"Controller": null,
				"ParentId": 1,
				"Area": "Ventas",
				"Children":[
					{
						"Id": 3,
						"Name": "Factura",
						"Icon": null,
						"Controller": "Invoices",
						"ParentId": 2,
						"Area": "reports",
						"Code": null,
						"Action": null,
						"Favorit": false,
						"IsControlEditor": false,
						"TypeProgram": 0,
						"Parameters": null,
						"Default": null,
						"Image": null
					}
				]
	
			},
			{
				"Id": 4,
				"Name": "DashBoard Admin",
				"Icon": "fa-fa-file",
				"Controller": "DashBoard",
				"ParentId": 1,
				"Area": "Clientes",
				"Code": null,
				"Action": null,
				"Favorit": false,
				"IsControlEditor": false,
				"TypeProgram": 0,
				"Parameters": null,
				"Default": null,
				"Image": null
			},
			{
				"Id": 5,
				"Name": "DashBoard User",
				"Icon": "fa-fa-file",
				"Controller": "DashBoard",
				"ParentId": 1,
				"Area": "Clientes",
				"Code": null,
				"Action": null,
				"Favorit": false,
				"IsControlEditor": false,
				"TypeProgram": 0,
				"Parameters": null,
				"Default": null,
				"Image": null
			}
		]
	}];
	return menu;
  }

}

/*

*/


