<div class="row border-bottom">
  <nav class="navbar navbar-static-top" role="navigation" style="margin-bottom:
    0">
    <div class="navbar-header"> <!--hidden-lg hidden-md hidden-sm   Aqui-->
      <a *ngIf="!isDefaultUser" class="minimalize-styl-2 btn hamburguer-menu"
        (click)="toggleNavigation()">
        <i class="ti-menu"></i>
      </a>
    </div>

    <div *ngIf="isDefaultUser" class="navbar-header">
      <!-- <div class="col-xs-12"> -->
      <div class="container-fluid">
        <img class="img-responsive btn-hover" style="margin: auto;"
          width="350px;" src="assets/images/logos/companylogo.png"
          (click)="goHome()" />
      </div>
      <!-- </div> -->
    </div>
    <ul class="nav header-top-left navbar-right">

      <li *ngIf="isLoading" class="dropdown">
        <a class="dropdown-toggle" data-toggle="dropdown">
          <i class="fa fa-cog fa-spin"   aria-hidden="true" 
            style="font-size: 16px; color: rgb(80, 95, 175);">
          </i>          
        </a>
      </li>

      <li class="dropdown">
        <a class="dropdown-toggle"
          data-toggle="dropdown">
          {{ currentLanguage?.id | uppercase}}
        </a>
        <ul class="dropdown-menu dropdown-messages animated fadeIn"
          style="width: 170px;">
          <ng-container *ngFor="let language of languages; trackBy: trackById">
            <li class="selectable" (click)="selectLanguage(language.id)">
              <div class="dropdown-language-box">
                <a class="pull-left">
                  <img alt="image" [attr.src]="language?.image">
                </a>
                <div class="media-body">
                  <p class="p-language-flag">{{ language?.name | translate}}</p>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </li>

      <li *ngIf="!isDefaultUser" class="dropdown">
        <a class="dropdown-toggle" data-toggle="dropdown">
          <i class="ti-user"></i><span class="hidden-xs hidden-sm">{{ userRole |
            translate}}</span>
        </a>
        <ul class="dropdown-menu dropdown-messages animated fadeIn"
          style="width: 170px;">
          <li><a [routerLink]="['/app/user/profile']">{{'Profile' | translate}}</a></li>
          <li class="divider"></li>
          <li><a (click)="logout()">{{'Logout' | translate}}</a></li>
        </ul>
      </li>

      <li class="dropdown" *ngIf="isCartEnabled">
        <a class="dropdown-toggle count-info p-r-md" (click)="goToCart(null)"
          data-toggle="dropdown">
          <i class="ti-shopping-cart"></i><span class="label label-yellow
            cart-counter m-l-xs">{{counter}}</span>
        </a>
        <ul class="dropdown-menu dropdown-cart dropdown-menu-right"
          style="right: auto; left: auto;"
          *ngIf="cartItems?.length> 0" id="cartItems">
          <div class="slimscroll-wrapper scroll-wrapper">
            <div class="scrollbar" id="scrollableContent">
              <div *ngFor="let item of cartItems">
                <li>
                  <div class="dropdown-messages-box">
                    <a class="pull-left">
                      <img class="img-md" src="{{item?.imageUrl | noimage}}">
                    </a>
                    <div class="media-body">
                      <p class="title-product">{{item?.Description | titlecase
                        }}</p>
                      <span class="price-product">{{item?.Price |
                        currency:'USD':'symbol':'1.2-2'}}</span>
                      <span class="pull-right quantity-product">{{'Qty' |
                        translate}}: {{item?.Quantity}}</span>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </div>
          <li>
            <div class="text-center">
              <a class="btn cart-btn btn-hover btn-block" (click)="goToCart(true)">
                <span> {{'GoToCart' | translate}}</span>
              </a>
            </div>
          </li>
        </ul>
      </li>

      <li>
        <ng-container *ngIf="isDefaultUser; then defaultUser; else
          notDefaultUser">
        </ng-container>

        <ng-template #defaultUser>
          <a (click)="login()">
            <span>{{'Login' | translate}}</span>
          </a>
        </ng-template>
        <ng-template #notDefaultUser>
          <a (click)="logout()">
            <i class="ti-power-off"></i><span class="hidden-xs hidden-sm m-l-xs">{{'Logout'
              | translate}}</span>
          </a>
        </ng-template>
      </li>
    </ul>
  </nav>
</div>
