import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { PersonnelPermit } from '../../models';
import { PersonnelResourceParameters } from '../../helpers';


@Injectable({
  providedIn: 'root'
})
export class PersonnelPermitService extends BaseService {

  constructor(injetor: Injector) {
    super(injetor);
   }

   getPersonnelPermits(params?: PersonnelResourceParameters): Observable<Array<PersonnelPermit>> {
     const url = params.SearchTerm != null ? this.getApiQuery(`RRHH/Employee/PERMIT?FROM=${params.From}&TO=${params.To}&ID_PER=${params.SearchTerm}`):this.getApiQuery(`RRHH/Employee/PERMIT?FROM=${params.From}&TO=${params.To}&ID_PER=`);
     return this._httpClient.get<Array<PersonnelPermit>>(url, {headers: this.getAuthorizationHeader()});
   }

   getPersonnelPermit(searchTerm: string) {
    const url = this.getApiQuery(`RRHH/Employee/PERMIT?ID_SOLPERM=${searchTerm}`);
    return this._httpClient.get<PersonnelPermit>(url, {headers: this.getAuthorizationHeader()});
   }
}
