import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoimagePipe } from './noimage.pipe';
import { CapitalizedPipe } from './capitalized.pipe';
import { ItemsSubtotalPipe } from './items-subtotal.pipe';
import { ItemsDiscountPipe } from './items-discount.pipe';
import { ItemsTaxPipe } from './items-tax.pipe';
import { ItemsTotalPipe } from './items-total.pipe';
import { CustomBaseCurrencyPipe } from './custom-base-currency.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    NoimagePipe,
    CapitalizedPipe,
    ItemsSubtotalPipe,
    ItemsDiscountPipe,
    ItemsTaxPipe,
    ItemsTotalPipe,
    CustomBaseCurrencyPipe
  ],
  exports: [
    NoimagePipe,
    CapitalizedPipe,
    ItemsSubtotalPipe,
    ItemsDiscountPipe,
    ItemsTaxPipe,
    ItemsTotalPipe
  ]
})
export class PipesModule { }
