import { Injectable } from '@angular/core';

import swal, { SweetAlertResult, SweetAlertOptions, SweetAlertType, } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  options: SweetAlertOptions;
  options2: SweetAlertOptions;

  constructor() {
    this.options = {
      confirmButtonColor: 'darkcyan',
      showConfirmButton: false,
      timer: 3000000
    };
    
  }

  getInstance(message?: string, type?: SweetAlertType): Promise<SweetAlertResult> {
    if (message) {
      this.options.text = message;
    }
    if (type) {
      this.options.type = type;
    }
    return swal(this.options);
  }

  wait(message?: string): Promise<SweetAlertResult> {
    this.options2=null;
    this.options2=this.options;
    if (message) {
      this.options2.html = `<i class="fa fa-cog fa-spin" aria-hidden="true"></i>` + message;
    }
    
    return swal(this.options2);
  }
  close(){
    this.options2=null;
    return swal.close();
  }
}
