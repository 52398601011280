import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CustomersService,SessionService,SweetAlertService } from '../../../../services';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { enableRipple } from '@syncfusion/ej2-base';
import { takeUntil} from 'rxjs/operators';

export interface TypeTipo{
  id:number;
  name:string;
}

export interface dateFilter{
  dateFrom  : Date;
  dateTo    : Date;
}

export interface prmsCard {
  title       : string;
  subtitle    : string;
  value       : number;
  button?     : string;
  percent?    : number;
  dateRange?  : dateFilter;
  addParams1? : any;
  addParams2? : any;
  addParams3? : any;

}

enableRipple(false);

@Component({
  selector: 'board-card',
  templateUrl: './board-card.component.html',
  styleUrls: ['./board-card.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BoardcardComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();

  @Input() data : prmsCard;

  constructor(private _formBuilder: FormBuilder){
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


}
