import { Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatafastCheckoutResponse, DatafastPaymentResponse } from '../../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatafastService {
  scriptAddress: 'https://www.datafast.com.ec/js/dfAdditionalValidations1.js';


  constructor(private _httpClient: HttpClient) {
  }

  generateCheckoutId(checkoutData: any, headerKey: string = 'Content-Type', headerValue: string = 'application/json; utf-8'): Observable<DatafastCheckoutResponse>  {

    const headers = new HttpHeaders({'Accept': 'application/json'});
    return this._httpClient.post<DatafastCheckoutResponse>(`${environment.datafastApi}/checkout.php`, checkoutData, {headers: headers});
  }

  generatePayment(resourcePath: string, headerKey: string = 'Content-Type', headerValue: string = 'application/json; utf-8'): Observable<any> {
    const headers = new HttpHeaders({'Accept': 'application/json'});
    const paymentRequest = {
      'resourcePath': resourcePath
    };
    return this._httpClient.post<DatafastPaymentResponse>(`${environment.datafastApi}/payment.php`, paymentRequest, {headers: headers});
  }
}
