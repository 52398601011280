import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { Debts } from '../../models';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DebtsService extends BaseService{

  constructor(injetor: Injector) {
    super(injetor);
   }

   getList(id: string): Observable<Debts[]> {
    let params: HttpParams = new HttpParams();

    if (id) {
      params = params.append('from',id);
      params = params.append('to',id);
    }

    const url = this.getApiQuery(`Customer/Ledger`);
    return this._httpClient.get<Debts[]>(
        url,
        { params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }


  /*
  getOveralllCustomerPortfolio(): Observable<OverallCustomerPortfolioReport[]> {
    const url = this.getApiQuery('Customer/OverallCustomerPortfolio');
    return this._httpClient.get<OverallCustomerPortfolioReport[]>(url, {headers: this.getAuthorizationHeader()});
  }
  */
}
