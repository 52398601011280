import { InvoiceItem } from './InvoiceItem';
import { IDocument } from './IDocument';
import { Contact } from './Sales/Contact';

export class DocumentBase {
    Id: string;
    Fecha: Date;
    GUID: string;
    IdCliente: string;
    Id_Vend: number;
    NombVendedor: string;
    NombCli: string;
    Nota: string;
    IdUser: string;
    TipoPrecio: number;
    DocumentItems: InvoiceItem[];

    toIDocument?(this: DocumentBase): IDocument{
        const doc: IDocument = new IDocument();
        doc.Id = this.Id;
        doc.Fecha = this.Fecha;
        doc.GUID = this.GUID;

        const customer = new Contact();
        customer.Name = this.NombCli;
        customer.RUC = this.IdCliente;
        doc.Customer = customer

        doc.Id_Vend = this.Id_Vend;
        doc.Nota = this.Nota;
        doc.IdUser = this.IdUser;
        doc.DocumentItems = this.DocumentItems;

        return doc;
    }
}
