import { ResourceParameters } from './ResourceParamteres';

export class ProductResourceParameters extends ResourceParameters {
  SearchTerm = '';
  PriceType = 1;
  WarehouseNumber = 1;
  FilterCategory = null;
  FilterSubgroup = null;
  FilterModel = null;
  FilterBrand = null;
}
