<!--
<page-heading pageTitle="{{ title | translate }}" >
  <div class="title-action  pull-right" style="padding-right: 5px;padding-left: 5px;">
    <button *ngIf="dialogInstance" type="button" class="btn btn-blue" style="padding: 6px; width: 40px;" tabindex="-1" (click)="closeDialog()">
      <i class="ti-close"></i>
    </button>
  </div>
</page-heading>
-->

  <div class="modal-header">
    <button (click)="closeDialog()" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1">
      <i class="ti-close"></i>
    </button>
    <h2>{{ title | translate}}</h2>
  </div>

  <!--height: 570px; calc(100vh - 195px)-->
  <div class="wrapper-content">
    <div id="idviewer" class="report" 
      [style.height.px]="height"
      style="overflow: scroll;">
      <ng-container *ngTemplateOutlet="currentTemplate">
    
      </ng-container>
    </div>

    <div class="modal-footer">
        <ng-container *ngTemplateOutlet="pieTemplate">

        </ng-container>
    </div>
  </div>

