import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CustomersService, SweetAlertService } from '../../../services';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { Customer } from '../../../models';
import { CustomerLocation } from '../../../models/Sales/CustomerLocation';
import { enableRipple } from '@syncfusion/ej2-base';
import { getTime } from 'ngx-bootstrap/chronos/utils/date-getters';

enableRipple(false);

@Component({
  selector: 'location-customer',
  templateUrl: './location-customer.component.html',
  styleUrls: ['./location-customer.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LocationcustomerComponent implements OnInit, OnDestroy {

  @Input() Location: CustomerLocation;// Contact;
  @Input() dialogInstance: MatDialogRef<LocationcustomerComponent, any>

  ngUnsubscribe = new Subject();

  action: string;
  titleComponent: string;

  public Form: FormGroup;

  CtrTo_Id            : FormControl = new FormControl('');
  CtrTo_City          : FormControl = new FormControl('');
  CtrTo_Name          : FormControl = new FormControl('');
  CtrTo_Address1      : FormControl = new FormControl('');
  CtrTo_Phone         : FormControl = new FormControl('');
  CtrTo_Contact       : FormControl = new FormControl('');
  CtrTo_Windows_e     : FormControl = new FormControl('');
  CtrTo_Windows_s     : FormControl = new FormControl('');
  CtrTo_Windows2_e    : FormControl = new FormControl('');
  CtrTo_Windows2_s    : FormControl = new FormControl('');
  CtrTo_Lat           : FormControl = new FormControl('');
  CtrTo_Long          : FormControl = new FormControl('');

  //public branch : Branch;

  constructor(private _formBuilder: FormBuilder,
    private _customersService: CustomersService,
    private swalService: SweetAlertService,
    private _translateService: TranslateService) {

    this.initCtr();
  }



  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.setCtr();

    let labelBtnAction='Update';
    let labelTitle='EditEstablishments';
    if (this.Location == null) {
      labelBtnAction='Register';
      labelTitle='NewEstablishments';
    }
    this._translateService.get(labelBtnAction)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: string) => {
          this.action = result;
    });
    this._translateService.get(labelTitle)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: string) => {
          this.titleComponent = result;
    });


    //this.dataBranch=[];

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onRegister() {
      //const rdata: Branch = new Branch();
      //rdata.Address='';

      //(this.grid.dataSource as object[]).unshift(rdata);
      //this.grid.refresh();
      let result: object = {
        success:true,
        record:this.getForm()
      }

      //let record3=this.getForm();
      //let we= new Date(record3.Windows_e);
      //let wt=getTime(we);
      //var hora = we.getHours() + ':' + we.getMinutes() + ':' + we.getSeconds();
      //console.log(we.toLocaleTimeString());
      //console.log(result);
      this.dialogInstance.close(result);
  }


  private cleanForm() {
    this.Form.reset('');
  }
  closeDialog() {
    let result: object = {
      success:false,
      record:null
    }
    this.dialogInstance.close(result);
  }

  private initCtr(){
    this.Form = this._formBuilder.group({
      'Id'        : this.CtrTo_Id,
      'City'      : this.CtrTo_City,
      'Name'      : this.CtrTo_Name,
      'Address1'  : this.CtrTo_Address1,
      'Phone'     : this.CtrTo_Phone,
      'Contact'   : this.CtrTo_Contact,
      'Windows_e' : this.CtrTo_Windows_e,
      'Windows_s' : this.CtrTo_Windows_s,
      'Windows2_e': this.CtrTo_Windows2_e,
      'Windows2_s': this.CtrTo_Windows2_s,
      'Lat'       : this.CtrTo_Lat,
      'Long'      : this.CtrTo_Long,
    });
    this.Form.controls['Id'].disable();

  }

  private setCtr(){
    //console.log(this.Location);
    if (this.Location.CustomerLocationID != null) {
      //https://stackoverflow.com/questions/46591254/converting-a-time-string-to-a-time-value-in-javascript
      var now = new Date();
      var nowDateTime = now.toISOString();
      var nowDate = nowDateTime.split('T')[0];

      var hmse = this.Location.Windows_e;//'01:12:33';
      var we = new Date(nowDate +' '+ hmse);

      var hmss = this.Location.Windows_s;
      var ws = new Date(nowDate +' '+ hmss);

      var hms2e = this.Location.Windows2_e;
      var w2e = new Date(nowDate +' '+ hms2e);

      var hms2s = this.Location.Windows2_s;
      var w2s = new Date(nowDate +' '+ hms2s);

      this.CtrTo_Id.setValue(this.Location.CustomerLocationID);
      this.CtrTo_City.setValue(this.Location.City);
      this.CtrTo_Name.setValue(this.Location.Name);
      this.CtrTo_Address1.setValue(this.Location.Address1);
      this.CtrTo_Phone.setValue(this.Location.Phone);
      this.CtrTo_Contact.setValue(this.Location.Contact);
      this.CtrTo_Windows_e.setValue(we);
      this.CtrTo_Windows_s.setValue(ws);
      this.CtrTo_Windows2_e.setValue(w2e);
      this.CtrTo_Windows2_s.setValue(w2s);
      this.CtrTo_Lat.setValue(this.Location.Lat);
      this.CtrTo_Long.setValue(this.Location.Long);
      //console.log(this.getForm());
    }
    else{
      let fecha = new Date();
      let time= new Date(fecha.getFullYear(),fecha.getMonth(),fecha.getDay(),0,0,0,0)
      this.CtrTo_Windows_e.setValue(time);
      this.CtrTo_Windows_s.setValue(time);
      this.CtrTo_Windows2_e.setValue(time);
      this.CtrTo_Windows2_s.setValue(time);

    }
  }
  isDate(value) {
    switch (typeof value) {
        case 'number':
            return true;
        case 'string':
            return !isNaN(Date.parse(value));
        case 'object':
            if (value instanceof Date) {
                return !isNaN(value.getTime());
            }
        default:
            return false;
    }
  }
  private getForm(): CustomerLocation {

    let we=new Date();let ws=new Date();let w2e=new Date();let w2s=new Date();
    if (this.isDate(this.CtrTo_Windows_e.value)){ we = new Date(this.CtrTo_Windows_e.value) }
    if (this.isDate(this.CtrTo_Windows_s.value)){ ws = new Date(this.CtrTo_Windows_s.value) }
    if (this.isDate(this.CtrTo_Windows2_e.value)){ w2e = new Date(this.CtrTo_Windows2_e.value) }
    if (this.isDate(this.CtrTo_Windows2_s.value)){ w2s = new Date(this.CtrTo_Windows2_s.value) }

    const record = new CustomerLocation();
      record.CustomerLocationID   = this.CtrTo_Id.value;
      record.City                 = this.CtrTo_City.value;
      record.Name                 = this.CtrTo_Name.value;
      record.Address1             = this.CtrTo_Address1.value;
      record.Phone                = this.CtrTo_Phone.value;
      record.Contact              = this.CtrTo_Contact.value;
      record.Windows_e            = we.toLocaleTimeString();
      record.Windows_s            = ws.toLocaleTimeString();
      record.Windows2_e           = w2e.toLocaleTimeString();
      record.Windows2_s           = w2s.toLocaleTimeString();
      record.Lat                  = this.CtrTo_Lat.value;
      record.Long                 = this.CtrTo_Long.value;
    return record;
  }


}
